import { IGetMeasurementExtendedResponseDto } from '../dto/Measurements/IMeasurements';
import type {
  CreateMeasurementRequestDto,
  GetMeasurementResponseDto,
  CreateMeasurementResponseDto,
  GetMeasurementExtendedResponseDto,
  UpdateMeasurementRequestDto,
  UpdateMeasurementResponseDto
} from '../dto/Measurements/Measurements'
import { ResponseApi, ResponseApiList, TypeOnError } from '../global'
import { CreateAction, CreateActionWithEndpoint, IUseBase, useInstance } from './IUseBaseElements'

export interface IUseMeasurements extends IUseBase<ResponseApiList<GetMeasurementResponseDto>> {
  add: (measurement: CreateMeasurementRequestDto) => Promise<ResponseApi<CreateMeasurementResponseDto>>;
  addMany: (measurements: CreateMeasurementRequestDto[]) => Promise<ResponseApi<CreateMeasurementResponseDto[]>>;
  // Find All By Alert actually doesnt use alerts. It's just a findAll.
  findAllByAlert: (patientId: number, deviceId: number, date1?: string, date2?: string) => Promise<ResponseApi<GetMeasurementResponseDto[]>>;
  findAllByTreatment: (treatmentId: number, date1?: string, date2?: string) => Promise<ResponseApi<GetMeasurementResponseDto[]>>;
  findAllByTreatmentExtended: (treatmentId: number) => Promise<ResponseApi<GetMeasurementExtendedResponseDto[]>>;
  findAllByPatientId: (patientId: number) => Promise<ResponseApi<IGetMeasurementExtendedResponseDto[]>>;
  findAllByReadDevice: (readDeviceId: number) => Promise<ResponseApi<GetMeasurementResponseDto[]>>;
  updateMany: (measurement: UpdateMeasurementRequestDto[]) => Promise<ResponseApi<UpdateMeasurementResponseDto[]>>;
  del: (measurementId: number) =>Promise<ResponseApi<boolean>>;
  delByReadDevice: (readDeviceId: number) =>Promise<ResponseApi<boolean>>;
}

function useMeasurements(baseURL: string, token: string, patientId?: number,
  deviceId?: number, date1?: string, date2?: string, shouldFetch?: boolean, onError?: TypeOnError): IUseMeasurements {
  let data: { patientId?: string, deviceId?: string, date1?: string, date2?: string } = {};
  if (patientId !== undefined) data.patientId = `${patientId}`;
  if (deviceId !== undefined) data.deviceId = `${deviceId}`;
  if (date1 !== undefined) data.date1 = date1;
  if (date2 !== undefined) data.date2 = date2;
  const searchParams: URLSearchParams = new URLSearchParams(data);
  let path: string = '/measurements';
  if (searchParams.toString().length > 0) path += `?${searchParams.toString()}`;

  const swrResponse: IUseBase<ResponseApiList<GetMeasurementResponseDto>> = useInstance<ResponseApiList<GetMeasurementResponseDto>>(
    baseURL,
    token,
    shouldFetch ? path : null,
    onError
  );


  const findAllByPatientId = async (patientId: number): Promise<ResponseApi<IGetMeasurementExtendedResponseDto[]>> => {
    const data: { patientId: string} = {patientId: `${patientId}`};

    const searchParams: URLSearchParams = new URLSearchParams(data);
    let path: string = '/measurements';
    if (searchParams.toString().length > 0) path += `?${searchParams.toString()}`;

    const findAllByPatientId = CreateAction<ResponseApi<IGetMeasurementExtendedResponseDto[]>, boolean, ResponseApiList<GetMeasurementResponseDto>>(
      baseURL,
      token,
      swrResponse.mutate,
      'get',
      path,
      onError
    );

    return findAllByPatientId();

  }


  const findAllByAlert = async (patientId: number, deviceId: number, date1?: string, date2?: string): Promise<ResponseApi<GetMeasurementResponseDto[]>> => {


    const searchParams: URLSearchParams = new URLSearchParams(data);
    let path = `/measurements`;
    if (searchParams.toString().length > 0) path += `?${searchParams.toString()}`;
    const action = CreateAction<ResponseApi<GetMeasurementResponseDto[]>, boolean, ResponseApiList<GetMeasurementResponseDto>>(
      baseURL,
      token,
      swrResponse.mutate,
      'get',
      path,
      onError
    );
    return action();
  };

  const add = async (measurement: CreateMeasurementRequestDto) => {
    let path: string = '/measurements';
    const action = CreateActionWithEndpoint<ResponseApi<CreateMeasurementResponseDto>, CreateMeasurementRequestDto, ResponseApiList<GetMeasurementResponseDto>>(
      baseURL,
      token,
      swrResponse.mutate,
      'post',
      onError
    );
    return action(path, measurement);
  };

  const addMany = async (measurements: CreateMeasurementRequestDto[]) => {
    let path: string = '/measurements/many';
    const action = CreateActionWithEndpoint<ResponseApi<CreateMeasurementResponseDto[]>, CreateMeasurementRequestDto[], ResponseApiList<GetMeasurementResponseDto>>(
      baseURL,
      token,
      swrResponse.mutate,
      'post',
      onError
    );
    return action(path, measurements);
  };

  const findAllByTreatment = async (treatmentId: number, date1?: string, date2?: string): Promise<ResponseApi<GetMeasurementResponseDto[]>> => {
    let data: { date1?: string, date2?: string } = {};
    if (date1 !== undefined) data.date1 = date1;
    if (date2 !== undefined) data.date2 = date2;
    const searchParams: URLSearchParams = new URLSearchParams(data);
    let path = `/measurements/treatment/${treatmentId}`;
    if (searchParams.toString().length > 0) path += `?${searchParams.toString()}`;
    const action = CreateAction<ResponseApi<GetMeasurementResponseDto[]>, boolean, ResponseApiList<GetMeasurementResponseDto>>(
      baseURL,
      token,
      swrResponse.mutate,
      'get',
      path,
      onError
    );
    return action();
  };

  const findAllByTreatmentExtended = async (treatmentId: number): Promise<ResponseApi<GetMeasurementExtendedResponseDto[]>> => {
    let path: string = `/measurements/treatment/extended/${treatmentId}`;
    const action = CreateAction<ResponseApi<GetMeasurementExtendedResponseDto[]>, boolean, ResponseApiList<GetMeasurementResponseDto>>(
      baseURL,
      token,
      swrResponse.mutate,
      'get',
      path,
      onError
    );
    return action();
  };

  const findAllByReadDevice = async (readDeviceId: number): Promise<ResponseApi<GetMeasurementResponseDto[]>> => {
    let path = `/measurements/readdevice/${readDeviceId}`;
    const action = CreateAction<ResponseApi<GetMeasurementResponseDto[]>, boolean, ResponseApiList<GetMeasurementResponseDto>>(
      baseURL,
      token,
      swrResponse.mutate,
      'get',
      path,
      onError
    );
    return action();
  };

  const updateMany = async (measurements: UpdateMeasurementRequestDto[]) => {
    let path: string = '/measurements';
    const action = CreateActionWithEndpoint<ResponseApi<UpdateMeasurementResponseDto[]>, UpdateMeasurementRequestDto[], ResponseApiList<GetMeasurementResponseDto>>(
      baseURL,
      token,
      swrResponse.mutate,
      'patch',
      onError
    );
    return action(path, measurements);
  };

  const del = async (measurementId: number): Promise<ResponseApi<boolean>> => {
    let path: string = `/measurements/${measurementId.toString()}`;
    const action = CreateAction<ResponseApi<boolean>, boolean, ResponseApiList<GetMeasurementResponseDto>>(
      baseURL,
      token,
      swrResponse.mutate,
      'delete',
      path,
      onError
    );
    return action();
  };

  const delByReadDevice = async (readDeviceId: number): Promise<ResponseApi<boolean>> => {
    let path: string = `/measurements/readdevice/${readDeviceId.toString()}`;
    const action = CreateAction<ResponseApi<boolean>, boolean, ResponseApiList<GetMeasurementResponseDto>>(
      baseURL,
      token,
      swrResponse.mutate,
      'delete',
      path,
      onError
    );
    return action();
  };


  return {
    ...swrResponse,
    add,
    addMany,
    findAllByTreatment,
    findAllByAlert,
    findAllByTreatmentExtended,
    findAllByPatientId,
    findAllByReadDevice,
    updateMany,
    del,
    delByReadDevice
  }
}

export { useMeasurements }
