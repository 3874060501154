import { Expose, Type } from 'class-transformer'
import { ValidateNested } from 'class-validator'
import { GetDeviceResponseDto } from '../Devices';
import { GetDiseaseResponseDto } from '../Diseases'
import { IPatientList } from '../IItemList';
import { GetPatientResponseDto } from '../Patient/Patient';
import {
  IAddTreatmentRequestDto, IAddTreatmentResponseDto, IGetTreatmentResponseDto,
  IUpdateTreatmentRequestDto, IUpdateTreatmentResponseDto
} from './ITreatments';

export class AddTreatmentRequestDto implements IAddTreatmentRequestDto {
  patientId: number = 0;
  diseaseId: number = 0;
  applyFrequenciesTemplates: boolean = false;
  startDate?: Date;
  endDate?: Date;
}

export class AddTreatmentResponseDto implements IAddTreatmentResponseDto {
  id: number = 0
  patientId: number = 0
  diseaseId: number = 0
  @Expose({ name: 'created_at' }) createdAt: Date = new Date()
  message: string = ''
  @ValidateNested()
  @Type(() => GetDeviceResponseDto)
  devices: GetDeviceResponseDto[] = []
  name: string = ''
}

export class ItemTreatmentResponseDto implements ItemTreatmentResponseDto {
  @Expose() id: number = 0;
  @Expose() name: string = '';
}

export class ItemTreatmentListResponseDto {
  @Expose() id: number = 0;
  @Expose() name: string = '';
  @Expose() createdAt: Date = new Date();
  @Expose() start_at?: Date = undefined;
  @Expose() closed_at?: Date = undefined;
  @Expose() deleted_at?: Date = undefined;
  @Expose() lastMeasurement?: Date = undefined;
  @Expose() disease: GetDiseaseResponseDto = new GetDiseaseResponseDto();
  @Expose() patient: GetPatientResponseDto = new GetPatientResponseDto();
  @Expose() devices: GetDeviceResponseDto[] = [];
}

export class GetTreatmentResponseDto extends ItemTreatmentResponseDto implements IGetTreatmentResponseDto {
  patientId: number = 0;
  diseaseId: number = 0;
  @Expose({ name: 'created_at' }) createdAt: Date = new Date()
  deleted_at?: Date;
  start_at?: Date;
  closed_at?: Date;
  lastMeasurement?: Date;
  alert?: Date;


  patient: IPatientList = { id: 0, name: '', externalId: '' };

  @ValidateNested()
  @Type(() => GetDiseaseResponseDto)
  disease?: GetDiseaseResponseDto;

  @ValidateNested()
  @Type(() => GetDeviceResponseDto)
  devices: GetDeviceResponseDto[] = [];
}
export class UpdateTreatmentRequestDto implements IUpdateTreatmentRequestDto {
  devices: number[] = []
}
export class UpdateTreatmentResponseDto implements IUpdateTreatmentResponseDto {
  id: number = 0;
  patientId: number = 0;
  diseaseId: number = 0;
  @Expose({ name: 'created_at' }) createdAt: Date = new Date()

  @ValidateNested()
  @Type(() => GetDiseaseResponseDto)
  disease?: GetDiseaseResponseDto

  @ValidateNested()
  @Type(() => GetDeviceResponseDto)
  devices: GetDeviceResponseDto[] = []

  message: string = ''
  name: string = '';
}