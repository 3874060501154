import { ItemAlertConfigUserResponseDto } from "../../dto/Alerts/config/user/AlertConfigUser";
import { Role } from "../../dto/User";
import { ResponseApiList, TypeOnError } from "../../global";
import { IUseBase, useInstance } from "../IUseBaseElements";

export interface IUseCompanyActorsPaginated
  extends IUseBase<ResponseApiList<ItemAlertConfigUserResponseDto>> {
}

export function useCompanyActorsPaginated(baseURL: string,
  token: string,
  companyId: number,
  query: any,
  search: string,
  role?: Role,
  onError?: TypeOnError): IUseCompanyActorsPaginated {

  let queryFilters = '';

  if (search && search.length > 0) {
    queryFilters += `search=${search}`;
  }

  if (query) {
    queryFilters += `${queryFilters === '' ? '' : '&'}limit=${query.limit}&page=${query.page}`;
  }

  const path = role ? `/companies/${companyId}/actors?role=${role}&${queryFilters}` : `/companies/${companyId}/actors?${queryFilters}`;

  const swrResponse: IUseBase<ResponseApiList<ItemAlertConfigUserResponseDto>> = useInstance<ResponseApiList<ItemAlertConfigUserResponseDto>>(
    baseURL,
    token,
    path,
    onError
  );

  return {
    ...swrResponse
  } as IUseCompanyActorsPaginated;
}