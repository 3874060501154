import React, { useState } from "react";
import { Page } from "../Page";
import {
  Select,
  Box,
  MenuItem,
  FormControl,
  InputLabel,
} from "@material-ui/core";
import { useApi } from "@doctomatic/sdk/build/Api";
import { DocumentType, UpdateLegalRequestDto } from "@doctomatic/sdk/build/dto/Legal/Legal";
import { GetCompanyResponseDto } from "@doctomatic/sdk/build/dto/Company";
import { DocumentEditor } from "./DocumentEditor";
import { toast } from 'react-toastify'
import { useNavigate } from "react-router-dom";
import { processError } from "../../../App/errorToast";

const values = Object.values(DocumentType)
const keys = Object.keys(DocumentType)
const documentType: { key: string, value: string }[] = []
for (let i = 0; i < values.length; i++) {
  documentType.push({ key: keys[i], value: values[i] })
}

interface CompaniesSelectProps {
  companyId?: string;
  setCompanyId: (id: string) => void;
}

export const CompaniesSelect = ({
  companyId = "",
  setCompanyId,
}: CompaniesSelectProps): React.ReactElement => {
  const { useCompanies, logout } = useApi();
  const history = useNavigate();
  const { response: companiesResponse } = useCompanies(true, processError(logout, history));
  const companies = companiesResponse?.data;
  if (!companies) return <></>;

  return (
    <FormControl variant="outlined" style={{ minWidth: 200, marginRight: 20 }}>
      <InputLabel id="company">{"Centro médico"}</InputLabel>
      <Select
        labelId="company"
        id="company-select"
        data-cy="company-select"
        label={"Centro médico"}
        onChange={(event) => setCompanyId(event.target.value as string)}
        value={companyId}
      >
        {companies.map((c: GetCompanyResponseDto) => (
          <MenuItem key={`c${c.id}`} data-cy={`c${c.id}`} value={c.id}>
            {c.name}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

interface TypeSelectProps {
  type?: DocumentType;
  setType: (documentType: DocumentType) => void;
}

const TypeSelect = ({ type = DocumentType.contract, setType }: TypeSelectProps): React.ReactElement => {

  return (
    <FormControl variant="outlined" style={{ minWidth: 140, marginRight: 20 }}>
      <InputLabel id="type">{"Documento"}</InputLabel>
      <Select
        labelId="type"
        id="type-select"
        data-cy="type-select"
        label={"Documento"}
        onChange={(event) => setType(event.target.value as DocumentType)}
        value={type}
      >
        {documentType.map(d => (
          <MenuItem key={`${d.key}`} data-cy={`${d.key}`} value={d.value}>
            {d.key}
            {/* TODO: d.value to translation */}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

interface LegalProps {
  company_id?: string;
  type_?: DocumentType;
}

const Legal = ({ company_id = "", type_ = DocumentType.contract }: LegalProps): React.ReactElement => {
  const history = useNavigate();
  const { useLegal, logout } = useApi();
  const { update, legal } = useLegal(true, processError(logout, history));
  const [companyId, setCompanyId] = useState<string>(company_id);
  const [type, setType] = useState<DocumentType>(type_);
  const [md, setMd] = useState<string>("");
  const [loading, setLoading] = useState(false)

  React.useEffect(() => {
    async function getTemplate() {
      setLoading(true);
      try {
        const response = (await legal(+companyId, type))
        if (response && response.data) setMd(response.data.md);
        else setMd("")
      } catch (err: any) {
        setMd("")
      }
      setLoading(false)
    }
    getTemplate();

  }, [companyId, type]);

  const filters = (
    <Box display="flex" justifyContent="flex-end" height={"100%"}>
      <CompaniesSelect companyId={companyId} setCompanyId={setCompanyId} />
      <TypeSelect type={type} setType={setType} />
    </Box>
  );

  return (
    <Page title={"Documentos legales"} buttons={filters}>
      {companyId !== '' && !loading && <DocumentEditor
        md={md}
        contract={(type === DocumentType.contract)}
        onSave={async (md: string) => {
          const dto = new UpdateLegalRequestDto();
          dto.md = md;
          try {
            const response = await update(+companyId, type, dto);
            if (response) toast.info('Guardado correctamente')
          } catch (err) {
            toast.error('Ha habido un error al guardar')
          }
        }}
      />}
    </Page>
  );
};

export { Legal };
