import { GetTreatmentResponseDto } from '../../dto/Treatment/Treatments';
import { ResponseApi, TypeOnError } from '../../global'
import { IUseBase, useInstance } from '../IUseBaseElements'

export interface IUseTreatment
  extends IUseBase<ResponseApi<GetTreatmentResponseDto>> {
}

export function useTreatment(baseURL: string, token: string, id: number, shouldFetch?: boolean, onError?: TypeOnError): IUseTreatment {

  const swrResponse: IUseBase<ResponseApi<GetTreatmentResponseDto>> = useInstance<ResponseApi<GetTreatmentResponseDto>>(
    baseURL,
    token,
    shouldFetch ? `/trackings/${id}` : null,
    onError
  );

  return {
    ...swrResponse
  } as IUseTreatment;
}
