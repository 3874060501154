import {
  IGroupPermissionDto,
  IGroupPermissionUserDto,
  IPermissionDto,
  IPermissionUserDto,
  IRequestAssignGroupPermissionDto,
  IRequestAssignPermissionDto,
  IRequestUnassignGroupPermissionDto,
  IRequestUnassignPermissionDto
} from '../dto/GroupPermission/IGroupPermission'
import { ResponseApi, ResponseApiList, TypeOnError } from '../global'
import { IResponseIdNumberDto } from '../dto/IResponseIdNumberDto'
import { CreateAction, CreateActionWithEndpoint, IUseBase, useInstance } from './IUseBaseElements'
import { GroupPermissionUserDto } from '../dto/GroupPermission/GroupPermission'

export interface IUseGroupPermission
  extends IUseBase<ResponseApiList<GroupPermissionUserDto>> {

  getGroupPermissions: () => Promise<ResponseApiList<IGroupPermissionDto>>;
  getGroupPermissionsByActor: (actorId: number) => Promise<ResponseApiList<IGroupPermissionUserDto>>;
  getPermissions: () => Promise<ResponseApiList<IPermissionDto>>;
  getPermissionsByActor: (actorId: number) => Promise<ResponseApiList<IPermissionUserDto>>;
  assignGroupPermission: (actorId: number, name_id: string | number) => Promise<ResponseApi<IResponseIdNumberDto>>;
  unassignGroupPermission: (groupPermissionId: number) => Promise<ResponseApi<boolean>>;
  assignPermission: (actorId: number, permissionId: number) => Promise<ResponseApi<IResponseIdNumberDto>>;
  unassignPermission: (permissionId: number) => Promise<ResponseApi<boolean>>;
}

type mutateType = ResponseApiList<GroupPermissionUserDto>

export function useGroupPermissions(baseURL: string, token: string, actorId: number, shouldFetch?: boolean, onError?: TypeOnError): IUseGroupPermission {

  const swrResponse: IUseBase<mutateType> = useInstance<mutateType>(
    baseURL,
    token,
    shouldFetch ? `/grouppermissions/actor/${actorId}` : null,
    onError
  );

  const getGroupPermissions = async (): Promise<ResponseApiList<IGroupPermissionDto>> => {
    let path: string = `/grouppermissions`;
    const action = CreateAction<ResponseApiList<IGroupPermissionDto>, boolean, mutateType>(
      baseURL,
      token,
      swrResponse.mutate,
      'get',
      path,
      onError
    );
    return action();
  }

  const getGroupPermissionsByActor = async (actorId: number): Promise<ResponseApiList<IGroupPermissionUserDto>> => {
    let path: string = `/grouppermissions/actor/${actorId}`;
    const action = CreateAction<ResponseApiList<IGroupPermissionUserDto>, boolean, mutateType>(
      baseURL,
      token,
      swrResponse.mutate,
      'get',
      path,
      onError
    );
    return action();
  }

  const getPermissions = async () => {
    let path: string = `/permissions`;
    const action = CreateAction<ResponseApiList<IPermissionDto>, boolean, mutateType>(
      baseURL,
      token,
      swrResponse.mutate,
      'get',
      path,
      onError
    );
    return action();
  }

  const getPermissionsByActor = async (actorId: number): Promise<ResponseApiList<IPermissionUserDto>> => {
    let path: string = `/permissions/actor/${actorId}`;
    const action = CreateAction<ResponseApiList<IPermissionUserDto>, boolean, mutateType>(
      baseURL,
      token,
      swrResponse.mutate,
      'get',
      path,
      onError
    );
    return action();
  }

  const assignGroupPermission = async (actorId: number, name_id: string | number): Promise<ResponseApi<IResponseIdNumberDto>> => {
    let path: string = '/grouppermissions/assign';
    const action = CreateActionWithEndpoint<ResponseApi<IResponseIdNumberDto>,
      IRequestAssignGroupPermissionDto,
      mutateType>(
        baseURL,
        token,
        swrResponse.mutate,
        'post',
        onError
      );
    return action(path, { name_id: name_id, actorId: actorId } as IRequestAssignGroupPermissionDto);
  }

  const unassignGroupPermission = async (userGroupPermissionId: number) => {
    let path: string = '/grouppermissions/unassign';
    const action = CreateActionWithEndpoint<ResponseApi<boolean>,
      IRequestUnassignGroupPermissionDto,
      mutateType>(
        baseURL,
        token,
        swrResponse.mutate,
        'post',
        onError
      );
    return action(path, { userGroupPermissionId: userGroupPermissionId } as IRequestUnassignGroupPermissionDto);
  }

  const assignPermission = async (actorId: number, permissionId: number): Promise<ResponseApi<IResponseIdNumberDto>> => {
    let path: string = '/permissions/assign';
    const action = CreateActionWithEndpoint<ResponseApi<IResponseIdNumberDto>,
      IRequestAssignPermissionDto,
      mutateType>(
        baseURL,
        token,
        swrResponse.mutate,
        'post',
        onError
      );
    return action(path, { permissionId: permissionId, actorId: actorId } as IRequestAssignPermissionDto);
  }

  const unassignPermission = async (userPermissionId: number): Promise<ResponseApi<boolean>> => {
    let path: string = '/permissions/unassign';
    const action = CreateActionWithEndpoint<ResponseApi<boolean>,
      IRequestUnassignPermissionDto,
      mutateType>(
        baseURL,
        token,
        swrResponse.mutate,
        'post',
        onError
      );
    return action(path, { userPermissionId: userPermissionId } as IRequestUnassignPermissionDto);

  }

  return {
    ...swrResponse,
    getGroupPermissions,
    getGroupPermissionsByActor,
    getPermissions,
    getPermissionsByActor,
    assignGroupPermission,
    unassignGroupPermission,
    assignPermission,
    unassignPermission,
  }
}
