import React from "react";
import { Typography, Container } from "@material-ui/core";
import { CMS_VERSION } from "../../version";
import useStyles from './styles'

const Footer = (): React.ReactElement => {
  const classes = useStyles()

  return (
    <Container className={classes.root}  maxWidth={false}>
      <Typography style={{margin: 10, textAlign: 'center'}}>Doctomatic CMS v{CMS_VERSION}</Typography>
    </Container>
  );
};

export { Footer };
