import { ItemAlertConfigByValueIndividualDto } from '../dto/Alerts/config/individual/AlertConfigByValueIndividual';
import { ResponseApi, ResponseApiList, TypeOnError } from '../global'
import {
  CreateAction,
  IUseBase,
  useInstance
} from './IUseBaseElements'

export interface IUseAlertConfigsByValueByPatientFromTemplate
  extends IUseBase<ResponseApiList<ItemAlertConfigByValueIndividualDto>> {
}

export function useAlertConfigsByValueByPatientFromTemplate(baseURL: string, token: string, patientId: number, query?: any, customFilter?: string, activeFilter?: string, shouldFetch?: boolean, onError?: TypeOnError): IUseAlertConfigsByValueByPatientFromTemplate {

  let path = `/patients/${patientId}/alerts_config/value_template?limit=${query.limit}&page=${query.page}`;

  if (customFilter) path += `&customFilter=${customFilter}`

  if (activeFilter) path += `&activeFilter=${activeFilter}`

  const swrResponse: IUseBase<ResponseApiList<ItemAlertConfigByValueIndividualDto>> = useInstance<ResponseApiList<ItemAlertConfigByValueIndividualDto>>(
    baseURL,
    token,
    shouldFetch ? path : null,
    onError
  );

  

  return {
    ...swrResponse,
  } as IUseAlertConfigsByValueByPatientFromTemplate;

}
