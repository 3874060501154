import { GetUserSubscriptionInfoDto } from '../dto/User'
import { ResponseApi, TypeOnError } from '../global';
import { CreateAction, IUseBase, useInstance } from './IUseBaseElements';

export interface IUseUserSubscription extends IUseBase<ResponseApi<GetUserSubscriptionInfoDto>> {
  userSubscriptionInfo: () => Promise<ResponseApi<GetUserSubscriptionInfoDto>>;
}

export function useUserSubscription(baseURL: string, token: string, shouldFetch?: boolean, onError?: TypeOnError): IUseUserSubscription {
  const swrResponse: IUseBase<ResponseApi<GetUserSubscriptionInfoDto>> = useInstance<ResponseApi<GetUserSubscriptionInfoDto>>(
    baseURL,
    token,
    shouldFetch ? `/payments/subscription/info` : null,
    onError
  );

  const userSubscriptionInfo = async () => {
    let path: string = `/payments/subscription/info`;
    const action = CreateAction<ResponseApi<GetUserSubscriptionInfoDto>, boolean, ResponseApi<GetUserSubscriptionInfoDto>>(
      baseURL,
      token,
      swrResponse.mutate,
      'get',
      path,
      onError
    );
    return action();
  };

  return { ...swrResponse, userSubscriptionInfo }
}
