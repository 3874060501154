import { Mode } from "../../dto/Images";
import {
  ReadDeviceValidateRequestDTO,
  GetValidateImageResponseDto,
} from "../../dto/ImagesValidator";
import {
  UpdateMeasurementRequestDto,
  UpdateMeasurementResponseDto,
} from "../../dto/Measurements/Measurements";
import { ResponseApi } from "../../global";
import { DataRange } from "../../utils/query/filter.interface";
import { CreateAction, IUseBase, useInstance } from "../IUseBaseElements";
import { Symbol } from "../../utils/query/query.enum";
interface Device {
  id: number;
  name: string;
}

type ReadDeviceValidate = {
  position: number;
  status: number;
};

export enum SelectOptions {
  ALL = "all",
  ONLY = "only",
  NO = "no",
}

export interface IUseImagesValidatorPaginated
  extends IUseBase<ResponseApi<any>> {
  getNameDevices: () => Promise<ResponseApi<Device[]>>;
  updateReadDeviceValidate: (
    readDeviceId: number,
    readDeviceValidate: ReadDeviceValidate[]
  ) => Promise<ResponseApi<void>>;
  updateMeasurements: (
    measurement: UpdateMeasurementRequestDto[]
  ) => Promise<ResponseApi<UpdateMeasurementResponseDto[]>>;
  updateMode: (readDeviceId: number, mode: Mode) => Promise<ResponseApi<void>>;
}

export function useImagesValidatorPaginated(
  baseURL: string,
  token: string,
  query: any,
  dateRange?: DataRange<string>,
  readDeviceRange?: DataRange<number>,
  deviceSelect?: number,
  measurementOnlyPaki?: boolean,
  paki?: SelectOptions,
  validating?: SelectOptions,
  wrongDevice?: SelectOptions,
  removed?: SelectOptions,
  mode?: SelectOptions,
  isStraight?: SelectOptions
): IUseImagesValidatorPaginated {
  const selectOptionsToBoolean = (
    selectOptions: SelectOptions
  ): boolean | undefined => {
    if (selectOptions == SelectOptions.ONLY) {
      return true;
    } else if (selectOptions == SelectOptions.NO) {
      return false;
    } else {
      return undefined;
    }
  };

  let queryFilters = "";
  let simbol = Symbol.FIRST;
  if (dateRange) {
    queryFilters += `${simbol}dateRangeStart=${dateRange.start}&dateRangeEnd=${dateRange.end}`;
    simbol = Symbol.AND;
  }
  if (readDeviceRange) {
    queryFilters += `${simbol}readDeviceRangeStart=${readDeviceRange.start}&readDeviceRangeEnd=${readDeviceRange.end}`;
    simbol = Symbol.AND;
  }
  if (deviceSelect) {
    queryFilters += `${simbol}deviceSelect=${deviceSelect}`;
    simbol = Symbol.AND;
  }
  if (measurementOnlyPaki) {
    queryFilters += `${simbol}onlyPaki=${measurementOnlyPaki}`;
    simbol = Symbol.AND;
  }
  if (paki) {
    queryFilters += `${simbol}paki=${selectOptionsToBoolean(paki)}`;
    simbol = Symbol.AND;
  }
  if (validating) {
    queryFilters += `${simbol}validating=${selectOptionsToBoolean(validating)}`;
    simbol = Symbol.AND;
  }
  if (wrongDevice) {
    queryFilters += `${simbol}wrongDevice=${selectOptionsToBoolean(
      wrongDevice
    )}`;
    simbol = Symbol.AND;
  }
  if (removed) {
    queryFilters += `${simbol}removed=${selectOptionsToBoolean(removed)}`;
    simbol = Symbol.AND;
  }
  if (mode) {
    queryFilters += `${simbol}mode=${selectOptionsToBoolean(mode)}`;
    simbol = Symbol.AND;
  }
  if (isStraight) {
    queryFilters += `${simbol}isStraight=${selectOptionsToBoolean(isStraight)}`;
    simbol = Symbol.AND;
  }

  if (query) {
    queryFilters += `${simbol}limit=${query.limit}&page=${query.page}`;
  }

  if (query.sortBy && query.sortBy[0]) {
    queryFilters += `${simbol}${queryFilters}&field=${
      query.sortBy[0]["field"]
    }&sort=${query.sortBy[0]["sort"].toUpperCase()}`;
  }

  const path = `/image_validate${queryFilters}`;

  const apiResponse: IUseBase<ResponseApi<GetValidateImageResponseDto[]>> =
    useInstance<ResponseApi<GetValidateImageResponseDto[]>>(
      baseURL,
      token,
      path
    );

  const getNameDevices = async (): Promise<ResponseApi<Device[]>> => {
    const path: string = `/image_validate/get_name_devices`;
    const action = CreateAction<
      ResponseApi<Device[]>,
      void,
      ResponseApi<GetValidateImageResponseDto[]>
    >(baseURL, token, apiResponse.mutate, "get", path);
    return action();
  };

  const updateReadDeviceValidate = async (
    readDeviceId: number,
    readDeviceValidate: ReadDeviceValidate[]
  ): Promise<ResponseApi<void>> => {
    const path: string = `/image_validate/${readDeviceId}/readDeviceValidate`;
    const action = CreateAction<
      ResponseApi<void>,
      ReadDeviceValidateRequestDTO[],
      ResponseApi<GetValidateImageResponseDto[]>
    >(baseURL, token, apiResponse.mutate, "patch", path);
    return action(readDeviceValidate);
  };

  const updateMeasurements = async (
    measurements: UpdateMeasurementRequestDto[]
  ) => {
    let path: string = "/image_validate/measurements";
    const action = CreateAction<
      ResponseApi<UpdateMeasurementResponseDto[]>,
      UpdateMeasurementRequestDto[],
      ResponseApi<GetValidateImageResponseDto[]>
    >(baseURL, token, apiResponse.mutate, "patch", path);
    return action(measurements);
  };

  const updateMode = async (
    readDeviceId: number,
    mode: Mode
  ): Promise<ResponseApi<void>> => {
    const path: string = `/image_validate/${readDeviceId}/mode`;
    const action = CreateAction<
      ResponseApi<void>,
      ReadDeviceValidateRequestDTO,
      ResponseApi<GetValidateImageResponseDto[]>
    >(baseURL, token, apiResponse.mutate, "patch", path);
    return action({ mode: mode });
  };

  return {
    ...apiResponse,
    getNameDevices,
    updateReadDeviceValidate,
    updateMeasurements,
    updateMode,
  } as IUseImagesValidatorPaginated;
}
