export enum CustomEvent {
  IMAGEMATIC_RECEPTION = "IMAGEMATIC_RECEPTION",
  NEW_READ_DEVICE = "NEW_READ_DEVICE",
  AIMATIC_ERROR = "AIMATIC_ERROR",
  AIMATIC_NO_VALUES_DETECTED = "AIMATIC_NO_VALUES_DETECTED",
  AIMATIC_INCOMPLETE_VALUES_RETURNED = "AIMATIC_INCOMPLETE_VALUES_RETURNED",
  AIMATIC_INCORRECT_VALUES_RETURNED = "AIMATIC_INCORRECT_VALUES_RETURNED",
  AIMATIC_ALL_VALUES_CORRECT = "AIMATIC_ALL_VALUES_CORRECT",
  COGNIMATIC_ERROR = "COGNIMATIC_ERROR",
  COGNIMATIC_NO_VALUES_RETURNED = "COGNIMATIC_NO_VALUES_RETURNED",
  COGNIMATIC_NO_VALUES_DUE_TO_INCOHERENCE = "COGNIMATIC_NO_VALUES_DUE_TO_INCOHERENCE",
  COGNIMATIC_INCORRECT_AND_CORRECT_VALUES_RETURNED = "COGNIMATIC_INCORRECT_AND_CORRECT_VALUES_RETURNED",
  COGNIMATIC_ALL_VALUES_CORRECT = "COGNIMATIC_ALL_VALUES_CORRECT",
  COGNIMATIC_INCOMPLETE_SIGNALS_RETURNED = "COGNIMATIC_INCOMPLETE_SIGNALS_RETURNED",
  EXTERNAL_AI_ERROR = "EXTERNAL_AI_ERROR",
  EXTERNAL_AI_NO_VALUES = "EXTERNAL_AI_NO_VALUES",
  EXTERNAL_AI_INCORRECT_VALUES = "EXTERNAL_AI_INCORRECT_VALUES",
  EXTERNAL_AI_CORRECT_VALUES = "EXTERNAL_AI_CORRECT_VALUES",
  USER_REFUSE = "USER_REFUSE",
  USER_CONFIRM = "USER_CONFIRM",
  PAKI_CREATED = "PAKI_CREATED",
  PAKI_TRANSLATED = "PAKI_TRANSLATED",
  PAKI_REMOVED = "PAKI_REMOVED",
}
