import { ItemAlertConfigByValueIndividualDto } from '../dto/Alerts/config/individual/AlertConfigByValueIndividual';
import { ResponseApi, ResponseApiList, TypeOnError } from '../global'
import {
  CreateAction,
  IUseBase,
  useInstance
} from './IUseBaseElements'

export interface IUseAlertConfigsByValueByPatient
  extends IUseBase<ResponseApiList<ItemAlertConfigByValueIndividualDto>> {
    del: (id: number) => Promise<ResponseApi<boolean>>
}

export function useAlertConfigsByValueByPatient(baseURL: string, token: string, patientId: number, query?: any, activeFilter?: string, shouldFetch?: boolean, onError?: TypeOnError): IUseAlertConfigsByValueByPatient {

  let path = `/patients/${patientId}/alerts_config/value?limit=${query.limit}&page=${query.page}`

  if (activeFilter) path += `&activeFilter=${activeFilter}`
  
  const swrResponse: IUseBase<ResponseApiList<ItemAlertConfigByValueIndividualDto>> = useInstance<ResponseApiList<ItemAlertConfigByValueIndividualDto>>(
    baseURL,
    token,
    shouldFetch ? path : null,
    onError
  );

  const del = async (id: number): Promise<ResponseApi<boolean>> => {
    let path: string = `/alerts_config/value/${id}`;
    const action = CreateAction<ResponseApi<boolean>, boolean, ResponseApiList<ItemAlertConfigByValueIndividualDto>>(
      baseURL,
      token,
      swrResponse.mutate,
      'delete',
      path,
      onError
    );
    return action();
  };

  return {
    ...swrResponse,
    del
  } as IUseAlertConfigsByValueByPatient;

}
