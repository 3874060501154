import useSWR from 'swr';
import { KeyedMutator } from 'swr/dist/types';
import {
  AddAlertConfigByNoMeasureIndividualRequestDto, 
  AddAlertConfigByNoMeasureIndividualResponseDto,
  GetAlertConfigByNoMeasureIndividualResponseDto,
  ItemAlertConfigByNoMeasureIndividualDto,
  UpdateAlertConfigByNoMeasureIndividualRequestDto,
  UpdateAlertConfigByNoMeasureIndividualResponseDto
} from '../../dto/Alerts/config/individual/AlertConfigByNoMeasureIndividual';
import { ResponseApi, ResponseApiList, TypeOnError } from '../../global';
import { CreateAction, CreateActionWithEndpoint, CreateActionWithoutMutate, IUseBase, useInstance } from '../IUseBaseElements';


type mutateAlertConfigsByNoMeasure = ResponseApiList<ItemAlertConfigByNoMeasureIndividualDto>;
export interface IUseAlertConfigsByNoMeasureIndividual
  // extends IUseBase<mutateAlertConfigsByNoMeasure> 
  {
  // mutate: KeyedMutator<ResponseApiList<ItemAlertConfigByNoMeasureIndividualDto>>,
  findOneById: (id: number) => Promise<ResponseApi<GetAlertConfigByNoMeasureIndividualResponseDto>>,
  add: (alertConfig: AddAlertConfigByNoMeasureIndividualRequestDto) => Promise<ResponseApi<AddAlertConfigByNoMeasureIndividualResponseDto>>,
  patch: (id: number, alertConfig: UpdateAlertConfigByNoMeasureIndividualRequestDto) => Promise<ResponseApi<UpdateAlertConfigByNoMeasureIndividualResponseDto>>
  del: (id: number) => Promise<ResponseApi<any>>
}

export function useAlertConfigsByNoMeasureIndividual(baseURL: string, token: string, shouldFetch?: boolean, onError?: TypeOnError): IUseAlertConfigsByNoMeasureIndividual {

  // TODO: delete - type in interface - return object
  // const swrResponse: IUseBase<mutateAlertConfigsByNoMeasure> = useInstance<mutateAlertConfigsByNoMeasure>(
  //   baseURL,
  //   token,
  //   shouldFetch ? '/alerts_config/no_measure' : null,
  //   onError
  // );

  const findOneById = async (id: number): Promise<ResponseApi<GetAlertConfigByNoMeasureIndividualResponseDto>> => {
    let path: string = `/alerts_config/no_measure/${id}`;
    const action = CreateActionWithoutMutate<ResponseApi<GetAlertConfigByNoMeasureIndividualResponseDto>, void>(
      baseURL,
      token,
      'get',
      path,
      onError
    );
    return action();
  }

  const add = async (alertConfig: AddAlertConfigByNoMeasureIndividualRequestDto): Promise<ResponseApi<AddAlertConfigByNoMeasureIndividualResponseDto>> => {
    let path: string = '/alerts_config/no_measure';
    const action = CreateActionWithoutMutate<
      ResponseApi<AddAlertConfigByNoMeasureIndividualResponseDto>,
      AddAlertConfigByNoMeasureIndividualRequestDto>(
        baseURL,
        token,
        'post',
        path,
        onError
      );
    return action(alertConfig);

  }

  const patch = async (id: number, alertConfig: UpdateAlertConfigByNoMeasureIndividualRequestDto): Promise<ResponseApi<UpdateAlertConfigByNoMeasureIndividualResponseDto>> => {
    let path: string = `/alerts_config/no_measure/${id}`;
    const action = CreateActionWithoutMutate<
      ResponseApi<UpdateAlertConfigByNoMeasureIndividualResponseDto>,
      UpdateAlertConfigByNoMeasureIndividualRequestDto>(
        baseURL,
        token,
        'patch',
        path,
        onError
      );
    return action(alertConfig);
  }

  const del = async (id: number): Promise<ResponseApi<any>> => {

    let path: string = `/alerts_config/no_measure/${id}`;
    const action = CreateActionWithoutMutate<ResponseApi<any>, void>(
      baseURL,
      token,
      'delete',
      path,
      onError
    );
    return action();
  }

  return {
    // ...swrResponse,
    findOneById,
    add,
    patch,
    del
  };
}