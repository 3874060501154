import { Type } from 'class-transformer';
import { ValidateNested } from 'class-validator';
import { GetDoctorResponseDto } from './Actors/Doctor/Doctor';
import { GetDeviceResponseDto } from './Devices';
import { GetFrequenciesTemplateResponseDto, UpdateFrequenciesTemplateRequestDto } from './FrequenciesTemplate/FrequenciesTemplate';
import { IGetFrequenciesTemplateResponseDto, IUpdateFrequenciesTemplateRequestDto } from './FrequenciesTemplate/IFrequenciesTemplate';

export interface ICreateDiseaseRequestDto {
  name: string;
  devices: number[];
  stripePriceId?: string;
}

export interface IUpdateDiseaseRequestDto {
  name?: string;
  devices?: IUpdateFrequenciesTemplateRequestDto[];
  stripePriceId?: string;
  forceFrequenciesCreation?: boolean;
}

export interface ICreateDiseaseResponseDto {
  id: number;
  name: string;
  stripePriceId: string;
  message: string;
  devices: GetDeviceResponseDto[];
}

export interface IGetDiseaseResponseDto {
  id: number;
  name: string;
  stripePriceId: string;
  doctor_owner?: GetDoctorResponseDto;
  devices: GetDeviceResponseDto[];
  frequenciesTemplates: IGetFrequenciesTemplateResponseDto[];
  forceFrequenciesCreation: boolean;
}

export interface IUpdateDiseaseResponseDto {
  id: number;
  name: string;
  stripePriceId: string;
  message: string;
  devices: GetDeviceResponseDto[];
  frequenciesTemplates: IGetFrequenciesTemplateResponseDto[];
  forceFrequenciesCreation: boolean;
}

export class CreateDiseaseRequestDto implements ICreateDiseaseRequestDto {
  name: string = '';
  devices: number[] = [];
  stripePriceId?: string;
}

export class UpdateDiseaseRequestDto implements IUpdateDiseaseRequestDto {
  name?: string;
  @ValidateNested()
  @Type(() => UpdateFrequenciesTemplateRequestDto)
  devices?: UpdateFrequenciesTemplateRequestDto[] = [];
  stripePriceId?: string;
  forceFrequenciesCreation?: boolean;
}

export class CreateDiseaseResponseDto implements ICreateDiseaseResponseDto {
  id: number = 0;
  name: string = '';
  stripePriceId: string = '';
  message: string = '';

  @ValidateNested()
  @Type(() => GetDeviceResponseDto)
  devices: GetDeviceResponseDto[] = [];
}

export class GetDiseaseResponseDto implements IGetDiseaseResponseDto{
  id: number = 0;
  name: string = '';
  stripePriceId: string = '';

  @ValidateNested()
  @Type(() => GetDoctorResponseDto)
  doctor_owner?: GetDoctorResponseDto;

  @ValidateNested()
  @Type(() => GetDeviceResponseDto)
  devices: GetDeviceResponseDto[] = [];

  @ValidateNested()
  @Type(() => GetFrequenciesTemplateResponseDto)
  frequenciesTemplates: GetFrequenciesTemplateResponseDto[] = [];

  forceFrequenciesCreation: boolean = false;
}

export class UpdateDiseaseResponseDto implements IUpdateDiseaseResponseDto{
  id: number = 0;
  name: string = '';
  stripePriceId: string = '';
  message: string = '';

  @ValidateNested()
  @Type(() => GetDeviceResponseDto)
  devices: GetDeviceResponseDto[] = [];

  @ValidateNested()
  @Type(() => GetFrequenciesTemplateResponseDto)
  frequenciesTemplates: GetFrequenciesTemplateResponseDto[] = [];

  forceFrequenciesCreation: boolean = false;
}
