import type { GetUserResponseDto } from '../dto/User'
import { ResponseApi, TypeOnError } from '../global'
import { IUseBase, useInstance } from './IUseBaseElements'

export interface IUseUser extends IUseBase<ResponseApi<GetUserResponseDto>> { }

export function useUser(baseURL: string, token: string, id: number, shouldFetch?: boolean, onError?: TypeOnError): IUseUser {

  const swrResponse: IUseBase<ResponseApi<GetUserResponseDto>> = useInstance<ResponseApi<GetUserResponseDto>>(
    baseURL,
    token,
    shouldFetch ? `/users/${id}` : null,
    onError
  );

  return {
    ...swrResponse
  }
}
