import { KeyedMutator } from 'swr/dist/types'
import type { GetAdminCompanyResponseDto } from '../dto/Actors/AdminCompany/AdminCompany'
import { ResponseApi, TypeOnError } from '../global'
import { IUseBase, useInstance } from './IUseBaseElements'

export interface IUseAdminCompany
  extends IUseBase<ResponseApi<GetAdminCompanyResponseDto>> {
  adminCompany: ResponseApi<GetAdminCompanyResponseDto> | undefined,
  mutate: KeyedMutator<ResponseApi<GetAdminCompanyResponseDto>>
}

export function useAdminCompany(baseURL: string, token: string, id: number, shouldFetch?: boolean, onError?: TypeOnError): IUseAdminCompany {
  const swrResponse: IUseBase<ResponseApi<GetAdminCompanyResponseDto>> = useInstance<ResponseApi<GetAdminCompanyResponseDto>>(
    baseURL,
    token,
    shouldFetch ? `/admin_company/${id}` : null,
    onError
  );

  return {
    ...swrResponse,
  } as IUseAdminCompany
}
