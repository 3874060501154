import { Expose, Type } from "class-transformer";
import { ValidateNested } from "class-validator";
import { IBasicItem } from "./IItemList";
import { ISignDto } from "./Sign/ISign";
import { SignDto } from "./Sign/Signs";
import { IItemFormDevice } from "./Form/IFormDevice";
import { GetFormDeviceResponse } from "./Form/Form";

export interface IGetDeviceResponseDto extends IBasicItem {
  forceTranslateImage: boolean;
  companyId: number;
  typeName: string;
  signs: ISignDto[];
  deleted_at?: Date;
  translations?: any;
  active?: boolean;
  autoRead?: boolean;
  visible?: boolean;
}

export interface IExtendedGetDeviceResponseDto extends IGetDeviceResponseDto {
  formDevice?: GetFormDeviceResponse;
}
export interface IUpdateDeviceRequestDto {
  forceTranslateImage?: boolean;
  companyId: number;
  active?: boolean;
  autoRead?: boolean;
  visible?: boolean;
}
export class GetDeviceResponseDto implements IGetDeviceResponseDto {
  id: number = 0;
  name: string = "";
  forceTranslateImage: boolean = false;
  companyId: number = 0;
  typeName: string = "";
  @ValidateNested()
  @Type(() => SignDto)
  signs: SignDto[] = [];
  description?: string = "";
  translations?: any;
  active?: boolean;
  autoRead?: boolean;
  visible?: boolean;
}

export class ExtendedGetDeviceResponseDto
  extends GetDeviceResponseDto
  implements IExtendedGetDeviceResponseDto
{
  formDevice?: GetFormDeviceResponse;
}

export class UpdateDeviceRequestDto implements IUpdateDeviceRequestDto {
  @Expose() forceTranslateImage?: boolean = false;
  @Expose() companyId: number = 0;
  @Expose() active?: boolean = true;
  @Expose() autoRead?: boolean = true;
  @Expose() visible?: boolean = true;
}

export interface ICreateDeviceRequestDto {
  name: string;
  imageUrl: string;
  forceTranslateImage: boolean;
  typeName: string;
}

export interface IGetTypeDeviceResponseDto {
  id: number;
  name: string;
  confidence: number;
  extraData: string;
}

export class GetTypeDeviceResponseDto implements IGetTypeDeviceResponseDto {
  @Expose() id: number = 0;
  @Expose() name: string = "";
  @Expose() confidence: number = 0;
  @Expose() extraData: string = "";
}
