import React, { useEffect, useState } from 'react'
import { Routes, Route, useLocation, useNavigate, Navigate } from 'react-router-dom'
import { Appbar } from '../../components/Appbar'
import { Companies } from '../../components/Pages/Company/Companies'
import { Tickets } from '../../components/Pages/Ticket'
import { Theme } from '../../components/Theme'
import { Legal } from '../../components/Pages/Legal'
import { Devices } from '../../components/Pages/Device'
import { ImageTranslations } from '../../components/Pages/ImageTranslations'
import { Role } from '@doctomatic/sdk/build/dto/User'
import jwt_decode from "jwt-decode";
import { useApi } from '@doctomatic/sdk/build/Api'
import { Ranking } from '../../components/Pages/Ranking/Ranking'
import { Status } from '../../components/Pages/Status'
import { Footer } from '../../components/Footer'
import { processError } from '../errorToast'
import { Dashboard } from '../../components/Pages/Dashboard'
import { ImageValidator } from '../../components/Pages/ImageValidator'
import { ReadDeviceViewer } from '../../components/Pages/ReadDeviceViewer'
const reactRouterToArray = require('react-router-to-array')

interface Props {
  token: string
}

const LoggedApp = ({ token }:Props): React.ReactElement => {
  const history = useNavigate();
  const { useProfile, role, logout } = useApi();
  const { response: profileResponse } = useProfile(false, true, processError(logout, history));
  const profile = profileResponse?.data;
  const [match, setMatch] = useState<boolean>(false);
  const location = useLocation();

  const superadminRoutes = (
    <Routes>
      <Route path='/companies' element={<Companies profile={profile} />} />
      <Route path='/tickets' element={<Tickets/>} />
      <Route path='/legal' element={<Legal/>} />
      <Route path='/devices' element={<Devices />} />
      <Route path='/status' element={<Status />} />
      <Route path='/dashboard' element={<Dashboard />} />
      <Route path='/read_devices_viewer' element={<ReadDeviceViewer />} />
      <Route path='/ranking' element={<Ranking />} />
      {process.env.REACT_APP_VALIDATE === 'true' && (<Route path='/validator' element={<ImageValidator />} />)}
      <Route path="*" element={<Navigate replace to="/companies" />} />
    </Routes>
  )

  const translatorRoutes = (
    <Routes>
      <Route path='/translations' element={<ImageTranslations />} />
      <Route path="*" element={<Navigate replace to="/translations" />} />
    </Routes>
  )

  useEffect(() => {
    const decoded: any = jwt_decode(token);
    const userId = typeof decoded !== 'string' ? decoded.sub : Number(decoded);
    if (profile?.id === userId) {
      setMatch(true);
      if(profile && profile.role === Role.superadmin && !reactRouterToArray(superadminRoutes).includes(location.pathname)) history('/companies', { replace: true })
      if(profile && profile.role === Role.translatorimage && !reactRouterToArray(translatorRoutes).includes(location.pathname)) history('/translations', { replace: true })
    }
  })

  if (!match || !profile) return <></>

  const isSuperadmin = role === Role.superadmin

  return (
    <Theme>
      <Appbar />
      { isSuperadmin ? superadminRoutes : translatorRoutes }
      <Footer />
    </Theme>
  )
}

export { LoggedApp }
