import { Button } from "@material-ui/core";
import { GridColumns } from "@mui/x-data-grid";
import ComanyUserDataRow from "./CompanyUserDataRow";
import { Role } from '@doctomatic/sdk/build/dto/User'
import CheckIcon from '@material-ui/icons/Check';
import ClearIcon from '@material-ui/icons/Clear';
import { PatientOnboardingOptions } from "@doctomatic/sdk/build/dto/Patient/Patient";

export const CompanyUserGridColumns = (
  columnNameName: string,
  columnNameEmail: string,
  columnNameRole: string,
  onLoginAs: (userId: number) => void,
  onLoginAsApp: (userId: number, role: string) => void,
  handleClose: () => void,
  onSendSms: (id: number, name: string, role: string) => void,
  onSendEmail: (id: number, name: string, role: string) => void,
): GridColumns<ComanyUserDataRow> => {


  return [
    {
      field: 'name',
      headerName: columnNameName,
      width: 200,
      align: 'left'
    },
    {
      field: 'email',
      headerName: columnNameEmail,
      width: 200,
      align: 'left'
    },
    {
      field: 'role',
      headerName: columnNameRole,
      width: 150,
      align: 'left'
    },
    {
      field: 'OnBoarding',
      headerName: 'OnBoarding Check',
      width: 170,
      align: 'left',
      renderCell: (params) => {
        if(params.row.role !== Role.user) return(<></>)
        return (
        <>
         { params.row.acceptedContract && params.row.acceptedConsent && params.row.hasPassword ?
            <CheckIcon /> : <ClearIcon />
         }
         {
          params.row.onBoardingReq && 
          (params.row.onBoardingReq === PatientOnboardingOptions.CompleteOnboarding ? ' (Full OnBoarding)' : ' (Simple OnBoarding)') 
         }
        </>
        )
      }
    },
    {
      field: 'action',
      headerName: 'actions',
      width: 250,
      align: 'left',
      renderCell: (params) => {
        return (
        <>
          <Button size='small' variant='contained' style={{ marginRight: 8 }} onClick={() => { handleClose(); onLoginAs(params.row.id) }}>Web</Button>
          { params.row.role !== Role.admin && <Button size='small' variant='contained' onClick={() => { handleClose(); onLoginAsApp(params.row.id, params.row.role) }}>App</Button> }
        </>
        )
      }
    },
    {
      field: 'sendSMS',
      headerName: 'On boarding SMS',
      width: 140,
      align: 'left',
      renderCell: (params) => (
        <Button
          size='small'
          variant='contained'
          onClick={() => {
            onSendSms(params.row.id, params.row.name, params.row.role);
            handleClose();
          }}
        >
          Enviar SMS
        </Button>
      )
    },
    {
      field: 'sendEmail',
      headerName: 'On boarding Email',
      width: 140,
      align: 'left',
      renderCell: (params) => (
        <Button
          size='small'
          variant='contained'
          onClick={() => {
            onSendEmail(params.row.id, params.row.name, params.row.email);
            handleClose();
          }}
        >
          Enviar Email
        </Button>
      )
    }
  ]
}