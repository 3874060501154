import React, { useEffect, useMemo, useState } from 'react'
import { useApi } from '@doctomatic/sdk/build/Api'
import { SelectModal } from '@doctomatic/components-react/build/SelectModal/SelectModal'
import { CompanyUserGridColumns } from './CompanyUserGridColumns';
import ComanyUserDataRow from './CompanyUserDataRow';
import { processError } from '../../../App/errorToast';
import { useNavigate } from 'react-router-dom';
import { IUseCompanyActors } from "@doctomatic/sdk/build/modules/CompanyActors";
import { IUseCompanyActorsPaginated } from '@doctomatic/sdk/build/modules/CompanyActors/CompanyActorsPaginated';

export interface Props {
    companyId: number,
    isOpen: boolean,
    onLoginAs: (userId: number) => void,
    onLoginAsApp: (userId: number, role: string) => void,
    onClose: () => void,
    onSendSms: (id: number, name: string, role: string) => void,
    onSendEmail: (id: number, name: string, role: string) => void,
}

const CompanyUserSelector = (props: Props): React.ReactElement => {
    const { logout, useCompanyActorsPaginated } = useApi();
    const history = useNavigate();
    const [allActors, setAllActors] = useState<any[]>([]);
    
    // declaration of hooks to handle pagination
    const [pageSize, setPageSize] = useState<number>(10);
    const [page, setPage] = useState<number>(1);

    // Store the pagination
    // Update pagination when page or pageSize changes
    const pagination = useMemo(() => {
        return {
            page: page,
            limit: pageSize,
            sortBy: []
        }
    }, [page, pageSize]);

    const { response: allActorsResponse }: IUseCompanyActorsPaginated = useCompanyActorsPaginated(props.companyId, pagination, '', undefined, processError(logout, history));
    const actorsPaginationInfo = allActorsResponse?.meta;

    useEffect(() => {
        // save all the actors in usestate actor if the response is success and length > 0
        if(allActorsResponse?.success && allActorsResponse?.data.length > 0){
            setAllActors(allActorsResponse.data);
        }    
    }, [allActorsResponse]);
    
    const companyUserGridColumns = CompanyUserGridColumns('Name', 'Email', 'Role', props.onLoginAs, props.onLoginAsApp, props.onClose, props.onSendSms, props.onSendEmail);

    const companyUsersDataRow: ComanyUserDataRow[] = allActors.map((actor) => {
        const companyUsersDataRow: ComanyUserDataRow = {
            id: actor.id,
            name: actor.name,
            email: actor.email,
            role: actor.role,
            hasPassword: actor.hasPassword, 
            acceptedContract: actor.acceptedContract, 
            acceptedConsent: actor.acceptedConsent, 
            onBoardingReq: actor.onBoardingReq, 
            getText: () => actor.name
        }
        return companyUsersDataRow
    })

    return (
      <SelectModal<ComanyUserDataRow> 
        breadcrumbItems={[]}
        permissions={[]}
        open={true}
        handleClose={props.onClose}
        item={undefined}
        columns={companyUserGridColumns}
        rows={companyUsersDataRow} 
        id={`users_list-c${props.companyId}`}
        pagination={actorsPaginationInfo}
        onPageChange={setPage}
        onPageSizeChange={setPageSize}
      />
    )
}

export { CompanyUserSelector }