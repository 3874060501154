import type {
  UpdateLegalRequestDto,
  DocumentType,
  GetLegalResponseDto,
  GetPdfUrlResponseDto,
} from "../dto/Legal/Legal";
import { ResponseApi, TypeOnError } from "../global";
import Fetcher from "./Fetcher";
import { CreateActionWithoutMutate } from "./IUseBaseElements";

export interface IUseLegal {
  legal(
    companyId: number,
    type: DocumentType
  ): Promise<ResponseApi<GetLegalResponseDto>>;
  render: (type: DocumentType, id: number) => Promise<ResponseApi<GetLegalResponseDto>>;
  pdf: (type: DocumentType, id: number) => Promise<ResponseApi<GetPdfUrlResponseDto>>;
  accept: (type: DocumentType) => Promise<ResponseApi<void>>;
  update: (
    companyId: number,
    type: DocumentType,
    dto: UpdateLegalRequestDto
  ) => Promise<ResponseApi<boolean>>;
}

export function useLegal(baseURL: string, token: string, shouldFetch?: boolean, onError?: TypeOnError): IUseLegal {

  const legal = async (companyId: number, type: DocumentType) => {
    let path: string = `/legal/${companyId}?type=${type}`;
    const action = CreateActionWithoutMutate<ResponseApi<GetLegalResponseDto>, unknown>(
      baseURL,
      token,
      'get',
      path,
      onError
    );
    return action();
  };

  const update = async (
    companyId: number,
    type: DocumentType,
    dto: UpdateLegalRequestDto
  ) => {
    let path: string = `/legal/${companyId}?type=${type}`;
    const action = CreateActionWithoutMutate<ResponseApi<boolean>, UpdateLegalRequestDto>(
      baseURL,
      token,
      'patch',
      path,
      onError
    );
    return action(dto);
  };

  const render = async (type: DocumentType, id: number) => {
    let path: string = `/legal/${id}/render?type=${type}`;
    const action = CreateActionWithoutMutate<ResponseApi<GetLegalResponseDto>, boolean>(
      baseURL,
      token,
      'get',
      path,
      onError
    );
    return action();
  };

  const pdf = async (type: DocumentType, id: number) => {
    let path: string = `/legal/${id}/pdf?type=${type}`;
    const action = CreateActionWithoutMutate<ResponseApi<GetPdfUrlResponseDto>, boolean>(
      baseURL,
      token,
      'get',
      path,
      onError
    );
    return action();
  };

  const accept = async (type: DocumentType) => {
    let path: string = `/legal/accept?type=${type}`;
    const action = CreateActionWithoutMutate<ResponseApi<void>, boolean>(
      baseURL,
      token,
      'patch',
      path,
      onError
    );
    return action();
  };

  return { legal, pdf, update, accept, render };
}
