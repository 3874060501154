import { FlexLayoutGrid } from "@doctomatic/components-react/build/DataGrid/DataGrid"
import { GridColumns } from "@mui/x-data-grid";
import { Page } from "../../Page"
import { ActiveUsersGridColumns } from "./ActiveUsersGridColumns";
import { GetActiveUsersByDayResponseDto } from "@doctomatic/sdk/build/dto/CmsDashboard/CmsDashboard";

export interface IActiveUsers {
    day: string;
    count: number;
    id: number;
}

export interface IActiveUsersGrid {
    activeUsersByDay?: GetActiveUsersByDayResponseDto[];
}

const ActiveUsersGrid = ({activeUsersByDay}:IActiveUsersGrid): React.ReactElement => {

    const activeUsersDataRows: IActiveUsers[] | undefined = activeUsersByDay?.map((
        activeUsers: GetActiveUsersByDayResponseDto,
        index: number
        ) => ({
            id: index,
            day: activeUsers.day,
            count: activeUsers.count
    }))

    const activeUsersColumns: GridColumns<IActiveUsers> = ActiveUsersGridColumns();

    return(
        <Page title={'Usuarios activos'} primaryColor="black">
            <FlexLayoutGrid
                columns={activeUsersColumns}
                rows={activeUsersDataRows ? activeUsersDataRows : []}
            ></FlexLayoutGrid>
        </Page>     
    )
}

export { ActiveUsersGrid }