import React, { useEffect, useState } from 'react'
import { TextField, DialogContentText, InputAdornment, makeStyles, Theme } from '@material-ui/core'
import { GetSignResponseDto, UpdateSignRequestDto } from '@doctomatic/sdk/build/dto/Sign/Signs'

const useStyles = makeStyles((theme: Theme) => ({
  deviceName: {
    textDecoration: 'underline',
    marginBottom: 15
  },
  adornedEnd: { '& p': { fontSize: 12 } }
}))

interface SignEditProps {
  signToEdit: GetSignResponseDto
  onEdit: (sign: UpdateSignRequestDto) => void
}

const SignEdit = ({ signToEdit, onEdit }: SignEditProps): React.ReactElement => {

  const sign = new UpdateSignRequestDto()
  sign.signId = signToEdit.id
  sign.defaultMin = signToEdit.defaultMin
  sign.defaultMax = signToEdit.defaultMax

  const [updatedSign, setUpdatedSign] = useState<UpdateSignRequestDto>(sign)
  const classes = useStyles()

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    let value: string | null = event.target.value
    if (value === '') value = null
    else if (isNaN(+value)) return;
    else if (!signToEdit.decimals) value = value.split('.')[0]
    setUpdatedSign({ ...updatedSign, [event.target.name] : value })
  }

  useEffect(() => {
    onEdit(updatedSign)
  }, [updatedSign])

  return (
    <>
        <DialogContentText style={{fontWeight: 'bolder', marginTop: '20px', marginBottom: '5px'}}>{signToEdit.name}</DialogContentText>
        
        <TextField 
        InputProps={{
          endAdornment:<InputAdornment position="end" className={classes.adornedEnd}>{signToEdit.unit}</InputAdornment>
        }}
        style={{ width: 200, marginRight: '40px' }} margin='dense' variant='outlined' name='defaultMin' label='Default Min' type='text' 
        onChange={(event) => handleInputChange(event)} value={updatedSign.defaultMin}/>
        <TextField InputProps={{
          endAdornment:<InputAdornment position="end" className={classes.adornedEnd}>{signToEdit.unit}</InputAdornment>
        }}
        style={{ width: 200}} margin='dense' variant='outlined' name='defaultMax' label='Default Max' type='text'
        onChange={(event) => handleInputChange(event)} value={updatedSign.defaultMax}/>
    </>
  )
}


export { SignEdit }


