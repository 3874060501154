import { GridColumns, GridValueGetterParams } from "@mui/x-data-grid";
import { IUsersByRole } from "./UsersByRoleGrid";

export const UsersByRoleGridColumns = (): GridColumns<IUsersByRole> => {

  return [
    {
      field: 'role',
      headerName: 'Rol',
      width: 200,
      align: 'left',
      valueGetter: (params: GridValueGetterParams) => params.row.name,
      renderCell: (params) => {
        return <p>{params.row.role}</p>
      }
    },
    {
      field: 'countUsers',
      headerName: 'Cantidad usuarios',
      width: 200,
      align:'left',
      valueGetter: (params: GridValueGetterParams) => params.row.group,
      renderCell: (params) => {
        return <p>{params.row.countUsers}</p>
      }
    }
  ];
};