import { ItemAlertByFrequencyList, LastAlertsByFrequencyPatient } from "../dto/Alerts/Alerts";
import { Role } from "../dto/User";
import { ResponseApiList, TypeOnError } from "../global";
import { IUseBase, useInstance } from "./IUseBaseElements";

export interface IUseLastAlertsByFrequencyByPatient
  extends IUseBase<ResponseApiList<ItemAlertByFrequencyList>> {
  getAlertsPatients: (
    actorId: number
  ) => Promise<ResponseApiList<ItemAlertByFrequencyList>>;
}

export function useLastAlertsByFrequencyByPatient(
  baseURL: string,
  token: string,
  actorId: number,
  actorRole: Role,
  patientId: number,
  shouldFetch?: boolean,
  query?: any,
  onError?: TypeOnError
): IUseLastAlertsByFrequencyByPatient {
  const formatActorRole = (actorRole: Role) => {
    if (actorRole === Role.user) return "patients";
    if (actorRole === Role.superadmin) return Role.superadmin;
    if (actorRole === Role.admin) return "admin_company";
    if (actorRole === Role.nurse) return "nurses";
    if (actorRole === Role.doctor) return "doctors";
    if (actorRole === Role.translatorimage) return "translators";
  };
  const roleName = formatActorRole(actorRole);

  let queryFilters = "";
  if (query) {
    queryFilters = `?limit=${query.limit}&page=${query.page}`;
    if (query.sortBy && query.sortBy[0]) {
      queryFilters = `${queryFilters}&field=${
        query.sortBy[0]["field"]
      }&sort=${query.sortBy[0]["sort"].toUpperCase()}`;
    }
  }

  const swrResponse: IUseBase<ResponseApiList<ItemAlertByFrequencyList>> =
    useInstance<ResponseApiList<ItemAlertByFrequencyList>>(
      baseURL,
      token,
      shouldFetch
        ? `/${roleName}/${actorId}/patients/${patientId}/alerts/frequency${queryFilters}`
        : null,
      onError
    );

  return {
    ...swrResponse,
  } as IUseLastAlertsByFrequencyByPatient;
}
