import { GetLocationResponseDto } from "../../Location/Location";
import {
  CreateUserRequestDto,
  CreateUserResponseDto,
  GetUserResponseDto,

  UpdateProfileRequestDto,
  UpdateProfileResponseDto,
  UpdateUserRequestDto,
  UpdateUserResponseDto,
} from "../../User";
import { ItemActorListDto } from "../ActorItemListDto";

import {   
  IAddDoctorRequestDto, 
  IAddDoctorResponseDto,
  IGetDoctorResponseDto,
  IItemDoctorListDto,
  IUpdateDoctorProfileRequestDto,
  IUpdateDoctorProfileResponseDto,
  IUpdateDoctorRequestDto,
  IUpdateDoctorResponseDto} from './IDoctor';

export class AddDoctorRequestDto
  extends CreateUserRequestDto
  implements IAddDoctorRequestDto {
    licenseNumber?: string;
  }

export class AddDoctorResponseDto
  extends CreateUserResponseDto
  implements IAddDoctorResponseDto {
    licenseNumber?: string;
  }

export class GetDoctorResponseDto
  extends GetUserResponseDto
  implements IGetDoctorResponseDto {
    licenseNumber?: string;
    ownLocation?: GetLocationResponseDto
  }
  
export class UpdateDoctorProfileRequestDto
  extends UpdateProfileRequestDto
  implements IUpdateDoctorProfileRequestDto
{
  licenseNumber?: string;
}

export class UpdateDoctorProfileResponseDto
  extends UpdateProfileResponseDto
  implements IUpdateDoctorProfileResponseDto
{
  licenseNumber?: string;
}

export class UpdateDoctorRequestDto
  extends UpdateUserRequestDto
  implements IUpdateDoctorRequestDto
{
  licenseNumber?: string;
}

export class UpdateDoctorResponseDto
  extends UpdateUserResponseDto
  implements IUpdateDoctorResponseDto
{
  licenseNumber?: string;
}

export class ItemDoctorListDto extends ItemActorListDto implements IItemDoctorListDto {
 
  locations:{id:number,name:string}[]=[];
  trackings:{id:number,name:string}[]=[];
  licenseNumber?: string;
}
