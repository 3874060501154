import { List, ListItem, ListItemIcon, ListItemText, Popover } from "@mui/material";
import CompanyDataRow from "./CompanyDataRow";
import { PopoverConfig } from "./CompanyGridColumns";
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline'
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import DashboardIcon from '@material-ui/icons/Dashboard';
import MedicalServicesIcon from '@mui/icons-material/MedicalServices';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';

interface Props {
    row: CompanyDataRow;
    popoverConf?: PopoverConfig,
}

export const CompanyActionsPopover = (props: Props) => {
    return (
        <Popover id={`m${props.row.id}`} open={props.popoverConf?.popoverId === `m${props.row.id}`} anchorEl={props.popoverConf?.anchorEl} onClose={props.popoverConf?.onClose}>
            <List>
          <ListItem button onClick={() => { props.popoverConf?.onClose(); props.popoverConf?.onEdit(props.row) }}>
            <ListItemIcon><EditOutlinedIcon /></ListItemIcon>
            <ListItemText primary={`Editar centro médico`} />
          </ListItem>

          <ListItem button onClick={() => { props.popoverConf?.onClose(); props.popoverConf?.onDelete(props.row) }}>
            <ListItemIcon><DeleteOutlineIcon /></ListItemIcon>
            <ListItemText primary={`Borrar centro médico`} />
          </ListItem>

          <ListItem button onClick={() => { props.popoverConf?.onClose(); props.popoverConf?.onAddAdmin(props.row) }}>
            <ListItemIcon><PersonAddIcon /></ListItemIcon>
            <ListItemText primary={`Añadir admin al centro médico`} />
          </ListItem>

          <ListItem button onClick={() => { props.popoverConf?.onClose(); props.popoverConf?.onLoginAs(props.row.id) }}>
            <ListItemIcon><OpenInNewIcon /></ListItemIcon>
            <ListItemText primary={`Ver listado de usuarios del centro`} />
          </ListItem>

          <ListItem button onClick={() => { props.popoverConf?.onClose(); props.popoverConf?.onDashboardSelect(props.row.id)}}>
            <ListItemIcon><DashboardIcon/></ListItemIcon>
            <ListItemText primary={`Ver Dashboard del centro médico`} />
          </ListItem>

          <ListItem button onClick={() => { props.popoverConf?.onClose(); props.popoverConf?.onDevicesSelect(props.row.id)}}>
            <ListItemIcon><MedicalServicesIcon/></ListItemIcon>
            <ListItemText primary={`Ver dispositivos del centro médico`} />
          </ListItem>

          <ListItem button onClick={() => { props.popoverConf?.onClose(); props.popoverConf?.onReadDevicesSelect(props.row.id)}}>
            <ListItemIcon><VisibilityOutlinedIcon/></ListItemIcon>
            <ListItemText primary={`Visor de lecturas del centro médico`} />
          </ListItem>
        </List>
        </Popover>);
}
