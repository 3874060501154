import React, { useEffect } from 'react'
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, withStyles, createStyles, Theme, Button } from '@material-ui/core'
import { useNavigate, useLocation } from 'react-router-dom'
import { useApi } from '@doctomatic/sdk/build/Api'
import { GetTranslateImageResponseDto } from '@doctomatic/sdk/build/dto/TranslateImage';
import moment from 'moment';
import 'moment/locale/es';
import { processError } from '../../../App/errorToast';
moment.locale('es');

const StyledTableRow = withStyles((theme: Theme) =>
  createStyles({
    root: {
      '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
      },
    },
  }),
)(TableRow);

const StyledTableCell = withStyles({
  root: {
    borderBottom: "none"
  }
})(TableCell);

interface Props {
  onEdit: (device: GetTranslateImageResponseDto) => void
}

const ImageTranslationList = ({ onEdit }: Props): React.ReactElement => {
  const history = useNavigate()
  const location = useLocation()
  const { useTranslateImages, logout } = useApi()
  const { response: translateImagesResponse } = useTranslateImages(false, true, processError(logout, history))
  const translateImages = translateImagesResponse?.data;
  useEffect(() => {
    const query = new URLSearchParams(location.search);
    const translateImageId = query.get('id');
    if (translateImageId !== null && translateImageId !== undefined && translateImages && translateImages.length > 0) {
      const imageToTranslate = translateImages.find(t => t.id === +translateImageId);
      if (imageToTranslate) onEdit(imageToTranslate);
    }
  }, [translateImages?.length])

  if (!translateImages) return <></>

  return (
    <TableContainer>
      <Table id='imageTranslations'>
        <TableHead>
          <TableRow>
            <TableCell>Imagen</TableCell>
            <TableCell>Dispositivo</TableCell>
            <TableCell>Fecha</TableCell>
            <TableCell style={{ width: '100px' }}>Acción</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {
            translateImages.map((translateImage: GetTranslateImageResponseDto) => (
              <StyledTableRow key={translateImage.id}>
                <StyledTableCell width='30%'><img style={{ width: '50%' }} src={translateImage.imageUrl} alt="" /></StyledTableCell>
                <StyledTableCell style={{ fontWeight: 'bolder', textAlign: 'left' }} >{translateImage.image?.device?.name}</StyledTableCell>
                <StyledTableCell style={{ fontWeight: 'bolder', textAlign: 'left' }} >{moment(translateImage.created_at).startOf('minutes').fromNow()}</StyledTableCell>
                <StyledTableCell><Button size='small' variant='contained' onClick={() => onEdit(translateImage)}>Traducir</Button></StyledTableCell>
              </StyledTableRow>
            ))
          }
        </TableBody>
      </Table>
    </TableContainer>
  )
}

export { ImageTranslationList }


