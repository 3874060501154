import { Page } from "../../Page";
import { GetSankeyGraphResponseData } from "@doctomatic/sdk/build/dto/CmsDashboard/CmsDashboard";
import { Box } from "@material-ui/core";
import { useEffect, useState } from "react";
import { Chart } from "react-google-charts";
import { CustomEvent } from "@doctomatic/dto/dist/eventManager/events.enum";

export interface ISankeyGraph {
  traces: GetSankeyGraphResponseData[];
}

type SankeyChartData = (
  | string
  | number
  | { type: string; role: string; p: { html: boolean } }
)[][];

export const options = {
  sankey: {
    node: {
      interactivity: true, // Habilita la interacción con los nodos
      width: 10, // Aumenta el ancho de los nodos para etiquetas largas
      nodePadding: 100, // Mayor espacio entre los nodos
      label: {
        fontName: "Arial", // Fuente de las etiquetas
        fontSize: 10, // Tamaño de fuente para etiquetas más compactas
        color: "#000", // Color de las etiquetas
        bold: false, // Desactiva negrita para hacer las etiquetas más compactas
        italic: false, // No usar itálica
      },
    },
    link: {
      colorMode: "target",
      fillOpacity: 0.5, // Hacer los enlaces ligeramente transparentes
    },
  },
  tooltip: {
    isHtml: true, // Habilita tooltips en HTML
  },
};

// ? Custom tooltip
const createTooltip = (
  source: string,
  target: string,
  value: number,
  percentageReadDevice: number,
  percentagePreviousValue: number
) => {
  return `<div style="padding: 10px; background-color: white; color: black;">
            <strong>Source:</strong> ${source.replace(/\n/g, " ")}<br>
            <strong>Target:</strong> ${target.replace(/\n/g, " ")}<br>
            <strong>Value:</strong> ${value}<br>
            <strong>Value %/new read device:</strong> ${percentageReadDevice.toFixed(2)}%<br>
            <strong>Value %/previous step:</strong> ${percentagePreviousValue.toFixed(2)}%<br>
          </div>`;
};

const splitLabel = (label: string) => {
  return label.replace(/_/g, "\n"); // Reemplaza "_" por saltos de línea
};

const SankeyGraph = ({ traces }: ISankeyGraph): React.ReactElement => {
  const [data, setData] = useState<SankeyChartData>([]);

  useEffect(() => {
    if (traces?.length > 0) {
      const initialStep = traces.filter(
        (t) => t.source === CustomEvent.NEW_READ_DEVICE
      );
      const initialStepValue = initialStep.reduce(
        (total, t) => (total += t.value),
        0
      );
      const chartData: SankeyChartData = [
        [
          "From",
          "To",
          "Weight",
          { type: "string", role: "tooltip", p: { html: true } },
        ],
      ];
      traces.forEach((item) => {
        let percentageReadDevice = 100;
        let percentagePreviousValue = 100;

        const source = splitLabel(item.source);
        const target = splitLabel(item.target);

        if (source !== CustomEvent.NEW_READ_DEVICE) {
          if (initialStepValue !== 0) {
            percentageReadDevice = (item.value / initialStepValue) * 100;
          }
          const previousStepTraces = traces.filter((t) => t.target === item.source);
          const previousStepValue = previousStepTraces.reduce(
            (total, t) => (total += t.value),
            0
          );

          if (previousStepValue !== 0) {
            percentagePreviousValue = (item.value / previousStepValue) * 100;
          }
        }
        const tooltip = createTooltip(
          item.source,
          item.target,
          item.value,
          percentageReadDevice,
          percentagePreviousValue
        );
        chartData.push([source, target, item.value, tooltip]);
      });
      setData(chartData);
    } else {
      setData([]);
    }
  }, [traces]);

  return (
    <Page title={"Gráfico sankey"} primaryColor="black">
      <Box
        component="section"
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "calc(100vh - 20px)",
          overflow: "hidden",
        }}
      >
        {traces?.length > 0 && (
          <Chart
            chartType="Sankey"
            width="100%"
            height="70%"
            data={data}
            options={options}
          />
        )}
      </Box>
    </Page>
  );
};

export { SankeyGraph };
