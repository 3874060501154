
import {  CreateReadFormRequestDto, CreateReadFormResponseDto, GetReadFormResponseDto } from '../../dto/ReadForm/ReadFormDto';
import { ResponseApi, TypeOnError } from '../../global';
import { CreateActionWithEndpoint, IUseBase, useInstance } from '../IUseBaseElements';

export interface IUseReadForm
  extends IUseBase<ResponseApi<GetReadFormResponseDto>> {
  add: (readForm: CreateReadFormRequestDto) => Promise<ResponseApi<CreateReadFormResponseDto>>;
}

type mutateType = ResponseApi<GetReadFormResponseDto>;

export function useReadForm(baseURL: string, token: string, shouldFetch?: boolean, onError?: TypeOnError): IUseReadForm {

  const swrResponse: IUseBase<ResponseApi<GetReadFormResponseDto>> = useInstance<ResponseApi<GetReadFormResponseDto>>(
    baseURL,
    token,
    shouldFetch ? `/form` : null,
    onError
  );

  const add = async (createReadFormRequestDto: CreateReadFormRequestDto): Promise<ResponseApi<CreateReadFormResponseDto>> => {
    let path: string = '/form';
    const action = CreateActionWithEndpoint<ResponseApi<CreateReadFormResponseDto>, CreateReadFormRequestDto, mutateType>(
      baseURL,
      token,
      swrResponse.mutate,
      'post',
      onError
    );
    return action(path, createReadFormRequestDto);
  };

  return {
    ...swrResponse,
    add,
  } as IUseReadForm;
}
