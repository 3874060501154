import { GetSubscriptionsInfoResponseDto } from '../dto/Company';
import { ResponseApi, TypeOnError } from '../global';
import { IUseBase, useInstance } from './IUseBaseElements';

export interface IUseCompanySubscriptions extends IUseBase<ResponseApi<GetSubscriptionsInfoResponseDto>> { }

export function useCompanySubscriptions(baseURL: string, token: string, companyId: number, shouldFetch?: boolean, onError?: TypeOnError): IUseCompanySubscriptions {
  const swrResponse: IUseBase<ResponseApi<GetSubscriptionsInfoResponseDto>> = useInstance<ResponseApi<GetSubscriptionsInfoResponseDto>>(
    baseURL,
    token,
    shouldFetch ? `/companies/${companyId}/subscriptions` : null,
    onError
  );

  return {
    ...swrResponse
  } as IUseCompanySubscriptions;
}
