import { GetUserResponseDto, IGetUserResponseDto } from "./User";
import { GetLocationResponseDto } from "./Location/Location"
import { IGetLocationResponseDto } from "./Location/ILocation";

export interface IGetLocationUserResponseDto {
  /**
   * location_user_id
   */
  id: number;
  location: IGetLocationResponseDto;
  user: IGetUserResponseDto;
  inheritance: boolean;
}

export class GetLocationUserResponseDto implements IGetLocationUserResponseDto {
  id: number = 0;
  location: GetLocationResponseDto;
  user: GetUserResponseDto;

  constructor (
    location: GetLocationResponseDto,
    user: GetUserResponseDto
  ) {
    this.location = location;
    this.user = user;
  }
  inheritance: boolean = false;
}