import type { CreateFrequencyRequestDto, UpdateFrequencyResponseDto } from '../dto/Frequencies'
import { ResponseApi, TypeOnError } from '../global'
import { CreateActionWithoutMutate } from './IUseBaseElements'

export interface IUseFrequencies {
  updateAll: (patientId: number, frequencies: CreateFrequencyRequestDto[]) => Promise<ResponseApi<UpdateFrequencyResponseDto[]>>,
  displayMessage: (deviceId: number) => Promise<ResponseApi<boolean>>
}

function useFrequencies(baseURL: string, token: string, shouldFetch?: boolean, onError?: TypeOnError): IUseFrequencies {

  const updateAll = async (patientId: number, frequencies: CreateFrequencyRequestDto[]): Promise<ResponseApi<UpdateFrequencyResponseDto[]>> => {
    let path: string = `/frequencies?patientId=${patientId}`;
    const action = CreateActionWithoutMutate<ResponseApi<UpdateFrequencyResponseDto[]>, CreateFrequencyRequestDto[]>(
      baseURL,
      token,
      'post',
      path,
      onError
    );
    return action(frequencies);
  };

  const displayMessage = async (deviceId: number): Promise<ResponseApi<boolean>> => {
    let path: string = `/frequencies/message/${deviceId}`;
    const action = CreateActionWithoutMutate<ResponseApi<boolean>, boolean>(
      baseURL,
      token,
      'get',
      path,
      onError
    );
    return action();
  };

  return { updateAll, displayMessage }
}

export { useFrequencies }
