import { Box, Modal } from "@mui/material";
import React, { useEffect, useState } from "react";
import { BreadcrumbDocto } from "../BreadcrumbDocto/BreadcrumbDocto";
import { FlexLayoutGrid } from "../DataGrid/DataGrid";
export var SelectModal = function (props) {
    var _a;
    var _b = useState(props.rows), rows = _b[0], setRows = _b[1];
    var totalWidth = 60;
    props.columns.forEach(function (element) {
        element.width ? totalWidth = totalWidth + element.width : null;
    });
    var style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: totalWidth,
        bgcolor: 'background.paper',
        boxShadow: 24,
        outline: 'none',
        paddingLeft: '20px',
        paddingRight: '20px',
        paddingBottom: '20px',
        overflowX: "auto",
        maxWidth: "80vw",
        // maxWidth: '360px',
    };
    useEffect(function () {
        setRows(props.rows);
    }, [props.rows]);
    return (React.createElement("div", null,
        React.createElement(Modal, { open: props.open, onClose: props.handleClose, "aria-labelledby": "modal-modal-title", "aria-describedby": "modal-modal-description", id: (_a = props.id) !== null && _a !== void 0 ? _a : undefined },
            React.createElement(Box, { sx: style },
                React.createElement(BreadcrumbDocto, { breadcrumbItems: props.breadcrumbItems }),
                React.createElement(FlexLayoutGrid, { columns: props.columns, rows: rows, onSelectionModelChange: props.onSelectionModelChange, pagination: props.pagination, onPageChange: props.onPageChange, onPageSizeChange: props.onPageSizeChange })))));
};
