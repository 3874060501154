import { Type } from "class-transformer";
import { ValidateNested } from "class-validator";
import { GetDeviceResponseDto } from "../Devices";
import { GetDiseaseResponseDto } from "../Diseases";
import { IGetFrequenciesTemplateResponseDto, IUpdateFrequenciesTemplateRequestDto } from "./IFrequenciesTemplate";

export class UpdateFrequenciesTemplateRequestDto implements IUpdateFrequenciesTemplateRequestDto {
  deviceId: number = 0;
  weekdays: string = '';
  min: string = '';
}

export class GetFrequenciesTemplateResponseDto implements IGetFrequenciesTemplateResponseDto {
  id: number = 0;
  @ValidateNested()
  @Type(() => GetDiseaseResponseDto)
  disease?: GetDiseaseResponseDto;
  @ValidateNested()
  @Type(() => GetDeviceResponseDto)
  device?: GetDeviceResponseDto;
  weekdays: string = '';
  min: string = '';
}
