import React from 'react'
import { Page } from '../Page'

import { useApi } from '@doctomatic/sdk/build/Api'
import { GetTicketResponseDto } from '@doctomatic/sdk/build/dto/Tickets'
import { TicketList } from './TicketList'
import { toast } from 'react-toastify'
import { processError } from '../../../App/errorToast'
import { useNavigate } from 'react-router-dom'

const Tickets = (): React.ReactElement => {
  const history = useNavigate()
  const { useTickets, logout } = useApi()
  const { patch } = useTickets(true, processError(logout, history))

  const updateTicket = async (ticket: GetTicketResponseDto, status: string) => {
    try {
      await patch(ticket.id, { status: status })
      toast.success('Ticket actualizado correctamente')
    } catch (err: any) {
      toast.error('Error al editar el estado')
    }
  }


  return (
    <Page title={'Tickets de soporte'}>
      <TicketList
        onEdit={updateTicket}
      />
    </Page>
  )
}

export { Tickets }