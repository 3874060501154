import { IItemActorListDto } from "./IActorItemListDto";

export class ItemActorListDto implements IItemActorListDto{
    id:number=0;
    name:string='';
    deletedAt?: Date = undefined;
    permissions:{id:number,name:string}[]=[];
    groupPermissions: { id: number; name: string; }[]=[];
    haveEmail:boolean = false;
    havePhone:boolean = false;
    email:string = '';
    phone:string = '';
    
  }