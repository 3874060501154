import FormData from "form-data";
import { CreateImageOldResponseDto } from "../dto/Images";
import { ResponseApi, TypeOnError } from "../global";
import { CreateActionWithoutMutate } from "./IUseBaseElements";

export interface IUseImages {
  create: (
    deviceId: number,
    formData: FormData,
    patientId?: number,
    description?: string
    // TO BE REPLACED WITH CreateImageResponseDto ON 4.0.0
  ) => Promise<ResponseApi<CreateImageOldResponseDto>>;
  confirm: (
    imageId: number,
    patientId: number
  ) => Promise<ResponseApi<boolean>>;
  refuse: (imageId: number, patientId: number) => Promise<ResponseApi<boolean>>;
  getUrl: (
    imageId: number,
    measurementId?: number
  ) => Promise<ResponseApi<string>>;
}

export function useImages(
  baseURL: string,
  token: string,
  shouldFetch?: boolean,
  onError?: TypeOnError
): IUseImages {
  const create = async (
    deviceId: number,
    formData: FormData,
    patientId?: number,
    description?: string
  ) => {
    let path = `/images?deviceId=${deviceId}`;
    if (patientId) path += `&patientId=${patientId}`;
    if (description) path += `&description=${description}`;
    // TO BE REPLACED WITH CreateImageResponseDto ON 4.0.0
    const action = CreateActionWithoutMutate<
      ResponseApi<CreateImageOldResponseDto>,
      FormData
    >(baseURL, token, "post", path, onError);
    return action(formData);
  };

  const confirm = async (imageId: number, patientId: number) => {
    let path = `/images/confirm/${imageId}?patientId=${patientId}`;
    const action = CreateActionWithoutMutate<ResponseApi<boolean>, boolean>(
      baseURL,
      token,
      "post",
      path,
      onError
    );
    return action();
  };

  const refuse = async (imageId: number, patientId: number) => {
    let path = `/images/refuse/${imageId}?patientId=${patientId}`;
    const action = CreateActionWithoutMutate<ResponseApi<boolean>, boolean>(
      baseURL,
      token,
      "post",
      path,
      onError
    );
    return action();
  };

  const getUrl = async (imageId: number, measurementId?: number) => {
    let path = `/images/${imageId}`;
    if (measurementId) path += `?measurementId=${measurementId}`;
    const action = CreateActionWithoutMutate<ResponseApi<string>, FormData>(
      baseURL,
      token,
      "get",
      path,
      onError
    );
    return action();
  };

  return { create, confirm, refuse, getUrl };
}
