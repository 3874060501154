import { GetMeasurementResponseDto } from '../dto/Measurements/Measurements';
import { ResponseApiList, TypeOnError } from '../global';
import { CreateAction, IUseBase, useInstance } from './IUseBaseElements';

export interface IUseMeasurementsByAlert
  extends IUseBase<ResponseApiList<GetMeasurementResponseDto>> {
    findAll: (patientId: number, deviceId: number, date1?: string, date2?: string) => Promise<ResponseApiList<GetMeasurementResponseDto>>,
}

export function useMeasurementsByAlert(baseURL: string, token: string, patientId: number, deviceId: number, date1?: string, date2?: string, shouldFetch?: boolean, onError?: TypeOnError): IUseMeasurementsByAlert {
  const data: { patientId: string, deviceId: string, date1?: string, date2?: string } = {
    patientId: `${patientId}`,
    deviceId: `${deviceId}`
  };
  if (date1 !== undefined) data.date1 = date1;
  if (date2 !== undefined) data.date2 = date2;
  const searchParams: URLSearchParams = new URLSearchParams(data);
  let path = `/measurements`;
  if (searchParams.toString().length > 0) path += `?${searchParams.toString()}`;
  const swrResponse: IUseBase<ResponseApiList<GetMeasurementResponseDto>> = useInstance<ResponseApiList<GetMeasurementResponseDto>>(
    baseURL,
    token,
    shouldFetch ? path : null,
    onError
  );

  const findAll = async (patientId: number, deviceId: number, date1?: string, date2?: string) => {
    const data: { patientId: string, deviceId: string, date1?: string, date2?: string } = {
      patientId: `${patientId}`,
      deviceId: `${deviceId}`
    };
    if (date1 !== undefined) data.date1 = date1;
    if (date2 !== undefined) data.date2 = date2;
    const searchParams: URLSearchParams = new URLSearchParams(data);
    let path = `/measurements`;
    if (searchParams.toString().length > 0) path += `?${searchParams.toString()}`;
    const action = CreateAction<ResponseApiList<GetMeasurementResponseDto>, void, ResponseApiList<GetMeasurementResponseDto>>(
      baseURL,
      token,
      swrResponse.mutate,
      'get',
      path,
      onError
    );
    return action();
  }

  return {
    ...swrResponse,
    findAll,
  } as IUseMeasurementsByAlert;
}
