
import { CreateReadSixMWTRequestDto, CreateReadSixMWTResponseDto, GetReadSixMWTResponseDto } from '../../dto/ReadSixMWT/ReadSixMWTDto';
import { ResponseApi, TypeOnError } from '../../global';
import { CreateActionWithEndpoint, CreateActionWithoutMutate, IUseBase, useInstance } from '../IUseBaseElements';

export interface IUseReadSixMWT
  extends IUseBase<ResponseApi<GetReadSixMWTResponseDto>> {
  add: (readSixMWT: CreateReadSixMWTRequestDto) => Promise<ResponseApi<CreateReadSixMWTResponseDto>>;
}

export function useReadSixMWT(baseURL: string, token: string, shouldFetch?: boolean, onError?: TypeOnError): IUseReadSixMWT {

  const add = async (createReadSixMWTRequestDto: CreateReadSixMWTRequestDto): Promise<ResponseApi<CreateReadSixMWTResponseDto>> => {
    let path: string = '/six_mwt';
    const action = CreateActionWithoutMutate<ResponseApi<CreateReadSixMWTResponseDto>, CreateReadSixMWTRequestDto>(
      baseURL,
      token,
      'post',
      path,
      onError
    );
    return action(createReadSixMWTRequestDto);
  };

  return {
    add,
  } as IUseReadSixMWT;
}
