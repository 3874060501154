import { CreateExternalReferenceRequestDto, CreateExternalReferenceResponseDto, GetExternalReferenceResponseDto, UpdateExternalReferenceRequestDto, UpdateExternalReferenceResponseDto } from "../../dto/ExternalReferences/ExternalReferences";
import { ResponseApiList, ResponseApi, TypeOnError } from "../../global";
import { CreateAction, CreateActionWithEndpoint, IUseBase, useInstance } from "../IUseBaseElements";

export interface IUseExternalReferences
  extends IUseBase<ResponseApiList<GetExternalReferenceResponseDto>> {
  add: (exernalReference: CreateExternalReferenceRequestDto) => Promise<ResponseApi<CreateExternalReferenceResponseDto>>;
  patch: (id: number, exernalReference: UpdateExternalReferenceRequestDto) => Promise<ResponseApi<UpdateExternalReferenceResponseDto>>;
  del: (id: number) => Promise<ResponseApi<boolean>>;
}

export function useExternalReferences(baseURL: string, token: string, shouldFetch?: boolean, onError?: TypeOnError): IUseExternalReferences {

  const swrResponse: IUseBase<ResponseApiList<GetExternalReferenceResponseDto>> = useInstance<ResponseApiList<GetExternalReferenceResponseDto>>(
    baseURL,
    token,
    shouldFetch ? `/externalreference` : null,
    onError
  );

  const add = async (exernalReference: CreateExternalReferenceRequestDto) => {
    const path: string = '/externalreference';
    const action = CreateActionWithEndpoint<ResponseApi<CreateExternalReferenceResponseDto>, CreateExternalReferenceRequestDto, ResponseApiList<GetExternalReferenceResponseDto>>(
      baseURL,
      token,
      swrResponse.mutate,
      'post',
      onError
    );
    return action(path, exernalReference);
  };

  const patch = async (id: number, exernalReference: UpdateExternalReferenceRequestDto) => {
    let path: string = `/externalreference/${id}`;
    const action = CreateActionWithEndpoint<ResponseApi<UpdateExternalReferenceResponseDto>, UpdateExternalReferenceRequestDto, ResponseApiList<GetExternalReferenceResponseDto>>(
      baseURL,
      token,
      swrResponse.mutate,
      'patch',
      onError
    );
    return action(path, exernalReference);
  };

  const del = async (id: number): Promise<ResponseApi<boolean>> => {
    let path: string = `/externalreference/${id}`;
    const action = CreateAction<ResponseApi<boolean>, boolean, ResponseApiList<GetExternalReferenceResponseDto>>(
      baseURL,
      token,
      swrResponse.mutate,
      'delete',
      path,
      onError
    );
    return action();
  };

  return {
    ...swrResponse,
    add,
    patch,
    del,
  } as IUseExternalReferences;
}
