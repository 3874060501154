import {
  GetPatientResponseDto,
  AddPatientRequestDto,
  UpdatePatientRequestDto,
  UpdatePatientResponseDto,
  AddPatientResponseDto,
  ItemPatientListExtendedResponseDto,
} from "../dto/Patient/Patient";
import { ResponseApi, ResponseApiList, TypeOnError } from "../global";
import { Filter } from "../utils/pagination/Filter";
import { formatPaginationFilters } from "../utils/pagination/formatFunctions";
import { CreateAction, IUseBase, useInstance } from "./IUseBaseElements";

export type mutateTypePatientsByActor =
  ResponseApiList<ItemPatientListExtendedResponseDto>;

export interface IUsePatientsByActor
  extends IUseBase<mutateTypePatientsByActor> {
  patch: (
    id: number,
    patient: UpdatePatientRequestDto
  ) => Promise<ResponseApi<UpdatePatientResponseDto>>;
  add: (
    patient: AddPatientRequestDto
  ) => Promise<ResponseApi<AddPatientResponseDto>>;
  del: (id: number) => Promise<ResponseApi<boolean>>;
  restore: (id: number) => Promise<ResponseApi<boolean>>;
  get: (id: number) => Promise<ResponseApi<GetPatientResponseDto>>;
}

export function usePatientsByActor(
  baseURL: string,
  token: string,
  actorId: number,
  role: "doctors" | "nurses" | "admin_company",
  query: any,
  search: string,
  filters: Filter[],
  showDeleted: boolean,
  removeFinishedTrackings: boolean = false,
  shouldFetch?: boolean,
  onError?: TypeOnError
): IUsePatientsByActor {
  let queryFilters = "";
  if (search && search.length > 0) {
    queryFilters += `&search=${search}`;
  }

  const filtersFormatted = formatPaginationFilters(filters);
  queryFilters += filtersFormatted;

  if (query.sortBy && query.sortBy[0]) {
    queryFilters += `&${queryFilters}field=${
      query.sortBy[0]["field"]
    }&sort=${query.sortBy[0]["sort"].toUpperCase()}`;
  }

  if (query) {
    queryFilters += `&limit=${query.limit}&page=${query.page}`;
  }

  const swrResponse: IUseBase<mutateTypePatientsByActor> =
    useInstance<mutateTypePatientsByActor>(
      baseURL,
      token,
      shouldFetch
        ? `/${role}/${actorId}/patients?showDeleted=${showDeleted}&removeFinishedTrackings=${removeFinishedTrackings}${queryFilters}`
        : null,
      onError
    );

  const patch = async (
    id: number,
    patient: UpdatePatientRequestDto
  ): Promise<ResponseApi<UpdatePatientResponseDto>> => {
    const path: string = `/patients/${id}`;
    const action = CreateAction<
      ResponseApi<UpdatePatientResponseDto>,
      UpdatePatientRequestDto,
      mutateTypePatientsByActor
    >(baseURL, token, swrResponse.mutate, "patch", path, onError);
    return action(patient);
  };

  const add = async (
    patient: AddPatientRequestDto
  ): Promise<ResponseApi<AddPatientResponseDto>> => {
    const path: string = "/patients";
    const action = CreateAction<
      ResponseApi<AddPatientResponseDto>,
      AddPatientRequestDto,
      mutateTypePatientsByActor
    >(baseURL, token, swrResponse.mutate, "post", path, onError);
    return action(patient);
  };

  const del = async (id: number): Promise<ResponseApi<boolean>> => {
    const path: string = `/patients/${id}`;
    const action = CreateAction<
      ResponseApi<boolean>,
      void,
      mutateTypePatientsByActor
    >(baseURL, token, swrResponse.mutate, "delete", path, onError);
    return action();
  };

  const restore = async (id: number): Promise<ResponseApi<boolean>> => {
    const path: string = `/patients/${id}/restore`;
    const action = CreateAction<
      ResponseApi<boolean>,
      void,
      mutateTypePatientsByActor
    >(baseURL, token, swrResponse.mutate, "post", path, onError);
    return action();
  };

  const get = async (
    id: number
  ): Promise<ResponseApi<GetPatientResponseDto>> => {
    try {
      throw Error("not implemented");
    } catch (error: any) {
      console.log(error);
      onError && onError(error, "", "");
      throw error;
    }
  };

  return {
    ...swrResponse,
    patch,
    add,
    del,
    restore,
    get,
  } as IUsePatientsByActor;
}
