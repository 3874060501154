
/**
 * endpoint for kafka_consumer socket messages
 */
export const RESOURCE_ENDPOINT = "message"


export const ENDPOINT_COGNIMATIC_DETECT = "cognimatic.detec";

export const SING_UNIT_NOT_SHOW = ['text', 'foot with pain', undefined, 'pain level', 'question', 'image']

export const MAX_IMAGE_SIZE = 1 * 1024 * 1024; //Se quiere un mega de tamaño

export const MB_CONVERSION_RATE = 1024 * 1024;