import { makeStyles, Theme } from '@material-ui/core/styles'

export default makeStyles((theme: Theme) => ({
  link: {
    color: 'inherit',
    textDecoration: 'none',
    marginRight: theme.spacing(3),
    '&.active': {
      textDecoration: 'underline'
    }
  },
  homeLink: {
    color: 'inherit',
    textDecoration: 'none'
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
  appBarLinksMobile: {
    [theme.breakpoints.down("sm")]: {
      display: 'none'
    },
    display: 'flex',
    alignItems: 'center',
    width: '100%'
  },
  menuLinks: {
    [theme.breakpoints.up("sm")]: {
      display: 'none'
    },
    [theme.breakpoints.down("sm")]: {
      display: 'flex'
    }
  },
  toolbarMobile: {
    [theme.breakpoints.down("sm")]: {
      display: 'flex',
      justifyContent: 'space-between'
    }
  }
}))
