import { KeyedMutator } from 'swr/dist/types';
import {
  AddAlertConfigByNoMeasureTemplateRequestDto,
  AddAlertConfigByNoMeasureTemplateResponseDto,
  GetAlertConfigByNoMeasureTemplateResponseDto,
  ItemAlertConfigByNoMeasureTemplateDto,
  UpdateAlertConfigByNoMeasureTemplateRequestDto,
  UpdateAlertConfigByNoMeasureTemplateResponseDto
} from '../../dto/Alerts/config/template/AlertConfigByNoMeasureTemplate';
import { ResponseApi, ResponseApiList, TypeOnError } from '../../global';
import { CreateAction, CreateActionWithEndpoint, IUseBase, useInstance } from '../IUseBaseElements';


type mutateAlertConfigsByNoMeasure = ResponseApiList<ItemAlertConfigByNoMeasureTemplateDto>;
export interface IUseAlertConfigsByNoMeasureTemplate
  extends IUseBase<mutateAlertConfigsByNoMeasure> {
  mutate: KeyedMutator<ResponseApiList<ItemAlertConfigByNoMeasureTemplateDto>>,
  findOneById: (id: number) => Promise<ResponseApi<GetAlertConfigByNoMeasureTemplateResponseDto>>,
  add: (alertConfig: AddAlertConfigByNoMeasureTemplateRequestDto) => Promise<ResponseApi<AddAlertConfigByNoMeasureTemplateResponseDto>>,
  patch: (id: number, alertConfig: UpdateAlertConfigByNoMeasureTemplateRequestDto) => Promise<ResponseApi<UpdateAlertConfigByNoMeasureTemplateResponseDto>>
  del: (id: number) => Promise<ResponseApi<any>>
}

export function useAlertConfigsByNoMeasureTemplate(baseURL: string, token: string, query?: any, shouldFetch?: boolean, onError?: TypeOnError): IUseAlertConfigsByNoMeasureTemplate {
  let path = '/alert_config_templates/no_measure';
  if(query) path = path + `?limit=${query.limit}&page=${query.page}`

  const swrResponse: IUseBase<mutateAlertConfigsByNoMeasure> = useInstance<mutateAlertConfigsByNoMeasure>(
    baseURL,
    token,
    shouldFetch ? path : null,
    onError
  );

  const findOneById = async (id: number): Promise<ResponseApi<GetAlertConfigByNoMeasureTemplateResponseDto>> => {
    let path: string = `/alert_config_templates/no_measure/${id}`;
    const action = CreateAction<ResponseApi<GetAlertConfigByNoMeasureTemplateResponseDto>, void, mutateAlertConfigsByNoMeasure>(
      baseURL,
      token,
      swrResponse.mutate,
      'get',
      path,
      onError
    );
    return action();

  }

  const add = async (alertConfig: AddAlertConfigByNoMeasureTemplateRequestDto): Promise<ResponseApi<AddAlertConfigByNoMeasureTemplateResponseDto>> => {
    let path: string = '/alert_config_templates/no_measure';
    const action = CreateActionWithEndpoint<
      ResponseApi<AddAlertConfigByNoMeasureTemplateResponseDto>,
      AddAlertConfigByNoMeasureTemplateRequestDto,
      mutateAlertConfigsByNoMeasure>(
        baseURL,
        token,
        swrResponse.mutate,
        'post',
        onError
      );
    return action(path, alertConfig);

  }

  const patch = async (id: number, alertConfig: UpdateAlertConfigByNoMeasureTemplateRequestDto): Promise<ResponseApi<UpdateAlertConfigByNoMeasureTemplateResponseDto>> => {
    let path: string = `/alert_config_templates/no_measure/${id}`;
    const action = CreateActionWithEndpoint<
      ResponseApi<UpdateAlertConfigByNoMeasureTemplateResponseDto>,
      UpdateAlertConfigByNoMeasureTemplateRequestDto,
      mutateAlertConfigsByNoMeasure>(
        baseURL,
        token,
        swrResponse.mutate,
        'patch',
        onError
      );
    return action(path, alertConfig);
  }

  const del = async (id: number): Promise<ResponseApi<any>> => {

    let path: string = `/alert_config_templates/no_measure/${id}`;
    const action = CreateAction<ResponseApi<any>, void, mutateAlertConfigsByNoMeasure>(
      baseURL,
      token,
      swrResponse.mutate,
      'delete',
      path,
      onError
    );
    return action();
  }

  return {
    ...swrResponse,
    findOneById,
    add,
    patch,
    del
  };
}