import { GetDiseaseResponseDto } from '../dto/Diseases';
import { ResponseApiList, TypeOnError } from '../global'
import { IUseBase, useInstance } from './IUseBaseElements'

export interface IUseDiseasesByCompany
  extends IUseBase<ResponseApiList<GetDiseaseResponseDto>> {
}

export function useDiseasesByCompany(baseURL: string, token: string, companyId: number, query: any, shouldFetch?: boolean, onError?: TypeOnError): IUseDiseasesByCompany {

  let path: string = `/tracking_types/company/${companyId}`;
  if(query) path = path + `?limit=${query.limit}&page=${query.page}`
  const swrResponse: IUseBase<ResponseApiList<GetDiseaseResponseDto>> = useInstance<ResponseApiList<GetDiseaseResponseDto>>(
    baseURL,
    token,
    shouldFetch ? path : null,
    onError
  );

  return {
    ...swrResponse
  } as IUseDiseasesByCompany;

}
