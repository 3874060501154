import { GetImageResponseDto } from "./Images";
import { CreateMeasurementImageRequestDto } from "./MeasurementImage";
import { GetUserResponseDto } from "./User";

export enum Time {
  ALL = 'ALL TIME',
  WEEK = 'PAST WEEK',
  TWOWEEK = 'PAST TWO WEEKS',
  DAY = 'TODAY',
}

export enum TranslationStatus {
  TRANSLATED = 'translated',
  PENDING = 'pending',
  REMOVED = 'removed',
}

export interface IGetTranslateImageResponseDto {
  id: number;
  image?: GetImageResponseDto;
  imageUrl: string;
  imageId: number;
  translator?: GetUserResponseDto;
  translatorId: number;
  translator2?: GetUserResponseDto;
  translator2Id: number;
  translated: boolean;
  translatorRemoveId: number;
  translatorRemove2Id: number;
  created_at?: Date;
}

export class GetTranslateImageResponseDto implements IGetTranslateImageResponseDto {
  id: number = 0
  image?: GetImageResponseDto;
  imageId: number = 0;
  imageUrl: string = '';
  translator?: GetUserResponseDto;
  translatorId: number = 0;
  translator2?: GetUserResponseDto;
  translator2Id: number = 0;
  translatorRemoveId: number = 0;
  translatorRemove?: GetUserResponseDto;
  translatorRemove2Id: number = 0;
  translatorRemove2?: GetUserResponseDto;
  translated: boolean = false;
  created_at?: Date;
}

export interface ICreateTranslateImageRequestDto {
  image?: GetImageResponseDto;
  imageId: number;
  translator?: GetUserResponseDto;
  translatorId: number;
  translator2?: GetUserResponseDto;
  translator2Id: number;
  translatorRemove?: GetUserResponseDto
  translatorRemoveId: number
  translatorRemove2?: GetUserResponseDto;
  translatorRemove2Id: number
  translated: boolean;
  created_at?: Date;
  measurementImages?: CreateMeasurementImageRequestDto[];
}

export interface IGetTopTranslatorResponseDto { 
  id: number;
  name: string;
  translatedImages: number;
}

export class CreateTranslateImageRequestDto implements ICreateTranslateImageRequestDto {
  image?: GetImageResponseDto;
  imageId: number = 0;
  translator?: GetUserResponseDto;
  translatorId: number = 0;
  translator2?: GetUserResponseDto;
  translator2Id: number = 0;
  translatorRemove?: GetUserResponseDto;
  translatorRemoveId: number = 0;
  translatorRemove2?: GetUserResponseDto;
  translatorRemove2Id: number = 0;
  translated: boolean = false;
  created_at?: Date;
  measurementImages?: CreateMeasurementImageRequestDto[];
}

export interface ICreateTranslateImageResponseDto {
  id: number;
  image?: GetImageResponseDto;
  imageId: number;
  translator?: GetUserResponseDto;
  translatorId: number;
  translator2?: GetUserResponseDto;
  translator2Id: number;
  translatorRemove?: GetUserResponseDto
  translatorRemoveId: number
  translatorRemove2?: GetUserResponseDto
  translatorRemove2Id: number
  translated: boolean;
  created_at?: Date;
  checkedAt?: Date;
  checkedTanslatorAt?: Date;
  checkedTanslator2At?: Date;
}

export class CreateTranslateImageResponseDto implements ICreateTranslateImageResponseDto {
  id: number = 0;
  image?: GetImageResponseDto;
  imageId: number = 0;
  translator?: GetUserResponseDto;
  translatorId: number = 0;
  translator2?: GetUserResponseDto;
  translator2Id: number = 0;
  translatorRemove?: GetUserResponseDto;
  translatorRemoveId: number = 0;
  translatorRemove2?: GetUserResponseDto;
  translatorRemove2Id: number = 0;
  translated: boolean = false;
  created_at?: Date;
  message: string = '';
}

export interface IAddTranslationRequestDto {
  translatable:boolean;
  measurementImages?: CreateMeasurementImageRequestDto[];
}

export class AddTranslationRequestDto {
  translatable:boolean = true;
  measurementImages?: CreateMeasurementImageRequestDto[];
}

export interface IUpdateTranslateImageResponseDto {
  id: number;
  image?: GetImageResponseDto;
  imageId: number;
  translator?: GetUserResponseDto;
  translatorId: number;
  translator2?: GetUserResponseDto;
  translator2Id: number;
  translated: boolean;
  created_at?: Date;
}

export class UpdateTranslateImageResponseDto implements IUpdateTranslateImageResponseDto {
  id: number = 0;
  image?: GetImageResponseDto;
  imageId: number = 0;
  translator?: GetUserResponseDto;
  translatorId: number = 0;
  translator2?: GetUserResponseDto;
  translator2Id: number = 0;
  translated: boolean = false;
  created_at?: Date;
}

export interface IUpdateTranslateImageRequestDto {
  image?: GetImageResponseDto;
  imageId: number;
  translator?: GetUserResponseDto;
  translatorId: number;
  translator2?: GetUserResponseDto;
  translator2Id: number;
  translated: boolean;
  created_at?: Date;
}

export class UpdateTranslateImageRequestDto implements IUpdateTranslateImageRequestDto {
  image?: GetImageResponseDto;
  imageId: number = 0;
  translator?: GetUserResponseDto;
  translatorId: number = 0;
  translator2?: GetUserResponseDto;
  translator2Id: number = 0;
  translated: boolean = false;
  created_at?: Date;
}
export class GetTopTranslatorResponseDto implements IGetTopTranslatorResponseDto { 
  id: number = 0;
  name: string = '';
  translatedImages: number = 0;
}
