import React, { useState } from 'react'
import { toast } from 'react-toastify'
import { NavLink, useNavigate } from 'react-router-dom'
import Button from '@material-ui/core/Button'
import CssBaseline from '@material-ui/core/CssBaseline'
import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'
import Container from '@material-ui/core/Container'
import logo from '../../assets/logo.png'
import { useApi, ILoginData } from '@doctomatic/sdk/build/Api'
import useStyles from './styles'
import { InputAdornment, Link } from '@material-ui/core'
import { IoEye, IoEyeOff } from 'react-icons/io5';


interface LoginProps {
  pathname: string | undefined
}

const Login = ({ pathname }: LoginProps): React.ReactElement => {
  const classes = useStyles()
  const history = useNavigate()
  const { login } = useApi()

  const [userData, setUserData] = useState<ILoginData>({ email: '', password: '', super: true })
  const [showPassword, setShowPassword] = useState(false);

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault()

    try {
      const { accessToken: token, refreshToken } = await login(userData)
      localStorage.setItem('token', token || '')
      localStorage.setItem('refreshToken', refreshToken || '')
      if (pathname) history(pathname, { replace: true })
    } catch (err: any) {
      toast.error('login incorrecto')
    }
  }

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setUserData({ ...userData, [event.target.name]: event.target.value })
  }

  return (
    <Container component='main' maxWidth='xs'>
      <CssBaseline />
      <div className={classes.paper}>
        <img src={logo} style={{ width: 200, marginBottom: 20 }} />
        <Typography variant='h1'>CMS</Typography>
        <form id='login' className={classes.form} noValidate onSubmit={handleSubmit}>
          <TextField variant='outlined' margin='normal' required fullWidth id='email' label='Email Address' name='email' autoComplete='email' autoFocus onChange={handleInputChange} />
          <TextField variant='outlined' margin='normal' required fullWidth name='password' label='Password' type={showPassword ? 'text' : 'password'} id='password' autoComplete='current-password' onChange={handleInputChange}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <button
                    type="button"
                    onClick={() => setShowPassword(!showPassword)}
                    style={{ background: 'none', border: 'none', padding: 0, marginTop: 5 }}
                  >
                    {!showPassword ? <IoEyeOff size={20} color="gray" /> : <IoEye size={20} color="gray" />}
                  </button>
                </InputAdornment>
              ),
              style: { borderRadius: 0 },
            }} />
          <Button id='button' type='submit' fullWidth variant='contained' className={classes.submit}>Entrar</Button>
          <Link component={NavLink} to="/forgot">Olvidaste la contraseña?</Link>
        </form>
      </div>
    </Container>
  )
}

export { Login }