import { Filter } from "./Filter";

export const formatPaginationFilters = (filters: Filter[]) => {
    let queryFilters = '';
    filters.forEach((filter: Filter) => {
        const key = filter.column;
        const rawValues = JSON.stringify(filter.values);
        const fineValues = rawValues.substring(1, rawValues.length - 1);
        const valuesNoQuotes = fineValues.replace(/"([^"]+(?="))"/g, '$1');

        // this is to handle complex typing
        // I.e: AlertByValue and AlertByNoMeasure can both be filtered by 'device'
        // But AlertByValue 'device' is following this relationship: alert.sign.device
        // In the other hand, AlertByNoMeasure has 'device' under: alert.device
        // Also those AlertConfigs are diffrents (AlertConfigByValue and AlertConfigByNoMeasure)
        // So we needed to twek the column searchBy to handle both
        const columns = key.split(',');
        columns.forEach((column: string) => {
            queryFilters += `&filter.${column}=${filter.typeQuery}:${valuesNoQuotes}`
        });
    });
    return queryFilters;
}