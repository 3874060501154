import { GetDoctorResponseDto } from "../dto/Actors/Doctor/Doctor";
import { GetNurseResponseDto } from "../dto/Actors/Nurse/Nurse";
import {
  GetPatientResponseDto,
} from "../dto/Patient/Patient";
import { ResponseApi, TypeOnError } from "../global";
import { IUseBase, useInstance } from "./IUseBaseElements";

export interface IUseActorByRole
  extends IUseBase<ResponseApi<GetPatientResponseDto | GetDoctorResponseDto | GetNurseResponseDto>> {
}

export function useActorByRole(
  baseURL: string,
  token: string,
  actorId: number,
  role: 'doctors' | 'nurses' | 'patients',
  shouldFetch?: boolean,
  onError?: TypeOnError
): IUseActorByRole {
  const swrResponse: IUseBase<ResponseApi<GetPatientResponseDto | GetDoctorResponseDto | GetNurseResponseDto>> = useInstance<ResponseApi<GetPatientResponseDto | GetDoctorResponseDto | GetNurseResponseDto>>(
    baseURL,
    token,
    shouldFetch ? `/${role}/${actorId}` : null,
    onError
  );

  return {
    ...swrResponse
  };
}
