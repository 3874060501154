import { CreateAddressSubscriptionRequestDto } from '../dto/Addresses';
import type {
  CreateSubscriptionResponseDto,
  CreateSubscriptionRequestDto,
  ValidateCodeResponseDto,
  ValidateCodeRequestDto,
} from '../dto/Payments';
import { ResponseApi, TypeOnError } from '../global';
import { CreateActionWithoutMutate } from './IUseBaseElements';

export interface IUsePayments {
  subscribe: (
    paymentMethodId: string,
    dni?: string,
    trialCode?: string,
    address?: CreateAddressSubscriptionRequestDto
  ) => Promise<ResponseApi<any>>;
  validate: (code: string) => Promise<ResponseApi<ValidateCodeResponseDto>>;
  assignSubscription: (subscriptionId: string) => Promise<ResponseApi<any>>;
}

export function usePayments(baseURL: string, token: string, shouldFetch?: boolean, onError?: TypeOnError): IUsePayments {

  const subscribe = async (
    paymentMethodId: string,
    dni?: string,
    trialCode?: string,
    address?: CreateAddressSubscriptionRequestDto
  ): Promise<ResponseApi<any>> => {
    let path: string = '/payments/subscription';
    const action = CreateActionWithoutMutate<ResponseApi<any>, any>(
      baseURL,
      token,
      'post',
      path,
      onError
    );
    return action({ paymentMethodId, dni, trialCode, address });
  };

  const assignSubscription = async (subscriptionId: string): Promise<ResponseApi<any>> => {
    let path: string = '/payments/assign_subscription';
    const action = CreateActionWithoutMutate<ResponseApi<any>, any>(
      baseURL,
      token,
      'patch',
      path,
      onError
    );
    return action({ subscriptionId });
  };

  const validate = async (trialCode: string): Promise<ResponseApi<ValidateCodeResponseDto>> => {
    let path: string = `/payments/trial-code/`;
    const action = CreateActionWithoutMutate<ResponseApi<ValidateCodeResponseDto>, ValidateCodeRequestDto>(
      baseURL,
      token,
      'post',
      path,
      onError
    );
    return action({ trialCode });
  };

  return { subscribe, validate, assignSubscription };
}
