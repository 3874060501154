
import { ResponseApi, TypeOnError } from '../../global';
import { CreateActionWithoutMutate } from '../IUseBaseElements';


export interface IUseAlertConfigs {
  subscribe: (id: number, userId: number) => Promise<ResponseApi<any>>
  unsubscribe: (id: number, userId: number) => Promise<ResponseApi<any>>
}

export function useAlertConfigs(baseURL: string, token: string, shouldFetch?: boolean, onError?: TypeOnError): IUseAlertConfigs {

  const subscribe = async (id: number, userId: number): Promise<ResponseApi<any>> => {
    let path: string = `/alerts_config/${id}/subscribe?userId=${userId}`;
    const action = CreateActionWithoutMutate<ResponseApi<any>>(
      baseURL,
      token,
      'post',
      path,
      onError
    );
    return action();
  };

  const unsubscribe = async (id: number, userId: number): Promise<ResponseApi<any>> => {
    let path: string = `/alerts_config/${id}/unsubscribe?userId=${userId}`;
    const action = CreateActionWithoutMutate<ResponseApi<any>>(
      baseURL,
      token,
      'post',
      path,
      onError
    );
    return action();
  };

  return {
    subscribe,
    unsubscribe
  };
}