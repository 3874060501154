import React, { useState } from "react";
import { Page } from "../Page";
import { ImageTranslationList } from "./ImageTranslationList";
import { ImageTranslationEdit } from "./ImageTranslationEdit";
import { GetTranslateImageResponseDto } from "@doctomatic/sdk/build/dto/TranslateImage";

const ImageTranslations = (): React.ReactElement => {
  const [imageToTranslate, setImageToTranslate] =
    useState<GetTranslateImageResponseDto | null>(null);
  const [isFormOpen, setIsFormOpen] = useState<boolean>(false);

  const translateImageForm = imageToTranslate && (
    <ImageTranslationEdit
      open={isFormOpen}
      translateImageToEdit={imageToTranslate}
      onClose={() => {
        setImageToTranslate(null);
        setIsFormOpen(false);
      }}
      onSave={() => {
        setImageToTranslate(null);
        setIsFormOpen(false);
      }}
    />
  );

  return (
    <Page title={"Traducciones"}>
      <ImageTranslationList
        onEdit={(translateImage: GetTranslateImageResponseDto) => {
          setImageToTranslate(translateImage);
          setIsFormOpen(true);
        }}
      />
      {translateImageForm}
    </Page>
  );
};

export { ImageTranslations };
