import {
  CreateLocationRequestDto,
  UpdateLocationRequestDto,
  UpdateLocationResponseDto,
  CreateLocationResponseDto,
  ItemLocation,
} from '../dto/Location/Location'
import { ResponseApi, TypeOnError } from '../global'
import {
  CreateAction,
  CreateActionWithEndpoint,
  IUseBase,
  useInstance
} from './IUseBaseElements'

export interface IUseLocations
  extends IUseBase<ResponseApi<ItemLocation[]>> {
  patch: (id: number, location: UpdateLocationRequestDto) => Promise<ResponseApi<UpdateLocationResponseDto>>;
  add: (location: CreateLocationRequestDto) => Promise<ResponseApi<CreateLocationResponseDto>>;
  del: (id: number) => Promise<ResponseApi<boolean>>;
}

export function useLocations(baseURL: string, token: string, shouldFetch?: boolean, onError?: TypeOnError): IUseLocations {

  const swrResponse: IUseBase<ResponseApi<ItemLocation[]>> = useInstance<ResponseApi<ItemLocation[]>>(
    baseURL,
    token,
    shouldFetch ? `/locations` : null,
    onError
  );

  const patch = async (id: number, location: UpdateLocationRequestDto): Promise<ResponseApi<UpdateLocationResponseDto>> => {
    let path: string = `/locations/${id}`;
    const action = CreateActionWithEndpoint<ResponseApi<UpdateLocationResponseDto>, UpdateLocationRequestDto, ResponseApi<ItemLocation[]>>(
      baseURL,
      token,
      swrResponse.mutate,
      'patch',
      onError
    );
    return action(path, location);
  };

  const add = async (location: CreateLocationRequestDto): Promise<ResponseApi<CreateLocationResponseDto>> => {
    let path: string = '/locations';
    const action = CreateActionWithEndpoint<ResponseApi<CreateLocationResponseDto>, CreateLocationRequestDto, ResponseApi<ItemLocation[]>>(
      baseURL,
      token,
      swrResponse.mutate,
      'post',
      onError
    );
    return action(path, location);
  };

  const del = async (id: number): Promise<ResponseApi<boolean>> => {
    let path: string = `/locations/${id.toString()}`;
    const action = CreateAction<ResponseApi<boolean>, boolean, ResponseApi<ItemLocation[]>>(
      baseURL,
      token,
      swrResponse.mutate,
      'delete',
      path,
      onError
    );
    return action();
  };



  return {
    ...swrResponse,
    patch,
    add,
    del,
  } as IUseLocations;

}
