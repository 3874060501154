import {
  GetAlertByValueResponseDto,
  GetAlertByNoMeasureResponseDto,
  GetAlertByFrequencyResponseDto,
} from "../../dto/Alerts/Alerts";
import { AlertTypeAlert } from "../../dto/Alerts/IAlerts";
import { ResponseApi, TypeOnError } from "../../global";
import { IUseBase, useInstance } from "../IUseBaseElements";
import { Filter } from "../../utils/pagination/Filter";
import { formatPaginationFilters } from "../../utils/pagination/formatFunctions";

export interface IUseAlertsPaginated extends IUseBase<ResponseApi<any>> {}

export function useAlertsPaginated(
  baseURL: string,
  token: string,
  alertTypeAlert: AlertTypeAlert,
  query: any,
  search: string,
  filters: Filter[],
  patientId?: number,
  shouldFetch: boolean = true,
  onError?: TypeOnError
): IUseAlertsPaginated {
  let queryFilters = "";
  if (search && search.length > 0) {
    queryFilters += `&search=${search}`;
  }

  const filtersFormatted = formatPaginationFilters(filters);
  queryFilters += filtersFormatted;

  if (query.sortBy && query.sortBy[0]) {
    queryFilters += `&${queryFilters}&field=${
      query.sortBy[0]["field"]
    }&sort=${query.sortBy[0]["sort"].toUpperCase()}`;
  }

  const patientIdFilter = patientId ? `&patient.id=${patientId}` : "";

  if (query) {
    queryFilters += `&limit=${query.limit}&page=${query.page}`;
  }

  const path = `/alerts?type=${alertTypeAlert}${patientIdFilter}${queryFilters}`;

  if (AlertTypeAlert.alert_by_value === alertTypeAlert) {
    const apiResponse: IUseBase<ResponseApi<GetAlertByValueResponseDto[]>> =
      useInstance<ResponseApi<GetAlertByValueResponseDto[]>>(
        baseURL,
        token,
        shouldFetch ? path : null,
        onError
      );

    return { ...apiResponse } as IUseAlertsPaginated;
  } else if (AlertTypeAlert.alert_by_no_measure === alertTypeAlert) {
    const apiResponse: IUseBase<ResponseApi<GetAlertByNoMeasureResponseDto[]>> =
      useInstance<ResponseApi<GetAlertByNoMeasureResponseDto[]>>(
        baseURL,
        token,
        shouldFetch ? path : null,
        onError
      );

    return { ...apiResponse } as IUseAlertsPaginated;
  } else if (AlertTypeAlert.alert_by_frequency === alertTypeAlert) {
    const apiResponse: IUseBase<ResponseApi<GetAlertByFrequencyResponseDto[]>> =
      useInstance<ResponseApi<GetAlertByFrequencyResponseDto[]>>(
        baseURL,
        token,
        shouldFetch ? path : null,
        onError
      );

    return { ...apiResponse } as IUseAlertsPaginated;
  }

  return {} as IUseAlertsPaginated;
}
