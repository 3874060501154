import React from "react";
import { Button } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
export default function ConfirmModal(_a) {
    var open = _a.open, content = _a.content, cancelText = _a.cancelText, confirmText = _a.confirmText, onCancel = _a.onCancel, onConfirm = _a.onConfirm, onClose = _a.onClose, onCancelColor = _a.onCancelColor, onConfirmColor = _a.onConfirmColor, autofocus = _a.autofocus;
    return (React.createElement(Dialog, { open: open, onClose: onClose, "aria-labelledby": "confirm-dialog" },
        React.createElement(DialogContent, null,
            React.createElement(DialogContentText, { id: "confirm-dialog" }, content)),
        React.createElement(DialogActions, null,
            React.createElement(Button, { size: "small", onClick: onCancel, color: onCancelColor !== null && onCancelColor !== void 0 ? onCancelColor : "error", autoFocus: autofocus === "cancel" ? true : false }, cancelText),
            React.createElement(Button, { size: "small", onClick: onConfirm, variant: "contained", color: onConfirmColor !== null && onConfirmColor !== void 0 ? onConfirmColor : "success", autoFocus: autofocus === "confirm" ? true : false }, confirmText))));
}
export { ConfirmModal };
