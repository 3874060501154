import type { GetPatientResponseDto, GetPatientWithDevicesAndMeasurementsDto } from '../dto/Patient/Patient';
import { ResponseApi, TypeOnError } from '../global';
import { CreateAction, IUseBase, useInstance } from './IUseBaseElements';

export interface IUsePatient extends IUseBase<ResponseApi<GetPatientResponseDto>> {
  findOneWithTreatments: () => Promise<ResponseApi<GetPatientResponseDto>>
  findOneWithDevicesAndMeasurements: () => Promise<ResponseApi<GetPatientWithDevicesAndMeasurementsDto>>
}

export function usePatient(baseURL: string, token: string, id: number, shouldFetch?: boolean, onError?: TypeOnError): IUsePatient {

  const swrResponse: IUseBase<ResponseApi<GetPatientResponseDto>> = useInstance<ResponseApi<GetPatientResponseDto>>(
    baseURL,
    token,
    shouldFetch ? `/patients/${id}` : null,
    onError
  );

  const findOneWithTreatments = async (): Promise<ResponseApi<GetPatientResponseDto>> => {
    let path: string = `/patients/${id}/trackings`;
    const action = CreateAction<ResponseApi<GetPatientResponseDto>, boolean, ResponseApi<GetPatientResponseDto>>(
      baseURL,
      token,
      swrResponse.mutate,
      'get',
      path,
      onError
    );
    return action();
  };

  const findOneWithDevicesAndMeasurements = async (): Promise<ResponseApi<GetPatientWithDevicesAndMeasurementsDto>> => {
    let path: string = `/patients/${id}/devices`;
    const action = CreateAction<ResponseApi<GetPatientWithDevicesAndMeasurementsDto>, boolean, ResponseApi<GetPatientResponseDto>>(
      baseURL,
      token,
      swrResponse.mutate,
      'get',
      path,
      onError
    );
    return action();
  };

  return {
    ...swrResponse,
    findOneWithTreatments,
    findOneWithDevicesAndMeasurements
  };
}
