import { AddressType } from "./Addresses";
import { GetLocationUserResponseDto } from "./LocationUser";

export enum Role {
  user = "user",
  doctor = "doctor",
  admin = "admin",
  superadmin = "superadmin",
  translatorimage = "translatorimage",
  nurse = "nurse",
  // admincompany = 'admincompany',
  // patient = "patient",
  aiclient = "aiclient",
}

export enum Gender {
  male = 'male',
  female = 'female',
  notdefined = "notdefined"
}

export interface IExternalReferenceDto {
  externalId:string;
  /**
   * like, "fhir", "resiplus"
   */
  source:string;
}

export interface ICreateUserRequestDto {
  email: string;
  name: string;
  phone: string;
  language?: string;
  doctorId?: number;
  birthYear?: number;
  password?: string;
  externalReference? :Array<IExternalReferenceDto>;
}

export interface IUpdateProfileRequestDto {
  email?: string;
  name?: string;
  phone?: string;
  password?: string;
  pushToken?: string;
  cameraPermission?: boolean;
  timezone?: string;
  language?: string;
  birthYear?: number;
  dni?: string;
  type?: AddressType;
  address1?: string;
  address2?: string;
  cp?: string;
  city?: string;
  country?: string;
  companyName?: string;
  nationality?: string;
  gender?: Gender;
  lastAccess?: Date;
}

export interface IUpdateUserRequestDto {
  email?: string;
  name?: string;
  phone?: string;
  timezone?: string;
  language?: string;
  birthYear?: number;
  externalReference? :Array<IExternalReferenceDto>;
}

export interface ICreateUserResponseDto {
  id: number;
  email: string;
  name: string;
  phone: string;
  birthYear?: number;
  password?: string;
  externalReference? :Array<IExternalReferenceDto>;
}

export interface IUpdateUserResponseDto {
  email?: string;
  name?: string;
  phone?: string;
  id?: number;
  timezone?: string;
  language?: string;
  message?: string;
  externalReference? :Array<IExternalReferenceDto>;
}

export interface IUpdateProfileResponseDto {
  email?: string;
  name?: string;
  phone?: string;
  id?: number;
  timezone?: string;
  language?: string;
  message?: string;
  birthYear?: number;
  lastAccess?: Date;
}

export interface IUserDto {
  id: number;
  name: string;
}
export interface IGetUserResponseDto extends IUserDto {
  role?: Role;
  email: string;
  phone: string;
  hasPassword: boolean;
  birthYear?: number;
  companyId: number;
  pushToken?: string;
  deletedAt?: Date;
  timezone: string;
  language: string;
  cameraPermission: boolean;
  nationality: string;
  location_user: GetLocationUserResponseDto[];
  gender?: Gender;
  created_at?: Date;
  lastAccess?: Date;
}

export interface IGetUserSubscriptionInfoDto {
  price: number;
}

export class CreateUserRequestDto implements ICreateUserRequestDto {
  email: string = "";
  name: string = "";
  phone: string = "";
  language?: string;
  timezone?: string;
  doctorId?: number;
  birthYear?: number;
  password?: string;
}

export class UpdateProfileRequestDto implements IUpdateProfileRequestDto {
  email?: string;
  name?: string;
  phone?: string;
  password?: string;
  pushToken?: string;
  cameraPermission?: boolean;
  timezone?: string;
  language?: string;
  birthYear?: number;
  dni?: string;
  type?: AddressType;
  address1?: string;
  address2?: string;
  cp?: string;
  city?: string;
  country?: string;
  companyName?: string;
  nationality?: string;
  gender?: Gender;
  lastAccess?: Date;
}

export class UpdateUserRequestDto implements IUpdateUserRequestDto {
  email?: string;
  name?: string;
  phone?: string;
  timezone?: string;
  language?: string;
  birthYear?: number;
}

export class CreateUserResponseDto implements ICreateUserResponseDto {
  id: number = 0;
  email: string = "";
  name: string = "";
  phone: string = "";
  message: string = "";
  doctor?: number;
  birthYear?: number;
  password?: string;
}

export class UserDto implements IUserDto {
  id: number = 0;
  name: string = "";

}
export class GetUserResponseDto extends UserDto implements IGetUserResponseDto {
  role?: Role;
  email: string = "";
  phone: string = "";
  hasPassword: boolean = false;
  birthYear?: number;
  companyId: number = 0;
  pushToken?: string;
  deletedAt?: Date;
  timezone: string = "";
  language: string = "";
  cameraPermission: boolean = false;
  nationality: string = "";
  location_user: GetLocationUserResponseDto[] = [];
  lastAccess?: Date;
}

export class UpdateUserResponseDto implements IUpdateUserResponseDto {
  email?: string;
  name?: string;
  phone?: string;
  id?: number;
  doctor?: number;
  timezone?: string;
  language?: string;
  message?: string;
  birthYear?: number;
}

export class UpdateProfileResponseDto implements IUpdateProfileResponseDto {
  email?: string;
  name?: string;
  phone?: string;
  id?: number;
  timezone?: string;
  language?: string;
  message?: string;
  birthYear?: number;
  lastAccess?: Date;
}

export class GetUserSubscriptionInfoDto implements IGetUserSubscriptionInfoDto {
  price: number = 0;
}
