import { AddPatientRequestDto, AddPatientResponseDto, ItemPatientListExtendedResponseDto, UpdatePatientRequestDto, UpdatePatientResponseDto } from '../dto/Patient/Patient';
import { ResponseApi, TypeOnError } from '../global'
import {
  CreateAction,
  CreateActionWithEndpoint,
  IUseBase,
  useInstance
} from './IUseBaseElements'

export interface IUsePatientsByCompany
  extends IUseBase<ResponseApi<ItemPatientListExtendedResponseDto[]>> {
  add: (location: AddPatientRequestDto) => Promise<ResponseApi<AddPatientResponseDto>>;
  del: (id: number) => Promise<ResponseApi<void>>;
  patch: (id: number, patient: UpdatePatientRequestDto) => Promise<ResponseApi<UpdatePatientResponseDto>>;
  restore: (id: number) => Promise<ResponseApi<boolean>>;
}

export function usePatientsByCompany(baseURL: string, token: string, companyId: number, showDeleted: boolean, shouldFetch?: boolean, onError?: TypeOnError): IUsePatientsByCompany {
  const swrResponse: IUseBase<ResponseApi<ItemPatientListExtendedResponseDto[]>> = useInstance<ResponseApi<ItemPatientListExtendedResponseDto[]>>(
    baseURL,
    token,
    shouldFetch ? `/companies/${companyId}/patients?showDeleted=${showDeleted}` : null,
    onError
  );

  const add = async (location: AddPatientRequestDto): Promise<ResponseApi<AddPatientResponseDto>> => {
    let path: string = '/patients';
    const action = CreateActionWithEndpoint<ResponseApi<AddPatientResponseDto>, AddPatientRequestDto, ResponseApi<ItemPatientListExtendedResponseDto[]>>(
      baseURL,
      token,
      swrResponse.mutate,
      'post',
      onError
    );
    return action(path, location);
  };

  const del = async (id: number): Promise<ResponseApi<boolean>> => {
    let path: string = `/patients/${id.toString()}`;
    const action = CreateAction<ResponseApi<boolean>, boolean, ResponseApi<ItemPatientListExtendedResponseDto[]>>(
      baseURL,
      token,
      swrResponse.mutate,
      'delete',
      path,
      onError
    );
    return action();
  };

  const patch = async (id: number, patient: UpdatePatientRequestDto): Promise<ResponseApi<UpdatePatientResponseDto>> => {
    const path: string = `/patients/${id}`;
    const action = CreateAction<ResponseApi<UpdatePatientResponseDto>, UpdatePatientRequestDto, ResponseApi<ItemPatientListExtendedResponseDto[]>>(
      baseURL,
      token,
      swrResponse.mutate,
      'patch',
      path,
      onError
    );
    return action(patient);
  }

  const restore = async (id: number): Promise<ResponseApi<boolean>> => {
    const path: string = `/patients/${id}/restore`;
    const action = CreateAction<ResponseApi<boolean>, void, ResponseApi<ItemPatientListExtendedResponseDto[]>>(
      baseURL,
      token,
      swrResponse.mutate,
      'post',
      path,
      onError
    );
    return action();
  }

  return {
    ...swrResponse,
    add,
    del,
    patch,
    restore,
  } as IUsePatientsByCompany;

}
