import FormData from "form-data";
import { ResponseApi, TypeOnError } from "../global";
import { CreateActionWithoutMutate } from "./IUseBaseElements";
import { CreateQuestionImageResponseDto } from "../dto/ResponseQuestionImage";

export interface IUseQuestionImage {
  create: (
    signId:number,
    formData: FormData,
    patientId?: number
  ) => Promise<ResponseApi<CreateQuestionImageResponseDto>>;
}

export function useQuestionImage(
  baseURL: string,
  token: string,
  shouldFetch?: boolean,
  onError?: TypeOnError
): IUseQuestionImage {
  const create = async (
    signId: number,
    formData: FormData,
    patientId?: number
  ) => {
    let path = `/questionImage/?signId=${signId}`;
    if (patientId) path += `&patientId=${patientId}`;
    const action = CreateActionWithoutMutate<ResponseApi<CreateQuestionImageResponseDto>, FormData>(
      baseURL,
      token,
      'post',
      path,
      onError
    );
    return action(formData);
  };
  return { create };
}
