import { useApi } from "@doctomatic/sdk/build/Api";
import { FormControl, InputLabel, MenuItem, Select } from "@material-ui/core";
import { useNavigate } from "react-router-dom";
import { processError } from "../../../../App/errorToast";

interface DevicesSelectProp {
  deviceId?: number;
  setDeviceId: (id: number) => void;
  whiteBg?: boolean;
}

interface IDevice {
  id: number;
  name: string;
}

const SelectDevice = ({
  deviceId,
  setDeviceId,
  whiteBg,
}: DevicesSelectProp): React.ReactElement => {
  const styleWhiteBg = whiteBg !== undefined ? whiteBg : true;
  const { useDevices, logout } = useApi();
  const history = useNavigate();
  const { response: devicesResponse } = useDevices(
    true,
    processError(logout, history),
    false,
    undefined,
    true
  );
  const devices = devicesResponse?.data;
  const allDevices: IDevice[] = Array.isArray(devices)
    ? [{ id: 0, name: "Todos los dispositivos" }, ...devices]
    : [];

  let formControlStyle: any = {
    minWidth: 200,
    marginRight: 20,
  };
  if (styleWhiteBg)
    formControlStyle = { ...formControlStyle, color: "black", border: "black" };

  return (
    <FormControl variant="outlined" style={formControlStyle}>
      <InputLabel style={styleWhiteBg ? { color: "black" } : {}} id="device">
        {"Dispositivos"}
      </InputLabel>
      <Select
        labelId="device"
        id="device-select"
        data-cy="device-select"
        label={"Dispositivo"}
        onChange={(event) => setDeviceId(event.target.value as number)}
        value={deviceId ?? 0}
        style={
          styleWhiteBg
            ? { color: "black", backgroundColor: "rgba(0,0,0,0.15)" }
            : {}
        }
      >
        {allDevices.map((c: IDevice) => (
          <MenuItem key={`c${c.id}`} data-cy={`c${c.id}`} value={c.id}>
            {c.name}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export { SelectDevice };
