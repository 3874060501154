import {
  Action,
  CreateAction,
  CreateActionWithEndpoint,
  IUseBase,
  useInstance,
} from "./IUseBaseElements";
import { ResponseApi, ResponseApiList, TypeOnError } from "../global";
import {
  AssignLocationRequestDto,
  AssignLocationResponseDto,
  CreateLocationRequestDto,
  CreateLocationResponseDto,
  ItemLocation,
  ItemLocationListResponseDto,
  UpdateLocationRequestDto,
  UpdateLocationResponseDto,
} from "../dto/Location/Location";

type mutateTypeActorLocations = ResponseApiList<ItemLocationListResponseDto>;

export interface IUseActorLocations
  extends IUseBase<mutateTypeActorLocations> {
  patch: (
    id: number,
    location: UpdateLocationRequestDto
  ) => Promise<ResponseApi<UpdateLocationResponseDto>>;
  assign: (locationId: number,
    assignLocationRequestDto: AssignLocationRequestDto
  ) => Promise<ResponseApi<AssignLocationResponseDto>>;
  unassign: (locationId: number, actorId: number) => Promise<ResponseApi<boolean>>;
  add: (
    location: CreateLocationRequestDto
  ) => Promise<ResponseApi<CreateLocationResponseDto>>;
  del: (id: number) => Promise<ResponseApi<boolean>>;
}

export function useActorLocations(
  baseURL: string,
  token: string,
  actorId: number,
  role: 'doctors' | 'nurses' | 'patients',
  shouldFetch?: boolean,
  onError?: TypeOnError
): IUseActorLocations {

  const swrResponse: IUseBase<mutateTypeActorLocations> = useInstance<mutateTypeActorLocations>(
    baseURL,
    token,
    shouldFetch ? `/${role}/${actorId}/locations` : null,
    onError
  );

  const patch = async (
    locationId: number,
    location: UpdateLocationRequestDto
  ): Promise<ResponseApi<UpdateLocationResponseDto>> => {
    let path: string = `/locations/${locationId}`;
    const action = CreateActionWithEndpoint<ResponseApi<UpdateLocationResponseDto>,
      UpdateLocationRequestDto,
      mutateTypeActorLocations>(
        baseURL,
        token,
        swrResponse.mutate,
        'patch',
        onError
      );
    return action(path, location);
  };

  const assign = async (locationId: number,
    assignLocationRequestDto: AssignLocationRequestDto
  ): Promise<ResponseApi<AssignLocationResponseDto>> => {

    let path: string = `/locations/${locationId}/assign`;
    const action = CreateActionWithEndpoint<ResponseApi<AssignLocationResponseDto>,
      AssignLocationRequestDto,
      mutateTypeActorLocations>(
        baseURL,
        token,
        swrResponse.mutate,
        'post',
        onError
      );
    return action(path, assignLocationRequestDto);
  };

  const unassign = async (locationId: number, actorId: number): Promise<ResponseApi<boolean>> => {
    let path: string = `/locations/${locationId}/unassign/${actorId}`;
    const action = CreateAction<ResponseApi<boolean>, boolean, mutateTypeActorLocations>(
      baseURL,
      token,
      swrResponse.mutate,
      'delete',
      path,
      onError
    );
    return action();

  };

  const add = async (
    location: CreateLocationRequestDto
  ): Promise<ResponseApi<CreateLocationResponseDto>> => {

    let path: string = `/locations`;
    const action = CreateActionWithEndpoint<ResponseApi<CreateLocationResponseDto>,
      CreateLocationRequestDto,
      mutateTypeActorLocations>(
        baseURL,
        token,
        swrResponse.mutate,
        'post',
        onError
      );
    return action(path, location);
  };

  const del = async (id: number): Promise<ResponseApi<boolean>> => {

    let path: string = `/locations/${id}`;
    const action = CreateAction<ResponseApi<boolean>, boolean, mutateTypeActorLocations>(
      baseURL,
      token,
      swrResponse.mutate,
      'delete',
      path,
      onError
    );
    return action();
  };

  return {
    ...swrResponse,
    patch,
    assign,
    unassign,
    add,
    del,
  } as IUseActorLocations;
}
