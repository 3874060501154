import { GetAlertByValueResponseDto } from "../../dto/Alerts/Alerts";
import { GetReadDeviceResponseDto } from "../../dto/ReadDevice/ReadDevice";
import { ResponseApi, TypeOnError } from "../../global";
import { IUseBase, useInstance } from "../IUseBaseElements";

export interface IUseReadDevice
  extends IUseBase<ResponseApi<GetReadDeviceResponseDto>> {
  readDevicesByPatient: IUseBase<ResponseApi<GetReadDeviceResponseDto[]>>;
  alertsByReadDevice: IUseBase<ResponseApi<GetAlertByValueResponseDto[]>>;
}

export function useReadDevice(
  baseURL: string,
  token: string,
  readDeviceId?: number,
  patientId?: number,
  query?: any,
  shouldFetch?: boolean,
  onError?: TypeOnError
): IUseReadDevice {
  let path = `/read_device`;
  const swrResponse: IUseBase<ResponseApi<GetReadDeviceResponseDto>> =
    useInstance<ResponseApi<GetReadDeviceResponseDto>>(
      baseURL,
      token,
      shouldFetch ? `${path}/${readDeviceId}` : null,
      onError
    );

  if (patientId) {
    path += `?patientId=${patientId}`;
    if (query) path += `&limit=${query.limit}&page=${query.page}`;
  } else {
    if (query) path += `?limit=${query.limit}&page=${query.page}`;
  }
  const readDevicesByPatient: IUseBase<
    ResponseApi<GetReadDeviceResponseDto[]>
  > = useInstance<ResponseApi<GetReadDeviceResponseDto[]>>(
    baseURL,
    token,
    shouldFetch ? path : null,
    onError
  );
  let alertPath = "/read_device";
  if (readDeviceId) {
    alertPath += `/${readDeviceId}/alerts`;
    if (query) alertPath += `?limit=${query.limit}&page=${query.page}`;
  }
  const alertsByReadDevice: IUseBase<
    ResponseApi<GetAlertByValueResponseDto[]>
  > = useInstance<ResponseApi<GetAlertByValueResponseDto[]>>(
    baseURL,
    token,
    shouldFetch ? alertPath : null,
    onError
  );

  return {
    ...swrResponse,
    readDevicesByPatient,
    alertsByReadDevice,
  };
}
