import { GetTreatmentResponseDto } from "../../dto/Treatment/Treatments";
import { Role } from "../../dto/User";
import { ResponseApiList, ResponseApi, TypeOnError } from "../../global";
import { IUseBase, useInstance, CreateAction } from "../IUseBaseElements";

export interface IUseTreatmentsByActor
  extends IUseBase<ResponseApiList<GetTreatmentResponseDto>> {
  end: (id: number) => Promise<ResponseApi<boolean>>,
  reopen: (id: number) => Promise<ResponseApi<boolean>>
}

export function useTreatmentsByActor(baseURL: string, token: string, actorId: number, role: Role, showEnded: boolean, shouldFetch?: boolean, query?: any, onError?: TypeOnError): IUseTreatmentsByActor {
  let actor;
  if (role === Role.doctor) actor = 'doctors';
  if (role === Role.nurse) actor = 'nurses';
  let baseUrl = `/${actor}/${actorId}/trackings?showEnded=${showEnded}`;
  if (query) baseUrl += `&limit=${query.limit}&page=${query.page}`;
  const swrResponse: IUseBase<ResponseApiList<GetTreatmentResponseDto>> = useInstance<ResponseApiList<GetTreatmentResponseDto>>(
    baseURL,
    token,
    shouldFetch ? baseUrl : null,
    onError
  );

  const end = async (id: number): Promise<ResponseApi<boolean>> => {
    const path: string = `/trackings/${id}/end`;
    const action = CreateAction<ResponseApi<boolean>, void, ResponseApiList<GetTreatmentResponseDto>>(
      baseURL,
      token,
      swrResponse.mutate,
      'patch',
      path,
      onError
    );
    return action();
  };

  const reopen = async (id: number): Promise<ResponseApi<boolean>> => {
    const path: string = `/trackings/${id}/reopen`;
    const action = CreateAction<ResponseApi<boolean>, void, ResponseApiList<GetTreatmentResponseDto>>(
      baseURL,
      token,
      swrResponse.mutate,
      'patch',
      path,
      onError
    );
    return action();
  };

  return {
    ...swrResponse,
    end,
    reopen,
  } as IUseTreatmentsByActor;

}

