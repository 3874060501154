import { Box, TablePagination } from "@mui/material";
import Skeleton from "@mui/material/Skeleton";
import { DataGrid, esES, } from "@mui/x-data-grid";
import React from "react";
var handleClick = function (event, cellValues) {
    console.log(cellValues.row);
};
var handleCellClick = function (param, event) {
    event.stopPropagation();
};
var handleRowClick = function (param, event) {
    event.stopPropagation();
};
var LoadingSkeleton = function () { return (React.createElement(Skeleton, { variant: "rectangular", sx: { my: 4, mx: 1 } })); };
export function FlexLayoutGrid(props) {
    var _a, _b, _c;
    var onSelectionModelChangeFunc = function (selectionModel, details) {
        if (props.onSelectionModelChange != undefined)
            props.onSelectionModelChange(selectionModel, details);
    };
    function CustomPagination() {
        var rowsPerPageOptions = props.rowsPerPageOptions ? props.rowsPerPageOptions : [10, 25, 50, 100];
        var pageSizeChange = function (newPageSize) {
            // When page size changes, we need to reset the page
            if (props.onPageSizeChange && props.onPageChange) {
                props.onPageSizeChange(newPageSize);
                props.onPageChange(1);
            }
            else {
                console.warn("Page size change handler not provided");
            }
        };
        var pageChange = function (event, newPage) {
            if (props.onPageChange) {
                props.onPageChange(newPage + 1);
            }
            else {
                console.log("Page change handler not provider");
            }
        };
        var tablePagination = (React.createElement(TablePagination, { component: "div", count: props.pagination.totalItems, page: props.pagination.currentPage - 1, onPageChange: function (event, newPage) {
                return pageChange(event, newPage);
            }, onRowsPerPageChange: function (event) { return pageSizeChange(parseInt(event.target.value, 10)); }, rowsPerPage: props.pagination.itemsPerPage, rowsPerPageOptions: rowsPerPageOptions }));
        return tablePagination;
    }
    var localeText = undefined;
    if (props.language) {
        if (props.language === "es") {
            localeText = esES.components.MuiDataGrid.defaultProps.localeText;
        }
    }
    var handleSortModelChange = function (newSort) {
        var _a;
        if (props.onSortChange && props.onPageChange) {
            if (newSort.length !== ((_a = props.sortBy) === null || _a === void 0 ? void 0 : _a.length) ||
                newSort[0] !== props.sortBy[0]) {
                props.onSortChange(newSort);
                if (newSort.length > 0) {
                    props.onPageChange(1);
                }
            }
        }
        else {
            console.warn("Sort change handler not provided");
        }
    };
    var sorting = ((_a = props.pagination) === null || _a === void 0 ? void 0 : _a.sortBy[0])
        ? [
            {
                field: (_b = props.pagination) === null || _b === void 0 ? void 0 : _b.sortBy[0][0],
                sort: (_c = props.pagination) === null || _c === void 0 ? void 0 : _c.sortBy[0][1].toLowerCase(),
            },
        ]
        : undefined;
    var dataGridCustomPagination = (React.createElement(DataGrid, { localeText: localeText, onCellClick: handleCellClick, onRowClick: handleRowClick, columns: props.columns, rows: props.rows, getRowClassName: props.setRowClassName, checkboxSelection: props.checkboxSelection ? props.checkboxSelection : false, onSelectionModelChange: onSelectionModelChangeFunc, pagination: true, initialState: {
            pagination: props.pagination,
            sorting: { sortModel: sorting },
        }, loading: props.isLoading, components: {
            LoadingOverlay: LoadingSkeleton,
            Pagination: CustomPagination,
        }, onSortModelChange: handleSortModelChange, rowHeight: props.rowHeight ? props.rowHeight : 50 }));
    var dataGridTablePagination = (React.createElement(DataGrid, { localeText: localeText, onCellClick: handleCellClick, onRowClick: handleRowClick, columns: props.columns, rows: props.rows, getRowClassName: props.setRowClassName, checkboxSelection: props.checkboxSelection ? props.checkboxSelection : false, onSelectionModelChange: onSelectionModelChangeFunc, pagination: true, initialState: {
            pagination: props.pagination,
            sorting: { sortModel: sorting },
        }, onPageChange: function (newPage) {
            return props.onPageChange
                ? props.onPageChange(newPage + 1)
                : console.log("Page change handler not provider");
        }, onPageSizeChange: function (newPageSize) {
            return props.onPageSizeChange
                ? props.onPageSizeChange(newPageSize)
                : console.warn("Page size change handler not provided");
        }, onSortModelChange: handleSortModelChange, rowHeight: props.rowHeight ? props.rowHeight : 50 }));
    if (!props.sx)
        return (React.createElement("div", { style: { height: 600, width: "100%" } },
            React.createElement("div", { style: { display: "flex", height: "100%" } },
                React.createElement("div", { style: { flexGrow: 1 } }, props.pagination
                    ? dataGridCustomPagination
                    : dataGridTablePagination))));
    else
        return (React.createElement("div", { style: { height: props.height ? props.height : 600, width: "100%" } },
            React.createElement("div", { style: { display: "flex", height: "100%" } },
                React.createElement("div", { style: { flexGrow: 1 } },
                    React.createElement(Box, { sx: props.sx }, props.pagination
                        ? dataGridCustomPagination
                        : dataGridTablePagination)))));
}
