import { LastMeasurementsDto } from '../dto/Measurements/Measurements';
import { ResponseApiList, TypeOnError } from '../global';
import { CreateAction } from './IUseBaseElements';
import { IUseBase, useInstance } from './IUseBaseElements';

export interface IUseLastMeasurements
  extends IUseBase<ResponseApiList<LastMeasurementsDto>> {
  getLastMeasurements: (actorId: number) => Promise<ResponseApiList<LastMeasurementsDto>>
  getLastMeasurementsByLocation: (locationId: number) => Promise<ResponseApiList<LastMeasurementsDto>>
}

export function useLastMeasurements(
  baseURL: string,
  token: string,
  actorId: number,
  shouldFetch?: boolean,
  query?: any,
  onError?: TypeOnError
): IUseLastMeasurements {

  let queryFilters = '';
  if (query) {
    queryFilters = `?limit=${query.limit}&page=${query.page}`;
    if (query.sortBy && query.sortBy[0]) {
      queryFilters = `${queryFilters}&field=${query.sortBy[0]['field']}&sort=${query.sortBy[0]['sort'].toUpperCase()}`;
    }
  }

  const swrResponse: IUseBase<ResponseApiList<LastMeasurementsDto>> = useInstance<ResponseApiList<LastMeasurementsDto>>(
    baseURL,
    token,
    shouldFetch ? `/measurements/lastbyactor/${actorId}${queryFilters}` : null,
    onError
  );

  // const getLastMeasurements = (actorId: number) => {
  //   let path: string = `/measurements/lastbyactor/${actorId}${queryFilters}`;
  //   const action = CreateAction<ResponseApiList<LastMeasurementsDto>, void, ResponseApiList<LastMeasurementsDto>>(
  //     baseURL,
  //     token,
  //     swrResponse.mutate,
  //     'get',
  //     path,
  //     onError
  //   );
  //   return action();
  // };

  // const getLastMeasurementsByLocation = async (locationId: number): Promise<ResponseApiList<LastMeasurementsDto>> => {
  //   let path: string = `/measurements/lastbylocation/${locationId}${queryFilters}`;
  //   const action = CreateAction<ResponseApiList<LastMeasurementsDto>, void, ResponseApiList<LastMeasurementsDto>>(baseURL, token, swrResponse.mutate,
  //     'get',
  //     path,
  //     onError
  //   );
  //   return action();
  // }

  return {
    ...swrResponse,
    // getLastMeasurements,
    // getLastMeasurementsByLocation
  } as IUseLastMeasurements;

}
