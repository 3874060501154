import type {
  AddAlertGroupRequestDto,
  AddAlertGroupResponseDto,
  UpdateAlertGroupRequestDto,
  UpdateAlertGroupResponseDto,
  GetAlertGroupResponseDto
} from '../../dto/Alerts/group/AlertGroup'
import useSWR, { KeyedMutator } from 'swr'
import Fetcher from '../Fetcher'
import { ResponseApi, ResponseApiList } from '../../global'

export interface IUseAlertGroups {
  alertGroups: ResponseApiList<GetAlertGroupResponseDto> | undefined,
  findOneById: (id: number) => Promise<ResponseApi<GetAlertGroupResponseDto>>,
  mutate: KeyedMutator<ResponseApiList<GetAlertGroupResponseDto>>
  update: (id: number, dto: UpdateAlertGroupRequestDto) => Promise<void>,
  add: (dto: AddAlertGroupRequestDto) => Promise<ResponseApi<AddAlertGroupResponseDto>>,
  del: (id: number) => Promise<void>,
  subscribe: (id: number, userId: number) => Promise<ResponseApi<any>>
  unsubscribe: (id: number, userId: number) => Promise<ResponseApi<any>>
}

export function useAlertGroups(baseURL: string, token: string, query?: any): IUseAlertGroups {
  let path: string =`/alert_groups`;
  if(query) path = path + `?limit=${query.limit}&page=${query.page}`

  const fetcher = Fetcher<ResponseApiList<GetAlertGroupResponseDto>>(baseURL, token)
  const { data: alertGroups, mutate } = useSWR<ResponseApiList<GetAlertGroupResponseDto>>(path, fetcher, { shouldRetryOnError: false })

  const findOneById = async (id: number) => {
    const response = await Fetcher<ResponseApi<GetAlertGroupResponseDto>, void>(baseURL, token)(`/alert_groups/${id}`, 'get')
    return response
  }

  const update = async (id: number, dto: UpdateAlertGroupRequestDto) => {
    await Fetcher<ResponseApi<UpdateAlertGroupResponseDto>, UpdateAlertGroupRequestDto>(baseURL, token)(`/alert_groups/${id}`, 'patch', dto)
  }

  const add = async (dto: AddAlertGroupRequestDto): Promise<ResponseApi<AddAlertGroupResponseDto>> => {
    const response = await Fetcher<ResponseApi<AddAlertGroupResponseDto>, AddAlertGroupRequestDto>(baseURL, token)('/alert_groups', 'post', dto)
    return response
  }

  const del = async (id: number): Promise<void> => {
    const response = await Fetcher<void>(baseURL, token)(`/alert_groups/${id}`, 'delete')
    return response
  }

  const subscribe = async (id: number, userId: number): Promise<ResponseApi<any>> => {
    const response: ResponseApi<any> = await Fetcher<ResponseApi<any>>(baseURL, token)(`/alert_groups/${id}/subscribe?userId=${userId}`, 'post');
    return response;
  }

  const unsubscribe = async (id: number, userId: number): Promise<ResponseApi<any>> => {
    const response: ResponseApi<any> = await Fetcher<ResponseApi<any>>(baseURL, token)(`/alert_groups/${id}/unsubscribe?userId=${userId}`, 'post');
    return response;
  }

  return { alertGroups, findOneById, mutate, update, add, del, subscribe, unsubscribe }
}
