import { IUseBase, useInstance } from './IUseBaseElements';
import { ResponseApiList, TypeOnError } from '../global';
import { ItemList } from '../dto/IItemList';

export interface IUseConsentDoctors
  extends IUseBase<ResponseApiList<ItemList>> { }

export function useConsentDoctors(
  baseURL: string,
  token: string,
  shouldFetch?: boolean,
  onError?: TypeOnError
): IUseConsentDoctors {
  const swrResponse: IUseBase<ResponseApiList<ItemList>> = useInstance<ResponseApiList<ItemList>>(
    baseURL,
    token,
    shouldFetch ? '/doctors/consent-doctors' : null,
    onError
  );
  return {
    ...swrResponse
  } as IUseConsentDoctors;
}
