import React, { useState } from "react";
import { Page } from "../Page";
import { toast } from "react-toastify";
import { CompanyList } from "./CompanyList";
import { CompanyCreate } from "./CompanyCreate";
import { Confirm } from "../../Confirm";
import { useLoading } from "../../Loading/Loading";
import { Box, Button, Typography } from "@material-ui/core";
import { useApi } from "@doctomatic/sdk/build/Api";
import {
  CreateCompanyRequestDto,
  GetCompanyResponseDto,
} from "@doctomatic/sdk/build/dto/Company";
import { CompanyEdit } from "./CompanyEdit";
import { CompanyUserSelector } from "./CompanyUserSelector";
import axios from "axios";
import {
  AddAdminCompanyRequestDto,
  AddAdminCompanyResponseDto,
} from "@doctomatic/sdk/build/dto/Actors/AdminCompany/AdminCompany";
import { AdminCreate } from "./AdminCreate";
import { processError } from "../../../App/errorToast";
import { useNavigate } from "react-router";
import { GetActorResponseDto } from "@doctomatic/sdk/build/modules/Profile";
import { ResponseApi } from "@doctomatic/sdk/build/global";

interface ICompaniesProps {
  profile: GetActorResponseDto | undefined;
}

const Companies = ({ profile }: ICompaniesProps): React.ReactElement => {
  const history = useNavigate();
  const {
    token,
    useCompanies,
    useAdminCompanies,
    useGroupPermissions,
    useSMS,
    useMail,
    logout,
  } = useApi();
  const { sendRegistrationEmail } = useMail();
  const { sendRegistrationSMS } = useSMS(true, processError(logout, history));
  const {
    response: companiesResponse,
    add,
    del,
    update,
    mutate,
  } = useCompanies(true, processError(logout, history));
  const { add: addAdmin } = useAdminCompanies(
    false,
    false,
    processError(logout, history)
  );
  const { assignGroupPermission } = useGroupPermissions(
    profile?.id ?? 0,
    false,
    processError(logout, history)
  );
  const { setIsLoading } = useLoading();
  const companies = companiesResponse?.data;
  const [isCompanyFormOpen, setIsCompanyFormOpen] = useState<boolean>(false);
  const [isAdminFormOpen, setIsAdminFormOpen] = useState<boolean>(false);
  const [companyToDelete, setCompanyToDelete] =
    useState<GetCompanyResponseDto | null>(null);
  const [companyToAddAdmin, setCompanyToAddAdmin] =
    useState<GetCompanyResponseDto | null>(null);
  const [companyToEdit, setCompanyToEdit] =
    useState<GetCompanyResponseDto | null>(null);
  const [selectorCompanyId, setSelectorCompanyId] = useState<number | null>(
    null
  );
  const [actorToSendSms, setActorToSendSms] = useState<{
    id: number;
    name: string;
    role: string;
  } | null>(null);
  const [actorToSendEmail, setActorToSendEmail] = useState<{
    id: number;
    name: string;
    email: string;
  } | null>(null);

  const loginAsSelector = (companyId: number) => {
    setSelectorCompanyId(companyId);
  };

  const onSendSms = async (id: number, name: string, role: string) => {
    setActorToSendSms({ id, name, role });
  };

  const onSendEmail = async (id: number, name: string, email: string) => {
    setActorToSendEmail({ id, name, email });
  };

  const buttons = companies && companies.length > 0 && (
    <Box display="flex" justifyContent="flex-end">
      <Button
        size="small"
        variant="contained"
        onClick={() => setIsCompanyFormOpen(true)}
      >
        Crear
      </Button>
    </Box>
  );

  const handleAddAdmin = (company: GetCompanyResponseDto) => {
    setCompanyToAddAdmin(company);
    setIsAdminFormOpen(true);
  };

  const confirmDelete = companyToDelete && (
    <Confirm
      open={Boolean(companyToDelete)}
      content={
        <>
          <Typography>
            ¿Realmente desea borrar el centro médico{" "}
            <b>{companyToDelete.name}</b>?
          </Typography>
          <Typography>Se borrarán todos sus usuarios y valores.</Typography>
        </>
      }
      cancelText="Cancelar"
      confirmText="Borrar"
      onCancel={() => setCompanyToDelete(null)}
      onClose={() => setCompanyToDelete(null)}
      onConfirm={async () => {
        setIsLoading(true);
        try {
          await del(companyToDelete.id);
          toast.success(`Se ha borrado la company ${companyToDelete.name}`);
        } catch (err: any) {
          toast.error("Error al eliminar");
        }
        setCompanyToDelete(null);
        setIsLoading(false);
      }}
    />
  );

  const companyCreateForm = (
    <CompanyCreate
      open={isCompanyFormOpen}
      onClose={() => setIsCompanyFormOpen(false)}
      onSave={async (dto: CreateCompanyRequestDto) => {
        setIsLoading(true);
        try {
          await add(dto);
        } catch (err: any) {
          toast.error(`Error al crear`);
        }
        setIsCompanyFormOpen(false);
        setIsLoading(false);
      }}
    />
  );

  const adminCreateForm = companyToAddAdmin && profile && (
    <AdminCreate
      company={companyToAddAdmin}
      open={isAdminFormOpen}
      onClose={() => setIsAdminFormOpen(false)}
      onSave={async (dto: AddAdminCompanyRequestDto) => {
        setIsLoading(true);
        try {
          const response: ResponseApi<AddAdminCompanyResponseDto> =
            await addAdmin(dto);
          setIsAdminFormOpen(false);
          if (response.data) {
            await assignGroupPermission(response.data.id, "admin_master");
            toast.info("Admin creado correctamente");
            toast.info(
              `Recuerda asignar un Grupo de permisos al administrador ${response.data.name}`
            );
          } else {
            toast.info("No se ha podido asignar permisos al admin");
          }
        } catch (err: any) {
          toast.error(`Error al crear`);
        }
        setIsLoading(false);
      }}
    />
  );

  const companyEditForm = companyToEdit && (
    <CompanyEdit
      open={true}
      companyToEditId={companyToEdit.id}
      onClose={() => setCompanyToEdit(null)}
      onSave={async (company) => {
        setIsLoading(true);
        try {
          await update(companyToEdit.id, company);
        } catch (err: any) {
          toast.error("Error al editar");
        }
        setCompanyToEdit(null);
        setIsLoading(false);
      }}
    />
  );

  const loginAs = async (userId: number) => {
    const options = { headers: { Authorization: "Bearer " + token } };
    const { accessToken, refreshToken } = (
      await axios.post(
        `${process.env.REACT_APP_API_URL}/token/${userId}`,
        null,
        options
      )
    ).data;
    window.open(
      `${process.env.REACT_APP_WEB_URL}/profile?management=true&token=${accessToken}&refreshToken=${refreshToken}`,
      "_blank"
    );
  };

  const loginAsApp = async (userId: number, role: string) => {
    const options = { headers: { Authorization: "Bearer " + token } };
    const { accessToken, refreshToken } = (
      await axios.post(
        `${process.env.REACT_APP_API_URL}/token/${userId}`,
        null,
        options
      )
    ).data;
    const redirectURL = `${
      process.env.REACT_APP_WEB_URL
    }/app.html?path=home/${userId}/${role}/${accessToken}/${refreshToken}/${encodeURIComponent(
      process.env.REACT_APP_API_URL ?? ""
    )}`;

    // Try with EXPO:
    // const redirectURL = `exp://192.168.1.131:8082/--/home/${userId}/${role}/${accessToken}/${refreshToken}/${encodeURIComponent(process.env.REACT_APP_API_URL as string)}`

    // Reedirect to the url
    window.location.href = redirectURL;
  };

  const companyUserSelector = selectorCompanyId && (
    <CompanyUserSelector
      companyId={selectorCompanyId}
      isOpen={Boolean(selectorCompanyId)}
      onClose={() => setSelectorCompanyId(null)}
      onLoginAs={loginAs}
      onLoginAsApp={loginAsApp}
      onSendSms={onSendSms}
      onSendEmail={onSendEmail}
    />
  );

  const confirmSms = actorToSendSms && (
    <Confirm
      open={Boolean(actorToSendSms)}
      content={
        <>
          ¿Realmente desea enviar el SMS de registro al usuario{" "}
          <b>{actorToSendSms.name}</b> con teléfono <b>{""}</b>?
        </>
      }
      cancelText="Cancelar"
      confirmText="Enviar SMS de registro"
      onCancel={() => setActorToSendSms(null)}
      onClose={() => setActorToSendSms(null)}
      onConfirm={async () => {
        setIsLoading(true);
        try {
          const { data } = await sendRegistrationSMS(actorToSendSms.id);
          await mutate();
          if (process.env.REACT_APP_SHOW_MSG_SMS_CONSOLE === "true") {
            console.log(data?.message);
            toast.info(
              "Mira en la consola. Copia el enlace de On Boarding y pégalo en una nueva ventana en modo incógnito (sin token en localStorage)."
            );
            toast.info(data?.message);
          }
        } catch (err: any) {
          toast.error(
            `Error al enviar SMS: ${err.response?.data?.message || err.message}`
          );
        }
        setActorToSendSms(null);
        setIsLoading(false);
      }}
    />
  );

  const confirmEmail = actorToSendEmail && (
    <Confirm
      open={Boolean(actorToSendEmail)}
      content={
        <>
          ¿Realmente desea enviar el Email de registro al usuario{" "}
          <b>{actorToSendEmail.name}</b> con email{" "}
          <b>{actorToSendEmail.email}</b>?
        </>
      }
      cancelText="Cancelar"
      confirmText="Enviar Email de registro"
      onCancel={() => setActorToSendEmail(null)}
      onClose={() => setActorToSendEmail(null)}
      onConfirm={async () => {
        setIsLoading(true);
        try {
          // call the hook to send the email
          const { data } = await sendRegistrationEmail(
            actorToSendEmail?.id as number
          );
          await mutate();
          toast.info(data?.message);
        } catch (err: any) {
          toast.error(
            `Error al enviar Email: ${
              err.response?.data?.message || err.message
            }`
          );
        }
        setActorToSendEmail(null);
        setIsLoading(false);
      }}
    />
  );

  return (
    <Page title={"Centros médicos"} buttons={buttons}>
      <CompanyList
        onDelete={setCompanyToDelete}
        onEdit={setCompanyToEdit}
        onLoginAs={loginAsSelector}
        onAddAdmin={handleAddAdmin}
      />
      {companyCreateForm}
      {companyEditForm}
      {confirmDelete}
      {companyUserSelector}
      {adminCreateForm}
      {confirmSms}
      {confirmEmail}
    </Page>
  );
};

export { Companies };
