import { GetTreatmentResponseDto } from "../../../types"
import { AddTreatmentRequestDto, AddTreatmentResponseDto, ItemTreatmentListResponseDto, ItemTreatmentResponseDto } from "../../dto/Treatment/Treatments"
import { ResponseApi, ResponseApiList, TypeOnError } from "../../global"
import Fetcher from "../Fetcher"
import { CreateAction, CreateActionWithEndpoint, IUseBase, useInstance } from "../IUseBaseElements"

export interface IUseTreatments extends IUseBase<ResponseApiList<ItemTreatmentListResponseDto>> {
  add: (treatment: AddTreatmentRequestDto) => Promise<ResponseApi<AddTreatmentResponseDto>>,
  del: (id: number) => Promise<ResponseApi<boolean>>,
  findOne: (id: number) => Promise<ResponseApi<GetTreatmentResponseDto>>,
  end: (id: number) => Promise<ResponseApi<boolean>>,
  reopen: (id: number) => Promise<ResponseApi<boolean>>
}

function useTreatments(baseURL: string, token: string, shouldFetch?: boolean, onError?: TypeOnError): IUseTreatments {

  const swrResponse: IUseBase<ResponseApiList<ItemTreatmentListResponseDto>> = useInstance<ResponseApiList<ItemTreatmentListResponseDto>>(
    baseURL,
    token,
    shouldFetch ? `/trackings` : null,
    onError
  );

  const add = async (treatment: AddTreatmentRequestDto): Promise<ResponseApi<AddTreatmentResponseDto>> => {
    let path: string = '/trackings';
    const action = CreateActionWithEndpoint<ResponseApi<AddTreatmentResponseDto>, AddTreatmentRequestDto, ResponseApiList<ItemTreatmentListResponseDto>>(
      baseURL,
      token,
      swrResponse.mutate,
      'post',
      onError
    );
    return action(path, treatment);
  };

  const del = async (id: number) => {
    let path: string = `/trackings/${id}`;
    const action = CreateAction<ResponseApi<boolean>, boolean, ResponseApiList<ItemTreatmentListResponseDto>>(
      baseURL,
      token,
      swrResponse.mutate,
      'delete',
      path,
      onError
    );
    return action();
  };

  const findOne = async (id: number): Promise<ResponseApi<GetTreatmentResponseDto>> => {
    let path: string = `/trackings/${id}`;
    const action = CreateAction<ResponseApi<GetTreatmentResponseDto>, boolean, ResponseApiList<ItemTreatmentListResponseDto>>(
      baseURL,
      token,
      swrResponse.mutate,
      'get',
      path,
      onError
    );
    return action();
  };

  const end = async (id: number): Promise<ResponseApi<boolean>> => {
    let path: string = `/trackings/${id}/end`;
    const action = CreateActionWithEndpoint<ResponseApi<boolean>, boolean, ResponseApiList<ItemTreatmentListResponseDto>>(
      baseURL,
      token,
      swrResponse.mutate,
      'patch',
      onError
    );
    return action(path);
  };

  const reopen = async (id: number): Promise<ResponseApi<boolean>> => {
    let path: string = `/trackings/${id}/reopen`;
    const action = CreateActionWithEndpoint<ResponseApi<boolean>, boolean, ResponseApiList<ItemTreatmentListResponseDto>>(
      baseURL,
      token,
      swrResponse.mutate,
      'patch',
      onError
    );
    return action(path);
  };

  return {
    ...swrResponse,
    add,
    del,
    findOne,
    end,
    reopen
  } as IUseTreatments;
}

export { useTreatments }
