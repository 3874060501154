import { toast } from "react-toastify";
import { JwtMalformedError } from '@doctomatic/sdk/build/exceptions/JwtMalformedError';

export const processError = (logout: any, history: any) => (
  async (err: any, key: any, config: any): Promise<void> => {
    if (err instanceof JwtMalformedError) {
      localStorage.removeItem('token');
      localStorage.removeItem('refreshToken');
      logout();
      history.push('/');
      toast.error(err.message);
      console.error(err);
    } else {
      let message = '';
      if (err && err.response) {
        if (err.response.status)
          message = `${err.response.status}:`;
        if (err.response.data && err.response.data.message)
          message = message + ` ${err.response.data.message}`;
        toast.error(message);
        console.error(message);
      } else {
        console.error("Error without object");
        toast.warning("Error without object");
      }
    }
  }
);
