import {
  GetNurseResponseDto,
  AddNurseRequestDto,
  UpdateNurseRequestDto,
  UpdateNurseResponseDto,
  AddNurseResponseDto,
  ItemNurseListDto,
} from '../dto/Actors/Nurse/Nurse';
import { ResponseApi, ResponseApiList, TypeOnError } from '../global';
import { CreateAction, CreateActionWithEndpoint, IUseBase, useInstance } from './IUseBaseElements';

export interface IUseNurses
  extends IUseBase<ResponseApiList<ItemNurseListDto>> {
  get: (id: number) => Promise<ResponseApi<GetNurseResponseDto>>;
  patch: (id: number, dto: UpdateNurseRequestDto) => Promise<ResponseApi<UpdateNurseResponseDto>>;
  add: (nurse: AddNurseRequestDto) => Promise<ResponseApi<AddNurseResponseDto>>;
  del: (id: number) => Promise<ResponseApi<boolean>>;
  restore: (id: number) => Promise<ResponseApi<boolean>>;
}

export function useNurses(
  baseURL: string,
  token: string,
  showDeleted: boolean,
  query?: any, 
  shouldFetch?: boolean,
  onError?: TypeOnError
): IUseNurses {

  let path: string =`/nurses?showDeleted=${showDeleted}`;
  if(query) path = path + `&limit=${query.limit}&page=${query.page}`
  
  const swrResponse: IUseBase<ResponseApiList<ItemNurseListDto>> = useInstance<ResponseApiList<ItemNurseListDto>>(
    baseURL,
    token,
    shouldFetch ? path : null,
    onError
  );

  const get = async (id: number): Promise<ResponseApi<GetNurseResponseDto>> => {
    let path: string = `/nurses/${id}`;
    const action = CreateAction<ResponseApi<GetNurseResponseDto>, boolean, ResponseApiList<ItemNurseListDto>>(
      baseURL,
      token,
      swrResponse.mutate,
      'get',
      path,
      onError
    );
    return action();
  };

  const patch = async (id: number, nurse: UpdateNurseRequestDto): Promise<ResponseApi<UpdateNurseResponseDto>> => {
    let path: string = `/nurses/${id}`;
    const action = CreateActionWithEndpoint<ResponseApi<UpdateNurseResponseDto>, UpdateNurseRequestDto, ResponseApiList<ItemNurseListDto>>(
      baseURL,
      token,
      swrResponse.mutate,
      'patch',
      onError
    );
    return action(path, nurse);
  };

  const add = async (nurse: AddNurseRequestDto): Promise<ResponseApi<AddNurseResponseDto>> => {
    let path: string = '/nurses';
    const action = CreateActionWithEndpoint<ResponseApi<AddNurseResponseDto>, AddNurseRequestDto, ResponseApiList<ItemNurseListDto>>(
      baseURL,
      token,
      swrResponse.mutate,
      'post',
      onError
    );
    return action(path, nurse);
  };

  const del = async (id: number): Promise<ResponseApi<boolean>> => {
    let path: string = `/nurses/${id}`;
    const action = CreateAction<ResponseApi<boolean>, boolean, ResponseApiList<ItemNurseListDto>>(
      baseURL,
      token,
      swrResponse.mutate,
      'delete',
      path,
      onError
    );
    return action();
  };

  const restore = async (id: number): Promise<ResponseApi<boolean>> => {
    const path: string = `/nurses/${id}/restore`;
    const action = CreateAction<ResponseApi<boolean>, void, ResponseApiList<ItemNurseListDto>>(
      baseURL,
      token,
      swrResponse.mutate,
      'post',
      path,
      onError
    );
    return action();
  };

  return {
    ...swrResponse,
    get,
    add,
    patch,
    del,
    restore,
    onError,
  } as IUseNurses;
}
