import { AlertState } from '../dto/Alerts/IAlerts';
import { ResponseApi, TypeOnError } from '../global'
import Fetcher from './Fetcher'
import { CreateActionWithoutMutate } from './IUseBaseElements'

export interface IUseAlerts {
  setAck: (id: number) => Promise<ResponseApi<any>>,
  updateAlertState: (id: number, dto: { state: any }) => Promise<ResponseApi<any>>,
  updateAlertComments: (id: number, dto: { comments: string | undefined, type?: string }) => Promise<ResponseApi<any>>
}

export function useAlerts(baseURL: string, token: string, shouldFetch?: boolean, onError?: TypeOnError): IUseAlerts {

  const setAck = async (id: number): Promise<ResponseApi<any>> => {
    let path: string = `/alerts/${id}/ack`;
    const action = CreateActionWithoutMutate<ResponseApi<any>>(
      baseURL,
      token,
      'post',
      path,
      onError
    );
    return action();
  };

  const updateAlertState = async (id: number, dto: { state: AlertState, type?: string }): Promise<ResponseApi<any>> => {
    let path: string = `/alerts/${id}/state`;
    const action = CreateActionWithoutMutate<ResponseApi<any>, any>(
      baseURL,
      token,
      'patch',
      path,
      onError
    );
    return action(dto);
  };

  const updateAlertComments = async (id: number, dto: { comments: string | undefined, type?: string }): Promise<ResponseApi<any>> => {
    const response = await Fetcher<ResponseApi<any>>(baseURL, token)(`/alerts/${id}/comments`, 'patch', dto)
    return response
  }
  return {
    setAck,
    updateAlertState,
    updateAlertComments
  }
}
