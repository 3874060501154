import { GetAdminCompanyResponseDto } from "../dto/Actors/AdminCompany/AdminCompany";
import { GetDoctorResponseDto } from "../dto/Actors/Doctor/Doctor";
import { GetNurseResponseDto } from "../dto/Actors/Nurse/Nurse";
import { GetPatientResponseDto } from "../dto/Patient/Patient";
import { GetSuperadminResponseDto } from "../dto/Superadmin";
import { GetTranslatorResponseDto } from "../dto/Translators";
import { UpdateProfileRequestDto, UpdateProfileResponseDto } from "../dto/User";
import { ResponseApi, TypeOnError } from "../global";
import { CreateActionWithoutMutate, IUseBase, useInstance } from "./IUseBaseElements";

const JWT_REGEX: RegExp = /^[A-Za-z0-9-_=]+\.[A-Za-z0-9-_=]+\.?[A-Za-z0-9-_.+/=]*$/;

export type GetActorResponseDto =
  GetPatientResponseDto
  | GetDoctorResponseDto
  | GetNurseResponseDto
  | GetAdminCompanyResponseDto
  | GetSuperadminResponseDto
  | GetTranslatorResponseDto;

export interface IUseProfile extends IUseBase<ResponseApi<GetActorResponseDto>> {
  update: (user: UpdateProfileRequestDto) => Promise<ResponseApi<UpdateProfileResponseDto>>;
}

export function useProfile(
  baseURL: string,
  token: string,
  getPermissions: boolean = false,
  shouldFetch?: boolean,
  onError?: TypeOnError
): IUseProfile {

  let path = `/profile`
  if (getPermissions) path += `?getPermissions=true`
  const swrResponse: IUseBase<ResponseApi<GetActorResponseDto>> = useInstance<ResponseApi<GetActorResponseDto>>(
    baseURL,
    token,
    shouldFetch ? path : null,
    onError
  );

  const update = async (profile: UpdateProfileRequestDto) => {
    const path: string = `/profile`;
    const action = CreateActionWithoutMutate<
      Promise<ResponseApi<UpdateProfileResponseDto>>,
      UpdateProfileRequestDto>(
        baseURL,
        token,
        'patch',
        path,
        onError
      );
    return action(profile);
  }

  return {
    ...swrResponse,
    update
  };
}
