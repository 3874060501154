import {
  GetAdminCompanyResponseDto,
  AddAdminCompanyRequestDto,
  UpdateAdminCompanyRequestDto,
  UpdateAdminCompanyResponseDto,
  AddAdminCompanyResponseDto,
  ItemAdminCompanyListDto
} from '../dto/Actors/AdminCompany/AdminCompany'
import { ResponseApi, ResponseApiList, TypeOnError } from '../global'
import { CreateAction, CreateActionWithEndpoint, IUseBase, useInstance } from './IUseBaseElements';


export interface IUseAdminCompanies
  extends IUseBase<ResponseApiList<ItemAdminCompanyListDto>> {
  get: (id: number) => Promise<ResponseApi<GetAdminCompanyResponseDto>>;
  patch: (id: number, adminCompany: UpdateAdminCompanyRequestDto) => Promise<ResponseApi<UpdateAdminCompanyResponseDto>>;
  add: (adminCompany: AddAdminCompanyRequestDto) => Promise<ResponseApi<AddAdminCompanyResponseDto>>;
  del: (id: number) => Promise<ResponseApi<boolean>>;
  restore: (id: number) => Promise<ResponseApi<boolean>>;
}

export function useAdminCompanies(baseURL: string, token: string,
  showDeleted?: boolean, shouldFetch?: boolean,
  onError?: TypeOnError): IUseAdminCompanies {

  const swrResponse: IUseBase<ResponseApiList<ItemAdminCompanyListDto>> = useInstance<ResponseApiList<ItemAdminCompanyListDto>>(
    baseURL,
    token,
    shouldFetch ? `/admin_company?showDeleted=${showDeleted}` : null,
    onError
  );

  const get = async (id: number): Promise<ResponseApi<GetAdminCompanyResponseDto>> => {
    let path: string = `/admin_company/${id}`;
    const action = CreateAction<ResponseApi<GetAdminCompanyResponseDto>, boolean, ResponseApiList<ItemAdminCompanyListDto>>(
      baseURL,
      token,
      swrResponse.mutate,
      'get',
      path,
      onError
    );
    return action();
  }

  const patch = async (id: number, adminCompany: UpdateAdminCompanyRequestDto) => {
    let path: string = `/admin_company/${id}`;
    const action = CreateActionWithEndpoint<ResponseApi<UpdateAdminCompanyResponseDto>, UpdateAdminCompanyRequestDto, ResponseApiList<ItemAdminCompanyListDto>>(
      baseURL,
      token,
      swrResponse.mutate,
      'patch',
      onError
    );
    return action(path, adminCompany);
  };

  const add = async (adminCompany: AddAdminCompanyRequestDto): Promise<ResponseApi<AddAdminCompanyResponseDto>> => {
    let path: string = '/admin_company';
    const action = CreateActionWithEndpoint<ResponseApi<AddAdminCompanyResponseDto>, AddAdminCompanyRequestDto, ResponseApiList<ItemAdminCompanyListDto>>(
      baseURL,
      token,
      swrResponse.mutate,
      'post',
      onError
    );
    return action(path, adminCompany);
  };

  const del = async (id: number): Promise<ResponseApi<boolean>> => {
    let path: string = `/admin_company/${id}`;
    const action = CreateAction<ResponseApi<boolean>, boolean, ResponseApiList<ItemAdminCompanyListDto>>(
      baseURL,
      token,
      swrResponse.mutate,
      'delete',
      path,
      onError
    );
    return action();
  };

  const restore = async (id: number): Promise<ResponseApi<boolean>> => {
    const path: string = `/admin_company/${id}/restore`;
    const action = CreateAction<ResponseApi<boolean>, void, ResponseApiList<ItemAdminCompanyListDto>>(
      baseURL,
      token,
      swrResponse.mutate,
      'post',
      path,
      onError
    );
    return action();
  }

  return {
    ...swrResponse,
    get,
    patch,
    add,
    del,
    restore
  } as IUseAdminCompanies
}
