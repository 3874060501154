type TypeId = number;


export interface IBasicItem {
    id:TypeId;
    name:string;
}

export interface IItemList {
    id:TypeId;
    name:string;
}

export interface IItemDeviceList {
    id:TypeId;
    name:string;
    typeName:string;
    form?:string;
    translations?:string;
    visible?:boolean;
}

export class BasicItem implements IBasicItem {
    id: TypeId = 0;
    name:string=''
}
export class ItemList implements IItemList {
    id: TypeId = 0;
    name:string=''
}
export interface IPatientList extends IBasicItem {
    externalId?: string;
}

export class PatientList extends ItemList implements IPatientList {
    externalId?: string;
}
