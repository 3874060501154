import { IsNotEmpty, IsOptional } from "class-validator";
export interface IGetCompanyResponseDto {
  id: number;
  fee: number;
  onBoardingReq: OnBoardingOptions;
  tax: number;
  stripeAccount: string;
  stripePriceId: string;
  primary: string;
  secondary: string;
  logo: string;
  name: string;
  description?: string;
  internDescription?: string;
  trialCode?: string;
  trialDays?: number;
  maxPatientsWithoutSubscription: number;
  showExternalId?: boolean;
  forceFrequenciesCreation?: boolean;
  maxCost?: number;
  actualCost?: number;
  buttonColor?: string;
  font?: string;
  countSms?: number;
  messengerChannel: string;
  externalButton?: string;
  autoRead?: boolean;
  companyType?: CompanyType;
  parametersUrl?: string;
  readDeviceDescription: boolean;
}

export interface IGetMyCompanyResponseDto {
  primary?: string;
  secondary?: string;
  logo?: string;
  name?: string;
  showExternalId?: boolean;
  forceFrequenciesCreation?: boolean;
  stripeAccount?: string;
  hasStripePriceId: boolean;
  buttonColor?: string;
  font?: string;
}

export interface ICreateCompanyRequestDto {
  name: string;
  isDemo?: boolean;
  addDevices?: boolean;
}

export interface ICreateCompanyResponseDto extends GetCompanyResponseDto {}

export interface IDeleteCompanyResponseDto {
  usersDeleted: number;
}

export interface IUpdateCompanyCmsRequestDto {
  name?: string;
  description?: string;
  internDescription?: string;
  fee?: number;
  tax?: number;
  stripeAccount?: string;
  stripePriceId?: string;
  trialCode?: string;
  trialDays?: number;
  taxId?: string;
  directOnBoarding?: boolean;
  maxPatientsWithoutSubscription?: number;
  maxCost?: number;
  actualCost?: number;
  buttonColor?: string;
  logo?: string;
  font?: string;
  countSms?: number;
  onBoardingReq?: OnBoardingOptions;
  autoRead?: boolean;
  companyType?: CompanyType;
  readDeviceDescription?: boolean;
}

export interface IUpdateCompanyRequestDto {
  primary?: string;
  secondary?: string;
  logo?: string;
  name?: string;
  showExternalId?: boolean;
  forceFrequenciesCreation?: boolean;
  autoRead?: boolean;
}

export interface IUpdateCompanyCmsResponseDto {
  id: number;
  fee: number;
  tax: number;
  stripeAccount: string;
  stripePriceId: string;
  primary: string;
  secondary: string;
  logo: string;
  name: string;
  description?: string;
  internDescription?: string;
  maxCost?: number;
  actualCost?: number;
  buttonColor?: string;
  font?: string;
  countSms?: number;
  onBoardingReq?: OnBoardingOptions;
}

export interface IUpdateCompanyResponseDto {
  primary: string;
  secondary: string;
  logo: string;
  name: string;
}

export interface IGetSubscriptionsInfoResponseDto {
  companyHasSubscription: boolean;
  subscribedPatients: number;
  maxPatientsWithoutSubscription: number;
}

export enum OnBoardingOptions {
  Value1 = 1,
  Value2 = 2,
  Value3 = 3,
  Value4 = 4,
}

export class GetCompanyResponseDto implements IGetCompanyResponseDto {
  id: number = 0;
  fee: number = 0;
  tax: number = 0;
  stripeAccount: string = "";
  stripePriceId: string = "";
  primary: string = "";
  secondary: string = "";
  logo: string = "";
  name: string = "";
  onBoardingReq: OnBoardingOptions = OnBoardingOptions.Value4;

  trialCode?: string;
  trialDays?: number;
  taxId?: string;
  maxPatientsWithoutSubscription: number = 0;
  showExternalId?: boolean;
  forceFrequenciesCreation?: boolean;
  maxCost?: number = 0;
  actualCost?: number = 0;
  buttonColor?: string = "";
  font?: string = "";
  countSms?: number = 0;
  messengerChannel: string = "";
  description?: string = "";
  internDescription?: string = "";
  externalButton?: string = "";
  autoRead?: boolean;
  companyType?: CompanyType;
  parametersUrl?: string = "";
  readDeviceDescription: boolean = false;
}

export class GetMyCompanyResponseDto implements IGetMyCompanyResponseDto {
  primary?: string;
  secondary?: string;
  logo?: string;
  name?: string;
  showExternalId?: boolean;
  forceFrequenciesCreation?: boolean;
  stripeAccount?: string;
  hasStripePriceId: boolean = false;
  buttonColor?: string = "";
  font?: string = "";
}

export class CreateCompanyRequestDto implements ICreateCompanyRequestDto {
  @IsNotEmpty() name: string = "";

  @IsOptional() isDemo?: boolean;

  @IsOptional() addDevices?: boolean;
}

export class CreateCompanyResponseDto
  extends GetCompanyResponseDto
  implements ICreateCompanyResponseDto {}

export class DeleteCompanyResponseDto implements IDeleteCompanyResponseDto {
  usersDeleted: number = 0;
}

export class UpdateCompanyCmsRequestDto implements IUpdateCompanyCmsRequestDto {
  name?: string;
  description?: string;
  internDescription?: string;
  fee?: number;
  tax?: number;
  stripeAccount?: string;
  stripePriceId?: string;
  trialCode?: string;
  trialDays?: number;
  taxId?: string;
  directOnBoarding?: boolean;
  maxPatientsWithoutSubscription?: number;
  maxCost?: number;
  actualCost?: number;
  buttonColor?: string;
  logo?: string;
  font?: string;
  countSms?: number;
  onBoardingReq?: OnBoardingOptions;
  autoRead?: boolean;
  companyType?: CompanyType;
  readDeviceDescription?: boolean;
}

export class UpdateCompanyRequestDto implements IUpdateCompanyRequestDto {
  primary?: string;
  secondary?: string;
  logo?: string;
  name?: string;
  showExternalId?: boolean;
  forceFrequenciesCreation?: boolean;
  autoRead?: boolean;
  readDeviceDescription?: boolean;
}

export class UpdateCompanyCmsResponseDto
  implements IUpdateCompanyCmsResponseDto
{
  id: number = 0;
  fee: number = 0;
  tax: number = 0;
  stripeAccount: string = "";
  stripePriceId: string = "";
  primary: string = "";
  secondary: string = "";
  logo: string = "";
  name: string = "";
  description?: string = "";
  internDescription?: string = "";
  maxCost: number = 0;
  actualCost: number = 0;
  buttonColor?: string = "";
  font?: string = "";
  countSms: number = 0;
  onBoardingReq: OnBoardingOptions = OnBoardingOptions.Value4;
}

export class UpdateCompanyResponseDto implements IUpdateCompanyResponseDto {
  primary: string = "";
  secondary: string = "";
  logo: string = "";
  name: string = "";
}

export class GetSubscriptionsInfoResponseDto
  implements IGetSubscriptionsInfoResponseDto
{
  companyHasSubscription: boolean = false;
  subscribedPatients: number = 0;
  maxPatientsWithoutSubscription: number = 0;
}

export enum CompanyType {
  enterprise = "enterprise",
  family = "family",
}
