
import { ItemAlertConfigUserResponseDto } from '../dto/Alerts/config/user/AlertConfigUser';
import type {
  CreateCompanyRequestDto,
  CreateCompanyResponseDto,
  DeleteCompanyResponseDto,
  GetCompanyResponseDto,
  UpdateCompanyCmsRequestDto,
  UpdateCompanyResponseDto
} from '../dto/Company'
import { ResponseApi, ResponseApiList, TypeOnError } from '../global'
import { CreateAction, CreateActionWithEndpoint, IUseBase, useInstance } from './IUseBaseElements'

export interface IUseCompanies
  extends IUseBase<ResponseApiList<GetCompanyResponseDto>> {
  get: (id: number) => Promise<ResponseApi<GetCompanyResponseDto>>,
  update: (id: number, user: UpdateCompanyCmsRequestDto) => Promise<ResponseApi<UpdateCompanyResponseDto>>,
  add: (dto: CreateCompanyRequestDto) => Promise<ResponseApi<CreateCompanyResponseDto>>,
  del: (id: number) => Promise<ResponseApi<DeleteCompanyResponseDto>>,
}

export function useCompanies(baseURL: string, token: string, shouldFetch?: boolean, onError?: TypeOnError): IUseCompanies {

  const swrResponse: IUseBase<ResponseApiList<GetCompanyResponseDto>> = useInstance<ResponseApiList<GetCompanyResponseDto>>(
    baseURL,
    token,
    shouldFetch ? `/companies` : null,
    onError
  );

  const get = async (id: number): Promise<ResponseApi<GetCompanyResponseDto>> => {
    let path: string = `/companies/${id}`;
    const action = CreateAction<ResponseApi<GetCompanyResponseDto>, boolean, ResponseApiList<GetCompanyResponseDto>>(
      baseURL,
      token,
      swrResponse.mutate,
      'get',
      path,
      onError
    );
    return action();
  }


  const update = async (id: number, company: UpdateCompanyCmsRequestDto): Promise<ResponseApi<UpdateCompanyResponseDto>> => {

    let path: string = `/companies/${id}`;
    const action = CreateActionWithEndpoint<ResponseApi<UpdateCompanyResponseDto>,
      UpdateCompanyCmsRequestDto,
      ResponseApiList<GetCompanyResponseDto>>(
        baseURL,
        token,
        swrResponse.mutate,
        'patch',
        onError
      );
    return action(path, company);
  }

  const add = async (dto: CreateCompanyRequestDto): Promise<ResponseApi<CreateCompanyResponseDto>> => {

    let path: string = `/companies`;
    const action = CreateActionWithEndpoint<ResponseApi<CreateCompanyResponseDto>,
      CreateCompanyRequestDto,
      ResponseApiList<GetCompanyResponseDto>>(
        baseURL,
        token,
        swrResponse.mutate,
        'post',
        onError
      );
    return action(path, dto);
  }

  const del = async (id: number): Promise<ResponseApi<DeleteCompanyResponseDto>> => {
    let path: string = `/companies/${id}`;
    const action = CreateAction<ResponseApi<DeleteCompanyResponseDto>, boolean, ResponseApiList<GetCompanyResponseDto>>(
      baseURL,
      token,
      swrResponse.mutate,
      'delete',
      path,
      onError
    );
    return action();

  }

  return {
    ...swrResponse,
    get,
    update,
    add,
    del
  } as IUseCompanies;

}
