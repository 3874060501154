import { CreateMeasurementImageRequestDto } from "@doctomatic/sdk/build/dto/MeasurementImage";

export const formattedDate = (date: string) => {
  const d = new Date(date);
  // const dateOpt = { day: '2-digit', month: '2-digit', year: '2-digit' }
  return d.toLocaleDateString("es-ES");
};

export const removeDuplicates = (
  measurementImages: CreateMeasurementImageRequestDto[]
) => {
  const uniqueImages: Map<string, CreateMeasurementImageRequestDto> = new Map();

  for (const measurementImage of measurementImages) {
    const key = `${measurementImage.created_at}_${measurementImage.signId}_${measurementImage.translateImage?.image?.id}`;

    if (!uniqueImages.has(key)) {
      uniqueImages.set(key, measurementImage);
    }
  }
  return Array.from(uniqueImages.values());
};
