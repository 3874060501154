import { GetPatientResponseDto } from '../dto/Patient/Patient';
import { ResponseApi, TypeOnError } from '../global'
import { IUseBase, useInstance } from './IUseBaseElements'

export interface IUsePatientWithTreatments
  extends IUseBase<ResponseApi<GetPatientResponseDto>> {
}

export function usePatientWithTreatments(baseURL: string, token: string, id: number, showEnded?: boolean, shouldFetch?: boolean, onError?: TypeOnError): IUsePatientWithTreatments {

  const swrResponse: IUseBase<ResponseApi<GetPatientResponseDto>> = useInstance<ResponseApi<GetPatientResponseDto>>(
    baseURL,
    token,
    shouldFetch ? `/patients/${id}/trackings?showEnded=${showEnded}` : null,
    onError
  );

  return {
    ...swrResponse
  } as IUsePatientWithTreatments;
}
