import type { GetNurseResponseDto } from '../dto/Actors/Nurse/Nurse'
import { ResponseApi, TypeOnError } from '../global'
import { IUseBase, useInstance } from './IUseBaseElements'

export interface IUseNurse extends IUseBase<ResponseApi<GetNurseResponseDto>> { }

export function useNurse(baseURL: string, token: string, id: number, shouldFetch?: boolean, onError?: TypeOnError): IUseNurse {
  const swrResponse: IUseBase<ResponseApi<GetNurseResponseDto>> = useInstance<ResponseApi<GetNurseResponseDto>>(
    baseURL,
    token,
    shouldFetch ? `/nurses/${id}` : null,
    onError
  );

  return {
    ...swrResponse
  }
}
