import { GetSignResponseDto } from "./Sign/Signs";
import { GetTranslateImageResponseDto } from "./TranslateImage";
import { GetUserResponseDto } from "./User";

export interface ICreateMeasurementImageRequestDto {
  translateImage?: GetTranslateImageResponseDto;
  translateImageId: number;
  translator?: GetUserResponseDto;
  translatorId: number;
  sign?: GetSignResponseDto;
  signId: number;
  value?: number;
  created_at: Date;
}
  
export class CreateMeasurementImageRequestDto implements ICreateMeasurementImageRequestDto {
  translateImage?: GetTranslateImageResponseDto;
  translateImageId: number = 0;
  translator?: GetUserResponseDto;
  translatorId: number = 0;
  sign?: GetSignResponseDto;
  signId: number = 0;
  value?: number;
  created_at: Date = new Date();
}

export interface ICreateMeasurementImageResponsetDto {
  id: number
  translateImage?: GetTranslateImageResponseDto;
  translateImageId: number;
  translator?: GetUserResponseDto;
  translatorId: number;
  sign?: GetSignResponseDto;
  signId: number;
  value?: number;
  created_at: Date;
}
  
  export class CreateMeasurementImageResponsetDto implements ICreateMeasurementImageResponsetDto {
    id: number = 0;
    translateImage?: GetTranslateImageResponseDto;
    translateImageId: number = 0;
    translator?: GetUserResponseDto;
    translatorId: number = 0;
    sign?: GetSignResponseDto;
    signId: number=0;
    value?: number;
    created_at: Date = new Date();
  }


export interface IGetMeasurementImageResponseDto {
  id: number;
  translateImage: GetTranslateImageResponseDto;
  translateImageId: number;
  translator: GetUserResponseDto;
  translatorId: number;
  sign: GetSignResponseDto;
  signId: number;
  value: number;
  created_at: Date;
}
export class GetMeasurementImageResponseDto implements IGetMeasurementImageResponseDto {
  id: number = 0;
  translateImage: GetTranslateImageResponseDto = new GetTranslateImageResponseDto();
  translateImageId: number = 0;
  translator: GetUserResponseDto = new GetUserResponseDto();
  translatorId: number = 0;
  sign: GetSignResponseDto = new GetSignResponseDto();
  signId: number = 0;
  value: number = 0;
  created_at: Date = new Date();
}