import { GetAlertByNoMeasureResponseDto } from '../dto/Alerts/Alerts';
import { ResponseApi, TypeOnError } from '../global';
import { IUseBase, useInstance } from './IUseBaseElements';
import { Role } from '../dto/User';
export interface IUseAlertByNoMeasure
  extends IUseBase<ResponseApi<GetAlertByNoMeasureResponseDto>> {
}

export function useAlertByNoMeasure(baseURL: string, token: string, id: number, actorId: number, actorRole: Role, shouldFetch?: boolean, onError?: TypeOnError): IUseAlertByNoMeasure {
  const formatActorRole = (actorRole: Role) => {
    if (actorRole === Role.user) return "patients";
    if (actorRole === Role.superadmin) return Role.superadmin;
    if (actorRole === Role.admin) return "admin_company";
    if (actorRole === Role.nurse) return "nurses";
    if (actorRole === Role.doctor) return "doctors";
    if (actorRole === Role.translatorimage) return "translators";
  }
  const roleName = formatActorRole(actorRole);

  const path = `/${roleName}/${actorId}/alerts/no_measure/${id}`
  const swrResponse: IUseBase<ResponseApi<GetAlertByNoMeasureResponseDto>> = useInstance<ResponseApi<GetAlertByNoMeasureResponseDto>>(
    baseURL,
    token,
    shouldFetch ? path : null,
    onError
  );

  return {
    ...swrResponse
  } as IUseAlertByNoMeasure;
}
