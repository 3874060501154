import React from 'react';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';
function BreadcrumbItem(item, key) {
    var RouterLink = item.routerLink;
    return (React.createElement(React.Fragment, null,
        " ",
        item.url !== ""
            ?
                RouterLink ?
                    React.createElement(RouterLink, { key: key, to: item.url }, item.name)
                    : React.createElement(Link, { underline: "none", href: item.url, key: key }, item.name)
            : React.createElement("p", null, item.name)));
}
export function BreadcrumbDocto(_a) {
    var breadcrumbItems = _a.breadcrumbItems;
    return (React.createElement(Breadcrumbs, { "aria-label": "breadcrumb", separator: ">" }, breadcrumbItems.map(function (item, index, array) {
        return React.createElement(BreadcrumbItem, { url: item.url, name: item.name, key: index, routerLink: item.routerLink });
    })));
}
