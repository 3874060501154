import React, { useEffect, useState } from 'react'
import { useNavigate, useLocation, Routes, Route, Navigate } from 'react-router-dom'
import { Login } from '../components/Login'
import { useApi } from '@doctomatic/sdk/build/Api'
import { LoggedApp } from './LoggedApp'
import { ToastContainer, ToastClassName } from 'react-toastify'
import { SWRConfig } from 'swr'
import useStyles from './styles'
import { ForgotPassword } from './ForgotPassword'
import { ConfigError } from '@doctomatic/sdk/build/global'
import * as Sentry from '@sentry/react'
import { ErrorPage } from './ErrorPage'

const App = (): React.ReactElement => {
  const { token, logout, setToken, refreshTokens } = useApi()
  const classes = useStyles()
  const history = useNavigate()
  const location = useLocation()
  const [path, setPath] = useState(location.pathname)
  const [loaded, setLoaded] = React.useState(false)

  const onError = async () => {
    try {
      const { accessToken, refreshToken } = await refreshTokens(localStorage.getItem('refreshToken') || '')
      localStorage.setItem('token', accessToken || '')
      localStorage.setItem('refreshToken', refreshToken || '')
      return accessToken
    } catch (err : any) {
      localStorage.clear()
      logout()
    }
  }
  
  const toastClass: ToastClassName = options => 'Toastify__toast ' + (options?.type === 'error' ? classes.errorToast : classes.infoToast)
  const query = new URLSearchParams(location.search)

  useEffect(() => {
    ConfigError.onError = onError;
    const queryToken = query.get('token')
    if (queryToken) localStorage.setItem('token', queryToken || '')
    setToken(localStorage.getItem('token') || '')
    if (queryToken && location.pathname !== '/') history(location.pathname)
    setLoaded(true)
  })

  useEffect(() => {
    if(location.pathname !== '/') setPath(location.pathname + location.search)
  },[location])

  return (
    <Sentry.ErrorBoundary fallback={<ErrorPage />}>
    <SWRConfig value={{ onError }}>
      <main className={classes.root}>
        <ToastContainer autoClose={5000} toastClassName={toastClass} />
        { loaded && (
            token ?
              <LoggedApp token={token} />
              :
              <Routes>
                <Route path='/' element={<Login pathname={path}/>}/>      
                <Route path='/forgot' element={<ForgotPassword/>}/> 
                <Route path="*" element={<Navigate replace to="/" />} />
              </Routes>
          )
        }
      </main>
    </SWRConfig>
  </Sentry.ErrorBoundary>
  )
}

export { App }
