import { FlexLayoutGrid } from "@doctomatic/components-react/build/DataGrid/DataGrid"
import { GridColumns } from "@mui/x-data-grid";
import { Page } from "../../Page"
import { UsersByRoleGridColumns } from "./UsersByRoleGridColumns";
import { Role } from '@doctomatic/sdk/build/dto/User'
import { GetCountUsersByRoleResponseDto } from "@doctomatic/sdk/build/dto/CmsDashboard/CmsDashboard";

export interface IUsersByRole {
    id: number;
    role: Role;
    countUsers: number;
}

export interface IUsersByRoleGrid {
    countUsersByRole?: GetCountUsersByRoleResponseDto[];
}

const UsersByRoleGrid = ({countUsersByRole}:IUsersByRoleGrid): React.ReactElement => {

    const usersByRoleDataRows: IUsersByRole[] | undefined = countUsersByRole?.map((
        usersByRole: GetCountUsersByRoleResponseDto,
        index: number
        ) => ({
            id: index,
            role: usersByRole.role,
            countUsers: usersByRole.count
    }))

    const usersByRoleColumns: GridColumns<IUsersByRole> = UsersByRoleGridColumns();

    return(
        <Page title={'Usuarios por rol'} primaryColor="black">
            <FlexLayoutGrid
                columns={usersByRoleColumns}
                rows={usersByRoleDataRows ? usersByRoleDataRows : []}
            ></FlexLayoutGrid>
        </Page>     
    )
}

export { UsersByRoleGrid }