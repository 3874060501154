import useSWR from 'swr';
import { KeyedMutator } from 'swr/dist/types';
import {
  AddAlertConfigByFrequencyIndividualRequestDto, 
  AddAlertConfigByFrequencyIndividualResponseDto,
  GetAlertConfigByFrequencyIndividualResponseDto,
  ItemAlertConfigByFrequencyIndividualDto,
  UpdateAlertConfigByFrequencyIndividualRequestDto,
  UpdateAlertConfigByFrequencyIndividualResponseDto
} from '../../dto/Alerts/config/individual/AlertConfigByFrequencyIndividual';
import { ResponseApi, ResponseApiList, TypeOnError } from '../../global';
import { CreateAction, CreateActionWithEndpoint, IUseBase, useInstance } from '../IUseBaseElements';

type mutateAlertConfigsByFrequency = ResponseApiList<ItemAlertConfigByFrequencyIndividualDto>;

export interface IUseAlertConfigsByFrequencyIndividual
  extends IUseBase<mutateAlertConfigsByFrequency> {
  mutate: KeyedMutator<ResponseApiList<ItemAlertConfigByFrequencyIndividualDto>>,
  findOneById: (id: number) => Promise<ResponseApi<GetAlertConfigByFrequencyIndividualResponseDto>>,
  add: (alertConfig: AddAlertConfigByFrequencyIndividualRequestDto) => Promise<ResponseApi<AddAlertConfigByFrequencyIndividualResponseDto>>,
  patch: (id: number, alertConfig: UpdateAlertConfigByFrequencyIndividualRequestDto) => Promise<ResponseApi<UpdateAlertConfigByFrequencyIndividualResponseDto>>
  del: (id: number) => Promise<ResponseApi<any>>
}

export function useAlertConfigsByFrequencyIndividual(baseURL: string, token: string, shouldFetch?: boolean, onError?: TypeOnError): IUseAlertConfigsByFrequencyIndividual {

  const swrResponse: IUseBase<mutateAlertConfigsByFrequency> = useInstance<mutateAlertConfigsByFrequency>(
    baseURL,
    token,
    shouldFetch ? '/alerts_config/frequency' : null,
    onError
  );

  const findOneById = async (id: number): Promise<ResponseApi<GetAlertConfigByFrequencyIndividualResponseDto>> => {
    let path: string = `/alerts_config/frequency/${id}`;
    const action = CreateAction<ResponseApi<GetAlertConfigByFrequencyIndividualResponseDto>, void, mutateAlertConfigsByFrequency>(
      baseURL,
      token,
      swrResponse.mutate,
      'get',
      path,
      onError
    );
    return action();
  }

  const add = async (alertConfig: AddAlertConfigByFrequencyIndividualRequestDto): Promise<ResponseApi<AddAlertConfigByFrequencyIndividualResponseDto>> => {
    let path: string = '/alerts_config/frequency';
    const action = CreateActionWithEndpoint<
      ResponseApi<AddAlertConfigByFrequencyIndividualResponseDto>,
      AddAlertConfigByFrequencyIndividualRequestDto,
      mutateAlertConfigsByFrequency>(
        baseURL,
        token,
        swrResponse.mutate,
        'post',
        onError
      );
    return action(path, alertConfig);

  }

  const patch = async (id: number, alertConfig: UpdateAlertConfigByFrequencyIndividualRequestDto): Promise<ResponseApi<UpdateAlertConfigByFrequencyIndividualResponseDto>> => {
    let path: string = `/alerts_config/frequency/${id}`;
    const action = CreateActionWithEndpoint<
      ResponseApi<UpdateAlertConfigByFrequencyIndividualResponseDto>,
      UpdateAlertConfigByFrequencyIndividualRequestDto,
      mutateAlertConfigsByFrequency>(
        baseURL,
        token,
        swrResponse.mutate,
        'patch',
        onError
      );
    return action(path, alertConfig);
  }

  const del = async (id: number): Promise<ResponseApi<any>> => {

    let path: string = `/alerts_config/frequency/${id}`;
    const action = CreateAction<ResponseApi<any>, void, mutateAlertConfigsByFrequency>(
      baseURL,
      token,
      swrResponse.mutate,
      'delete',
      path,
      onError
    );
    return action();
  }

  return {
    ...swrResponse,
    findOneById,
    add,
    patch,
    del
  };
}