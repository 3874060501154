import React, { ReactElement } from 'react'
import { Button } from '@material-ui/core'

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';

interface Props {
  open: boolean
  content: ReactElement
  cancelText: string
  confirmText: string
  onCancel: () => void
  onConfirm: () => void
  onClose: () => void
}

const Confirm = ({ open, content, cancelText, confirmText, onCancel, onConfirm, onClose }: Props): ReactElement => {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogContent>
        <DialogContentText id="alert-dialog-description">{content}</DialogContentText>
      </DialogContent>

      <DialogActions>
        <Button size='small' onClick={onCancel} autoFocus>{cancelText}</Button>
        <Button size='small' onClick={onConfirm} variant='contained'>{confirmText}</Button>
      </DialogActions>
    </Dialog>
  )
}

export { Confirm }
