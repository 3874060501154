import { Expose, Type } from "class-transformer";
import { BasicDeviceDto, SignDto } from "../Sign/Signs";
import { ItemList, PatientList } from "../IItemList";
import {
  ICreateMeasurementRequestDto,
  ICreateMeasurementResponseDto,
  IGetMeasurementExtendedResponseDto,
  IGetMeasurementResponseDto,
  IItemMeasurement,
  ILastMeasurements,
  IUpdateMeasurementRequestDto,
  IUpdateMeasurementResponseDto,
} from "./IMeasurements";
import { ValidateNested } from "class-validator";
import { BasicReadDevice, GetReadDeviceExtendedDto } from "../ReadDevice/ReadDevice";

export class CreateMeasurementRequestDto
  implements ICreateMeasurementRequestDto
{
  patientId: number = 0;
  signId: number = 0;
  readDeviceId: number = 0;
  value: number = 0;
  inheritance: boolean = false;
  locationId?: number;
}

export class CreateMeasurementResponseDto
  implements ICreateMeasurementResponseDto
{
  @Expose() id = 0;
  @Expose() signId = 0;
  @Expose() readDeviceId = 0;
  @Expose() value = 0;
  @Expose({ name: "created_at" }) createdAt: Date = new Date();
  @Expose() message: string = "";
}

export class GetMeasurementResponseDto implements IGetMeasurementResponseDto {
  @Expose() id: number = 0;
  @Expose() signId: number = 0;
  @Expose({name: 'read_device'})
  @ValidateNested()
  @Type(() => BasicReadDevice)
  readDevice: BasicReadDevice = new BasicReadDevice();
  @Expose({ name: 'read_device_id' }) readDeviceId: number = 0;
  @Expose() value: number = 0;
  @Expose() valueText?: string;
  @Expose({ name: 'created_at' }) createdAt: Date = new Date();
  @Expose()
  @ValidateNested()
  @Type(() => SignDto)
  sign: SignDto = new SignDto();
}

export class GetMeasurementExtendedResponseDto
  implements IGetMeasurementExtendedResponseDto
{
  @Expose() id = 0;
  @Expose() signId = 0;
  @Expose() readDeviceId = 0;
  @Expose() value = 0;
  @Expose() valueText?: string;
  @Expose() createdAt: Date = new Date();

  @ValidateNested()
  @Type(() => SignDto)
  @Expose()
  sign = new SignDto();

  @ValidateNested()
  @Type(() => GetReadDeviceExtendedDto)
  @Expose()
  read_device = new GetReadDeviceExtendedDto();
  @Expose() responseUUID: string = "";
}
export class ItemMeasurement implements IItemMeasurement {
  id: number = 0;
  sign: SignDto = new SignDto();
  readDeviceId: number = 0;
  readDevice?: BasicReadDevice = new BasicReadDevice();
  value: string = "";
  valueText?: string;
  created_at: Date = new Date();
  trackings: ItemList[] = [];
  device: BasicDeviceDto = new BasicDeviceDto();
  
}

export class LastMeasurementsDto implements ILastMeasurements {
  @Expose()
  patient: PatientList = new PatientList();
  
  @Expose()
  measurement?: ItemMeasurement;
}

export class UpdateMeasurementRequestDto
  implements IUpdateMeasurementRequestDto
{
  @Expose() value: number = 0;
  @Expose() measurementId: number = 0;
  @Expose() readDeviceId: number = 0;
  @Expose() signId: number = 0;
}

export class UpdateMeasurementResponseDto
  extends GetMeasurementResponseDto
  implements IUpdateMeasurementResponseDto {}
