import React, { createContext, useContext, useState } from "react";
import Modal from "@material-ui/core/Modal";
import CircularProgress from "@material-ui/core/CircularProgress";

export interface IProvideLoading {
  isLoading: boolean;
  setIsLoading: (l: boolean) => void;
}

const LoadingContext = createContext<IProvideLoading>({
  isLoading: false,
  setIsLoading: (l: boolean) => {/* empty */ },
});

interface ProvideLoadingProps {
  children: React.ReactNode;
}

export function ProvideLoading({
  children,
}: ProvideLoadingProps): React.ReactElement {
  const [isLoading, setIsLoading] = useState(false);

  return (
    <LoadingContext.Provider value={{ isLoading, setIsLoading }}>
      {children}
      <Modal
        /**
         * USE WITH CAUTION
         * Disable enforce focus will not prevent triggering
         * items outside of the modal
         */
        // disableEnforceFocus
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
        open={isLoading}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <CircularProgress />
      </Modal>
    </LoadingContext.Provider>
  );
}

export const useLoading = (): IProvideLoading => {
  return useContext(LoadingContext);
};
