import { makeStyles, Theme } from '@material-ui/core/styles'

export default makeStyles((theme: Theme) => ({
  formControl: {
    width: '100%',
    '& .react-tel-input': {
      marginTop: 8,
      '& .flag-dropdown': {
        backgroundColor: `${theme.palette.background.paper}!important`,
        borderRight: 'none',
        '& :focus': {
          backgroundColor: theme.palette.background.paper,
        },
        '& .selected-flag' :{
          background: theme.palette.background.paper,
        },
        
      },
      '& input': {
        height: 40,
        width: '100%',
        backgroundColor: theme.palette.background.paper,
        color: 'white'
      },
    },
  },
  label: {
    left: '10px',
    zIndex: 99,
    backgroundColor: theme.palette.background.paper,
    paddingLeft: 8,
    paddingRight: 8
  },
  dropdown:{
    backgroundColor: theme.palette.background.paper,
    color: 'white'
  },

  error: {
    borderColor: `${theme.palette.error.main}!important`
  }
}))
