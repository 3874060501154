import {
  FilterOption,
  ItemReadDeviceResponse,
  ReadDeviceImageResponse,
  ReadDeviceOnlyImageResponse,
} from "../dto/CmsDashboard/CmsDashboard";
import { ResponseApi, ResponseApiList, TypeOnError } from "../global";
import { DataRange } from "../utils/query/filter.interface";
import { Symbol } from "../utils/query/query.enum";
import {
  CreateAction,
  CreateActionWithoutMutate,
  IUseBase,
  useInstance,
} from "./IUseBaseElements";

export interface IUseCmsReadDeviceViewer
  extends IUseBase<ResponseApiList<ItemReadDeviceResponse>> {
  getDebugImage: (id: number) => Promise<ResponseApi<string>>;
  getImageAndMeasurements: (
    id: number
  ) => Promise<ResponseApi<ReadDeviceImageResponse>>;
  getImage: (
    id: number
  ) => Promise<ResponseApi<ReadDeviceOnlyImageResponse>>;
}

export function useCmsReadDeviceViewer(
  baseURL: string,
  token: string,
  paginateQuery: any,
  companyId?:number,
  dateRange?: DataRange<string>,
  readDeviceRange?: DataRange<number>,
  device?: number,
  translated?: FilterOption,
  removed?: FilterOption,
  mode?: string,
  pendingTranslation?: FilterOption,
  pendingConfirm?: FilterOption,
  confirmed?: FilterOption,
  extendedData?: boolean,
  externalAiResponse?: boolean,
  onError?: TypeOnError
): IUseCmsReadDeviceViewer {
  let path: string = `/cmsdashboard/readDevices`;
  let query: string = "";
  let char: Symbol = Symbol.FIRST;

  if (companyId) {
    query += `${char}companyId=${companyId}`;
    char = Symbol.AND;
  }

  if (dateRange) {
    query += `${char}dateRangeStart=${dateRange.start}&dateRangeEnd=${dateRange.end}`;
    char = Symbol.AND;
  }
  if (readDeviceRange) {
    query += `${char}readDeviceRangeStart=${readDeviceRange.start}&readDeviceRangeEnd=${readDeviceRange.end}`;
    char = Symbol.AND;
  }
  if (device) {
    query += `${char}deviceSelect=${device}`;
    char = Symbol.AND;
  }
  if (translated) {
    query += `${char}translated=${translated}`;
    char = Symbol.AND;
  }
  if (removed) {
    query += `${char}removed=${removed}`;
    char = Symbol.AND;
  }
  if (mode) {
    query += `${char}mode=${mode}`;
    char = Symbol.AND;
  }
  if (pendingTranslation) {
    query += `${char}pendingTranslation=${pendingTranslation}`;
    char = Symbol.AND;
  }
  if (pendingConfirm) {
    query += `${char}pendingConfirm=${pendingConfirm}`;
    char = Symbol.AND;
  }
  if (confirmed) {
    query += `${char}confirmed=${confirmed}`;
    char = Symbol.AND;
  }
  if (extendedData) {
    query += `${char}extendedData=${extendedData}`;
    char = Symbol.AND;
  }

  if (externalAiResponse) {
    query += `${char}externalAiResponse=${externalAiResponse}`;
    char = Symbol.AND;
  }

  if (paginateQuery) {
    query += `${char}limit=${paginateQuery.limit}&page=${paginateQuery.page}`;
    char = Symbol.AND;
  }

  if (paginateQuery.sortBy && paginateQuery.sortBy[0]) {
    query += `${char}${query}&field=${
      paginateQuery.sortBy[0]["field"]
    }&sort=${paginateQuery.sortBy[0]["sort"].toUpperCase()}`;
  }

  path += query;

  const swrResponse: IUseBase<ResponseApiList<ItemReadDeviceResponse>> =
    useInstance<ResponseApiList<ItemReadDeviceResponse>>(
      baseURL,
      token,
      path,
      onError
    );

  const getDebugImage = async (id: number): Promise<ResponseApi<string>> => {
    const path: string = `/cmsdashboard/debugImage/${id}`;
    const action = CreateActionWithoutMutate<ResponseApi<string>>(
      baseURL,
      token,
      "get",
      path,
      onError
    );
    return action();
  };

  const getImageAndMeasurements = async (
    id: number
  ): Promise<ResponseApi<ReadDeviceImageResponse>> => {
    const path: string = `/cmsdashboard/readDevice/${id}`;
    const action = CreateActionWithoutMutate<
      ResponseApi<ReadDeviceImageResponse>
    >(baseURL, token, "get", path, onError);
    return action();
  };

  const getImage = async (
    id: number
  ): Promise<ResponseApi<ReadDeviceOnlyImageResponse>> => {
    const path: string = `/cmsdashboard/readDevice/${id}/image`;
    const action = CreateActionWithoutMutate<
      ResponseApi<ReadDeviceOnlyImageResponse>
    >(baseURL, token, "get", path, onError);
    return action();
  };

  return { ...swrResponse, getDebugImage, getImageAndMeasurements, getImage };
}
