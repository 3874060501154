import { makeStyles, Theme } from '@material-ui/core/styles'

export default makeStyles((theme: Theme) => ({
  root: {
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
    height: '100vh'
  },
  errorToast: {
    backgroundColor: theme.palette.error.main
  },
  infoToast: {
    backgroundColor: theme.palette.info.main
  }
}))
