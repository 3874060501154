import { GetAlertByValueResponseDto } from '../dto/Alerts/Alerts'
import { Role } from '../dto/User'
import { ResponseApiList, TypeOnError } from '../global'
import { IUseBase, useInstance } from './IUseBaseElements'

export interface IUseAlertsByValue extends IUseBase<ResponseApiList<GetAlertByValueResponseDto>> { }

export function useAlertsByValue(baseURL: string, token: string, actorId: number, actorRole: Role, shouldFetch?: boolean, simpleSearch?:string, advancedSearch?:any, onError?: TypeOnError): IUseAlertsByValue {
  const formatActorRole = (actorRole: Role) => {
    if (actorRole === Role.user) return "patients";
    if (actorRole === Role.superadmin) return Role.superadmin;
    if (actorRole === Role.admin) return "admin_company";
    if (actorRole === Role.nurse) return "nurses";
    if (actorRole === Role.doctor) return "doctors";
    if (actorRole === Role.translatorimage) return "translators";
  }
  const roleName = formatActorRole(actorRole);

  let queryParams = '';
  if(simpleSearch){
    queryParams=`?search=${simpleSearch}`;
  }
 
  if(advancedSearch.length){
    // if we are also aplying simple search, should we concatenate the advanced search?
    queryParams += simpleSearch ? '&' : '?';
    // query string
    let queryValues ='';
    advancedSearch.map((value: string) => queryValues+=`,${value}`);
    queryValues = queryValues.substring(1);
    // Now only have one posible filter => stateFilter: {state:boolean}
    queryParams += `filter.state=$in:${queryValues}`;
  }

  const swrResponse: IUseBase<ResponseApiList<GetAlertByValueResponseDto>> = useInstance<ResponseApiList<GetAlertByValueResponseDto>>(
    baseURL,
    token,
    shouldFetch ? `/${roleName}/${actorId}/alerts/value${queryParams}` : null,
    onError
  );

  return {
    ...swrResponse
  } as IUseAlertsByValue;
}