import type { PostMailResponseDto } from '../dto/Mail'
import { ResponseApi, TypeOnError } from '../global'
import { CreateActionWithoutMutate } from './IUseBaseElements'

export interface IUseSMS {
  sendRegistrationSMS: (id: number) => Promise<ResponseApi<PostMailResponseDto>>
}

export function useSMS(baseURL: string, token: string, shouldFetch?: boolean, onError?: TypeOnError): IUseSMS {

  const sendRegistrationSMS = async (id: number): Promise<ResponseApi<PostMailResponseDto>> => {
    let path: string = `/token/sms/subscription/${id}`;
    const action = CreateActionWithoutMutate<ResponseApi<PostMailResponseDto>, boolean>(
      baseURL,
      token,
      'post',
      path,
      onError
    );
    return action();
  };

  return { sendRegistrationSMS }
}
