import { Expose } from 'class-transformer'

export enum Status {
  PENDING = 'PENDING',
  IN_PROGRESS = 'IN PROGRESS',
  CLOSED = 'CLOSED'
}

export interface ICreateTicketRequestDto {
  message: string;
  appVersion: string
  os: string;
}

export interface IUpdateTicketRequestDto {
  status: string;
}

export interface ICreateTicketResponseDto {
  message: string;
  id: number;
  responseMessage: string;
}

export interface IGetTicketResponseDto {
  id: number;
  userId: number;
  userEmail: string;
  userName: string;
  doctorName: string;
  companyName: string;
  message: string;
  status: string;
  createdAt: Date;
  updatedAt: Date;
  appVersion: string
  os: string;
  issues: string;
}

export interface IUpdateTicketResponseDto {
  id: number;
  message: string;
  status: string;
  responseMessage: string;
}

export class CreateTicketRequestDto implements ICreateTicketRequestDto {
  message: string = Status.PENDING
  appVersion: string = ''
  os: string = ''
}

export class UpdateTicketRequestDto implements IUpdateTicketRequestDto {
  status: string = ''
}

export class CreateTicketResponseDto implements ICreateTicketResponseDto {
  message: string = ''
  id: number = 0
  responseMessage: string = ''
}

export class GetTicketResponseDto implements IGetTicketResponseDto {
  id: number = 0
  userId: number = 0
  userEmail: string = ''
  userName: string = ''
  doctorName: string = ''
  companyName: string = ''
  message: string = ''
  status: string = ''
  @Expose({ name: 'created_at' }) createdAt: Date = new Date()
  updatedAt: Date = new Date()
  appVersion: string = ''
  os: string = ''
  issues: string = ''
}

export class UpdateTicketResponseDto implements IUpdateTicketResponseDto {
  id: number = 0
  message: string = ''
  status: string = ''
  responseMessage: string = ''
}
