import { Input, ListItemIcon, FormControl, InputLabel, MenuItem, Select } from "@material-ui/core";
import CheckIcon from '@mui/icons-material/Check';

// TODO move to cte file
const names = [
    {
        label: 'Traducidas',
        key: 'translated'
    },
    {
        label: 'Eliminadas',
        key: 'removed'
    },
    {
        label: 'Traducción pendiente',
        key: 'pendingTranslation'
    },
    {
        label: 'Confirmadas',
        key: 'confirmed'
    },
    {
        label: 'Confirmación pendiente',
        key: 'pendingConfirm'
    },
];

interface StatusSelectProp {
    statusName?: string[];
    setStatusName: (value: string[]) => void;
}

const SelectStatus = ({
    statusName = names.map((name) => name.key),
    setStatusName,
}: StatusSelectProp) => {

    const handleChange = (value: string) => {
        const selectedValues = typeof value === 'string' ? value.split(',') : value;

        setStatusName(selectedValues as string[]);
    };

    return (
        <FormControl variant="outlined" style={{ minWidth: 200, marginRight: 20 }}>
            <InputLabel id="status-read-device">{"Estado lecturas"}</InputLabel>
            <Select
                labelId="status-read-device"
                id="status-read-device-select"
                multiple
                value={statusName}
                onChange={(event) => handleChange(event.target.value as string)}
                input={<Input id="select-multiple-chip" />}
                label={"Estado lecturas"}
                renderValue={() => "Estado lecturas"}
                displayEmpty
                MenuProps={{
                    getContentAnchorEl: null, // Evita que el menú cambie de posición
                    anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "left",
                    },
                    transformOrigin: {
                        vertical: "top",
                        horizontal: "left",
                    },
                    PaperProps: {
                        style: {
                            maxHeight: 200,
                        },
                    },
                }}
            >
                {names.map((name) => (
                    <MenuItem key={name.key} value={name.key}>
                        <ListItemIcon>
                            {statusName.indexOf(name.key) > -1 ? (
                                <CheckIcon />
                            ) : (
                                <span style={{ width: 24 }} />
                            )}
                        </ListItemIcon>
                        {name.label}
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    );
};

export { SelectStatus };

