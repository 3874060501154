import { GetStatusResponseDto } from '../dto/Status'
import { ResponseApi, TypeOnError } from '../global'
import { CreateAction, IUseBase, useInstance } from './IUseBaseElements'

export interface IUseStatus extends IUseBase<ResponseApi<GetStatusResponseDto | string>> {
  get: (json: boolean, url?: string) => Promise<ResponseApi<GetStatusResponseDto | string>>
}

export function useStatus(baseURL: string, token: string, json: boolean, shouldFetch?: boolean, onError?: TypeOnError): IUseStatus {

  const swrResponse: IUseBase<ResponseApi<GetStatusResponseDto | string>> = useInstance<ResponseApi<GetStatusResponseDto | string>>(
    baseURL,
    token,
    shouldFetch ? `/status?json=${json}` : null,
    onError
  );

  const get = async (json: boolean, url?: string): Promise<ResponseApi<GetStatusResponseDto | string>> => {
    let path: string = `/status?json=${json}`;
    const action = CreateAction<ResponseApi<GetStatusResponseDto | string>, boolean, ResponseApi<GetStatusResponseDto | string>>(
      baseURL,
      token,
      swrResponse.mutate,
      'get',
      path,
      onError
    );
    return action();
  };

  return {
    ...swrResponse,
    get
  }
}
