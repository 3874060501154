import { Expose, Type } from 'class-transformer'
import { ValidateNested, IsOptional, IsNotEmpty } from 'class-validator'
import { GetDeviceResponseDto } from './Devices'

export enum Weekday {
  Monday = 'Mon',
  Tuesday = 'Tue',
  Wednesday = 'Wed',
  Thursday = 'Thu',
  Friday = 'Fri',
  Saturday = 'Sat',
  Sunday = 'Sun'
};

export interface IGetFrequencyResponseDto {
  id: number;
  weekdays: string;
  min: string;
  nextNotificationAt?: Date;
  deleted_at?: Date;
  device?: GetDeviceResponseDto;
}

export interface ICreateFrequencyRequestDto {
  deviceId: number;
  weekdays: string;
  min: string;
}

export interface IUpdateFrequencyResponseDto {
  id: number;
  deviceId: number;
  patientId: number;
  weekdays: string;
  min: string;
  createdAt: Date;
  message: string;
  nextNotificationAt: Date;
}

export class GetFrequencyResponseDto implements IGetFrequencyResponseDto {
  id: number = 0;
  weekdays: string = '';
  min: string = '';
  nextNotificationAt?: Date;
  deleted_at?: Date;

  @ValidateNested()
  @Type(() => GetDeviceResponseDto)
  device?: GetDeviceResponseDto;
}
export class CreateFrequencyRequestDto implements ICreateFrequencyRequestDto{
  deviceId: number = 0;
  weekdays: string = '';
  min: string = '';
}

export class UpdateFrequencyResponseDto implements IUpdateFrequencyResponseDto{
  id: number = 0;
  deviceId: number = 0;
  patientId: number = 0;
  weekdays: string = '';
  min: string = '';
  @Expose({ name: 'created_at' }) createdAt: Date = new Date();
  message: string = '';
  nextNotificationAt: Date = new Date();
}
