import type { GetDoctorResponseDto } from '../dto/Actors/Doctor/Doctor'
import { ResponseApi, TypeOnError } from '../global'
import { IUseBase, useInstance } from './IUseBaseElements'

export interface IUseDoctor extends IUseBase<ResponseApi<GetDoctorResponseDto>> { }

export function useDoctor(baseURL: string, token: string, id: number, shouldFetch?: boolean, onError?: TypeOnError): IUseDoctor {

  const swrResponse: IUseBase<ResponseApi<GetDoctorResponseDto>> = useInstance<ResponseApi<GetDoctorResponseDto>>(
    baseURL,
    token,
    shouldFetch ? `/doctors/${id}` : null,
    onError
  );

  return { ...swrResponse }

}
