import { GridColumns, GridValueGetterParams } from "@mui/x-data-grid";
import { formattedDate } from "../../utils";
import { IActiveUsers } from "./ActiveUsersGrid";

export const ActiveUsersGridColumns = (): GridColumns<IActiveUsers> => {

  return [
    {
      field: 'updatedAt',
      headerName: 'Fecha',
      width: 200,
      align: 'left',
      valueGetter: (params: GridValueGetterParams) => params.row.name,
      renderCell: (params) => {
        return <p>{formattedDate(params.row.day)}</p>
      }
    },
    {
      field: 'countUsers',
      headerName: 'Cantidad usuarios',
      width: 200,
      align:'left',
      valueGetter: (params: GridValueGetterParams) => params.row.group,
      renderCell: (params) => {
        return <p>{params.row.count}</p>
      }
    }
  ];
};