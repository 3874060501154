import { makeStyles, Theme } from "@material-ui/core/styles";
import React, { useState } from "react";
import {
  Box,
  Button,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@material-ui/core";
import { CreateCompanyRequestDto } from "@doctomatic/sdk/build/dto/Company";
import RadioGroup from "@material-ui/core/RadioGroup";
import Radio from "@material-ui/core/Radio";
import FormControlLabel from "@material-ui/core/FormControlLabel/FormControlLabel";

enum AddCompanyMode {
  SIMPLE = "Simple",
  DEVICES = "Con dispositivos",
  DEMO = "Demo",
}
interface Props {
  open: boolean;
  onClose: () => void;
  onSave: (dto: CreateCompanyRequestDto) => void;
}

const useStyles = makeStyles((theme: Theme) => ({
  visible: { overflowY: "visible" },
}));

const CompanyCreate = ({
  open,
  onClose,
  onSave,
}: Props): React.ReactElement => {
  const [name, setName] = useState("");
  const [selectedMode, setSelectedMode] = useState<AddCompanyMode>(
    AddCompanyMode.SIMPLE
  );

  const classes = useStyles();

  const changeMode = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedMode(event.target.value as AddCompanyMode);
  };

  return (
    <Dialog
      open={open}
      fullWidth={true}
      maxWidth="sm"
      classes={{ paper: classes.visible }}
    >
      <DialogTitle>Crear centro médico</DialogTitle>

      <DialogContent className={classes.visible}>
        <TextField
          margin="dense"
          variant="outlined"
          name="name"
          label="Nombre"
          type="text"
          fullWidth
          onChange={(e) => setName(e.target.value)}
          value={name}
          autoFocus
        />
        <RadioGroup
          style={{ color: "white", display: "flex", flexDirection: "row" }}
          defaultValue={AddCompanyMode.SIMPLE}
          name="radio-buttons-group"
          value={selectedMode}
          onChange={changeMode}
        >
          <FormControlLabel
            style={{ color: "white" }}
            value={AddCompanyMode.SIMPLE}
            control={<Radio />}
            label={AddCompanyMode.SIMPLE}
          />
          <FormControlLabel
            style={{ color: "white" }}
            value={AddCompanyMode.DEVICES}
            control={<Radio />}
            label={AddCompanyMode.DEVICES}
          />
          <FormControlLabel
            style={{ color: "white" }}
            value={AddCompanyMode.DEMO}
            control={<Radio />}
            label={AddCompanyMode.DEMO}
          />
        </RadioGroup>
      </DialogContent>

      <DialogActions>
        <Box m={2}>
          <Box mr={2} display="inline-block">
            <Button onClick={onClose} color="default" size="small">
              Cancelar
            </Button>
          </Box>
          <Button
            onClick={() => {
              const dto = new CreateCompanyRequestDto();
              dto.name = name;
              dto.addDevices = selectedMode === AddCompanyMode.DEVICES;
              dto.isDemo = selectedMode === AddCompanyMode.DEMO;
              onSave(dto);
              setName("");
              setSelectedMode(AddCompanyMode.SIMPLE);
            }}
            variant="contained"
            size="small"
          >
            Guardar
          </Button>
        </Box>
      </DialogActions>
    </Dialog>
  );
};

export { CompanyCreate };
