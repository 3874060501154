import React from "react";
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@material-ui/core";
import { format } from "date-fns";
import { useApi } from "@doctomatic/sdk/build/Api";
import {
  GetTicketResponseDto,
  Status,
} from "@doctomatic/sdk/build/dto/Tickets";
import useStyles from "../styles";
import { processError } from "../../../App/errorToast";
import { useNavigate } from "react-router-dom";

interface Props {
  onEdit: (ticket: GetTicketResponseDto, status: string) => void;
}

const TicketList = ({ onEdit }: Props): React.ReactElement => {
  const history = useNavigate();
  const classes = useStyles();
  const { useTickets, logout } = useApi();
  const { response: ticketsResponse } = useTickets(true, processError(logout, history));
  const tickets = ticketsResponse?.data;
  if (!tickets) return <></>;

  const handleSubmit = (status: string, ticket: GetTicketResponseDto) => {
    onEdit(ticket, status);
  };

  return (
    <TableContainer>
      <Table id="tickets">
        <TableHead>
          <TableRow>
            <TableCell style={{ width: 180 }}>Estado</TableCell>
            <TableCell style={{ width: 400 }}>Mensaje</TableCell>
            <TableCell>Datos usuario</TableCell>
            <TableCell>Datos médicos</TableCell>
            <TableCell>Fecha de creacion</TableCell>
            <TableCell>v. APP</TableCell>
            <TableCell>v. OS</TableCell>
            <TableCell>Sentry issues</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {tickets
            .slice(0)
            .reverse()
            .map((ticket: GetTicketResponseDto) => (
              <TableRow key={ticket.id}>
                <TableCell>
                  <FormControl fullWidth>
                    <InputLabel id="status">Estado</InputLabel>
                    <Select
                      labelId="status-select"
                      data-cy="status-select"
                      id="status-update"
                      value={ticket.status}
                      label="Estado"
                      onChange={(event) => {
                        handleSubmit(event.target.value as string, ticket);
                      }}
                    >
                      <MenuItem value={Status.PENDING}
                        data-cy="select_option_pending">
                        {Status.PENDING}
                      </MenuItem>
                      <MenuItem value={Status.IN_PROGRESS}
                        data-cy="select_option_inprogress">
                        {Status.IN_PROGRESS}
                      </MenuItem>
                      <MenuItem value={Status.CLOSED}
                        data-cy="select_option_closed">{Status.CLOSED}</MenuItem>
                    </Select>
                  </FormControl>
                </TableCell>
                <TableCell>{ticket.message}</TableCell>
                <TableCell>
                  <Typography>{ticket.userName}</Typography>
                  <Typography>
                    <a
                      className={classes.link}
                      href={`mailto:${ticket.userEmail}?subject=Ticket%20${ticket.id}`}
                    >
                      {ticket.userEmail}
                    </a>
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography>{ticket.doctorName}</Typography>
                  <Typography>{ticket.companyName}</Typography>
                </TableCell>
                <TableCell>
                  {format(new Date(ticket.createdAt), "dd-MM-yyyy' 'HH:mm")}
                </TableCell>
                <TableCell>{ticket.appVersion}</TableCell>
                <TableCell>{ticket.os}</TableCell>
                <TableCell>
                  {ticket.issues &&
                    ticket.issues.split(",").map((issue: string) => (
                      <p>
                        <a className={classes.link} href={issue}>{issue}</a>
                      </p>
                    ))
                  }
                </TableCell>
              </TableRow>
            ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export { TicketList };
