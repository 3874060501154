import { GetMeasurementResponseDto } from "@doctomatic/sdk/types";
import ImagesDataRow from "./ImagesDataRow";
import { BasicDeviceDto, SignBasicDto } from "@doctomatic/sdk/build/dto/Sign/Signs";
import { Mode } from "@doctomatic/sdk/build/dto/Images";

export default class ImageValidateDataRow extends ImagesDataRow {
  device: BasicDeviceDto;
  signs: SignBasicDto[] = [];
  measurementsInRead: GetMeasurementResponseDto[] = [];
  debug: boolean;

  constructor(
    id: number,
    key: string,
    debugUrl: string,
    readDeviceValidate: string,
    additionalData: string,
    paki: boolean,
    mode: Mode,
    device: BasicDeviceDto,
    signs: SignBasicDto[],
    measurementsInRead: GetMeasurementResponseDto[],
    created_at: Date,
    debug: boolean,
  ) {
    super(
      id,
      key,
      debugUrl,
      readDeviceValidate,
      additionalData,
      paki,
      mode,
      created_at,
    );
    this.device = device;
    this.signs = signs;
    this.measurementsInRead = measurementsInRead;
    this.debug = debug;
  }

}
