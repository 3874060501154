import { CreateWebhookRequestDto, CreateWebhookResponseDto, GetWebhookResponseDto } from '../dto/Webhooks'
import { ResponseApi, ResponseApiList, TypeOnError } from '../global'
import { CreateAction, CreateActionWithEndpoint, IUseBase, useInstance } from './IUseBaseElements'

export interface IUseWebhooks extends IUseBase<ResponseApiList<GetWebhookResponseDto>> {
  add: (webhookDto: CreateWebhookRequestDto) => Promise<ResponseApi<CreateWebhookResponseDto>>;
  remove: (id: string) => Promise<ResponseApi<boolean>>;
}

export function useWebhooks(baseURL: string, token: string, shouldFetch?: boolean, onError?: TypeOnError): IUseWebhooks {

  const swrResponse: IUseBase<ResponseApiList<GetWebhookResponseDto>> = useInstance<ResponseApiList<GetWebhookResponseDto>>(
    baseURL,
    token,
    shouldFetch ? `/webhooks` : null,
    onError
  );

  const add = async (webhookDto: CreateWebhookRequestDto): Promise<ResponseApi<CreateWebhookResponseDto>> => {
    let path: string = '/webhooks';
    const action = CreateActionWithEndpoint<ResponseApi<CreateWebhookResponseDto>, CreateWebhookRequestDto, ResponseApiList<GetWebhookResponseDto>>(
      baseURL,
      token,
      swrResponse.mutate,
      'post',
      onError
    );
    return action(path, webhookDto);
  };

  const remove = async (id: string): Promise<ResponseApi<boolean>> => {
    let path: string = `/webhooks/${id}`;
    const action = CreateAction<ResponseApi<boolean>, boolean, ResponseApiList<GetWebhookResponseDto>>(
      baseURL,
      token,
      swrResponse.mutate,
      'delete',
      path,
      onError
    );
    return action();
  };

  return {
    ...swrResponse,
    add,
    remove,
  }
}
