import { Page } from "../../Page";
import { IMeasurementsGrid } from "./MeasurementsGrid";
import { GetReadDeviceByDayResponseDto } from "@doctomatic/sdk/build/dto/CmsDashboard/CmsDashboard";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import { Box, FormControlLabel, FormGroup } from "@material-ui/core";
import dateFormat from "dateformat";
import Switch from "@material-ui/core/Switch";
import { useState } from "react";
import { ThemeProvider, createTheme } from "@material-ui/core/styles";
import { colors } from "../../../Theme/Theme";

const theme = createTheme({
  palette: {
    action: {
      disabledBackground: colors.backgroundGrey,
      disabled: colors.borderGrey,
    },
  },
});

export interface IMeasurementsGraphic extends IMeasurementsGrid {
  deviceId: number | undefined;
}

interface IChartItem {
  dia: string;
  traducidas: number;
  eliminadas: number;
  confirmadas: number;
  traducidasExternalAi: number;
  eliminadasExternalAi: number;
  confirmadasExternalAi: number;
}

const barColors = [
  "#4bae51",
  "#007BB7",
  "#F4771F",
  // ----------------------------------------------------------------
  "#b3dfb6",
  "#52c5ff",
  "#fbd0b1",
];

const hexToRGBA = (hex: string, a: number): string => {
  hex = "0x" + hex.substring(1);
  const r = (+hex >> 16) & 0xff;
  const g = (+hex >> 8) & 0xff;
  const b = +hex & 0xff;
  return `rgba(${r}, ${g}, ${b}, ${a})`;
};

const MeasurementsGraphic = ({
  measurements,
  deviceId,
}: IMeasurementsGraphic): React.ReactElement => {
  const [showExternalAi, setShowExternalAi] = useState<boolean>(false);

  const chart = (data: GetReadDeviceByDayResponseDto[]) => {
    let parsedData: IChartItem[] = [];
    if (!deviceId) {
      // Filter unique days
      const allDays: string[] = data.map((x) => {
        return x.day;
      });
      const days: string[] = allDays.filter((x, i) => allDays.indexOf(x) == i);
      const reversed = days.sort((a, b) => {
        return new Date(a).getTime() - new Date(b).getTime();
      });

      reversed.forEach((item, index) => {
        const coincidences = data.filter((x) => x.day === item);
        const traducidas = coincidences.reduce((total, x) => {
          let sum = total + +x.countTranslateImage;
          if (showExternalAi) sum -= +x.countTranslateImageExternalAi;
          return sum;
        }, 0);
        const eliminadas = coincidences.reduce((total, x) => {
          let sum = total + +x.countDeleteImage;
          if (showExternalAi) sum -= +x.countDeleteImageExternalAi;
          return sum;
        }, 0);
        const confirmadas = coincidences.reduce((total, x) => {
          let sum =
            total + +x.count - +x.countTranslateImage - +x.countDeleteImage;
          if (showExternalAi) sum -= +x.countConfirmedImageExternalAi;
          return sum;
        }, 0);

        const traducidasExternalAi = coincidences.reduce((total, x) => {
          return total + +x.countTranslateImageExternalAi;
        }, 0);
        const eliminadasExternalAi = coincidences.reduce((total, x) => {
          return total + +x.countDeleteImageExternalAi;
        }, 0);
        const confirmadasExternalAi = coincidences.reduce((total, x) => {
          return total + +x.countConfirmedImageExternalAi;
        }, 0);

        const newChartItem: IChartItem = {
          dia: dateFormat(item, "dd/mm/yyyy"),
          traducidas,
          eliminadas,
          confirmadas,
          traducidasExternalAi,
          eliminadasExternalAi,
          confirmadasExternalAi,
        };

        parsedData.push(newChartItem);
      });
    } else {
      const reversed = data.sort((a, b) => {
        return new Date(a.day).getTime() - new Date(b.day).getTime();
      });
      parsedData = reversed.map((x) => {
        const newChartItem: IChartItem = {
          dia: dateFormat(x.day, "dd/mm/yyyy"),
          traducidas: x.countTranslateImage,
          eliminadas: x.countDeleteImage,
          confirmadas: x.count - x.countTranslateImage - x.countDeleteImage,
          traducidasExternalAi: x.countTranslateImageExternalAi,
          eliminadasExternalAi: x.countDeleteImageExternalAi,
          confirmadasExternalAi: x.countConfirmedImageExternalAi,
        };

        return newChartItem;
      });
    }

    return (
      <div style={{ width: "100%", height: 600, marginTop: "20px" }}>
        <ResponsiveContainer width="99%" height="99%" key={Math.random()}>
          <BarChart width={500} height={600} data={parsedData}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="dia" />
            <YAxis />
            <Tooltip />
            <Legend />
            <Bar
              dataKey="confirmadas"
              stackId="a"
              fill={hexToRGBA(barColors[0], 0.5)}
            />
            <Bar
              dataKey="traducidas"
              stackId="a"
              fill={hexToRGBA(barColors[1], 0.5)}
            />
            <Bar
              dataKey="eliminadas"
              stackId="a"
              fill={hexToRGBA(barColors[2], 0.5)}
            />
            <Bar
              hide={!showExternalAi}
              legendType="none"
              dataKey="confirmadasExternalAi"
              stackId="b"
              fill={hexToRGBA(barColors[3], 0.5)}
            />
            <Bar
              hide={!showExternalAi}
              legendType="none"
              dataKey="traducidasExternalAi"
              stackId="b"
              fill={hexToRGBA(barColors[4], 0.5)}
            />
            <Bar
              hide={!showExternalAi}
              legendType="none"
              dataKey="eliminadasExternalAi"
              stackId="b"
              fill={hexToRGBA(barColors[5], 0.5)}
            />
          </BarChart>
        </ResponsiveContainer>
      </div>
    );
  };

  const displayExternalAiSwitch = (
    <ThemeProvider theme={theme}>
      <FormGroup>
        <FormControlLabel
          control={
            <Switch
              checked={showExternalAi}
              onChange={(event) => setShowExternalAi(event.target.checked)}
              inputProps={{ "aria-label": "controlled" }}
            />
          }
          label="Mostrar external ai"
        />
      </FormGroup>
    </ThemeProvider>
  );

  return (
    <Page title={"Gráfico lecturas"} primaryColor="black">
      {measurements ? (
        <Box component="section">
          {displayExternalAiSwitch}
          {chart(measurements)}
        </Box>
      ) : (
        <Box
          component="section"
          sx={{
            marginTop: "20px",
            p: 2,
            border: "1px dashed red",
          }}
        >
          <p style={{ textAlign: "center" }}>Selecciona un dispositivo</p>
        </Box>
      )}
    </Page>
  );
};

export { MeasurementsGraphic };
