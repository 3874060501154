import React from 'react'
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import { FormControl, InputLabel } from '@material-ui/core'
import useStyles from './styles'
import clsx from 'clsx';

interface PhoneProps {
  value: string | null
  error?: boolean
  required?: boolean
  onUpdate: (formattedValue: string) => void
}

interface CountryData {
  name: string;
  dialCode: string;
  countryCode: string;
  format: string;
}

const Phone = ({ value, error, onUpdate, required }: PhoneProps): React.ReactElement => {
  const classes = useStyles()

  const updatePhone = async (value: string, data: CountryData | {}, event: React.ChangeEvent<HTMLInputElement>, formattedValue: string) => {
    await onUpdate(formattedValue)
  } 

  return (
    <FormControl className={classes.formControl}>
      <InputLabel shrink className={classes.label}>Teléfono</InputLabel>
      <PhoneInput
        buttonClass={clsx({[classes.error]: Boolean(error)} )}
        inputClass={clsx({[classes.error]: Boolean(error)})}
        dropdownClass={clsx({[classes.dropdown]: true})}
        preferredCountries={['es', 'pt', 'br']}
        country={'es'}
        onChange={updatePhone}
        value={value}
        countryCodeEditable={false}
        inputProps={{required: required ?? false}}
      />
    </FormControl>
  )
}

export { Phone }
