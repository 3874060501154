import axios, { Method } from 'axios'
import { ConfigError } from '../global'

const axiosInstance = axios.create();

axiosInstance.interceptors.response.use(
  response => {
    return response;
  },
  error => {
    if (error.config.url !== '/token/refresh' && error.request) {
      if (error.request.status === 401) {
        // Set Failed Request
        let failedRequest = error.config;
        
        //Method to get new token
        return ConfigError.onError(error).then((accessToken: string) => {
          // Set axios instance header
          axiosInstance.defaults.headers['Authorization'] = `Bearer ${accessToken}`;
          
          // Set failed request header
          failedRequest.headers['Authorization'] =  `Bearer ${accessToken}`;
          
          //Retry failed request
          return axios.request(failedRequest);
        });
      }
    }
    throw error;
  },
);

export type FetchFunction<ResponseType, DataType> = (url: string, method: Method, data?: DataType) => Promise<ResponseType>

export default <ResponseType, DataType = unknown> (baseURL: string, token: string) : FetchFunction<ResponseType, DataType> => {
  return async function fetcher (url: string, method: Method = 'get', data?: DataType): Promise<ResponseType> {
    const response = await axiosInstance({
      baseURL,
      url,
      method,
      data,
      headers: { Authorization: 'Bearer ' + token }
    })

    return response.data
  }
}