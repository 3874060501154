import { makeStyles, Theme } from '@material-ui/core/styles'
import React, { useState } from 'react'
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core'
import { GetDeviceResponseDto } from '@doctomatic/sdk/build/dto/Devices'
import { GetSignResponseDto, UpdateSignRequestDto } from '@doctomatic/sdk/build/dto/Sign/Signs'
import { SignEdit } from './SignEdit'
import { useLoading } from '../../Loading/Loading'

interface DeviceEditProps {
  open: boolean,
  deviceToEdit: GetDeviceResponseDto
  onClose: () => void
  onSave: (signsToUpdate: UpdateSignRequestDto[]) => Promise<void>
}

const useStyles = makeStyles((theme: Theme) => ({ visible: { overflowY: 'visible' } }))

const DeviceEdit = ({ open, deviceToEdit, onClose, onSave }: DeviceEditProps): React.ReactElement => {

  const [signsToUpdate, setSignsToUpdate] = useState<UpdateSignRequestDto[]>([])
  
  const { setIsLoading } = useLoading()
  
  const onEdit = (sign: UpdateSignRequestDto) => {
    const index = signsToUpdate.findIndex(s => s.signId === sign.signId)
    if(index !== -1) signsToUpdate.splice(index,1)
    signsToUpdate.push(sign)
    setSignsToUpdate(signsToUpdate)
  }

  const classes = useStyles()

  return (
    <Dialog open={open} fullWidth={true} maxWidth='sm' classes={{paper: classes.visible}} style={{display: 'flex', justifyContent: 'space-around'}}>
      <DialogTitle>
        Editar dispositivo
      </DialogTitle>

      <DialogContent className={classes.visible}>
         {
           deviceToEdit.signs.map((sign: GetSignResponseDto) => (
            <SignEdit 
              signToEdit={sign}
              onEdit={onEdit}
            />
           ))
         }
      </DialogContent>

      <DialogActions>
        <Box m={2}>
          <Box mr={2} display='inline-block'>
            <Button onClick={onClose} color='default' size='small'>Cancelar</Button>
          </Box>
          <Button onClick={async() => {
            setIsLoading(true)
            await onSave(signsToUpdate)
            setIsLoading(false)}} variant='contained' size='small'>Guardar</Button>
        </Box>
      </DialogActions>
    </Dialog>
  )
}


export { DeviceEdit }


