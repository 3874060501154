import { UpdateUserDeviceRequestDto, UpdateUserDeviceResponseDto } from '../dto/UserDevice'
import { ResponseApi, TypeOnError } from '../global'
import Fetcher from './Fetcher'
import { CreateActionWithoutMutate } from './IUseBaseElements'

export interface IUseUserDevice {
  update: (dto: UpdateUserDeviceRequestDto) => Promise<ResponseApi<UpdateUserDeviceResponseDto>>
}

export function useUserDevice(baseURL: string, token: string, shouldFetch?: boolean, onError?: TypeOnError): IUseUserDevice {

  const update = async (dto: UpdateUserDeviceRequestDto) => {
    let path: string = `/user-device`;
    const action = CreateActionWithoutMutate<ResponseApi<UpdateUserDeviceResponseDto>, UpdateUserDeviceRequestDto>(
      baseURL,
      token,
      'patch',
      path,
      onError
    );
    return action(dto);
  };

  return { update }
}