import React, { ReactElement } from 'react'

import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'

import useStyles from './styles'

interface Props {
  title?: string
  buttons?: React.ReactNode
  centerTitle?: boolean
  primaryColor?: string
  children: React.ReactNode
}

const Page: React.FC<Props> = ({ title, buttons, centerTitle, primaryColor, children }: Props): ReactElement => {
  const classes = useStyles()

  return (
    <Box className={classes.content} display='flex' flexDirection='column'>
      {
        (title !== '' || buttons) &&
        <Box p={3} display='flex' alignSelf={centerTitle ? 'center' : 'auto'} color={primaryColor ? primaryColor : 'white'}>
          <Typography variant='h1'>{title}</Typography>
          <Box flexGrow={1} paddingLeft={centerTitle ? 5 : 0}>{buttons}</Box>
        </Box>        
      }
      <Box px={3} flexGrow={1} color={primaryColor ? primaryColor : 'white'}>
        {children}
      </Box>
    </Box>
  )
}

export { Page }
