import type { UpdateSignRequestDto } from '../dto/Sign/Signs'
import { ResponseApi, TypeOnError } from '../global'
import { CreateActionWithoutMutate } from './IUseBaseElements'

export interface IUseSigns {
  updateAll: (signs: UpdateSignRequestDto[]) => Promise<ResponseApi<void>>,
}

function useSigns(baseURL: string, token: string, shouldFetch?: boolean, onError?: TypeOnError): IUseSigns {

  const updateAll = async (signs: UpdateSignRequestDto[]): Promise<ResponseApi<void>> => {
    let path: string = `/signs`;
    const action = CreateActionWithoutMutate<ResponseApi<void>, UpdateSignRequestDto[]>(
      baseURL,
      token,
      'patch',
      path,
      onError
    );
    return action(signs);
  };

  return { updateAll }
}

export { useSigns }
