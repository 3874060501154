import type {
  RecoverPasswordRequestDto,
  PostMailResponseDto,
  PostMailRequestDto,
} from "../dto/Mail";
import { ResponseApi, TypeOnError } from "../global";
import { CreateActionWithoutMutate } from "./IUseBaseElements";

export interface IUseMail {
  sendRecoverPasswordEmail: (dto: RecoverPasswordRequestDto) => Promise<void>;
  sendRegistrationEmail: (
    id: number
  ) => Promise<ResponseApi<PostMailResponseDto>>;
  sendEmail: (
    dto: PostMailRequestDto
  ) => Promise<ResponseApi<PostMailResponseDto>>;
}

export function useMail(
  baseURL: string,
  token: string,
  shouldFetch?: boolean,
  onError?: TypeOnError
): IUseMail {
  const sendRecoverPasswordEmail = async (
    dto: RecoverPasswordRequestDto
  ): Promise<void> => {
    let path: string = "/token/mail/recover";
    const action = CreateActionWithoutMutate<
      ResponseApi<PostMailResponseDto>,
      RecoverPasswordRequestDto
    >(baseURL, token, "post", path, onError);
    action(dto);
  };

  const sendRegistrationEmail = async (
    id: number
  ): Promise<ResponseApi<PostMailResponseDto>> => {
    let path: string = `/token/mail/subscription/${id}`;
    const action = CreateActionWithoutMutate<
      ResponseApi<PostMailResponseDto>,
      boolean
    >(baseURL, token, "post", path, onError);
    return action();
  };

  const sendEmail = async (
    dto: PostMailRequestDto
  ): Promise<ResponseApi<PostMailResponseDto>> => {
    let path: string = `/mail`;
    const action = CreateActionWithoutMutate<
      ResponseApi<PostMailResponseDto>,
      PostMailRequestDto
    >(baseURL, token, "post", path, onError);
    return action(dto);
  };

  return { sendRecoverPasswordEmail, sendRegistrationEmail, sendEmail };
}
