var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
export var SignType;
(function (SignType) {
    SignType["number"] = "number";
    SignType["text"] = "text";
    SignType["image"] = "image";
    SignType["radio"] = "radio";
    SignType["multiRadio"] = "multiRadio";
    SignType["device"] = "device";
    SignType["time"] = "time";
})(SignType || (SignType = {}));
var BasicMeasurement = /** @class */ (function () {
    function BasicMeasurement(id, value, createdAt, signId, readDeviceId, valueText, sign, readDevice) {
        this.id = 0;
        this.createdAt = new Date();
        this.signId = 0;
        this.readDeviceId = 0;
        this.readDevice = { id: 0, createdAt: new Date() };
        this.id = id;
        this.value = value;
        this.createdAt = createdAt;
        this.signId = signId;
        this.readDeviceId = readDeviceId;
        this.valueText = valueText;
        this.sign = sign;
        this.readDeviceId = readDeviceId;
        this.readDevice = readDevice;
    }
    return BasicMeasurement;
}());
export { BasicMeasurement };
var Measurement = /** @class */ (function (_super) {
    __extends(Measurement, _super);
    function Measurement(id, value, createdAt, signId, patientId, readDeviceId, valueText, sign, deviceTypeName, readDevice) {
        var _this = _super.call(this, id, value, createdAt, signId, readDeviceId, valueText, sign, readDevice) || this;
        _this.patientId = 0;
        _this.patientId = patientId;
        _this.deviceTypeName = deviceTypeName;
        return _this;
    }
    return Measurement;
}(BasicMeasurement));
export { Measurement };
