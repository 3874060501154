import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useLoading } from "../../Loading/Loading";
import { useApi } from "@doctomatic/sdk/build/Api";
import {
  AddTranslationRequestDto,
  GetTranslateImageResponseDto,
} from "@doctomatic/sdk/build/dto/TranslateImage";
import { CreateMeasurementImageRequestDto } from "@doctomatic/sdk/build/dto/MeasurementImage";
import { processError } from "../../../App/errorToast";
import { useNavigate } from "react-router-dom";
import {
  IDevice,
  ImageTranslateModal,
} from "@doctomatic/components-react/build/ImagesViews/ImageTranslateModal";
import { BasicMeasurement } from "@doctomatic/components-react/build/Graphs/models";
import { useTranslation } from "react-i18next";
import { GetDeviceResponseDto } from "@doctomatic/sdk/build/dto/Devices";

const formTypeName = "Form";
const ecgDevice = "ECG";
const read6MWTDevice = "ReadSixMWT";

const filterDevices = [ecgDevice, read6MWTDevice];
interface DeviceEditProps {
  open: boolean;
  translateImageToEdit: GetTranslateImageResponseDto;
  onClose: () => void;
  onSave: () => void;
}

const ImageTranslationEdit = ({
  open,
  translateImageToEdit,
  onClose,
  onSave,
}: DeviceEditProps): React.ReactElement => {
  const history = useNavigate();
  const { t, i18n } = useTranslation();
  const { setIsLoading } = useLoading();
  const { useProfile, useTranslateImages, logout, useDevices } = useApi();
  const { response: profileResponse } = useProfile(
    false,
    true,
    processError(logout, history)
  );
  const { addTranslation, mutate } = useTranslateImages(
    undefined,
    true,
    processError(logout, history)
  );
  const { response: devicesResponse } = useDevices(
    true,
    processError(logout, history),
    false,
    undefined,
    true
  );
  const devices: GetDeviceResponseDto[] = devicesResponse?.data
    ? devicesResponse?.data.filter(
        (x) => x.typeName !== formTypeName && !filterDevices.includes(x.name)
      )
    : [];
  const profile = profileResponse?.data;
  const [error, setError] = useState(false);
  const [inputValues, setInputValues] = useState({});
  const [measurements, setMeasurements] = useState<BasicMeasurement[]>([]);
  const [currentLanguage, setCurrentLanguage] = useState<string>(i18n.language);
  const [device, setDevice] = useState<IDevice>(
    translateImageToEdit.image!.device!
  );
  const handleError = () => {
    setError(true);
  };

  const onChange = (event: any, signId: number) => {
    const measurementImage: CreateMeasurementImageRequestDto =
      new CreateMeasurementImageRequestDto();
    measurementImage.signId = signId;
    measurementImage.value = +event.target.value;
    measurementImage.translateImage = translateImageToEdit;
    measurementImage.translateImageId = translateImageToEdit.id;
    measurementImage.translator = profile;
    measurementImage.translatorId = profile?.id || 0;
    setInputValues({ ...inputValues, [signId]: measurementImage });

    const toUpdate = measurements.findIndex((m) => m.signId === signId);
    const newMeasurements = [...measurements];
    if (toUpdate !== -1) {
      newMeasurements[toUpdate].value = event.target.value;
    } else {
      const basicMeasurement = new BasicMeasurement(
        0,
        +event.target.value,
        new Date(),
        signId,
        translateImageToEdit.imageId
      );
      newMeasurements.push(basicMeasurement);
    }
    setMeasurements(newMeasurements);
  };

  const handleAddTranslation = async () => {
    setIsLoading(true);
    try {
      const dto = new AddTranslationRequestDto();
      dto.translatable = true;
      dto.measurementImages = Object.values(inputValues);
      const { data: response } = await addTranslation(
        translateImageToEdit.id,
        dto
      );
      await mutate();
      if (response?.message !== "") toast.success(response?.message);
    } catch (err: any) {
      toast.error("Error al traducir la imagen");
    } finally {
      onSave();
    }
    setIsLoading(false);
  };

  const handleDeleteTranslation = async () => {
    setIsLoading(true);
    try {
      const dto = new AddTranslationRequestDto();
      dto.translatable = false;
      const { data: response } = await addTranslation(
        translateImageToEdit.id,
        dto
      );
      if (response?.message !== "") toast.success(response?.message);
    } catch (err: any) {
      toast.error("Error al eliminar la imagen");
    } finally {
      onSave();
    }
    setIsLoading(false);
  };

  useEffect(() => {
    setCurrentLanguage(i18n.language);
  }, [i18n.language]);

  return (
    <ImageTranslateModal
      measurements={measurements}
      onSave={handleAddTranslation}
      editPermissions={true}
      deletePermissions={true}
      onDeleteMany={handleDeleteTranslation}
      onChange={onChange}
      device={device}
      open={open}
      handleClose={onClose}
      imageUrl={translateImageToEdit.imageUrl}
      altText={""}
      t={t}
      error={error}
      handleError={handleError}
      onSaveText={"Guardar"}
      onDeleteText={"Eliminar"}
      onCloseText={"Cancelar"}
      translation={true}
      currentLanguage={currentLanguage}
      devices={devices}
      setDevice={setDevice}
      clearMeasurements={() => setMeasurements([])}
    />
  );
};

export { ImageTranslationEdit };
