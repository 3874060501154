import React from 'react';
import { Modal, Box, Typography, Button } from '@mui/material';
import { Download } from '@mui/icons-material';
import { ImageDisplay } from "./ImageDisplay";
export var CustomImageViewModal = function (_a) {
    var open = _a.open, handleClose = _a.handleClose, imageUrl = _a.imageUrl, altText = _a.altText, optionalElement = _a.optionalElement, t = _a.t, error = _a.error, handleError = _a.handleError;
    var handleDownloadImage = function () {
        var link = document.createElement('a');
        link.href = imageUrl;
        link.download = 'imagen_debug.jpg';
        link.click();
    };
    return (React.createElement(Modal, { open: open, onClose: handleClose },
        React.createElement(Box, { sx: styles.modalContainer },
            React.createElement(Box, { sx: styles.contentContainer },
                imageUrl ? (React.createElement(ImageDisplay, { imageUrl: imageUrl, altText: altText, t: t, error: error, handleError: handleError })) : (React.createElement(Typography, { sx: styles.errorMessage }, "No se ha encontrado la imagen")),
                optionalElement && (React.createElement(Box, { sx: styles.componentContainer }, optionalElement))),
            React.createElement(Box, { sx: styles.footer },
                React.createElement(Button, { variant: "contained", color: "error", onClick: handleClose }, "Cerrar"),
                React.createElement(Button, { variant: "contained", color: "primary", onClick: handleDownloadImage, disabled: imageUrl === '', startIcon: React.createElement(Download, null) }, "Descargar Imagen")))));
};
var styles = {
    modalContainer: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '80%',
        height: '80vh',
        bgcolor: 'background.paper',
        boxShadow: 24,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'flex-start',
        borderRadius: 2,
        overflow: 'hidden',
    },
    contentContainer: {
        overflowY: 'auto',
        width: '100%',
        height: 'calc(100% - 80px)',
        padding: '50px',
        paddingTop: '20px',
        paddingButton: '20px',
    },
    image: {
        width: '100%',
        height: 'auto',
        marginBottom: '20px',
    },
    footer: {
        position: 'sticky',
        bottom: 0,
        display: 'flex',
        justifyContent: 'space-between',
        width: '100%',
        padding: '10px',
        paddingTop: '30px',
        backgroundColor: 'white',
        borderTop: '1px solid #ddd',
    },
    componentContainer: {
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        marginTop: '20px',
    },
    errorMessage: {
        color: 'red',
        textAlign: 'center',
        fontSize: '18px',
        fontWeight: 'bold',
        marginBottom: '10%',
        marginTop: '10%'
    },
};
