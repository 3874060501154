import type FormData from 'form-data'
import type { GetFileResponseDto } from '../dto/Files'
import { ResponseApi, ResponseApiList, TypeOnError } from '../global'
import { CreateAction, CreateActionWithEndpoint, IUseBase, useInstance } from './IUseBaseElements'

export interface IUseFiles extends IUseBase<ResponseApiList<GetFileResponseDto>> {
  upload: (file: FormData) => Promise<void>,
  getLink: (fileId: number) => Promise<ResponseApi<string>>
}

export function useFiles(baseURL: string, token: string, patientId: number, query?: any, date1?: string, date2?: string, shouldFetch?: boolean, onError?: TypeOnError): IUseFiles {
  let path: string = `/files?patientId=${patientId}`
  if(query) path = path + `&limit=${query.limit}&page=${query.page}`
  if(date1) path = path + `&date1=${date1}`
  if(date2) path = path + `&date2=${date2}`

  const swrResponse: IUseBase<ResponseApiList<GetFileResponseDto>> = useInstance<ResponseApiList<GetFileResponseDto>>(
    baseURL,
    token,
    shouldFetch ? path : null,
    onError
  );

  const upload = async (file: FormData) => {
    let path: string = '/files';
    const action = CreateActionWithEndpoint<unknown, FormData, ResponseApiList<GetFileResponseDto>>(
      baseURL,
      token,
      swrResponse.mutate,
      'post',
      onError
    );
    action(path, file);
  };

  const getLink = async (fileId: number): Promise<ResponseApi<string>> => {
    let path: string = `/files/link/${fileId}`;
    const action = CreateAction<ResponseApi<string>, boolean, ResponseApiList<GetFileResponseDto>>(
      baseURL,
      token,
      swrResponse.mutate,
      'get',
      path,
      onError
    );
    return action();
  };

  return {
    ...swrResponse,
    upload,
    getLink
  }
}
