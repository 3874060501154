import { Expose } from "class-transformer";
import { ResponseApi, TypeOnError } from "../global";
import { IUseBase, useInstance } from "./IUseBaseElements";

export interface IGetUrlRedirectResponseDto {
  url: string;
}

export class GetUrlRedirectResponseDto implements IGetUrlRedirectResponseDto {
  @Expose() url: string = '';
}
  
export interface IUseUrlRedirect extends IUseBase<ResponseApi<GetUrlRedirectResponseDto>> { }

export function useUrlRedirect(
  baseURL: string,
  token: string,
  url: string,
  shouldFetch?: boolean,
  onError?: TypeOnError
): IUseUrlRedirect {

  let path = `/url/${url}`
  const swrResponse: IUseBase<ResponseApi<GetUrlRedirectResponseDto>> = useInstance<ResponseApi<GetUrlRedirectResponseDto>>(
    baseURL,
    token,
    shouldFetch ? path : null,
    onError
  );

  return {
    ...swrResponse,
  } as IUseUrlRedirect;
}
