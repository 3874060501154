import type {
  GetTicketResponseDto,
  CreateTicketRequestDto,
  UpdateTicketRequestDto,
  UpdateTicketResponseDto,
  CreateTicketResponseDto
} from '../dto/Tickets'
import { ResponseApi, ResponseApiList, TypeOnError } from '../global'
import { CreateAction, CreateActionWithEndpoint, IUseBase, useInstance } from './IUseBaseElements'

export interface IUseTickets extends IUseBase<ResponseApiList<GetTicketResponseDto>> {
  add: (user: CreateTicketRequestDto) => Promise<ResponseApi<CreateTicketResponseDto>>,
  patch: (id: number, user: UpdateTicketRequestDto) => Promise<ResponseApi<UpdateTicketResponseDto>>,
  getTicket: (id: number) => Promise<ResponseApi<GetTicketResponseDto>>,
}

export function useTickets(baseURL: string, token: string, shouldFetch?: boolean, onError?: TypeOnError): IUseTickets {

  const swrResponse: IUseBase<ResponseApiList<GetTicketResponseDto>> = useInstance<ResponseApiList<GetTicketResponseDto>>(
    baseURL,
    token,
    shouldFetch ? `/tickets` : null,
    onError
  );

  const patch = async (id: number, dto: UpdateTicketRequestDto): Promise<ResponseApi<UpdateTicketResponseDto>> => {
    let path: string = `/tickets/${id}`;
    const action = CreateActionWithEndpoint<ResponseApi<UpdateTicketResponseDto>, UpdateTicketRequestDto, ResponseApiList<GetTicketResponseDto>>(
      baseURL,
      token,
      swrResponse.mutate,
      'patch',
      onError
    );
    return action(path, dto);
  };

  const add = async (dto: CreateTicketRequestDto): Promise<ResponseApi<CreateTicketResponseDto>> => {
    let path: string = '/tickets';
    const action = CreateActionWithEndpoint<ResponseApi<CreateTicketResponseDto>, CreateTicketRequestDto, ResponseApiList<GetTicketResponseDto>>(
      baseURL,
      token,
      swrResponse.mutate,
      'post',
      onError
    );
    return action(path, dto);
  };

  const getTicket = async (id: number) => {
    let path: string = `/tickets/${id}`;
    const action = CreateAction<ResponseApi<GetTicketResponseDto>, boolean, ResponseApiList<GetTicketResponseDto>>(
      baseURL,
      token,
      swrResponse.mutate,
      'get',
      path,
      onError
    );
    return action();
  };

  return { ...swrResponse, add, patch, getTicket }
}
