import {
  GridColDef,
  GridColumns,
  GridValueGetterParams,
} from "@mui/x-data-grid";
import ImageValidatorDataRow from "./ImageValidatorDataRow";
import moment from "moment";
import { Button } from "@mui/material";
import { useTranslation } from "react-i18next";
import BoltIcon from '@mui/icons-material/Bolt';
import SignLanguageIcon from '@mui/icons-material/SignLanguage';
import DoneIcon from "@mui/icons-material/Done";
import CloseIcon from "@mui/icons-material/Close";

function printJSON(jsonData: any) {
  return (
    <ul>
      {Object.keys(jsonData).map((key, index) => (
        <li key={index}>
          <strong>{key}:</strong> {typeof jsonData[key] === 'object' ? printJSON(jsonData[key]) : jsonData[key]}
        </li>
      ))}
    </ul>
  );
}

const renderAdditionalData = (data: any) => {
  try{
    const jsonData = JSON.parse(data);
    return printJSON(jsonData)
  } catch (err) {
    return <p>{data}</p>
  }
}


export const ImageValidatorGridColumns = (
  showImageModal: (item: ImageValidatorDataRow, debug?: boolean) => void
): GridColumns<ImageValidatorDataRow> => {
  const { t } = useTranslation();

  const gridColumns: GridColDef[] = [
    {
      field: "id",
      headerName: "ReadDeviceId",
      width: 150,
      sortable: false,
      valueGetter: (params: GridValueGetterParams) => params.row.id,
      renderCell: (params: { row: ImageValidatorDataRow }) => {
        return <p>{params.row.id}</p>;
      },
    },
    {
      field: "device.name",
      headerName: "Dispositivo",
      width: 200,
      sortable: false,
      valueGetter: (params: GridValueGetterParams) => params.row.device.name,
      renderCell: (params: { row: ImageValidatorDataRow }) => {
        return <p>{t(params.row.device.name)}</p>;
      },
    },
    {
      field: "created_at",
      headerName: "Creación",
      width: 150,
      sortable: false,
      valueGetter: (params: GridValueGetterParams) => params.row.created_at,
      renderCell: (params: { row: ImageValidatorDataRow }) => {
        return <p>{moment(params.row.created_at).format("DD-MM-yyyy")}</p>;
      },
    },
    {
      field: "image",
      headerName: "Imagen",
      width: 100,
      sortable: false,
      renderCell: (params: { row: ImageValidatorDataRow }) => (
        <Button onClick={() => showImageModal(params.row)}>VER</Button>
      ),
    },
    {
      field: "debug",
      headerName: "Debug",
      width: 100,
      sortable: false,
      renderCell: (params: { row: ImageValidatorDataRow }) => (
        <Button onClick={() => showImageModal(params.row, true)}>VER</Button>
      ),
    },
    {
      field: "modo",
      headerName: "Modo",
      width: 70,
      sortable: false,
      valueGetter: (params: GridValueGetterParams) =>
        params.row.readDeviceValidate,
      renderCell: (params: { row: ImageValidatorDataRow }) => {
        if (
          params.row.readDeviceValidate &&
          params.row.readDeviceValidate.toString()[0] == "1"
        ) {
          return (
            <p>
              <DoneIcon style={{ color: "green" }} />
            </p>
          );
        } else {
          return (
            <p>
              <CloseIcon style={{ color: "red" }} />
            </p>
          );
        }
      },
    },
    {
      field: "medida",
      headerName: "Medida",
      width: 70,
      sortable: false,
      valueGetter: (params: GridValueGetterParams) =>
        params.row.readDeviceValidate,
      renderCell: (params: { row: ImageValidatorDataRow }) => {
        if (
          params.row.readDeviceValidate &&
          params.row.readDeviceValidate.toString()[1] == "1"
        ) {
          return (
            <p>
              <DoneIcon style={{ color: "green" }} />
            </p>
          );
        } else {
          return (
            <p>
              <CloseIcon style={{ color: "red" }} />
            </p>
          );
        }
      },
    },
    {
      field: "dispositivo_erroneo",
      headerName: "Dispositivo erroneo",
      width: 150,
      sortable: false,
      valueGetter: (params: GridValueGetterParams) =>
        params.row.readDeviceValidate,
      renderCell: (params: { row: ImageValidatorDataRow }) => {
        if (
          params.row.readDeviceValidate &&
          params.row.readDeviceValidate.toString()[3] == "1"
        ) {
          return (
            <p>
              <DoneIcon style={{ color: "green" }} />
            </p>
          );
        } else {
          return (
            <p>
              <CloseIcon style={{ color: "red" }} />
            </p>
          );
        }
      },
    },
    {
      field: "eliminada",
      headerName: "Eliminada",
      width: 80,
      sortable: false,
      valueGetter: (params: GridValueGetterParams) =>
        params.row.readDeviceValidate,
      renderCell: (params: { row: ImageValidatorDataRow }) => {
        if (
          params.row.readDeviceValidate &&
          params.row.readDeviceValidate.toString()[2] == "1"
        ) {
          return (
            <p>
              <DoneIcon style={{ color: "green" }} />
            </p>
          );
        } else {
          return (
            <p>
              <CloseIcon style={{ color: "red" }} />
            </p>
          );
        }
      },
    },
    {
      field: "paki",
      headerName: "Paki",
      width: 50,
      sortable: false,
      valueGetter: (params: GridValueGetterParams) => params.row.paki,
      renderCell: (params: { row: ImageValidatorDataRow }) => {
        if (params.row.paki === true) {
          return (
            <p>
              <SignLanguageIcon style={{ color: "red" }} />
            </p>
          );
        } else {
          return (
            <p>
              <BoltIcon style={{ color: "green" }} />
            </p>
          );
        }
      },
    },
    {
      field: "additionalData",
      headerName: "Additional data",
      width: 400,
      sortable: false,
      valueGetter: (params: GridValueGetterParams) => params.row.additionalData,
      renderCell: (params: { row: ImageValidatorDataRow }) => {
        // TODO: properly print json if height row is dinamically adjusted
        // return renderAdditionalData(params.row.additionalData)
        return <p>{params.row.additionalData}</p>
      },
    },
  ];

  return gridColumns;
};
