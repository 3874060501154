import { GetMeasurementResponseDto } from '../dto/Measurements/Measurements';
import { ResponseApiList, TypeOnError } from '../global';
import { IUseBase, useInstance } from './IUseBaseElements';

export interface IUseMeasurementsByTracking
  extends IUseBase<ResponseApiList<GetMeasurementResponseDto>> {
}

export function useMeasurementsByTracking(baseURL: string, token: string, trackingId: number, date1?: string, date2?: string, shouldFetch?: boolean, onError?: TypeOnError): IUseMeasurementsByTracking {
  let data: { date1?: string, date2?: string } = {};
  if (date1 !== undefined) data.date1 = date1;
  if (date2 !== undefined) data.date2 = date2;
  const searchParams: URLSearchParams = new URLSearchParams(data);
  let path = `/measurements/treatment/${trackingId}`;
  if (searchParams.toString().length > 0) path += `?${searchParams.toString()}`;
  const swrResponse: IUseBase<ResponseApiList<GetMeasurementResponseDto>> = useInstance<ResponseApiList<GetMeasurementResponseDto>>(
    baseURL,
    token,
    shouldFetch ? path : null,
    onError
  );

  return {
    ...swrResponse
  } as IUseMeasurementsByTracking;
}
