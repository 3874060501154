var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { IconButton, Modal, TextField } from "@mui/material";
import { Box } from "@mui/system";
import { Button } from "@mui/material";
import React from "react";
import { styles } from "./styles";
import { ImageDisplay } from "./ImageDisplay";
import DeleteIcon from "@mui/icons-material/Delete";
import DownloadIcon from "@mui/icons-material/Download";
import { ConfirmModal } from "../ConfirmModal/ConfirmModal";
export var ImageEditModal = function (_a) {
    var imageUrl = _a.imageUrl, altText = _a.altText, open = _a.open, handleClose = _a.handleClose, t = _a.t, error = _a.error, handleError = _a.handleError, measurements = _a.measurements, onSave = _a.onSave, editPermissions = _a.editPermissions, deletePermissions = _a.deletePermissions, onDeleteOne = _a.onDeleteOne, onDeleteMany = _a.onDeleteMany, onChange = _a.onChange, device = _a.device, readDeviceId = _a.readDeviceId, onSaveText = _a.onSaveText, onDeleteText = _a.onDeleteText, onCloseText = _a.onCloseText, translation = _a.translation, measurementToDelete = _a.measurementToDelete, setMeasurementToDelete = _a.setMeasurementToDelete, readDeviceToDelete = _a.readDeviceToDelete, setReadDeviceToDelete = _a.setReadDeviceToDelete, _b = _a.isModal, isModal = _b === void 0 ? true : _b, currentLanguage = _a.currentLanguage;
    var handleOnSave = function () { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, onSave(measurements)];
                case 1:
                    _a.sent();
                    return [2 /*return*/];
            }
        });
    }); };
    var confirmDeleteMany = readDeviceToDelete && (React.createElement(ConfirmModal, { open: readDeviceToDelete, content: React.createElement("p", null, t("MeasurementsDeleteConfirm")), cancelText: t("ButtonCancel"), confirmText: t("ButtonDelete"), onCancel: function () { return setReadDeviceToDelete(false); }, onClose: function () { return setReadDeviceToDelete(false); }, onConfirm: function () { return __awaiter(void 0, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: 
                    /**
                     * If this is a translation modal, set Read Device Id to 0
                     * since it won't be used to delete the translation.
                     * Otherwise, if a read device id is available, set
                     * it. If not, look for the read device from any measurement.
                     */
                    return [4 /*yield*/, onDeleteMany(translation ? 0 : readDeviceId !== null && readDeviceId !== void 0 ? readDeviceId : measurements[0].readDeviceId)];
                    case 1:
                        /**
                         * If this is a translation modal, set Read Device Id to 0
                         * since it won't be used to delete the translation.
                         * Otherwise, if a read device id is available, set
                         * it. If not, look for the read device from any measurement.
                         */
                        _a.sent();
                        setReadDeviceToDelete(false);
                        return [2 /*return*/];
                }
            });
        }); }, autofocus: "cancel", onCancelColor: "success", onConfirmColor: "error" }));
    var confirmDeleteOne = measurementToDelete !== null && onDeleteOne && (React.createElement(ConfirmModal, { open: measurementToDelete !== null, content: React.createElement("p", null, t("MeasurementDeleteConfirm", {
            signName: t(measurementToDelete.name),
        })), cancelText: t("ButtonCancel"), confirmText: t("ButtonDelete"), onCancel: function () { return setMeasurementToDelete(null); }, onClose: function () { return setMeasurementToDelete(null); }, onConfirm: function () { return __awaiter(void 0, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, onDeleteOne(measurementToDelete.id)];
                    case 1:
                        _a.sent();
                        setMeasurementToDelete(null);
                        return [2 /*return*/];
                }
            });
        }); }, autofocus: "cancel", onCancelColor: "success", onConfirmColor: "error" }));
    var handleOnDeleteMany = function () { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (!translation) return [3 /*break*/, 2];
                    return [4 /*yield*/, onDeleteMany(0)];
                case 1:
                    _a.sent();
                    return [3 /*break*/, 3];
                case 2:
                    setReadDeviceToDelete(true);
                    _a.label = 3;
                case 3: return [2 /*return*/];
            }
        });
    }); };
    var handleOnDeleteOne = function (id, name) { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            if (onDeleteOne)
                setMeasurementToDelete({ id: id, name: name });
            return [2 /*return*/];
        });
    }); };
    var handleDownload = function () {
        var link = document.createElement("a");
        link.href = imageUrl;
        link.download = "imagen.jpg";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };
    function getSignName(currentLanguage, sign, t) {
        var signName = sign.translations
            ? sign.translations.signName
                .map(function (data) {
                if (data.lang === currentLanguage) {
                    return data.text;
                }
                return null;
            })
                .filter(function (text) { return text !== null; })[0] || t(sign.name)
            : t(sign.name);
        return signName;
    }
    var imageEditContainer = (React.createElement(Box, { sx: isModal ? styles.containerModal : styles.container },
        React.createElement(ImageDisplay, { imageUrl: imageUrl, altText: altText, t: t, error: error, handleError: handleError }),
        React.createElement(Box, { m: 2, style: {
                maxHeight: "calc(100vh - 650px)",
                overflowY: "auto",
            } },
            React.createElement(Box, { sx: styles.inputGrid }, device.signs.map(function (sign, index) {
                var measurement = measurements.find(function (m) { return m.signId === sign.id; });
                return (React.createElement(Box, { sx: styles.centeredItem, key: "key-".concat(index) },
                    React.createElement(TextField, { key: index, disabled: !editPermissions, margin: "dense", variant: "outlined", name: getSignName(currentLanguage, sign, t), label: "".concat(getSignName(currentLanguage, sign, t), " (").concat(t(sign.unit), ")"), type: "number", value: measurement ? measurement.value : "", fullWidth: true, onChange: function (event) { return onChange(event, sign.id); }, autoFocus: index === 0 }),
                    deletePermissions &&
                        onDeleteOne &&
                        measurement &&
                        measurement.id !== 0 && (React.createElement(IconButton, { style: styles.deleteOneButton, onClick: function () {
                            var _a, _b;
                            return handleOnDeleteOne(measurement.id, (_b = (_a = measurement.sign) === null || _a === void 0 ? void 0 : _a.name) !== null && _b !== void 0 ? _b : "");
                        } },
                        React.createElement(DeleteIcon, null)))));
            })),
            React.createElement(Box, { m: 2, style: styles.bottomButtons },
                editPermissions && (React.createElement(Button, { sx: styles.botBtn, variant: "contained", size: "small", onClick: handleOnSave, disabled: measurements.length === 0 }, onSaveText !== null && onSaveText !== void 0 ? onSaveText : t("SaveMeasurements"))),
                deletePermissions && (React.createElement(Button, { sx: styles.botBtn, variant: "contained", size: "small", color: "secondary", onClick: handleOnDeleteMany, disabled: translation ? false : measurements.length === 0 }, onDeleteText !== null && onDeleteText !== void 0 ? onDeleteText : t("DeleteMeasurements"))),
                isModal && (React.createElement(Button, { sx: styles.botBtn, variant: "contained", color: "inherit", size: "small", onClick: handleClose }, onCloseText !== null && onCloseText !== void 0 ? onCloseText : t("CloseImage"))),
                React.createElement(Button, { sx: styles.dwnBtn, variant: "contained", color: "inherit", size: "small", onClick: handleDownload, title: t("DownloadButton"), disabled: imageUrl.length === 0 || error },
                    React.createElement(DownloadIcon, null)))),
        React.createElement(React.Fragment, null, confirmDeleteMany),
        React.createElement(React.Fragment, null, confirmDeleteOne)));
    return (React.createElement("div", null, isModal ? (React.createElement(Modal, { open: open, onClose: handleClose, "aria-labelledby": "modal-modal-title", "aria-describedby": "modal-modal-description" }, imageEditContainer)) : (React.createElement(React.Fragment, null, imageEditContainer))));
};
