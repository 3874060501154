import { LastMeasurementsDto } from "../dto/Measurements/Measurements";
import { ItemPatientListSummary } from "../dto/Patient/Patient";
import { CreateAction } from "./IUseBaseElements";
import { IUseBase, useInstance } from "./IUseBaseElements";
import jwt_decode from "jwt-decode";
import { ResponseApiList, TypeOnError } from "../global";
import { JwtMalformedError } from "../exceptions/JwtMalformedError";
import { Role } from "../dto/User";

const JWT_REGEX: RegExp = /^[A-Za-z0-9-_=]+\.[A-Za-z0-9-_=]+\.?[A-Za-z0-9-_.+/=]*$/;

export interface IUsePatientsWithLastMeasurements
  extends IUseBase<ResponseApiList<ItemPatientListSummary>> {
  getPatientsWithMeasurements: () => Promise<
    ResponseApiList<ItemPatientListSummary>
  >;
  getPatientsWithMeasurementsByLocation: (
    locationId: number
  ) => Promise<ResponseApiList<ItemPatientListSummary>>;
}

const formatRoleFromToken = (token: string, onError?: TypeOnError): string => {
  let role: string | undefined;
  try {
    const isJwt: boolean = JWT_REGEX.test(token);
    const decoded: any = isJwt ? jwt_decode(token) : undefined;
    if (!decoded) throw new JwtMalformedError('Could not decode token');
    if (!decoded?.role) throw new Error('Token has no role');
    role = decoded.role;
  } catch (error: any) {
    onError && onError(error, "", "");
    // throw error;
  }

  if (role === Role.user) return "patients";
  if (role === Role.superadmin) return Role.superadmin;
  if (role === Role.admin) return "admin_company";
  if (role === Role.nurse) return "nurses";
  if (role === Role.doctor) return "doctors";
  if (role === Role.translatorimage) return "translators";

  return role + "s";
};

export function usePatientsWithLastMeasurements(
  baseURL: string,
  token: string,
  actorId: number,
  shouldFetch?: boolean,
  query?: any,
  onError?: TypeOnError
): IUsePatientsWithLastMeasurements {
  const role: string = formatRoleFromToken(token, onError);

  const swrResponse: IUseBase<ResponseApiList<ItemPatientListSummary>> =
    useInstance<ResponseApiList<ItemPatientListSummary>>(
      baseURL,
      token,
      shouldFetch ? `/${role}/${actorId}/patients_measurements` : null,
      onError
    );
  const getPatientsWithMeasurements = () => {
    let queryFilters = '';
    if (query) {
      queryFilters = `&limit=${query.limit}&page=${query.page}`;
      if (query.search) {
        queryFilters += `&search=${query.search}`;
      }
    }
    let path: string = `/${role}/${actorId}/patients_measurements?${queryFilters}`;
    const action = CreateAction<
      ResponseApiList<ItemPatientListSummary>,
      void,
      ResponseApiList<ItemPatientListSummary>
    >(baseURL, token, swrResponse.mutate, "get", path, onError);
    return action();
  };

  const getPatientsWithMeasurementsByLocation = async (
    locationId: number
  ): Promise<ResponseApiList<ItemPatientListSummary>> => {
    let queryFilters = '';
    if (query) {
      queryFilters = `&limit=${query.limit}&page=${query.page}`;
      if (query.search) {
        queryFilters += `&search=${query.search}`;
      }
    }
    let path: string = `/${role}/${actorId}/patients_measurements?locationId=${locationId}${queryFilters}`;
    const action = CreateAction<
      ResponseApiList<ItemPatientListSummary>,
      void,
      ResponseApiList<ItemPatientListSummary>
    >(baseURL, token, swrResponse.mutate, "get", path, onError);

    return action();
  };

  return {
    ...swrResponse,
    getPatientsWithMeasurements,
    getPatientsWithMeasurementsByLocation,
  } as IUsePatientsWithLastMeasurements;
}
