import type {
  GetDeviceResponseDto,
  GetTypeDeviceResponseDto,
  UpdateDeviceRequestDto,
} from "../dto/Devices";
import { KeyedMutator } from "swr/dist/types";
import { ResponseApi, TypeOnError } from "../global";
import {
  CreateAction,
  CreateActionWithEndpoint,
  CreateActionWithoutMutate,
  IUseBase,
  useInstance,
} from "./IUseBaseElements";
import FormData from "form-data";
import { Symbol } from "../utils/query/query.enum";

type mutateDevice = ResponseApi<GetDeviceResponseDto[]>;

export interface IUseDevices extends IUseBase<mutateDevice> {
  translate: (
    id: number,
    dto: UpdateDeviceRequestDto
  ) => Promise<ResponseApi<any>>;
  mutate: KeyedMutator<ResponseApi<GetDeviceResponseDto[]>>;
  getDevice: (id: number) => Promise<ResponseApi<GetDeviceResponseDto>>;
  getDeviceByName: (name: string) => Promise<ResponseApi<GetDeviceResponseDto>>;
  getDeviceType: (
    formData: FormData
  ) => Promise<ResponseApi<GetTypeDeviceResponseDto[]>>;
}

export function useDevices(
  baseURL: string,
  token: string,
  shouldFetch?: boolean,
  onError?: TypeOnError,
  form_device?: boolean,
  companyId?: string,
  cms?: boolean,
  showDeletedForms?: boolean
): IUseDevices {
  let path = shouldFetch ? `/devices` : null;
  let query: string = "";
  let char: Symbol = Symbol.FIRST;

  if (form_device) {
    query += `${char}form_device=${form_device}`;
    char = Symbol.AND;
  }

  if (companyId && companyId !== "0") {
    query += `${char}companyId=${companyId}`;
    char = Symbol.AND;
  }

  if (cms) {
    query += `${char}cms=true`;
    char = Symbol.AND;
  }

  if (showDeletedForms) {
    query += `${char}showDeletedForms=${showDeletedForms}`;
    char = Symbol.AND;
  }

  if (shouldFetch) {
    path += query;
  }

  const swrResponse: IUseBase<mutateDevice> = useInstance<mutateDevice>(
    baseURL,
    token,
    path,
    onError
  );

  const translate = async (
    id: number,
    dto: UpdateDeviceRequestDto
  ): Promise<ResponseApi<any>> => {
    let path: string = `/devices/${id}`;
    const action = CreateActionWithEndpoint<
      ResponseApi<any>,
      UpdateDeviceRequestDto,
      mutateDevice
    >(baseURL, token, swrResponse.mutate, "patch", onError);
    return action(path, dto);
  };

  const getDevice = async (
    id: number
  ): Promise<ResponseApi<GetDeviceResponseDto>> => {
    let path: string = `/devices/${id}`;
    const action = CreateAction<
      ResponseApi<any>,
      UpdateDeviceRequestDto,
      mutateDevice
    >(baseURL, token, swrResponse.mutate, "get", path, onError);
    return action();
  };

  const getDeviceByName = async (
    name: string
  ): Promise<ResponseApi<GetDeviceResponseDto>> => {
    let path: string = `/devices/name/${name}`;
    const action = CreateAction<
      ResponseApi<any>,
      UpdateDeviceRequestDto,
      mutateDevice
    >(baseURL, token, swrResponse.mutate, "get", path, onError);
    return action();
  };

  const getDeviceType = async (
    formData: FormData
  ): Promise<ResponseApi<GetTypeDeviceResponseDto[]>> => {
    let path: string = `/devices/getTypeDevice`;
    const action = CreateActionWithoutMutate<
      ResponseApi<GetTypeDeviceResponseDto[]>,
      FormData
    >(baseURL, token, "post", path, onError);
    return action(formData);
  };

  return {
    ...swrResponse,
    translate,
    getDevice,
    getDeviceByName,
    getDeviceType,
  };
}
