import React, { ReactElement } from 'react'

import { unstable_createMuiStrictModeTheme as createMuiTheme } from '@material-ui/core' // https://stackoverflow.com/questions/61220424/material-ui-drawer-finddomnode-is-deprecated-in-strictmode
import { ThemeProvider } from '@material-ui/core/styles'
import CssBaseline from '@material-ui/core/CssBaseline'

import '@fontsource/montserrat/400.css'
import '@fontsource/montserrat/500.css'
import '@fontsource/montserrat/700.css'

interface Props {
  children: React.ReactNode,
}

const colors = {
  black: '#3e4247',
  white: '#FFFFFF',
  primary: '#4bae51',
  secondary: '#8d36a4',
  grey: '#B5B5B5',
  backgroundGrey:'#424242',
  borderGrey: "#515151",
  error: '#FF526D',
  info: '#00A9FF',
}

const Theme : React.FC<Props> = ({ children }: Props): ReactElement => {

  const defaultTheme = createMuiTheme({ palette: { type: 'dark' } });

  const theme = createMuiTheme({
    typography: {
      fontFamily: 'Montserrat, sans-serif',
      h1: { fontSize: '20px', lineHeight: '24px', fontWeight: 500 }
    },
    palette: {
      type: 'dark',
      common: { black: colors.black, white: colors.white },
      text: { primary: colors.white },
      primary: { main: colors.primary },
      secondary: { main: colors.secondary },
      error: { main: colors.error },
      info: { main: colors.info }
    },
    props: {
      MuiButton: { color: 'secondary' },
      MuiAppBar: { color: 'primary' }
    },
    overrides: {
      MuiAppBar: {
        colorPrimary: { color: colors.white }
      },
      MuiButton: {
        root: {
          transition: 'color .01s',
        },
        textPrimary: { color: colors.secondary },
      },   
      MuiInputBase: {
        input: {
          "&:-webkit-autofill": {
            WebkitBoxShadow: `0 0 0 1000px ${defaultTheme.palette.background.default} inset!important`
          }
        }
      },
      MuiCircularProgress: {
        colorPrimary: { color: colors.white }
      },
    }
  })

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      {children}
    </ThemeProvider>
  )
}

export { Theme, colors }
