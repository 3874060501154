import type {
  GetDiseaseResponseDto,
  CreateDiseaseRequestDto,
  UpdateDiseaseRequestDto,
  UpdateDiseaseResponseDto,
  CreateDiseaseResponseDto
} from '../dto/Diseases'
import { ResponseApi, ResponseApiList, TypeOnError } from '../global'
import { CreateAction, CreateActionWithEndpoint, IUseBase, useInstance } from './IUseBaseElements'

export interface IUseDiseases extends IUseBase<ResponseApiList<GetDiseaseResponseDto>> {
  add: (user: CreateDiseaseRequestDto) => Promise<ResponseApi<CreateDiseaseResponseDto>>,
  patch: (id: number, user: UpdateDiseaseRequestDto) => Promise<ResponseApi<UpdateDiseaseResponseDto>>,
  del: (id: number) => Promise<ResponseApi<boolean>>,
  findAllByCompany: (companyId: number) => Promise<ResponseApiList<GetDiseaseResponseDto>>
}

export function useDiseases(baseURL: string, token: string, query: any, shouldFetch?: boolean, onError?: TypeOnError): IUseDiseases {

  let path: string = `/tracking_types`;
  if(query) path = path + `?limit=${query.limit}&page=${query.page}`
  const swrResponse: IUseBase<ResponseApiList<GetDiseaseResponseDto>> = useInstance<ResponseApiList<GetDiseaseResponseDto>>(
    baseURL,
    token,
    shouldFetch ? path : null,
    onError
  );

  const patch = async (id: number, dto: UpdateDiseaseRequestDto) => {
    let path: string = `/tracking_types/${id}`;
    const action = CreateActionWithEndpoint<ResponseApi<UpdateDiseaseResponseDto>, UpdateDiseaseRequestDto, ResponseApiList<GetDiseaseResponseDto>>(
      baseURL,
      token,
      swrResponse.mutate,
      'patch',
      onError
    );
    return action(path, dto);
  };

  const add = async (dto: CreateDiseaseRequestDto) => {
    let path: string = '/tracking_types';
    const action = CreateActionWithEndpoint<ResponseApi<CreateDiseaseResponseDto>, CreateDiseaseRequestDto, ResponseApiList<GetDiseaseResponseDto>>(
      baseURL,
      token,
      swrResponse.mutate,
      'post',
      onError
    );
    return action(path, dto);
  };

  const del = async (id: number): Promise<ResponseApi<boolean>> => {
    let path: string = `/tracking_types/${id}`;
    const action = CreateAction<ResponseApi<boolean>, boolean, ResponseApiList<GetDiseaseResponseDto>>(
      baseURL,
      token,
      swrResponse.mutate,
      'delete',
      path,
      onError
    );
    return action();
  };

  const findAllByCompany = async (companyId: number): Promise<ResponseApiList<GetDiseaseResponseDto>> => {
    let path: string = `/tracking_types/company/${companyId}`;
    const action = CreateAction<ResponseApiList<GetDiseaseResponseDto>, boolean, ResponseApiList<GetDiseaseResponseDto>>(
      baseURL,
      token,
      swrResponse.mutate,
      'get',
      path,
      onError
    );
    return action();
  }

  return {
    ...swrResponse,
    add,
    patch,
    del,
    findAllByCompany
  }
}
