import React, { useEffect, useState } from "react";
import { Modal, TextField } from "@mui/material";
import { Box } from "@mui/system";
import { Button } from "@mui/material";
import DownloadIcon from "@mui/icons-material/Download";
import { ImageDisplay } from "@doctomatic/components-react/build/ImagesViews/ImageDisplay";
import { ImageViewModalProps } from "@doctomatic/components-react/build/ImagesViews/ImageViewModal";
import { useTranslation } from "react-i18next";
import { Measurement } from "./ImageValidatorList";
import { SignBasicDto } from "@doctomatic/sdk/build/dto/Sign/Signs";
import Radio from "@material-ui/core/Radio";
import FormControlLabel from "@material-ui/core/FormControlLabel/FormControlLabel";
import RadioGroup from "@material-ui/core/RadioGroup";
import { toast } from "react-toastify";
import { Mode } from "@doctomatic/sdk/build/dto/Images";
import RemoveIcon from "@mui/icons-material/Remove";
import AddIcon from "@mui/icons-material/Add";

export type ReadDeviceValidate = {
  position: number;
  status: number;
};

export interface ImageEditModalProps extends ImageViewModalProps {
  debug: boolean;
  signs: SignBasicDto[];
  measurements: Measurement[];
  readDeviceValidate: string | undefined;
  mode: Mode | undefined;
  onSave: (measurements: Measurement[], selectedMode: Mode) => Promise<void>;
  onUpdate: (
    readDeviceId: number,
    readDeviceValidate: ReadDeviceValidate[]
  ) => Promise<void>;
  onClose: () => void;
  readDeviceId: number;
  imageUrl: string;
  debugUrl: string;
  open: boolean;
  handleClose: () => void;
  error: boolean;
  handleError: () => void;
}

export const ImageEditModal = ({
  debug,
  signs,
  measurements,
  readDeviceValidate,
  mode,
  onSave,
  onUpdate,
  onClose,
  readDeviceId,
  imageUrl,
  debugUrl,
  open,
  handleClose,
  error,
  handleError,
}: ImageEditModalProps): React.ReactElement => {
  const { t } = useTranslation();

  const boxStyles = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    maxWidth: "100%",
    height: "auto",
    bgcolor: "white",
    boxShadow: 24,
    outline: "none",
    padding: 2,
    borderRadius: 2,
    overflowY: "auto",
    maxHeight: "80vh",
  };

  const gridBoxStyles = {
    display: "flex",
    gridTemplateColumns: "1fr 1fr",
    gridColumnGap: 16,
    justifyContent: "center",
  };

  // Estado para guardar el modo seleccionado
  const [selectedMode, setSelectedMode] = useState<string | Mode>("");
  const [isStraight, setIsStraight] = useState<boolean>(false);

  const [changeMeasurement, setChangeMeasurement] =
    useState<Measurement[]>(measurements);

  useEffect(() => {
    if (mode && readDeviceValidate) {
      if (readDeviceValidate[0] === "1") {
        setSelectedMode(mode);
      }
    }
  }, [readDeviceValidate, mode]);

  useEffect(() => {
    if (readDeviceValidate && readDeviceValidate[4] === "1") {
      setIsStraight(true);
    }
  }, [readDeviceValidate]);

  const changeMode = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedMode(event.target.value as Mode);
  };

  const update = async (
    readDeviceId: number,
    readDeviceValidate: ReadDeviceValidate[]
  ) => {
    await onUpdate(readDeviceId, readDeviceValidate);
  };

  const handleOnSave = async () => {
    if (selectedMode === "") {
      toast.error("Debe seleccionar un modo");
    } else {
      await onSave(changeMeasurement, selectedMode as Mode);
      update(readDeviceId, [
        {
          position: 0,
          status: 1,
        },
        {
          position: 1,
          status: 1,
        },
      ]);
      onClose();
    }
  };

  const handleOnChange = async (event: any, signId: number) => {
    const index: number = changeMeasurement.findIndex(
      (m: Measurement) => m.signId === signId
    );
    if (index !== -1) {
      const updatedMeasurements = [...changeMeasurement];
      updatedMeasurements[index] = {
        ...updatedMeasurements[index],
        value: event.target.value,
      };
      setChangeMeasurement(updatedMeasurements);
    } else {
      const newMeasurement: Measurement = {
        signId: signId,
        value: event.target.value,
        readDeviceId: readDeviceId,
      };
      setChangeMeasurement((prevMeasurements) => [
        ...prevMeasurements,
        newMeasurement,
      ]);
    }
  };

  const handleDownload = (debug: boolean) => {
    const link = document.createElement("a");
    link.href = debug ? debugUrl : imageUrl;
    link.download = "imagen.jpg";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  interface DebugProps {
    debug: boolean;
  }

  const Image: React.FC<DebugProps> = ({ debug }) => (
    <ImageDisplay
      imageUrl={debug ? debugUrl : imageUrl}
      altText={"Imagen"}
      t={t}
      error={error}
      handleError={handleError}
    />
  );

  const GenericButtons: React.FC<DebugProps> = ({ debug }) => (
    <Box
      m={2}
      style={{
        marginTop: 20,
        display: "flex",
        justifyContent: "flex-end",
        alignItems: "stretch",
        height: "100%",
        overflow: "auto",
      }}
    >
      <Button
        sx={{
          display: "flex",
          flex: 1,
          alignItems: "center",
          margin: "0 8px",
        }}
        variant="contained"
        color="inherit"
        size="small"
        onClick={handleClose}
      >
        {"Cerrar imagen"}
      </Button>
      <Button
        sx={{
          display: "flex",
          flex: 1,
          alignItems: "center",
          margin: "0 8px",
        }}
        variant="contained"
        color="info"
        size="small"
        onClick={() => handleDownload(debug)}
        title={t("DownloadButton")}
      >
        <DownloadIcon />
      </Button>
    </Box>
  );

  const EditButtons = signs.length > 0 && (
    <Box
      m={2}
      style={{
        marginTop: 20,
        display: "flex",
        justifyContent: "flex-end",
        alignItems: "stretch",
        height: "100%",
        overflow: "auto",
      }}
    >
      <Button
        sx={{
          display: "flex",
          flex: 1,
          alignItems: "center",
          margin: "0 8px",
        }}
        variant="contained"
        color="success"
        size="small"
        onClick={() => handleOnSave()}
      >
        {"Guardar"}
      </Button>

      <Button
        sx={{
          display: "flex",
          flex: 1,
          alignItems: "center",
          margin: "0 8px",
        }}
        variant="contained"
        color="error"
        size="small"
        onClick={() => update(readDeviceId, [{ position: 2, status: 1 }])}
      >
        {"Borrar"}
      </Button>

      <Button
        sx={{
          display: "flex",
          flex: 1,
          alignItems: "center",
          margin: "0 8px",
        }}
        variant="contained"
        color="error"
        size="small"
        onClick={() => update(readDeviceId, [{ position: 3, status: 1 }])}
      >
        {"Dispositivo erroneo"}
      </Button>
    </Box>
  );

  const debugImageContainer = (
    <Box sx={boxStyles}>
      <Image debug={true} />
      <Box m={2} style={gridBoxStyles}>
        <GenericButtons debug={true} />
      </Box>
    </Box>
  );

  const textWarning = signs.length < 1 && (
    <Box
      m={2}
      style={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "flex-start",
      }}
    >
      <p style={{ color: "red", fontSize: "20px" }}>
        {"Esta imagen aun no ha sido traducida"}
      </p>
    </Box>
  );

  const signsGrouped = [];

  for (let i = 0; i < signs.length; i += 4) {
    signsGrouped.push(signs.slice(i, i + 4));
  }

  const imageEditContainer = (
    <Box sx={boxStyles}>
      <Image debug={false} />
      {textWarning}
      <Box style={{ maxHeight: "calc(100vh - 650px)", overflowY: "auto" }}>
        {signs.length > 0 && (
          <Box
            m={2}
            style={{ ...gridBoxStyles, justifyContent: "space-around" }}
          >
            <RadioGroup
              style={{ color: "black" }}
              defaultValue="7s"
              name="radio-buttons-group"
              value={selectedMode}
              onChange={changeMode}
            >
              <FormControlLabel
                value="7s"
                control={<Radio style={{ color: "black" }} />}
                label="7 segmentos"
              />
              <FormControlLabel
                value="normal"
                control={<Radio style={{ color: "black" }} />}
                label="Normal"
              />
            </RadioGroup>

            <Button
              sx={{
                display: "flex",
                // flex: 1,
                alignItems: "center",
                margin: "0 8px",
              }}
              variant="contained"
              color={isStraight ? "error" : "info"}
              size="small"
              onClick={() => {
                setIsStraight(!isStraight);
                update(readDeviceId, [{ position: 4, status: +isStraight }]);
              }}
            >
              {isStraight ? (
                <>
                  <RemoveIcon style={{ marginRight: "10px" }} /> Quitar imágen
                  recta{" "}
                </>
              ) : (
                <>
                  <AddIcon style={{ marginRight: "10px" }} /> Añadir imágen
                  recta{" "}
                </>
              )}
            </Button>
          </Box>
        )}
        {signs.length > 0 && (
          <Box
            m={2}
            style={{
              flexDirection: "column",
              display: "flex",
              gridColumnGap: 16,
              justifyContent: "center",
            }}
          >
            {signsGrouped.map((group: SignBasicDto[], groupIndex: number) => (
              <Box
                key={`group-${groupIndex}`}
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "flex-start",
                  gridColumnGap: 16,
                }}
              >
                {group.map((sign: SignBasicDto, index: number) => {
                  const measurement: Measurement | undefined =
                    changeMeasurement.find(
                      (m: Measurement) => m.signId === sign.id
                    );
                  return (
                    <TextField
                      key={index}
                      margin="dense"
                      variant="outlined"
                      name={sign.name}
                      label={`${sign.name} (${sign.unit})`}
                      type="number"
                      value={measurement ? measurement.value : ""}
                      fullWidth
                      onChange={(event: any) => handleOnChange(event, sign.id)}
                      autoFocus={index === 0}
                    />
                  );
                })}
              </Box>
            ))}
          </Box>
        )}
        <GenericButtons debug={false} />
        {EditButtons}
      </Box>
    </Box>
  );

  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        {debug ? debugImageContainer : imageEditContainer}
      </Modal>
    </div>
  );
};
