import { GetDeviceResponseDto } from "./Devices";
import { BasicItem, IBasicItem } from "./IItemList";
import { GetMeasurementResponseDto } from "./Measurements/Measurements";
import { ISignBasicDto } from "./Sign/ISign";
import { SignBasicDto } from "./Sign/Signs";
import { GetUserResponseDto } from "./User";

export enum Mode {
  SEVENSEGMENT = "7s",
  NORMAL = "normal",
}

export type Detection = {
  num: number;
  conf: number;
  x: number;
  y: number;
  w: number;
  h: number;
};

export type MixedDetection = {
  numberDetection: Detection;
  digitsDetections: Detection[];
};

export interface AIDetectionJSON {
  id_train: string;
  arch: string;
  tags: any[];
  values: any[];
}

export interface IProposedMeasurement {
  value: number | string;
  sign: ISignBasicDto;
}

export class ProposedMeasurement implements IProposedMeasurement {
  value: number = 0;
  sign: SignBasicDto = new SignBasicDto();
}

export interface IGetImageResponseDto {
  id: number;
  key: string;
  aivalues: string;
  mode: string;
  candidate: boolean;
  confirmed: boolean;
  device?: GetDeviceResponseDto;
  deviceId: number;
  measurements?: GetMeasurementResponseDto[];
  user?: GetUserResponseDto;
  userId: number;
  doctor?: GetUserResponseDto;
  doctorId: number;
  admin?: GetUserResponseDto;
  adminId: number;
  created_at?: Date;
}

export interface ICreateImageResponseDto {
  id: number;
  proposedMeasurements: IProposedMeasurement[];
  device: IBasicItem;
  imageToTranslate: boolean;
  translateImageId?: number;
}

export interface IGetMixedDetectionsResponse {
  detection_json: MixedDetection[];
  proposed_measurements: IProposedMeasurement[];
  image_id: number;
  mongo_label: string;
}

export interface IGetMixedDetectionsRequest {
  detection_json: AIDetectionJSON;
  image_id: number;
  mongo_label: string;
  image_width: number;
  image_height: number;
}

export class GetImageResponseDto implements IGetImageResponseDto {
  id: number = 0;
  values: number[] = [];
  key: string = "";
  aivalues: string = "";
  mode: string = "";
  candidate: boolean = false;
  confirmed: boolean = false;
  device?: GetDeviceResponseDto;
  deviceId: number = 0;
  measurements?: GetMeasurementResponseDto[];
  user?: GetUserResponseDto;
  userId: number = 0;
  doctor?: GetUserResponseDto;
  doctorId: number = 0;
  admin?: GetUserResponseDto;
  adminId: number = 0;
  created_at?: Date;
}

export class CreateImageResponseDto implements ICreateImageResponseDto {
  id: number = 0;
  proposedMeasurements: ProposedMeasurement[] = [];
  imageToTranslate: boolean = false;
  translateImageId?: number;
  device: BasicItem = new BasicItem();
}

// TO BE REMOVED ON 4.0.0
export interface ICreateImageOldResponseDto {
  id: number;
  values: number[];
  imageToTranslate: boolean;
  translateImageId?: number;
}

export class CreateImageOldResponseDto implements ICreateImageOldResponseDto {
  id: number = 0;
  values: number[] = [];
  imageToTranslate: boolean = false;
  translateImageId?: number;
}
