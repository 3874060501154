import { Method } from "axios";
import useSWR, { KeyedMutator, SWRResponse } from "swr";
import { TypeOnError } from "../global";
import Fetcher from './Fetcher'

/**
 * 
 * TApiList-> ResponseApiList
 * TApi -> ResponseApi
 * TAdd -> Dto to add
 * Tupdate -> Dto to update
 *
 */
export interface IUseBaseElements<
  TGetListResponse,
  TGetResponse,
  TAddRequest,
  TAddResponse,
  TPatchRequest,
  TPatchResponse,
  TDelResponse,
  TRestoreResponse> {
  response: TGetListResponse | undefined;
  get: (id: number) => Promise<TGetResponse>,
  add: (item: TAddRequest) => Promise<TAddResponse>,
  patch: (id: number, item: TPatchRequest) => Promise<TPatchResponse>,
  del: (id: number) => Promise<TDelResponse>,
  restore: (id: number) => Promise<TRestoreResponse>,
  mutate: KeyedMutator<TGetListResponse>,
  onError?: (err: any, key: any, config: any) => Promise<void>;
  error: any;
  isValidating: boolean;
}


export type Action<T, DataType> = (
  data?: DataType) => Promise<T>

export type ActionWithEndpoint<T, DataType> = (
  endPoint: string,
  data?: DataType) => Promise<T>


export function CreateAction<ResponseType, DataType = void, MutateType = void>(
  baseURL: string,
  token: string,
  mutate: KeyedMutator<MutateType>,
  method: Method,
  endPoint: string,
  onError?: TypeOnError
): Action<ResponseType, DataType> {

  return async function (data?: DataType): Promise<ResponseType> {
    try {
      const response = await Fetcher<ResponseType, DataType>(baseURL, token)(endPoint, method, data);
      if (method !== "get" && method !== "GET") mutate();
      return response;
    } catch (error: any) {
      console.log(error);
      onError && onError(error, '', '');
      throw error;
    }
  }
}

export function CreateActionWithoutMutate<ResponseType, DataType = void>(
  baseURL: string,
  token: string,
  method: Method,
  endPoint: string,
  onError?: TypeOnError
): Action<ResponseType, DataType> {

  return async function (data?: DataType): Promise<ResponseType> {
    try {
      const response = await Fetcher<ResponseType, DataType>(baseURL, token)(endPoint, method, data);
      return response;
    } catch (error: any) {
      console.log(error);
      onError && onError(error, '', '');
      throw error;
    }
  }
}
/**
 * action to endpoint , patch, add, edit, delete...
 * @param baseURL 
 * @param token 
 * @param mutate 
 * @param method 
 * @param onError 
 * @returns action
 */
export function CreateActionWithEndpoint<ResponseType, DataType = void, MutateType = void>(
  baseURL: string,
  token: string,
  mutate: KeyedMutator<MutateType>,
  method: Method,
  onError?: TypeOnError):
  ActionWithEndpoint<ResponseType, DataType> {

  return async function (
    endPoint: string,
    data?: DataType): Promise<ResponseType> {
    try {
      const response = await Fetcher<ResponseType, DataType>(baseURL, token)(endPoint, method, data)
      mutate()
      return response;
    } catch (error: any) {
      console.log(error);
      onError && onError(error, '', '')
      throw error;
    }
  }

}

export interface IAction<T> {
  name: string;
  action: (endPoint: string, method: Method, type: T) => Promise<T>
}

export interface IActionParameters<T, DataType = void> {
  name: string;
  endPoint: string;
  method: Method;
  type: T;
  data?: DataType;
}

export interface IUseBase<T> {
  SWRResponse: SWRResponse<T, any>;
  response: T | undefined;
  mutate: KeyedMutator<T>;
  actions: { name: string, action: Action<any, any> }[] | undefined;
  isValidating: boolean;
  error: any;
}

export function useInstance<T>(baseURL: string,
  token: string,
  endPoint: string | null,
  onError?: TypeOnError,
  swrOptions?: any,
  actionsParameters?: IActionParameters<any>[]
): IUseBase<T> {

  const fetcher = Fetcher<T>(baseURL, token)
  const SWRResponse = useSWR<T>(endPoint, fetcher, swrOptions ? swrOptions : { shouldRetryOnError: false, onError: onError })
  const mutate = SWRResponse.mutate;
  const response = SWRResponse.data;
  const isValidating = SWRResponse.isValidating;
  const error = SWRResponse.error;

  const actions = actionsParameters?.map((x) => {
    return { name: x.name, action: CreateActionWithEndpoint<any, any, any>(baseURL, token, mutate, x.method, onError) }
  });

  return {
    SWRResponse,
    response,
    mutate,
    actions,
    error,
    isValidating
  }
}
