import { makeStyles, Theme } from "@material-ui/core/styles";
import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@material-ui/core";
import { GetCompanyResponseDto } from "@doctomatic/sdk/build/dto/Company";
import { AddAdminCompanyRequestDto } from "@doctomatic/sdk/build/dto/Actors/AdminCompany/AdminCompany";
import { Phone } from "../../Phone";
import { toast } from "react-toastify";

interface Props {
  open: boolean;
  company: GetCompanyResponseDto;
  onClose: () => void;
  onSave: (dto: AddAdminCompanyRequestDto) => void;
}

const useStyles = makeStyles((theme: Theme) => ({
  visible: { overflowY: "visible" },
}));

const AdminCreate = ({
  company,
  open,
  onClose,
  onSave,
}: Props): React.ReactElement => {
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [emailError, setEmailError] = useState<boolean>(false);
  const [nameError, setNameError] = useState<boolean>(false);
  const [timezone, setTimezone] = useState('');
  const classes = useStyles();
  
  const clear = () => {
    setEmail("")
    setName("")
    setPhone("")
    setEmailError(false)
    setNameError(false)
  }
  
  useEffect(() => {
    clear();
  }, [open])

  useEffect(() => {
    if (emailError && email.length > 0) setEmailError(false);
  }, [email, emailError]);

  useEffect(() => {
    if (nameError && name.length > 0) setNameError(false);
  }, [name, nameError]);

  useEffect(() => {
    const getTimezone = () => {
      const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
      setTimezone(timezone);
    };

    getTimezone();
  }, []);

  const checkErrors = () => {
    let error = false;
    if (email.length === 0) {
      setEmailError(true);
      error = true;
    }
    if (name.length === 0) {
      setNameError(true);
      error = true;
    }
    return error;
  };

  return (
    <Dialog
      open={open}
      fullWidth={true}
      maxWidth="sm"
      classes={{ paper: classes.visible }}
    >
      <DialogTitle>
        Crear admin para el centro médico {company.name}
      </DialogTitle>

      <DialogContent className={classes.visible}>
        <TextField
          margin="dense"
          variant="outlined"
          name="email"
          label="Email"
          type="text"
          fullWidth
          onChange={(e) => setEmail(e.target.value)}
          value={email}
          required={true}
          error={emailError}
          autoFocus
        />
        <TextField
          margin="dense"
          variant="outlined"
          name="name"
          label="Nombre"
          type="text"
          fullWidth
          onChange={(e) => setName(e.target.value)}
          value={name}
          error={nameError}
          required={true}
        />
        <Phone
          onUpdate={(value) => setPhone(value)}
          value={phone}
          required={false}
        />
      </DialogContent>

      <DialogActions>
        <Box m={2}>
          <Box mr={2} display="inline-block">
            <Button onClick={() => {clear(); onClose()}} color="default" size="small">
              Cancelar
            </Button>
          </Box>
          <Button
            onClick={() => {
              const error = checkErrors();
              if (error) {
                toast.error("Faltan campos");
              } else {
                const dto = new AddAdminCompanyRequestDto();
                dto.email = email;
                dto.name = name;
                dto.phone = phone;
                dto.companyId = company.id
                dto.timezone = timezone;
                onSave(dto);
              }
            }}
            variant="contained"
            size="small"
          >
            Guardar
          </Button>
        </Box>
      </DialogActions>
    </Dialog>
  );
};

export { AdminCreate };
