import { GridColumns, GridValueGetterParams } from "@mui/x-data-grid";
import { formattedDate } from "../../utils";
import { ITranslations } from "./TranslationsGrid";

export const TranslationsGridColumns = (): GridColumns<ITranslations> => {

  return [
    {
      field: 'updatedAt',
      headerName: 'Fecha',
      width: 200,
      align: 'left',
      valueGetter: (params: GridValueGetterParams) => params.row.day,
      renderCell: (params) => {
        return <p>{formattedDate(params.row.day)}</p>
      }
    },
    {
      field: 'countUsers',
      headerName: 'Cantidad traducciones',
      width: 200,
      align:'left',
      valueGetter: (params: GridValueGetterParams) => params.row.count,
      renderCell: (params) => {
        return <p>{params.row.count}</p>
      }
    }
  ];
};