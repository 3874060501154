import useSWR from 'swr';
import { KeyedMutator } from 'swr/dist/types';
import {
  AddAlertConfigByValueIndividualRequestDto,
  AddAlertConfigByValueIndividualResponseDto,
  GetAlertConfigByValueIndividualResponseDto,
  ItemAlertConfigByValueIndividualDto,
  UpdateAlertConfigByValueIndividualRequestDto,
  UpdateAlertConfigByValueIndividualResponseDto
} from '../../dto/Alerts/config/individual/AlertConfigByValueIndividual';
import { ResponseApi, ResponseApiList } from '../../global';
import Fetcher from '../Fetcher';

export interface IUseAlertConfigsByValue {
  // alertConfigsByValue: ResponseApiList<ItemAlertConfigByValueIndividualDto> | undefined,
  // mutate: KeyedMutator<ResponseApiList<ItemAlertConfigByValueIndividualDto>>,
  findOneById: (id: number) => Promise<ResponseApi<GetAlertConfigByValueIndividualResponseDto>>,
  add: (alertConfig: AddAlertConfigByValueIndividualRequestDto) => Promise<ResponseApi<AddAlertConfigByValueIndividualResponseDto>>,
  patch: (id: number, alertConfig: UpdateAlertConfigByValueIndividualRequestDto) => Promise<ResponseApi<UpdateAlertConfigByValueIndividualResponseDto>>
  del: (id: number) => Promise<ResponseApi<any>>
}

export function useAlertConfigsByValue(baseURL: string, token: string, shouldFetch?: boolean): IUseAlertConfigsByValue {
  // TODO: delete - type in interface - return
  // const fetcher = Fetcher<ResponseApiList<ItemAlertConfigByValueIndividualDto>>(baseURL, token);
  // const { data, mutate } = useSWR<ResponseApiList<ItemAlertConfigByValueIndividualDto>>
  // (
  //   shouldFetch ? '/alerts_config/value' : null, 
  //   fetcher, 
  //   { shouldRetryOnError: false }
  // );

  const findOneById = async (id: number): Promise<ResponseApi<GetAlertConfigByValueIndividualResponseDto>> => {
    const response: ResponseApi<GetAlertConfigByValueIndividualResponseDto> = await Fetcher<ResponseApi<GetAlertConfigByValueIndividualResponseDto>>(baseURL, token)(`/alerts_config/value/${id}`, 'get');
    return response;
  }

  const add = async (alertConfig: AddAlertConfigByValueIndividualRequestDto): Promise<ResponseApi<AddAlertConfigByValueIndividualResponseDto>> => {
    const response: ResponseApi<AddAlertConfigByValueIndividualResponseDto> = await Fetcher<ResponseApi<AddAlertConfigByValueIndividualResponseDto>, AddAlertConfigByValueIndividualRequestDto>(baseURL, token)('/alerts_config/value', 'post', alertConfig);
    return response;
  }

  const patch = async (id: number, alertConfig: UpdateAlertConfigByValueIndividualRequestDto): Promise<ResponseApi<UpdateAlertConfigByValueIndividualResponseDto>> => {
    const response: ResponseApi<UpdateAlertConfigByValueIndividualResponseDto> = await Fetcher<ResponseApi<UpdateAlertConfigByValueIndividualResponseDto>, UpdateAlertConfigByValueIndividualRequestDto>(baseURL, token)(`/alerts_config/value/${id}`, 'patch', alertConfig);
    return response;
  }

  const del = async (id: number): Promise<ResponseApi<any>> => {
    const response: ResponseApi<any> = await Fetcher<ResponseApi<any>>(baseURL, token)(`/alerts_config/value/${id}`, 'delete');
    return response;
  }

  return {
    // alertConfigsByValue: data,
    // mutate,
    findOneById,
    add,
    patch,
    del
  };
}