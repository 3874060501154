import { Type } from "class-transformer";
import { ValidateNested } from "class-validator";
import { GetFrequencyResponseDto } from "../Frequencies";
import { BasicItem, IItemDeviceList, ItemList } from "../IItemList";
import { GetLocationUserResponseDto } from "../LocationUser";
import { ItemMeasurement } from "../Measurements/Measurements";
import { GetTreatmentResponseDto } from "../Treatment/Treatments";
import {
  CreateUserRequestDto,
  CreateUserResponseDto,
  Gender,
  GetUserResponseDto,
  UpdateProfileRequestDto,
  UpdateProfileResponseDto,
  UpdateUserRequestDto,
  UpdateUserResponseDto,
} from "../User";
import {
  IAddPatientRequestDto,
  IAddPatientResponseDto,
  IGetPatientResponseDto,
  IGetPatientWithDevicesAndMeasurements,
  IItemPatientListExtendedResponseDto,
  IItemPatientListSummary,
  IPatientsDevicesMeasurementResponseDto,
  IUpdatePatientProfileRequestDto,
  IUpdatePatientProfileResponseDto,
  IUpdatePatientRequestDto,
  IUpdatePatientResponseDto,
} from "./IPatient";

export enum PatientOnboardingOptions {
  SimpleOnboarding = 1, 
  CompleteOnboarding = 2, 
}

export class AddPatientRequestDto
  extends CreateUserRequestDto
  implements IAddPatientRequestDto
{
  subscription: boolean = false;
  stripeCustomerId?: string;
  stripeAccount?: string;
  doctorId?: number;
  locationId?: number;
  language?: string;
  timezone?: string;
  inheritance?: boolean;
  activatePatient?: boolean;
  externalId?: string;
  consentDoctorId?: number;
  onBoardingReq: PatientOnboardingOptions =
    PatientOnboardingOptions.CompleteOnboarding;
}

export class AddPatientResponseDto
  extends CreateUserResponseDto
  implements IAddPatientResponseDto
{
  hasSubscription: boolean = false;
  stripeCustomerId?: string;
  stripeSubscriptionId?: string;
}

export class GetPatientResponseDto
  extends GetUserResponseDto
  implements IGetPatientResponseDto
{
  activatedAt?: Date | undefined;
  onBoardingReq: PatientOnboardingOptions = 2;
  acceptedContract: boolean = false;
  acceptedConsent: boolean = false;
  hasSubscription: boolean = false;
  stripeCustomerId?: string;
  stripeSubscriptionId?: string;
  externalId?: string;
  @ValidateNested()
  @Type(() => GetTreatmentResponseDto)
  treatments: GetTreatmentResponseDto[] = [];

  @ValidateNested()
  @Type(() => BasicItem)
  alertConfigByValue: BasicItem[] = [];

  @ValidateNested()
  @Type(() => BasicItem)
  alertConfigByNoMeasure: BasicItem[] = [];

  @ValidateNested()
  @Type(() => GetFrequencyResponseDto)
  frequencies: GetFrequencyResponseDto[] = [];

  @ValidateNested()
  @Type(() => GetLocationUserResponseDto)
  location_user: GetLocationUserResponseDto[] = [];

  mustHaveSubscription: boolean = false;
  gender?: Gender;
  birthYear?: number;
  created_at?: Date;
}
export class UpdatePatientProfileRequestDto
  extends UpdateProfileRequestDto
  implements IUpdatePatientProfileRequestDto {}

export class UpdatePatientProfileResponseDto
  extends UpdateProfileResponseDto
  implements IUpdatePatientProfileResponseDto {}

export class UpdatePatientRequestDto
  extends UpdateUserRequestDto
  implements IUpdatePatientRequestDto
{
  externalId?: string;
  gender?: Gender;
}

export class UpdatePatientResponseDto
  extends UpdateUserResponseDto
  implements IUpdatePatientResponseDto
{
  hasSubscription: boolean = false;
  stripeCustomerId?: string;
  stripeSubscriptionId?: string;
  @ValidateNested()
  @Type(() => GetTreatmentResponseDto)
  treatments: GetTreatmentResponseDto[] = [];

  @ValidateNested()
  @Type(() => BasicItem)
  alertConfigByValue: BasicItem[] = [];

  @ValidateNested()
  @Type(() => BasicItem)
  alertConfigByNoMeasure: BasicItem[] = [];

  @ValidateNested()
  @Type(() => GetFrequencyResponseDto)
  frequencies: GetFrequencyResponseDto[] = [];

  gender?: Gender;
}

export class PatientsDevicesMeasurementResponseDto
  implements IPatientsDevicesMeasurementResponseDto
{
  patientname: string = "";
  deviceid: number = 0;
  measurementcreatedat?: Date;
  measurementvalue?: number;
}

export class ItemPatientListExtendedResponseDto
  implements IItemPatientListExtendedResponseDto
{
  deletedAt?: Date;
  alerts: any;

  @ValidateNested()
  @Type(() => GetTreatmentResponseDto)
  treatments: GetTreatmentResponseDto[] = [];

  @ValidateNested()
  @Type(() => BasicItem)
  alertConfigByValue: BasicItem[] = [];

  @ValidateNested()
  @Type(() => BasicItem)
  alertConfigByNoMeasure: BasicItem[] = [];

  @ValidateNested()
  @Type(() => GetLocationUserResponseDto)
  location_user: GetLocationUserResponseDto[] = [];

  id: number = 0;
  name: string = "";
  email: string = "";
  externalId?: string;
  phone: string = '';
}

export class GetPatientWithDevicesAndMeasurementsDto
  implements IGetPatientWithDevicesAndMeasurements
{
  patient: ItemList = new ItemList();
  patientExternalId?: string | undefined;
  treatmentDevices: {
    device: IItemDeviceList;
    lastMeasurement?: ItemMeasurement;
    visible: boolean;
  }[] = [];
  lastMeasurements: ItemMeasurement[] = [];
}

export class ItemPatientListSummary implements IItemPatientListSummary {
  id: number = 0;
  name: string = "";
  externalId?: string | undefined;
  measurements?: ItemMeasurement[] = [];
}
