import { useApi } from "@doctomatic/sdk/build/Api";
import { FormControl, InputLabel, MenuItem, Select } from "@material-ui/core";
import { useNavigate } from "react-router-dom";
import { processError } from "../../../App/errorToast";
import { useEffect } from "react";

interface CompaniesSelectProp {
  companyId?: string;
  companyParam?: string | null;
  setCompanyId: (id: string) => void;
}

interface ICompany {
  id: number;
  name: string;
}

const SelectCompany = ({
  companyId,
  companyParam,
  setCompanyId,
}: CompaniesSelectProp): React.ReactElement => {
  const { useCompanies, logout } = useApi();
  const history = useNavigate();
  const { response: companiesResponse } = useCompanies(
    true,
    processError(logout, history)
  );
  const companies = companiesResponse?.data;
  const allCompanies: ICompany[] = Array.isArray(companies)
    ? [{ id: 0, name: "Todos los centros" }, ...companies]
    : [];

  useEffect(() => {
    if (companyParam) {
      const company = allCompanies.find((c) => c.name === companyParam);
      if (company) {
        setCompanyId(company.id.toString());
      }
    }
  }, [companyParam, allCompanies]);

  if (!companies) return <></>;

  return (
    <FormControl variant="outlined" style={{ minWidth: 200, marginRight: 20 }}>
      <InputLabel id="company">{"Centro médico"}</InputLabel>
      <Select
        labelId="company"
        id="company-select"
        data-cy="company-select"
        label={"Centro médico"}
        onChange={(event) => setCompanyId(event.target.value as string)}
        value={companyId}
      >
        {allCompanies.map((c: ICompany) => (
          <MenuItem key={`c${c.id}`} data-cy={`c${c.id}`} value={c.id}>
            {c.name}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export { SelectCompany };
