import React, { useEffect, useState } from "react";
import { Page } from "../Page";
import {
  Select,
  Box,
  MenuItem,
  FormControl,
  InputLabel,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  TableHead,
  withStyles,
  createStyles,
  Theme,
} from "@material-ui/core";
import { useApi } from "@doctomatic/sdk/build/Api";
import {
  GetTopTranslatorResponseDto,
  Time,
} from "@doctomatic/sdk/build/dto/TranslateImage";
import { toast } from "react-toastify";
import EmojiEventsIcon from "@material-ui/icons/EmojiEvents";
import { processError } from "../../../App/errorToast";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import { SelectDate } from "../../DatePicker/selectDate";

const values = Object.values(Time);
const keys = Object.keys(Time);
const timeRange: { key: string; value: string }[] = [];
for (let i = 0; i < values.length; i++) {
  timeRange.push({ key: keys[i], value: values[i] });
}

const StyledTableRow = withStyles((theme: Theme) =>
  createStyles({
    root: {
      "&:nth-of-type(odd)": {
        backgroundColor: theme.palette.action.hover,
      },
    },
  })
)(TableRow);

const StyledTableCell = withStyles({
  root: {
    borderBottom: "none",
  },
})(TableCell);

interface TimeSelectProps {
  time?: Time;
  setTime: (time: Time) => void;
}

const TimeSelect = ({
  time = Time.TWOWEEK,
  setTime,
}: TimeSelectProps): React.ReactElement => {
  return (
    <FormControl variant="outlined" style={{ minWidth: 140, marginRight: 20 }}>
      <InputLabel id="time">{"Fecha"}</InputLabel>
      <Select
        labelId="time"
        id="timetype-select"
        label={"Fecha"}
        onChange={(event) => setTime(event.target.value as Time)}
        value={time}
      >
        {timeRange.map((t) => (
          <MenuItem key={`${t.key}`} value={t.value}>
            {t.value}
            {/* TODO: d.value to translation */}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

interface RankingProps {
  time_?: Time;
}

const Ranking = ({ time_ = Time.TWOWEEK }: RankingProps): React.ReactElement => {
  const history = useNavigate();
  const { useTranslateImages, logout } = useApi();
  const { topTranslators } = useTranslateImages(undefined, false, processError(logout, history));
  const [translators, setTranslators] = useState<GetTopTranslatorResponseDto[]>(
    []
  );

  const [dateRange, setDateRange] = useState<[Date | null, Date | null]>([null, null]);
  const [initDate, setInitDate] = useState<string | undefined>();
  const [finDate, setFinDate] = useState<string | undefined>();

  // whenever the date in the datepicker is changed, it formats the two dates with moment and refresh the data in the hook
  useEffect(() => {
    const date1 = dateRange[0] === null
    ? undefined
    : moment(dateRange[0]).format("yyyy-MM-DD");
    setInitDate(date1);
    const date2 = dateRange[1] === null
    ? undefined
    : moment(dateRange[1]).format("yyyy-MM-DD");
    setFinDate(date2);
  }, [dateRange])

  useEffect(() => {
    async function getTopTranslators() {
      try {
        const t = (await topTranslators(initDate, finDate)).data;
        setTranslators(t);
      } catch (err: any) {
        toast.error("Ha habido un error al recibir el ranking de traductores");
      }
    }
    getTopTranslators();
  }, [initDate, finDate]);

  const filters = (
    <Box display="flex" justifyContent="flex-end" height={"100%"}>
      <SelectDate date={dateRange} setDate={setDateRange} />
    </Box>
  );

  if (!translators) return <></>;

  return (
    <Page title={"Ranking traducciones"} buttons={filters}>
      <TableContainer>
        <Table id="tickets">
          <TableHead>
            <TableRow>
              <TableCell style={{ fontWeight: "bold" }} width="15%">Posición</TableCell>
              <TableCell style={{ fontWeight: "bold" }}>Translator</TableCell>
              <TableCell style={{ fontWeight: "bold" }} width="20%">Imágenes traducidas</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {translators.map((t: GetTopTranslatorResponseDto, i: number) => (
              <StyledTableRow key={t.id}>
                <StyledTableCell>
                  {i === 0 && <EmojiEventsIcon />}
                  {i <= 1 && <EmojiEventsIcon />}
                  {i <= 2 && <EmojiEventsIcon />}
                  {i > 2 && i + 1}
                </StyledTableCell>
                <StyledTableCell>{t.name}</StyledTableCell>
                <StyledTableCell>{t.translatedImages}</StyledTableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Page>
  );
};

export { Ranking };
