
export interface IRecoverPasswordRequestDto {
  email: string;
}

export interface IPostMailResponseDto {
  message: string;
}

export interface IPostMailRequestDto {
  to: string[];
  subject?: string;
  body?: string;
  base64Image?: string;
}

export class RecoverPasswordRequestDto implements IRecoverPasswordRequestDto {
  email: string = ''
}

export class PostMailResponseDto implements IPostMailResponseDto {
  message: string = ''
}

export class PostMailRequestDto implements IPostMailRequestDto {
  to: string[] = []
  subject: string = ''
  body: string = ''
  base64Image?: string = ''
}
