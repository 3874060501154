import {
  IAddAdminCompanyRequestDto,
  IAddAdminCompanyResponseDto,
  IGetAdminCompanyResponseDto,
  IItemAdminCompanyListDto,
  IUpdateAdminCompanyProfileRequestDto,
  IUpdateAdminCompanyProfileResponseDto,
  IUpdateAdminCompanyRequestDto,
  IUpdateAdminCompanyResponseDto,
} from "./IAdminCompany";
import {
  CreateUserRequestDto,
  CreateUserResponseDto,
  GetUserResponseDto,
  UpdateProfileRequestDto,
  UpdateProfileResponseDto,
  UpdateUserRequestDto,
  UpdateUserResponseDto,
} from "../../User";
import { ItemActorListDto } from "../ActorItemListDto";
import { IItemDoctorListDto } from "../Doctor/IDoctor";
export class AddAdminCompanyRequestDto
  implements IAddAdminCompanyRequestDto
{
  email: string = "";
  name: string = "";
  phone?: string;
  companyId?: number;
  language?: string;
  timezone?: string;
  birthYear?: number;
}
export class AddAdminCompanyResponseDto
  extends CreateUserResponseDto
  implements IAddAdminCompanyResponseDto {}

export class GetAdminCompanyResponseDto
  extends GetUserResponseDto
  implements IGetAdminCompanyResponseDto {}

export class UpdateAdminCompanyProfileRequestDto
  extends UpdateProfileRequestDto
  implements IUpdateAdminCompanyProfileRequestDto {}

export class UpdateAdminCompanyProfileResponseDto
  extends UpdateProfileResponseDto
  implements IUpdateAdminCompanyProfileResponseDto {}

export class UpdateAdminCompanyRequestDto
  extends UpdateUserRequestDto
  implements IUpdateAdminCompanyRequestDto {}

export class UpdateAdminCompanyResponseDto
  extends UpdateUserResponseDto
  implements IUpdateAdminCompanyResponseDto {}

export class ItemAdminCompanyListDto
  extends ItemActorListDto
  implements IItemAdminCompanyListDto {}
