import { ItemAlertConfigByNoMeasureIndividualDto } from '../dto/Alerts/config/individual/AlertConfigByNoMeasureIndividual';
import { ResponseApi, ResponseApiList, TypeOnError } from '../global'
import {
  CreateAction,
  IUseBase,
  useInstance
} from './IUseBaseElements'

export interface IUseAlertConfigsByNoMeasureByPatientFromTemplate
  extends IUseBase<ResponseApiList<ItemAlertConfigByNoMeasureIndividualDto>> {
}

export function useAlertConfigsByNoMeasureByPatientFromTemplate(baseURL: string, token: string, patientId: number, query: any, customFilter?: string, activeFilter?: string, shouldFetch?: boolean, onError?: TypeOnError): IUseAlertConfigsByNoMeasureByPatientFromTemplate {

  let path = `/patients/${patientId}/alerts_config/no_measure_template?limit=${query.limit}&page=${query.page}`

  if (customFilter) path += `&customFilter=${customFilter}`

  if (activeFilter) path += `&activeFilter=${activeFilter}`

  const swrResponse: IUseBase<ResponseApiList<ItemAlertConfigByNoMeasureIndividualDto>> = useInstance<ResponseApiList<ItemAlertConfigByNoMeasureIndividualDto>>(
    baseURL,
    token,
    shouldFetch ? path : null,
    onError
  );

  

  return {
    ...swrResponse,
  } as IUseAlertConfigsByNoMeasureByPatientFromTemplate;

}
