import { makeStyles, Theme } from '@material-ui/core/styles'

export default makeStyles((theme: Theme) => ({
  content: {
    flexGrow: 1,
    overflowY: "auto"
  },
  link: {
    color: 'inherit',
    textDecoration: 'none',
    marginRight: theme.spacing(3),
    '&.active': {
      textDecoration: 'underline'
    }
  }
}))

const absolutePosition = 'absolute';

export const styles = {
  containerModal: {
    position: absolutePosition,
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    maxWidth: '100%',
    height: 'auto',
    bgcolor: 'white',
    boxShadow: 24,
    outline: 'none',
    padding: 2,
    borderRadius: 2,
    overflowY: 'auto',
    maxHeight: '80vh'
  }
};
