import { ItemAlertConfigUserResponseDto } from "../dto/Alerts/config/user/AlertConfigUser";
import { Role } from "../dto/User";
import { TypeOnError, ResponseApiList } from "../global";
import { IUseBase, useInstance } from "./IUseBaseElements";

export interface IUseCompanyActors extends IUseBase<ResponseApiList<ItemAlertConfigUserResponseDto>> { }

export function useCompanyActors(baseURL: string, token: string, companyId: number, role?: Role, name?: string, shouldFetch?: boolean, onError?: TypeOnError) {

  let path: string = role ? `/companies/${companyId}/actors?role=${role}` : `/companies/${companyId}/actors`;

  if (name) path += role ? `&name=${name}` : `?name=${name}`;

  const swrResponse: IUseBase<ResponseApiList<ItemAlertConfigUserResponseDto>> = useInstance<ResponseApiList<ItemAlertConfigUserResponseDto>>(
    baseURL,
    token,
    shouldFetch ? path : null,
    onError
  );

  return {
    ...swrResponse
  };
}