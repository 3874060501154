import { Typography } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import { styles } from "./styles";
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
export var ImageDisplay = function (_a) {
    var imageUrl = _a.imageUrl, altText = _a.altText, t = _a.t, error = _a.error, handleError = _a.handleError;
    return (React.createElement(Box, { sx: styles.image }, imageUrl.length === 0 || error ? (React.createElement(Box, null,
        React.createElement(Typography, { style: styles.error }, t("ImageNotAvailableError")))) : (React.createElement(TransformWrapper, null,
        React.createElement(TransformComponent, null,
            React.createElement("img", { style: { width: "100%" }, src: imageUrl, alt: altText, onError: handleError }))))));
};
