import { Expose } from "class-transformer";
import { IsNotEmpty } from "class-validator";
import {
  IGetLegalResponse,
  IGetPdfUrlResponse,
  IUpdateLegalRequest,
} from "./ILegal";

export enum DocumentType {
  contract = "contract",
  consent = "consent",
}

export enum LegalVariables {
  medicalPractice = "{{ medicalPractice }}",
  doctor = "{{ doctor }}",
  doctorDNI = "{{ doctorDNI }}",
  licenseNumber = "{{ licenseNumber }}",
  doctorNationality = "{{ doctorNationality }}",
  patient = "{{ patient }}",
  patientNationality = "{{ patientNationality }}",
  patientAddress = "{{ patientAddress }}",
  patientDNI = "{{ patientDNI }}",
  company = "{{ company }}",
}

export class UpdateLegalRequestDto implements IUpdateLegalRequest {
  @Expose() @IsNotEmpty() md: string = "";
}

export class GetLegalResponseDto implements IGetLegalResponse {
  @Expose() md = "";
}

export class GetPdfUrlResponseDto implements IGetPdfUrlResponse {
  @Expose() url = "";
}
