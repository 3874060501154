import React, { useCallback, useEffect, useState } from "react";
import { Page } from "../Page";
import { ImageValidatorList } from "./ImageValidatorList";
import { GridSortItem } from "@mui/x-data-grid";
import CircularProgress from "@material-ui/core/CircularProgress/CircularProgress";
import { useApi } from "@doctomatic/sdk/build/Api";
import { DateRangePicker } from "@doctomatic/components-react/build/Graphs/DateRangePicker";
import {
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@material-ui/core";
import moment from "moment";
import { IUseImagesValidatorPaginated } from "@doctomatic/sdk/build/modules/ImagesValidatorPaginated/ImagesValidatorPaginated";
import { debounce } from "lodash";

const INITIAL_PAGINATION = { page: 1, limit: 10, sortBy: [] };

enum SelectOptions {
  ALL = "all",
  ONLY = "only",
  NO = "no",
}

interface DateRange {
  start: string;
  end: string;
}

interface ReadDeviceRange {
  start: number;
  end: number;
}

interface Device {
  id: number;
  name: string;
}

const ImageValidator = (): React.ReactElement => {
  const { useImagesValidatorPaginated } = useApi();

  const [pageSize, setPageSize] = useState<number>(10);
  const [page, setPage] = useState<number>(1);
  const [loading, setLoading] = useState<boolean>(true);
  const [pagination, setPagination] = useState<any>(INITIAL_PAGINATION);
  const [sortBy, setSortBy] = useState<GridSortItem[]>([]);

  const [date, setDate] = useState<DateRange>();
  const [dateRange, setDateRange] = useState<[Date | null, Date | null]>([
    null,
    null,
  ]);
  const [readDeviceStart, setReadDeviceStart] = useState<number>();
  const [readDeviceEnd, setReadDeviceEnd] = useState<number>();
  const [readDeviceRange, setReadDeviceRange] = useState<ReadDeviceRange>();
  const [deviceSelect, setDeviceSelect] = useState<number>();
  const [paki, setPaki] = useState<SelectOptions>(SelectOptions.ALL);
  const [validating, setValidating] = useState<SelectOptions>(
    SelectOptions.ALL
  );
  const [wrongDevice, setWrongDevice] = useState<SelectOptions>(
    SelectOptions.ALL
  );
  const [removed, setRemoved] = useState<SelectOptions>(SelectOptions.ALL);
  const [mode, setMode] = useState<SelectOptions>(SelectOptions.ALL);

  const [devices, setDevices] = useState<Device[]>([]);

  const [isStraight, setIsStraight] = useState<SelectOptions>(SelectOptions.ALL);

  const debouncedSetSearch = useCallback(debounce(setReadDeviceEnd, 1000), []);

  const {
    response: responseImages,
    getNameDevices,
    updateReadDeviceValidate,
    updateMeasurements,
    updateMode,
    mutate,
  }: IUseImagesValidatorPaginated = useImagesValidatorPaginated(
    pagination,
    date,
    readDeviceRange,
    deviceSelect,
    false,
    paki,
    validating,
    wrongDevice,
    removed,
    mode,
    isStraight
  );

  const images = responseImages?.data;
  const imagesPaginationInfo = responseImages?.meta;

  const imagesList =
    loading || !images ? (
      <CircularProgress
        style={{
          justifyContent: "center",
          display: "flex",
          width: "100%",
          marginTop: 50,
        }}
      />
    ) : (
      images && (
        <ImageValidatorList
          images={images}
          onPageChange={setPage}
          onPageSizeChange={setPageSize}
          paginationInfo={imagesPaginationInfo}
          updateReadDeviceValidate={updateReadDeviceValidate}
          updateMeasurements={updateMeasurements}
          updateMode={updateMode}
          mutate={mutate}
          sortBy={sortBy}
          onSortChange={setSortBy}
        />
      )
    );

  const handleDeviceEnd = (readDeviceEnd: number) => {
    debouncedSetSearch(readDeviceEnd);
  };

  useEffect(() => {
    setLoading(true);
    setPagination({
      page: page,
      limit: pageSize,
      sortBy: sortBy,
    });
  }, [page, pageSize, sortBy]);

  useEffect(() => {
    setLoading(false);
  }, [images]);

  useEffect(() => {
    if (dateRange[0] && dateRange[1]) {
      setDate({
        start: moment(dateRange[0]).format("yyyy-MM-DD"),
        end: moment(dateRange[1]).format("yyyy-MM-DD"),
      });
    } else {
      setDate(undefined);
    }
  }, [dateRange]);

  useEffect(() => {
    if (readDeviceStart && readDeviceEnd) {
      setReadDeviceRange({ start: readDeviceStart, end: readDeviceEnd });
    } else {
      setReadDeviceRange(undefined);
    }
  }, [readDeviceStart, readDeviceEnd]);

  useEffect(() => {
    const getNameDevicesResponse = async () => {
      const response = await getNameDevices();

      if (response.data) {
        const devices = [];
        for (let i = 0; i < response.data.length; i++) {
          devices.push({
            id: response.data[i].id,
            name: response.data[i].name,
          });
        }
        setDevices(devices);
      }
    };

    if (devices.length === 0) {
      getNameDevicesResponse();
    }
  }, []);

  return (
    <Page title={"Validador"}>
      <Grid
        container
        spacing={2}
        alignItems="center"
        style={{ justifyContent: "center", marginBottom: 20 }}
      >
        <Grid item>
          <DateRangePicker
            dateRangePicked={dateRange}
            setDateRange={setDateRange}
          />
        </Grid>
        <Grid item>
          <TextField
            id="fromReadDevice"
            label="Desde readDeviceId"
            type="number"
            onChange={(event) =>
              setReadDeviceStart(event.target.value as unknown as number)
            }
            InputLabelProps={{
              shrink: true,
            }}
          />
        </Grid>
        <Grid item>
          <TextField
            id="toReadDevice"
            label="Hasta readDeviceId"
            type="number"
            onChange={(event) =>
              handleDeviceEnd(event.target.value as unknown as number)
            }
            InputLabelProps={{
              shrink: true,
            }}
          />
        </Grid>
        <Grid item>
          <InputLabel id="select-device">Dispositivo</InputLabel>
          <Select
            labelId="select-device"
            id="select-device"
            label="Dispositivo"
            value={deviceSelect}
            onChange={(event) => setDeviceSelect(event.target.value as number)}
            style={{ width: 100 }}
            disabled={devices.length === 0}
          >
            <MenuItem key="null" value={0}>
              {"---"}
            </MenuItem>
            {devices.map((device, index) => (
              <MenuItem key={index} value={device.id}>
                {device.name}
              </MenuItem>
            ))}
          </Select>
        </Grid>
        <Grid item>
          <InputLabel id="select-paki">Paki</InputLabel>
          <Select
            labelId="select-paki"
            id="select-paki"
            label="Paki"
            value={paki}
            onChange={(event) => setPaki(event.target.value as SelectOptions)}
            style={{ width: 100 }}
          >
            <MenuItem key="all" value={SelectOptions.ALL}>
              {"Todas"}
            </MenuItem>
            <MenuItem key="onlyPaki" value={SelectOptions.ONLY}>
              {"Solo paki"}
            </MenuItem>
            <MenuItem key="noPaki" value={SelectOptions.NO}>
              {"Solo sin paki"}
            </MenuItem>
          </Select>
        </Grid>
        <Grid item>
          <InputLabel id="select-validate">Validadas</InputLabel>
          <Select
            labelId="select-validate"
            id="select-validate"
            label="Validadas"
            value={validating}
            onChange={(event) =>
              setValidating(event.target.value as SelectOptions)
            }
            style={{ width: 100 }}
          >
            <MenuItem key="all" value={SelectOptions.ALL}>
              {"Todas"}
            </MenuItem>
            <MenuItem key="onlyValidate" value={SelectOptions.ONLY}>
              {"Solo validadas"}
            </MenuItem>
            <MenuItem key="noValidate" value={SelectOptions.NO}>
              {"Solo sin validar"}
            </MenuItem>
          </Select>
        </Grid>
        <Grid item>
          <InputLabel id="select-wrong-device">Dispositivo erroneo</InputLabel>
          <Select
            labelId="select-wrong-device"
            id="select-wrong-device"
            label="Dispositivo erroneo"
            value={wrongDevice}
            onChange={(event) =>
              setWrongDevice(event.target.value as SelectOptions)
            }
            style={{ width: 170 }}
          >
            <MenuItem key="all" value={SelectOptions.ALL}>
              {"Todas"}
            </MenuItem>
            <MenuItem key="onlyWrongDevice" value={SelectOptions.ONLY}>
              {"Solo dispositivos errones"}
            </MenuItem>
            <MenuItem key="noWrongDevice" value={SelectOptions.NO}>
              {"Solo dispositivos no erroneos"}
            </MenuItem>
          </Select>
        </Grid>
        <Grid item>
          <InputLabel id="select-removed">Eliminadas</InputLabel>
          <Select
            labelId="select-removed"
            id="select-removed"
            label="Eliminadas"
            value={removed}
            onChange={(event) =>
              setRemoved(event.target.value as SelectOptions)
            }
            style={{ width: 100 }}
          >
            <MenuItem key="all" value={SelectOptions.ALL}>
              {"Todas"}
            </MenuItem>
            <MenuItem key="onlyRemoved" value={SelectOptions.ONLY}>
              {"Solo eliminadas"}
            </MenuItem>
            <MenuItem key="noRemoved" value={SelectOptions.NO}>
              {"Solo sin eliminar"}
            </MenuItem>
          </Select>
        </Grid>
        <Grid item>
          <InputLabel id="select-mode">Modo</InputLabel>
          <Select
            labelId="select-mode"
            id="select-mode"
            label="Modo"
            value={mode}
            onChange={(event) =>
              setMode(event.target.value as SelectOptions)
            }
            style={{ width: 100 }}
          >
            <MenuItem key="all" value={SelectOptions.ALL}>
              {"Todas"}
            </MenuItem>
            <MenuItem key="onlyMode" value={SelectOptions.ONLY}>
              {"Solo 7s"}
            </MenuItem>
            <MenuItem key="noMode" value={SelectOptions.NO}>
              {"Solo normal"}
            </MenuItem>
          </Select>
        </Grid>
        <Grid item>
          <InputLabel id="select-is-straigh">Dispositivo recto</InputLabel>
          <Select
            labelId="select-is-straigh"
            id="select-is-straigh"
            label="Dispositivo recto"
            value={isStraight}
            onChange={(event) =>
              setIsStraight(event.target.value as SelectOptions)
            }
            style={{ width: 170 }}
          >
            <MenuItem key="all" value={SelectOptions.ALL}>
              {"Todas"}
            </MenuItem>
            <MenuItem key="onlyWrongDevice" value={SelectOptions.ONLY}>
              {"Solo dispositivos rectos"}
            </MenuItem>
            <MenuItem key="noWrongDevice" value={SelectOptions.NO}>
              {"Solo dispositivos no rectos"}
            </MenuItem>
          </Select>
        </Grid>
      </Grid>
      {imagesList}
    </Page>
  );
};

export { ImageValidator };
