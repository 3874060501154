import {
  Time,
  GetTopTranslatorResponseDto,
  AddTranslationRequestDto,
  CreateTranslateImageResponseDto,
  GetTranslateImageResponseDto,
} from "../dto/TranslateImage";
import { GetMeasurementImageResponseDto } from "../dto/MeasurementImage";
import { ResponseApi, ResponseApiList, TypeOnError } from "../global";
import {
  CreateAction,
  CreateActionWithEndpoint,
  IUseBase,
  useInstance,
} from "./IUseBaseElements";

export interface IUseTranslateImages
  extends IUseBase<ResponseApiList<GetTranslateImageResponseDto>> {
  getMeasurementImages: (
    translateImageId: string
  ) => Promise<ResponseApiList<GetMeasurementImageResponseDto>>;
  addTranslation: (
    translateImageId: number,
    translateImageDto: AddTranslationRequestDto
  ) => Promise<ResponseApi<CreateTranslateImageResponseDto>>;
  topTranslators: (
    initDate?: string,
    finDate?: string
  ) => Promise<ResponseApiList<GetTopTranslatorResponseDto>>;
}

export function useTranslateImages(
  baseURL: string,
  token: string,
  showTranslated: boolean,
  shouldFetch?: boolean,
  onError?: TypeOnError
): IUseTranslateImages {
  const swrResponse: IUseBase<ResponseApiList<GetTranslateImageResponseDto>> =
    useInstance<ResponseApiList<GetTranslateImageResponseDto>>(
      baseURL,
      token,
      shouldFetch
        ? `/images_to_translate?showTranslated=${showTranslated}`
        : null,
      onError
    );

  const addTranslation = async (
    translateImageId: number,
    translateImageDto: AddTranslationRequestDto
  ): Promise<ResponseApi<CreateTranslateImageResponseDto>> => {
    let path: string = `/images_to_translate/${translateImageId}/translation`;
    const action = CreateActionWithEndpoint<
      ResponseApi<CreateTranslateImageResponseDto>,
      AddTranslationRequestDto,
      ResponseApiList<GetTranslateImageResponseDto>
    >(baseURL, token, swrResponse.mutate, "post", onError);
    return action(path, translateImageDto);
  };

  const getMeasurementImages = async (
    translateImageId: string
  ): Promise<ResponseApiList<GetMeasurementImageResponseDto>> => {
    let path: string = `/images_to_translate/${translateImageId}/measurement-images`;
    const action = CreateAction<
      ResponseApiList<GetMeasurementImageResponseDto>,
      boolean,
      ResponseApiList<GetTranslateImageResponseDto>
    >(baseURL, token, swrResponse.mutate, "get", path, onError);
    return action();
  };

  const topTranslators = async (
    initDate?: string,
    finDate?: string
  ): Promise<ResponseApiList<GetTopTranslatorResponseDto>> => {
    let path: string = `/images_to_translate/top-translators`;
    if (initDate) {
      path += `?initDate=${initDate}`;
      if (finDate) path += `&finDate=${finDate}`;
    } else if (!initDate && finDate) {
      path += `?finDate=${finDate}`;
    }

    const action = CreateAction<
      ResponseApiList<GetTopTranslatorResponseDto>,
      boolean,
      ResponseApiList<GetTranslateImageResponseDto>
    >(baseURL, token, swrResponse.mutate, "get", path, onError);
    return action();
  };

  return {
    ...swrResponse,
    getMeasurementImages,
    addTranslation,
    topTranslators,
  };
}
