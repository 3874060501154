import { Mode } from "@doctomatic/sdk/build/dto/Images";

export default class ImagesDataRow {
  id = 0;
  key = '';
  debugUrl= '';
  readDeviceValidate: string;
  additionalData: string;
  paki = false;
  mode = Mode.SEVENSEGMENT;
  created_at = new Date();

  constructor(
    id: number,
    key: string,
    debugUrl: string,
    readDeviceValidate: string,
    additionalData: string,
    paki: boolean,
    mode: Mode,
    created_at: Date,
  ) {
    this.id = id;
    this.key = key;
    this.debugUrl = debugUrl;
    this.readDeviceValidate = readDeviceValidate;
    this.additionalData = additionalData;
    this.paki = paki;
    this.mode = mode;
    this.created_at = created_at;
  }
}
