import {
  GetDoctorResponseDto,
  AddDoctorRequestDto,
  UpdateDoctorRequestDto,
  UpdateDoctorResponseDto,
  AddDoctorResponseDto,
  ItemDoctorListDto,
} from '../dto/Actors/Doctor/Doctor'
import { ResponseApi, ResponseApiList, TypeOnError } from '../global'
import { CreateAction, CreateActionWithEndpoint, IUseBase, useInstance } from './IUseBaseElements'

export interface IUseDoctors
  extends IUseBase<ResponseApiList<ItemDoctorListDto>> {
  get: (id: number) => Promise<ResponseApi<GetDoctorResponseDto>>;
  patch: (id: number, doctor: UpdateDoctorRequestDto) => Promise<ResponseApi<UpdateDoctorResponseDto>>;
  add: (doctor: AddDoctorRequestDto) => Promise<ResponseApi<AddDoctorResponseDto>>;
  del: (id: number) => Promise<ResponseApi<boolean>>;
  restore: (id: number) => Promise<ResponseApi<boolean>>;
}

export function useDoctors(baseURL: string, token: string, showDeleted: boolean, query?: any, shouldFetch?: boolean, onError?: TypeOnError): IUseDoctors {

  let path: string =`/doctors?showDeleted=${showDeleted}`;
  if(query) path = path + `&limit=${query.limit}&page=${query.page}`

  const swrResponse: IUseBase<ResponseApiList<ItemDoctorListDto>> = useInstance<ResponseApiList<ItemDoctorListDto>>(
    baseURL,
    token,
    shouldFetch ? path : null,
    onError
  );

  const get = async (id: number): Promise<ResponseApi<GetDoctorResponseDto>> => {
    let path: string = `/doctors/${id}`;
    const action = CreateAction<ResponseApi<GetDoctorResponseDto>, boolean, ResponseApiList<ItemDoctorListDto>>(
      baseURL,
      token,
      swrResponse.mutate,
      'get',
      path,
      onError
    );
    return action();
  }

  const patch = async (id: number, doctor: UpdateDoctorRequestDto): Promise<ResponseApi<UpdateDoctorResponseDto>> => {
    let path: string = `/doctors/${id}`;
    const action = CreateActionWithEndpoint<ResponseApi<UpdateDoctorResponseDto>, UpdateDoctorRequestDto, ResponseApiList<ItemDoctorListDto>>(
      baseURL,
      token,
      swrResponse.mutate,
      'patch',
      onError
    );
    return action(path, doctor);
  };

  const add = async (doctor: AddDoctorRequestDto): Promise<ResponseApi<AddDoctorResponseDto>> => {
    let path: string = '/doctors';
    const action = CreateActionWithEndpoint<ResponseApi<AddDoctorResponseDto>, AddDoctorRequestDto, ResponseApiList<ItemDoctorListDto>>(
      baseURL,
      token,
      swrResponse.mutate,
      'post',
      onError
    );
    return action(path, doctor);
  };

  const del = async (id: number): Promise<ResponseApi<boolean>> => {
    let path: string = `/doctors/${id.toString()}`;
    const action = CreateAction<ResponseApi<boolean>, boolean, ResponseApiList<ItemDoctorListDto>>(
      baseURL,
      token,
      swrResponse.mutate,
      'delete',
      path,
      onError
    );
    return action();
  };

  const restore = async (id: number): Promise<ResponseApi<boolean>> => {
    const path: string = `/doctors/${id}/restore`;
    const action = CreateAction<ResponseApi<boolean>, void, ResponseApiList<ItemDoctorListDto>>(
      baseURL,
      token,
      swrResponse.mutate,
      'post',
      path,
      onError
    );
    return action();
  }

  return {
    ...swrResponse,
    get,
    add,
    patch,
    del,
    restore,
    onError,
  } as IUseDoctors
}
