import {
  GetPatientResponseDto,
  AddPatientRequestDto,
  UpdatePatientRequestDto,
  UpdatePatientResponseDto,
  AddPatientResponseDto,
  ItemPatientListExtendedResponseDto,
} from "../dto/Patient/Patient";
import { ResponseApi, ResponseApiList, TypeOnError } from "../global";
import { CreateAction, CreateActionWithEndpoint, IUseBase, useInstance } from "./IUseBaseElements";

export interface IUsePatients
  extends IUseBase<ResponseApiList<ItemPatientListExtendedResponseDto>> {
  patch: (id: number, patient: UpdatePatientRequestDto) => Promise<ResponseApi<UpdatePatientResponseDto>>
  add: (patient: AddPatientRequestDto) => Promise<ResponseApi<AddPatientResponseDto>>
  del: (id: number) => Promise<ResponseApi<boolean>>
  restore: (id: number) => Promise<ResponseApi<boolean>>
  findOneWithTreatments: (id: number) => Promise<ResponseApi<GetPatientResponseDto>>;
  findAllByLocation: (locationId: number) => Promise<ResponseApiList<ItemPatientListExtendedResponseDto>>;
  findAllByDoctor: (doctorId: number) => Promise<ResponseApiList<ItemPatientListExtendedResponseDto>>;
  findAllByCompany: (companyId: number) => Promise<ResponseApiList<ItemPatientListExtendedResponseDto>>;
}

export function usePatients(baseURL: string, token: string,
  showDeleted: boolean, shouldFetch?: boolean, onError?: TypeOnError): IUsePatients {

  const swrResponse: IUseBase<ResponseApiList<ItemPatientListExtendedResponseDto>> = useInstance<ResponseApiList<ItemPatientListExtendedResponseDto>>(
    baseURL,
    token,
    shouldFetch ? `/patients?showDeleted=${showDeleted}` : null,
    onError
  );

  const patch = async (id: number, patient: UpdatePatientRequestDto) => {
    let path: string = `/patients/${id}`;
    const action = CreateActionWithEndpoint<ResponseApi<UpdatePatientResponseDto>, UpdatePatientRequestDto, ResponseApiList<ItemPatientListExtendedResponseDto>>(
      baseURL,
      token,
      swrResponse.mutate,
      'patch',
      onError
    );
    return action(path, patient);
  };

  const add = async (patient: AddPatientRequestDto) => {
    let path: string = '/patients';
    const action = CreateActionWithEndpoint<ResponseApi<AddPatientResponseDto>, AddPatientRequestDto, ResponseApiList<ItemPatientListExtendedResponseDto>>(
      baseURL,
      token,
      swrResponse.mutate,
      'post',
      onError
    );
    return action(path, patient);
  };

  const del = async (id: number): Promise<ResponseApi<boolean>> => {
    let path: string = `/patients/${id}`;
    const action = CreateAction<ResponseApi<boolean>, boolean, ResponseApiList<ItemPatientListExtendedResponseDto>>(
      baseURL,
      token,
      swrResponse.mutate,
      'delete',
      path,
      onError
    );
    return action();
  };

  const restore = async (id: number) => {
    const path: string = `/patients/${id}/restore`;
    const action = CreateAction<ResponseApi<boolean>, void, ResponseApiList<ItemPatientListExtendedResponseDto>>(
      baseURL,
      token,
      swrResponse.mutate,
      'post',
      path,
      onError
    );
    return action();
  };

  const findAllByDoctor = async (
    doctorId: number
  ): Promise<ResponseApiList<ItemPatientListExtendedResponseDto>> => {
    let path: string = `/doctors/${doctorId}/patients`;
    const action = CreateAction<ResponseApiList<ItemPatientListExtendedResponseDto>, boolean, ResponseApiList<ItemPatientListExtendedResponseDto>>(
      baseURL,
      token,
      swrResponse.mutate,
      'get',
      path,
      onError
    );
    return action();
  };

  const findAllByLocation = async (
    locationId: number
  ): Promise<ResponseApiList<ItemPatientListExtendedResponseDto>> => {
    let path: string = `/patients/location/${locationId}`;
    const action = CreateAction<ResponseApiList<ItemPatientListExtendedResponseDto>, boolean, ResponseApiList<ItemPatientListExtendedResponseDto>>(
      baseURL,
      token,
      swrResponse.mutate,
      'get',
      path,
      onError
    );
    return action();
  };

  const get = async (
    id: number
  ): Promise<ResponseApi<GetPatientResponseDto>> => {
    let path: string = `/patients/${id}`;
    const action = CreateAction<ResponseApi<GetPatientResponseDto>, boolean, ResponseApiList<ItemPatientListExtendedResponseDto>>(
      baseURL,
      token,
      swrResponse.mutate,
      'get',
      path,
      onError
    );
    return action();
  };

  const findOneWithTreatments = async (
    id: number
  ): Promise<ResponseApi<GetPatientResponseDto>> => {
    let path: string = `/patients/${id}/trackings`;
    const action = CreateAction<ResponseApi<GetPatientResponseDto>, boolean, ResponseApiList<ItemPatientListExtendedResponseDto>>(
      baseURL,
      token,
      swrResponse.mutate,
      'get',
      path,
      onError
    );
    return action();
  };

  const findAllByCompany = async (companyId: number): Promise<ResponseApiList<ItemPatientListExtendedResponseDto>> => {
    let path: string = `/companies/${companyId}/patients?showDeleted=${showDeleted}`;
    const action = CreateAction<ResponseApiList<ItemPatientListExtendedResponseDto>, boolean, ResponseApiList<ItemPatientListExtendedResponseDto>>(
      baseURL,
      token,
      swrResponse.mutate,
      'get',
      path,
      onError
    );
    return action();
  };

  return {
    ...swrResponse,
    add,
    patch,
    del,
    restore,
    get,
    findAllByDoctor,
    findAllByLocation,
    findOneWithTreatments,
    findAllByCompany
  } as IUsePatients;
}
