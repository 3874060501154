import { FlexLayoutGrid } from "@doctomatic/components-react/build/DataGrid/DataGrid"
import { GridColumns } from "@mui/x-data-grid";
import { Page } from "../../Page"
import { TranslationsGridColumns } from "./TranslationsGridColumns";
import { GetTranslateByDayResponseDto } from "@doctomatic/sdk/build/dto/CmsDashboard/CmsDashboard";

export interface ITranslations{
    day: string;
    count: number;
    id: number;
}

export interface ITranslationsGrid {
    translations: GetTranslateByDayResponseDto[];
}

const TranslationsGrid = ({translations}: ITranslationsGrid): React.ReactElement => {
    
    const translationsDataRows: ITranslations[] = translations?.map((
        translations: GetTranslateByDayResponseDto, // better option interface sdk.
        index: number
        ) => ({
            id: index,
            day: translations.day,
            count: translations.count
    }))

    const translationsColumns: GridColumns<ITranslations> = TranslationsGridColumns();

    return(
        <Page title={'Traducciones'} primaryColor="black" >
            <FlexLayoutGrid
                columns={translationsColumns}
                rows={translationsDataRows ? translationsDataRows : []}
            ></FlexLayoutGrid>
        </Page>     
    )
}

export { TranslationsGrid }