import { GridColumns, GridValueGetterParams } from "@mui/x-data-grid";
import { formattedDate } from "../../utils";
import { IMeasurements } from "./MeasurementsGrid";

export const MeasurementsGridColumns = (): GridColumns<IMeasurements> => {

  const gridColumns: GridColumns<IMeasurements> =   
  [
    {
      field: 'day',
      headerName: 'Fecha',
      width: 100,
      align: 'left',
      valueGetter: (params: GridValueGetterParams) => params.row.day,
      renderCell: (params) => {
        return <p>{formattedDate(params.row.day)}</p>
      }
    },
    {
      field: 'device',
      headerName: 'Dispositivo',
      width: 100,
      align:'left',
      valueGetter: (params: GridValueGetterParams) => params.row.device,
      renderCell: (params) => {
        return <p>{params.row.device}</p>
      }
    },
    {
      field: 'count',
      headerName: 'Cantidad lecturas',
      width: 150,
      align:'center',
      valueGetter: (params: GridValueGetterParams) => params.row.count,
      renderCell: (params) => {
        return <p>{params.row.count}</p>
      }
    },
    {
      field: 'pakiTranslations',
      headerName: 'Traducidas manualmente',
      width: 180,
      align:'center',
      valueGetter: (params: GridValueGetterParams) => params.row.countTranslateImage,
      renderCell: (params) => {
        return <p>{params.row.countTranslateImage}</p>
      }
    },
    {
      field: 'deleteTranslations',
      headerName: 'Eliminadas',
      width: 100,
      align:'center',
      valueGetter: (params: GridValueGetterParams) => params.row.countDeleteImage,
      renderCell: (params) => {
        return <p>{params.row.countDeleteImage}</p>
      }
    }
  ];

  return gridColumns;

};