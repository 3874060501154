class ConfigError {
  static onError: any;
}
class ResponseApi<T> {
  success: boolean = true;
  data: T | undefined;
  additional_data: any = null;
  error: string | undefined;
  error_info: string | undefined;
  meta?: any;
}
class ResponseApiList<T> {
  success: boolean = true;
  data: T[] = [];
  meta: any;
  links: any;
  additional_data: any = null;
}
export type TypeOnError = (err: any, key: any, config: any) => Promise<void>

export { ConfigError, ResponseApi, ResponseApiList };