import { GetAlertByFrequencyResponseDto } from '../dto/Alerts/Alerts';
import { Role } from '../dto/User';
import { ResponseApiList, TypeOnError } from '../global';
import {
  IUseBase,
  useInstance
} from './IUseBaseElements';

export interface IUseAlertsByFrequencyByPatient
  extends IUseBase<ResponseApiList<GetAlertByFrequencyResponseDto>> {
}

export function useAlertsByFrequencyByPatient(baseURL: string, token: string, actorId: number, actorRole: Role, patientId: number, query?: any, shouldFetch?: boolean, onError?: TypeOnError): IUseAlertsByFrequencyByPatient {

  const formatActorRole = (actorRole: Role) => {
    if (actorRole === Role.user) return "patients";
    if (actorRole === Role.superadmin) return Role.superadmin;
    if (actorRole === Role.admin) return "admin_company";
    if (actorRole === Role.nurse) return "nurses";
    if (actorRole === Role.doctor) return "doctors";
    if (actorRole === Role.translatorimage) return "translators";
  }
  const roleName = formatActorRole(actorRole);
  
  let path: string =`/${roleName}/${actorId}/patients/${patientId}/alerts/frequency`;
  if(query) path = path + `?limit=${query.limit}&page=${query.page}`

  const swrResponse: IUseBase<ResponseApiList<GetAlertByFrequencyResponseDto>>
    = useInstance<ResponseApiList<GetAlertByFrequencyResponseDto>>(
      baseURL,
      token,
      shouldFetch ? path : null,
      onError
    );

  return {
    ...swrResponse,
  } as IUseAlertsByFrequencyByPatient;

}
