import { IItemReadDeviceResponseDto } from "../../dto/ReadDevice/IReadDevice";
import { ResponseApi, TypeOnError } from "../../global";
import { IUseBase, useInstance } from "../IUseBaseElements";
  
  
  export interface IUseLastReadDevices extends IUseBase<ResponseApi<IItemReadDeviceResponseDto[]>>{}
  
  export function useLastReadDevices(
    baseURL: string,
    token: string,
    patientId?: number,
    query?: any,
    shouldFetch?: boolean,
    onError?: TypeOnError
  ): IUseLastReadDevices {
    let path = `/read_device`
    if (patientId){
      path += `?patientId=${patientId}`;
      if (query) path = path + `&limit=${query.limit}&page=${query.page}`;
      if (query.sortBy && query.sortBy[0]) {
        path = `${path}&field=${query.sortBy[0]['field']}&sort=${query.sortBy[0]['sort'].toUpperCase()}`;
      }
    } else {
      if (query) path = path + `?limit=${query.limit}&page=${query.page}`;
      if (query.sortBy && query.sortBy[0]) {
        path = `${path}&field=${query.sortBy[0]['field']}&sort=${query.sortBy[0]['sort'].toUpperCase()}`;
      }
    }
    const swrResponse: IUseBase<ResponseApi<IItemReadDeviceResponseDto[]>> = useInstance<ResponseApi<IItemReadDeviceResponseDto[]>>(
      baseURL,
      token,
      shouldFetch ? path : null,
      onError
    );
      
    return {
      ...swrResponse,
    };
  }
  