import React, { useState } from "react";
import { EditorState, Modifier } from "draft-js";
import { LegalVariables } from "@doctomatic/sdk/build/dto/Legal/Legal";

const values = Object.values(LegalVariables);
const keys = Object.keys(LegalVariables);
const legalVariables: { key: string; value: string }[] = [];
for (let i = 0; i < values.length; i++) {
  legalVariables.push({ key: keys[i], value: values[i] });
}

interface PlaceholderProps {
  onChange: (editorState: EditorState) => void;
  editorState: EditorState;
}

const Placeholders = ({
  onChange,
  editorState,
}: PlaceholderProps): React.ReactElement => {
  const [open, setOpen] = useState(false);

  const addPlaceholder = (placeholder: string) => {
    const contentState = Modifier.replaceText(
      editorState.getCurrentContent(),
      editorState.getSelection(),
      placeholder,
      editorState.getCurrentInlineStyle()
    );
    onChange(EditorState.push(editorState, contentState, "insert-characters"));
  };

  return (
    <div
      onClick={() => setOpen(!open)}
      className="rdw-block-wrapper"
      aria-label="rdw-block-control"
    >
      <div
        className="rdw-dropdown-wrapper rdw-block-dropdown"
        aria-label="rdw-dropdown"
        style={{width: "100%"}}
      >
        <div className="rdw-dropdown-selectedtext" title="Placeholders">
          <span>Placeholder</span>
          <div
            className={`rdw-dropdown-caretto${open ? "close" : "open"}`}
          ></div>
        </div>
        <ul
          className={`rdw-dropdown-optionwrapper ${
            open ? "" : "placeholder-ul"
          }`}
        >
          {legalVariables.map((item) => (
            <li
              onClick={() => addPlaceholder(item.value)}
              key={item.key}
              className="rdw-dropdownoption-default placeholder-li"
            >
              {item.key}
            </li>
            // TODO: item.value to translation
          ))}
        </ul>
      </div>
    </div>
  );
};

export { Placeholders };
