import useSWR from 'swr';
import { KeyedMutator } from 'swr/dist/types';
import {
  AddAlertConfigByValueTemplateRequestDto,
  AddAlertConfigByValueTemplateResponseDto,
  GetAlertConfigByValueTemplateResponseDto,
  ItemAlertConfigByValueTemplateDto,
  UpdateAlertConfigByValueTemplateRequestDto,
  UpdateAlertConfigByValueTemplateResponseDto
} from '../../dto/Alerts/config/template/AlertConfigByValueTemplate';
import { ResponseApi, ResponseApiList, TypeOnError } from '../../global';
import Fetcher from '../Fetcher';

export interface IUseAlertConfigsByValueTemplate {
  alertConfigsByValue: ResponseApiList<ItemAlertConfigByValueTemplateDto> | undefined,
  mutate: KeyedMutator<ResponseApiList<ItemAlertConfigByValueTemplateDto>>,
  findOneById: (id: number) => Promise<ResponseApi<GetAlertConfigByValueTemplateResponseDto>>,
  add: (alertConfig: AddAlertConfigByValueTemplateRequestDto) => Promise<ResponseApi<AddAlertConfigByValueTemplateResponseDto>>,
  patch: (id: number, alertConfig: UpdateAlertConfigByValueTemplateRequestDto) => Promise<ResponseApi<UpdateAlertConfigByValueTemplateResponseDto>>
  del: (id: number) => Promise<ResponseApi<any>>
}

export function useAlertConfigsByValueTemplate(baseURL: string, token: string, query?: any, shouldFetch?: boolean, onError?: TypeOnError): IUseAlertConfigsByValueTemplate {
  const fetcher = Fetcher<ResponseApiList<ItemAlertConfigByValueTemplateDto>>(baseURL, token);
  let path = '/alert_config_templates/value';
  if(query) path = path + `?limit=${query.limit}&page=${query.page}`
  const { data, mutate } = useSWR<ResponseApiList<ItemAlertConfigByValueTemplateDto>>(shouldFetch ? path : null, fetcher, { shouldRetryOnError: false });

  const findOneById = async (id: number): Promise<ResponseApi<GetAlertConfigByValueTemplateResponseDto>> => {
    const response: ResponseApi<GetAlertConfigByValueTemplateResponseDto> = await Fetcher<ResponseApi<GetAlertConfigByValueTemplateResponseDto>>(baseURL, token)(`/alert_config_templates/value/${id}`, 'get');
    mutate();
    return response;
  }

  const add = async (alertConfig: AddAlertConfigByValueTemplateRequestDto): Promise<ResponseApi<AddAlertConfigByValueTemplateResponseDto>> => {
    const response: ResponseApi<AddAlertConfigByValueTemplateResponseDto> = await Fetcher<ResponseApi<AddAlertConfigByValueTemplateResponseDto>, AddAlertConfigByValueTemplateRequestDto>(baseURL, token)('/alert_config_templates/value', 'post', alertConfig);
    mutate();
    return response;
  }

  const patch = async (id: number, alertConfig: UpdateAlertConfigByValueTemplateRequestDto): Promise<ResponseApi<UpdateAlertConfigByValueTemplateResponseDto>> => {
    const response: ResponseApi<UpdateAlertConfigByValueTemplateResponseDto> = await Fetcher<ResponseApi<UpdateAlertConfigByValueTemplateResponseDto>, UpdateAlertConfigByValueTemplateRequestDto>(baseURL, token)(`/alert_config_templates/value/${id}`, 'patch', alertConfig);
    mutate();
    return response;
  }

  const del = async (id: number): Promise<ResponseApi<any>> => {
    const response: ResponseApi<any> = await Fetcher<ResponseApi<any>>(baseURL, token)(`/alert_config_templates/value/${id}`, 'delete');
    mutate();
    return response;
  }

  return {
    alertConfigsByValue: data,
    mutate,
    findOneById,
    add,
    patch,
    del
  };
}