import type {
  GetFormResponseDto,
  AddFormRequestDto,
  UpdateFormRequestDto,
} from "../dto/Forms";
import { ResponseApi, TypeOnError } from "../global";
import {
  CreateActionWithEndpoint,
  IUseBase,
  useInstance,
} from "./IUseBaseElements";

type mutateForm = ResponseApi<GetFormResponseDto[]>;

export interface IUseForms extends IUseBase<mutateForm> {
  get: (id: number) => Promise<ResponseApi<any>>;
  add: (dto: AddFormRequestDto) => Promise<ResponseApi<any>>;
  patch: (id: number, dto: UpdateFormRequestDto) => Promise<ResponseApi<any>>;
  del: (id: number) => Promise<ResponseApi<boolean>>;
  restore: (id: number) => Promise<ResponseApi<boolean>>;
}

export function useForms(
  baseURL: string,
  token: string,
  showDeleted: boolean,
  query?: any,
  shouldFetch?: boolean,
  onError?: TypeOnError
): IUseForms {
  let path = shouldFetch ? `/forms?showDeleted=${showDeleted}` : null;
  if(query) path = path + `&limit=${query.limit}&page=${query.page}`
  const swrResponse: IUseBase<mutateForm> = useInstance<mutateForm>(
    baseURL,
    token,
    path,
    onError
  );

  const get = async (id: number): Promise<ResponseApi<GetFormResponseDto>> => {
    let path: string = `/forms/${id}`;
    const action = CreateActionWithEndpoint<
      ResponseApi<any>,
      number,
      mutateForm
    >(baseURL, token, swrResponse.mutate, "get", onError);
    return action(path, id);
  };

  const add = async (dto: AddFormRequestDto): Promise<ResponseApi<any>> => {
    let path: string = `/forms`;
    const action = CreateActionWithEndpoint<
      ResponseApi<any>,
      AddFormRequestDto,
      mutateForm
    >(baseURL, token, swrResponse.mutate, "post", onError);
    return action(path, dto);
  };

  const patch = async (
    id: number,
    dto: UpdateFormRequestDto
  ): Promise<ResponseApi<any>> => {
    let path: string = `/forms/${id}`;
    const action = CreateActionWithEndpoint<
      ResponseApi<any>,
      UpdateFormRequestDto,
      mutateForm
    >(baseURL, token, swrResponse.mutate, "put", onError);
    return action(path, dto);
  };

  const del = async (id: number): Promise<ResponseApi<boolean>> => {
    let path: string = `/forms/${id.toString()}`;
    const action = CreateActionWithEndpoint<
      ResponseApi<any>,
      number,
      mutateForm
    >(baseURL, token, swrResponse.mutate, "delete", onError);
    return action(path, id);
  };

  const restore = async (id: number): Promise<ResponseApi<boolean>> => {
    const path: string = `/forms/${id}/restore`;
    const action = CreateActionWithEndpoint<
      ResponseApi<any>,
      number,
      mutateForm
    >(baseURL, token, swrResponse.mutate, "post", onError);
    return action(path, id);
  };

  return {
    ...swrResponse,
    get,
    add,
    patch,
    del,
    restore,
  };
}
