import { LastAlertsByNoMeasurePatient } from "../dto/Alerts/Alerts";
import { Role } from "../dto/User";
import { ResponseApiList, TypeOnError } from "../global";
import { CreateAction } from "./IUseBaseElements";
import { IUseBase, useInstance } from "./IUseBaseElements";

export interface IUseLastAlertsByNoMeasureByActor
  extends IUseBase<ResponseApiList<LastAlertsByNoMeasurePatient>> {
  getAlertsPatients: (
    actorId: number
  ) => Promise<ResponseApiList<LastAlertsByNoMeasurePatient>>;
}

export function useLastAlertsByNoMeasureByActor(
  baseURL: string,
  token: string,
  actorId: number,
  actorRole: Role,
  shouldFetch?: boolean,
  query?: any,
  onError?: TypeOnError
): IUseLastAlertsByNoMeasureByActor {
  const formatActorRole = (actorRole: Role) => {
    if (actorRole === Role.user) return "patients";
    if (actorRole === Role.superadmin) return Role.superadmin;
    if (actorRole === Role.admin) return "admin_company";
    if (actorRole === Role.nurse) return "nurses";
    if (actorRole === Role.doctor) return "doctors";
    if (actorRole === Role.translatorimage) return "translators";
  };
  const roleName = formatActorRole(actorRole);

  let queryFilters = "";
  if (query) {
    queryFilters = `?limit=${query.limit}&page=${query.page}`;
    if (query.sortBy && query.sortBy[0]) {
      queryFilters = `${queryFilters}&field=${
        query.sortBy[0]["field"]
      }&sort=${query.sortBy[0]["sort"].toUpperCase()}`;
    }
  }

  const swrResponse: IUseBase<ResponseApiList<LastAlertsByNoMeasurePatient>> =
    useInstance<ResponseApiList<LastAlertsByNoMeasurePatient>>(
      baseURL,
      token,
      shouldFetch
        ? `/${roleName}/${actorId}/alerts/last_no_measure${queryFilters}`
        : null,
      onError
    );

  return {
    ...swrResponse,
  } as IUseLastAlertsByNoMeasureByActor;
}
