import { ItemAlertByValueList, LastAlertsByValuePatient } from '../dto/Alerts/Alerts';
import { ResponseApiList, TypeOnError } from '../global';
import { Action, CreateAction } from './IUseBaseElements';
import { IActionParameters, IUseBase, useInstance } from './IUseBaseElements';
import { Role } from '../dto/User';
export interface IUseLastAlertsByValueByPatient extends IUseBase<ResponseApiList<ItemAlertByValueList>> { }

export function useLastAlertsByValueByPatient(
  baseURL: string,
  token: string,
  actorId: number,
  actorRole: Role,
  patientId: number,
  shouldFetch?: boolean,
  query?: any,
  onError?: TypeOnError
): IUseLastAlertsByValueByPatient {
  const formatActorRole = (actorRole: Role) => {
    if (actorRole === Role.user) return "patients";
    if (actorRole === Role.superadmin) return Role.superadmin;
    if (actorRole === Role.admin) return "admin_company";
    if (actorRole === Role.nurse) return "nurses";
    if (actorRole === Role.doctor) return "doctors";
    if (actorRole === Role.translatorimage) return "translators";
  }
  const roleName = formatActorRole(actorRole);

  let queryFilters = '';
  if (query) {
    queryFilters = `?limit=${query.limit}&page=${query.page}`;
    if (query.sortBy && query.sortBy[0]) {
      queryFilters = `${queryFilters}&field=${query.sortBy[0]['field']}&sort=${query.sortBy[0]['sort'].toUpperCase()}`;
    }
  }
  
  const swrResponse: IUseBase<ResponseApiList<ItemAlertByValueList>> = useInstance<ResponseApiList<ItemAlertByValueList>>(
    baseURL,
    token,
    shouldFetch ? `/${roleName}/${actorId}/patients/${patientId}/alerts/value${queryFilters}` : null,
    onError
  );

  return {
    ...swrResponse,
  } as IUseLastAlertsByValueByPatient;
}
