import { GridSortItem } from "@mui/x-data-grid";
import { useEffect, useState, useCallback } from "react";
import { DataRange } from "@doctomatic/sdk/build/utils/query/filter.interface";
import { useApi } from "@doctomatic/sdk/build/Api";
import { FilterOption, GetSankeyGraphResponseData } from "@doctomatic/sdk/build/dto/CmsDashboard/CmsDashboard";
import { Mode } from "@doctomatic/sdk/build/dto/Images";
import { IUseCmsReadDeviceViewer } from "@doctomatic/sdk/build/modules/CmsReadDeviceViewer";
import moment from "moment";
import { Page } from "../Page";
import {
  Checkbox,
  CircularProgress,
  FormControlLabel,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@material-ui/core";
import { DateRangePicker } from "@doctomatic/components-react/build/Graphs/DateRangePicker";
import { debounce } from "lodash";
import { SelectDevice } from "../Dashboard/Measurements/selectDevice";
import { ReadDeviceViewerList } from "./ReadDeviceViewerList";
import Switch from "@material-ui/core/Switch";
import { Box } from "@mui/material";
import { processError } from "../../../App/errorToast";
import { SelectCompany } from "../Dashboard/selectCompany";
import { useLocation } from "react-router-dom";
import {SelectStatus} from "./selectStatus";

const INITIAL_PAGINATION = { page: 1, limit: 10, sortBy: [] };

interface ReadDeviceViewerProps {
  selectedCompany?: number;
}
interface LocationProps {
  state: unknown;
  companyToSelect: number;
}

const ReadDeviceViewer = ({ selectedCompany = 0, }: ReadDeviceViewerProps): React.ReactElement => {
  //--------------------------------------------------------------------------------------//
  //                                  State declarations                                  //
  //--------------------------------------------------------------------------------------//

  const [pageSize, setPageSize] = useState<number>(INITIAL_PAGINATION.limit);
  const [page, setPage] = useState<number>(INITIAL_PAGINATION.page);
  const [loading, setLoading] = useState<boolean>(true);
  const [pagination, setPagination] = useState<any>(INITIAL_PAGINATION);
  const [sortBy, setSortBy] = useState<GridSortItem[]>([]);

  const [dateRange, setDateRange] = useState<DataRange<string>>(); // Query date
  const [date, setDate] = useState<[Date | null, Date | null]>([null, null]); // Date picker

  const [readDeviceRange, setReadDeviceRange] = useState<DataRange<number>>();
  const [readDeviceStart, setReadDeviceStart] = useState<number>();
  const [readDeviceEnd, setReadDeviceEnd] = useState<number>();

  const [device, setDevice] = useState<number>();

  const [translated, setTranslated] = useState<FilterOption>(
    FilterOption.INCLUDE
  );
  const [removed, setRemoved] = useState<FilterOption>(FilterOption.INCLUDE);
  const [pendingTranslation, setPendingTranslation] = useState<FilterOption>(
    FilterOption.INCLUDE
  );
  const [pendingConfirm, setPendingConfirm] = useState<FilterOption>(
    FilterOption.INCLUDE
  );

  const [confirmed, setConfirmed] = useState<FilterOption>(
    FilterOption.INCLUDE
  );

  const [statusName, setStatusName] = useState<string[]>();
  useEffect(() => {
    const statusMap = {
      translated: setTranslated,
      removed: setRemoved,
      pendingTranslation: setPendingTranslation,
      pendingConfirm: setPendingConfirm,
      confirmed: setConfirmed,
    };

    if (statusName === undefined) {
      Object.keys(statusMap).forEach((key) => {
        const setStatus = statusMap[key as keyof typeof statusMap];
        setStatus(FilterOption.INCLUDE);
      });
    } else {
      Object.keys(statusMap).forEach((key) => {
        const setStatus = statusMap[key as keyof typeof statusMap];
        setStatus(statusName?.includes(key) ? FilterOption.INCLUDE : FilterOption.EXCLUDE);
      });
    }

  }, [statusName])

  const [extendedData, setExtendedData] = useState<boolean>(false);
  const [externalAiResponse, setExternalAiResponse] = useState<boolean>(false);
  const [internalAiResponse, setInternalAiResponse] = useState<boolean>(false);

  const [mode, setMode] = useState<string>("Todos");

  const getMode = (mode: string): string | undefined => {
    if (mode === "Todos") {
      return undefined;
    }
    return mode;
  };

  const location = useLocation();
  const state = location.state as LocationProps;
  const params = new URLSearchParams(location.search);
  const [companyParam, setCompanyParam] = useState<string | null>(
    params.get("company")
  );
  const companyToSelect = state && state.companyToSelect;
  const [companyId, setCompanyId] = useState<string>(
    companyToSelect ? companyToSelect.toString() : selectedCompany.toString()
  );

  //--------------------------------------------------------------------------------------//
  //                                      Api calls                                       //
  //--------------------------------------------------------------------------------------//

  const { useCmsReadDeviceViewer, useSankeyGraph, logout } = useApi();

  const { getData } = useSankeyGraph(processError(logout, history));
  const {
    response: readDevicesResponse,
    getDebugImage,
    getImageAndMeasurements,
    getImage,
  }: IUseCmsReadDeviceViewer = useCmsReadDeviceViewer(
    pagination,
    +companyId,
    dateRange,
    readDeviceRange,
    device,
    translated,
    removed,
    getMode(mode),
    pendingTranslation,
    pendingConfirm,
    confirmed,
    extendedData,
    true
  );

  const readDevices = readDevicesResponse?.data;
  const readDevicesPaginationInfo = readDevicesResponse?.meta;

  const readDevicesList =
    // loading || !readDevices ? (
    loading ? (
      <CircularProgress
        style={{
          justifyContent: "center",
          display: "flex",
          width: "100%",
          marginTop: 50,
        }}
      />
    ) : (
      readDevices && (
        <ReadDeviceViewerList
            readDevices={readDevices}
            onPageChange={setPage}
            onPageSizeChange={setPageSize}
            paginationInfo={readDevicesPaginationInfo}
            sortBy={sortBy}
            onSortChange={setSortBy}
            getDebugImage={getDebugImage}
            getImageAndMeasurements={getImageAndMeasurements}
            extendedData={extendedData}
            externalAiResponse={externalAiResponse}
            internalAiResponse={internalAiResponse}
            getImage={getImage} 
            getSankeyData={getData}          
        />
      )
    );

  //--------------------------------------------------------------------------------------//
  //                               Use effect declarations                                //
  //--------------------------------------------------------------------------------------//

  useEffect(() => {
    setLoading(true);
    setPagination({
      page: page,
      limit: pageSize,
      sortBy: sortBy,
    });
  }, [page, pageSize, sortBy]);

  useEffect(() => {
    setLoading(false);
  }, [readDevices]);

  useEffect(() => {
    if (date[0] && date[1]) {
      setDateRange({
        start: moment(date[0])
          .startOf("day")
          .utc()
          .format("YYYY-MM-DD HH:mm:ss"),
        end: moment(date[1]).endOf("day").utc().format("YYYY-MM-DD HH:mm:ss"),
      });
    } else {
      setDateRange(undefined);
    }
  }, [date]);

  useEffect(() => {
    if (readDeviceStart && readDeviceEnd) {
      setReadDeviceRange({ start: readDeviceStart, end: readDeviceEnd });
    } else {
      setReadDeviceRange(undefined);
    }
  }, [readDeviceStart, readDeviceEnd]);

  useEffect(() => {
    setPage(INITIAL_PAGINATION.page);
  }, [
    dateRange,
    date,
    readDeviceRange,
    device,
    translated,
    removed,
    pendingTranslation,
    pendingConfirm,
    confirmed,
  ]);

  const debouncedSetSearch = useCallback(debounce(setReadDeviceEnd, 1000), []);

  const handleDeviceEnd = (readDeviceEnd: number) => {
    debouncedSetSearch(readDeviceEnd);
  };

  return (
    <Page title={"Read device viewer"}>
      <Grid
        container
        spacing={2}
        alignItems="center"
        style={{ justifyContent: "center", marginBottom: 20 }}
      >
        <Grid item>
          <DateRangePicker dateRangePicked={date} setDateRange={setDate} />
        </Grid>
        <Grid item>
          <TextField
            id="fromReadDevice"
            label="Desde readDeviceId"
            type="number"
            onChange={(event) =>
              setReadDeviceStart(event.target.value as unknown as number)
            }
            InputLabelProps={{
              shrink: true,
            }}
          />
        </Grid>
        <Grid item>
          <TextField
            id="toReadDevice"
            label="Hasta readDeviceId"
            type="number"
            onChange={(event) =>
              handleDeviceEnd(event.target.value as unknown as number)
            }
            InputLabelProps={{
              shrink: true,
            }}
          />
        </Grid>
        <Grid item>
          <SelectDevice
            deviceId={device}
            setDeviceId={setDevice}
            whiteBg={false}
          />
        </Grid>
        <Grid item>
          <SelectCompany
            companyId={companyId}
            companyParam={companyParam}
            setCompanyId={setCompanyId}
          />
        </Grid>
        <Grid item>
          <SelectStatus
            statusName={statusName}
            setStatusName={setStatusName}
          />
        </Grid>
        <Grid item>
          <InputLabel id="select-mode">Modo</InputLabel>
          <Select
            labelId="select-mode"
            id="select-mode"
            label="Modo"
            value={mode}
            onChange={(event) => setMode(event.target.value as string)}
            style={{ width: 100 }}
          >
            <MenuItem key="all" value={"Todos"}>
              {"Todos"}
            </MenuItem>
            <MenuItem key="7s" value={Mode.SEVENSEGMENT}>
              {"Solo 7s"}
            </MenuItem>
            <MenuItem key="normal" value={Mode.NORMAL}>
              {"Solo normal"}
            </MenuItem>
          </Select>
        </Grid>
        <Grid item>
          <InputLabel id="display-extended-data">Mostrar más info</InputLabel>
          <Switch
            checked={extendedData}
            onChange={(event) => setExtendedData(event.target.checked)}
            inputProps={{ "aria-label": "controlled" }}
          />
        </Grid>
        <Grid item>
          <InputLabel id="display-external-ai-response">
            Mostrar respuesta IA externa
          </InputLabel>
          <Switch
            checked={externalAiResponse}
            onChange={(event) => setExternalAiResponse(event.target.checked)}
            inputProps={{ "aria-label": "controlled" }}
          />
        </Grid>
        <Grid item>
          <InputLabel id="display-external-ai-response">
            Mostrar respuesta IA interna
          </InputLabel>
          <Switch
            checked={internalAiResponse}
            onChange={(event) => setInternalAiResponse(event.target.checked)}
            inputProps={{ "aria-label": "controlled" }}
          />
        </Grid>
      </Grid>
      {readDevicesList}
    </Page>
  );
};

export { ReadDeviceViewer };
