import { ItemAlertConfigByFrequencyIndividualDto } from '../dto/Alerts/config/individual/AlertConfigByFrequencyIndividual';
import { ResponseApi, ResponseApiList, TypeOnError } from '../global'
import {
  CreateAction,
  IUseBase,
  useInstance
} from './IUseBaseElements'

export interface IUseAlertConfigsByFrequencyByPatient
  extends IUseBase<ResponseApiList<ItemAlertConfigByFrequencyIndividualDto>> {
    del: (id: number) => Promise<ResponseApi<boolean>>
}

export function useAlertConfigsByFrequencyByPatient(baseURL: string, token: string, patientId: number, query?: any, activeFilter?: string, shouldFetch?: boolean, onError?: TypeOnError): IUseAlertConfigsByFrequencyByPatient {

  let path = `/patients/${patientId}/alerts_config/frequency?limit=${query.limit}&page=${query.page}`

  if (activeFilter) path += `&activeFilter=${activeFilter}`
  
  const swrResponse: IUseBase<ResponseApiList<ItemAlertConfigByFrequencyIndividualDto>> = useInstance<ResponseApiList<ItemAlertConfigByFrequencyIndividualDto>>(
    baseURL,
    token,
    shouldFetch ? path : null,
    onError
  );

  const del = async (id: number): Promise<ResponseApi<boolean>> => {
    let path: string = `/alerts_config/frequency/${id}`;
    const action = CreateAction<ResponseApi<boolean>, boolean, ResponseApiList<ItemAlertConfigByFrequencyIndividualDto>>(
      baseURL,
      token,
      swrResponse.mutate,
      'delete',
      path,
      onError
    );
    return action();
  };

  return {
    ...swrResponse,
    del
  } as IUseAlertConfigsByFrequencyByPatient;

}
