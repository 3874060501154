import { Type } from "class-transformer";
import { ValidateNested } from "class-validator";
import { BasicItem, IBasicItem } from "../IItemList";
import {
  IGetSignResponseDto,
  ISignBasicDto,
  ISignDto,
  IUpdateSignRequestDto,
  IUpdateSignResponseDto,
} from "./ISign";

export class BasicDeviceDto implements IBasicDeviceDto {
  id: number = 0;
  name: string = "";
  typeName: string = "";
  form?: string;
  translations?: string;
  visible?: boolean;
}

export interface IBasicDeviceDto extends IBasicItem {
  typeName: string;
  form?: string;
  translations?: string;
  visible?: boolean;
}

export enum SignType {
  number = "number",
  text = "text",
  image = "image",
  radio = "radio",
  multiRadio = "multiRadio",
  device = "device",
  time = "time",
}
export class SignBasicDto extends BasicItem implements ISignBasicDto {
  unit: string = "";
  typeSign: SignType = SignType.number;
  translations?: string;
}

export class SignDto extends SignBasicDto implements ISignDto {
  decimals: number = 0;
  defaultMax?: number;
  defaultMin?: number;
  max: number = 0;
  min: number = 0;
  name: string = "";
  device: BasicDeviceDto = new BasicDeviceDto();
}

export class SignFormDto extends SignBasicDto {
  decimals: number = 0;
  defaultMax: number = 0;
  defaultMin: number = 0;
  max: number = 0;
  min: number = 0;
  name: string = "";
}

/** UPDDATE */

export class GetSignResponseDto implements IGetSignResponseDto {
  id: number = 0;
  name: string = "";
  unit: string = "";
  typeSign: SignType = SignType.number;
  decimals: number = 0;
  defaultMax?: number;
  defaultMin?: number;
  max: number = 0;
  min: number = 0;
  @ValidateNested()
  @Type(() => BasicDeviceDto)
  device: BasicDeviceDto = new BasicDeviceDto();
}

export class UpdateSignRequestDto implements IUpdateSignRequestDto {
  signId: number = 0;
  defaultMax?: number;
  defaultMin?: number;
}

export class UpdateSignResponseDto implements IUpdateSignResponseDto {
  id: number = 0;
  name: string = "";
  unit: string = "";
  typeSign: SignType = SignType.number;
  decimals: number = 0;
  defaultMax?: number;
  defaultMin?: number;

  @ValidateNested()
  device: { id: number; name: string } = { id: 0, name: "" };
}
