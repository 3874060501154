import React, { useState, useEffect } from "react";
import { Page } from "../Page";
import { useApi } from "@doctomatic/sdk/build/Api";
import { Box } from "@material-ui/core";
import { StatusIndicator } from "./StatusIndicator";
import { GetStatusResponseDto } from "@doctomatic/sdk/build/dto/Status";
import { processError } from "../../../App/errorToast";
import { useNavigate } from "react-router-dom";

const Status = (): React.ReactElement => {
  const history = useNavigate();
  const { useStatus, logout } = useApi();
  const { response: statusResponse } = useStatus(true, true, processError(logout, history));
  const [status, setStatus] = useState<GetStatusResponseDto | undefined>((statusResponse?.data) as GetStatusResponseDto ?? undefined)

  useEffect(() => {
    if (statusResponse && statusResponse.data) {
      setStatus(statusResponse.data as GetStatusResponseDto)
    }
  }, [statusResponse]);

  if (!status) return <></>;

  return (
    <Page title={"API services status"} >
      <Box display='flex' justifyContent='center' >
        <Box width='fit-content' borderRadius={10} padding={4} bgcolor='gray'>
          <StatusIndicator serviceName={'AIMatic eco'} isActive={status.aiMaticStatus} />
          <StatusIndicator serviceName={'AIMatic S3'} isActive={status.aiMaticS3Status} />
          <StatusIndicator serviceName={'DB SQL'} isActive={status.dbSqlStatus} />
          <StatusIndicator serviceName={'DB MongoDB'} isActive={status.dbMongoDbStatus} />
          <StatusIndicator serviceName={'S3'} isActive={status.storageStatus} />
          <StatusIndicator serviceName={'Stripe'} isActive={status.stripeStatus} />
        </Box>
      </Box>
    </Page>
  );
};

export { Status };
