import React from "react";
import { useNavigate } from "react-router-dom";
import useStyles from "./styles";
import {
  Box,
  Button,
  Typography,
  Container
} from "@material-ui/core";
import './errorPage.css'

const ErrorPage = (): React.ReactElement => {
  const classes = useStyles();
  const history = useNavigate();

  const handleReturn = async() => {
    history('/', { replace: true })
    
  }
  return (
    <Container component="main" maxWidth="sm">
      <div className={classes.paper}>
        <Box sx={{ width: "100%"}}>
          <Typography variant="h1" component="div" gutterBottom>
            Oops!
          </Typography>
          <Typography variant="h2" component="div" gutterBottom>
            Parece que algo no ha ido bien
          </Typography>
          
          <Button size='small' variant='contained' onClick={handleReturn}>Volver al inicio</Button>
        </Box>
      </div>
    </Container>
  );
};

export { ErrorPage };
