import React from "react";
import {
  EditorState,
  convertToRaw,
  convertFromRaw,
} from "draft-js";
import { draftToMarkdown, markdownToDraft } from "markdown-draft-js";
import { Box, Button } from "@material-ui/core";
import { Editor } from "react-draft-wysiwyg";
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import './legal.css'
import { Placeholders } from './CustomToolbar'

interface DocumentEditorProps {
  md: string;
  onSave: (md: string) => void;
  contract: boolean;
}

const DocumentEditor = ({ md, onSave, contract }: DocumentEditorProps): React.ReactElement => {
  
  const [editorState, setEditorState] = React.useState<EditorState>(EditorState.createWithContent(convertFromRaw(markdownToDraft(md))));
  
  const handleClick = () => {
    const md = draftToMarkdown(convertToRaw(editorState.getCurrentContent()));
    onSave(md);
  };

  return (
    <div id='md-editor'>
      {/* @ts-ignore */}
      <Editor
        editorState={editorState}
        wrapperClassName="demo-wrapper"
        editorClassName="demo-editor"
        onEditorStateChange={setEditorState}
        toolbarCustomButtons={contract? [] : [<Placeholders onChange={setEditorState} editorState={editorState} />]}
        toolbar={{
          placeholders: { component: Placeholders},
          options: [
            "inline",
            "blockType",
            "fontSize",
            "list",
            "textAlign",
            "link",
            "history",
          ],
          inline: {
            inDropdown: false,
            className: undefined,
            component: undefined,
            dropdownClassName: undefined,
            options: ["bold", "italic", "underline"],
            
          },
          
        }}
      />
      <Box mt={3} display="flex" justifyContent="flex-end">
        <Button variant="contained" color="default" onClick={handleClick}>
          Guardar
        </Button>
      </Box>
    </div>
  );
};

export { DocumentEditor };
