import type {
  AddressType,
  CreateAddressRequestDto,
  CreateAddressResponseDto,
  UpdateAddressRequestDto,
  UpdateAddressResponseDto,
  GetAddressSubscriptionResponseDto
} from '../dto/Addresses'
import Fetcher from './Fetcher'
import { ResponseApi, TypeOnError } from '../global'
import {
  CreateActionWithEndpoint,
  IUseBase,
  useInstance
} from './IUseBaseElements'
export interface IUseAddresses
  extends IUseBase<ResponseApi<GetAddressSubscriptionResponseDto>> {
  patch: (id: number, dto: UpdateAddressRequestDto) => Promise<ResponseApi<UpdateAddressResponseDto>>,
  add: (dto: CreateAddressRequestDto) => Promise<ResponseApi<CreateAddressResponseDto>>,
  del: (id: number) => Promise<void>,
}

export function useAddresses(
  baseURL: string, token: string, addressType: AddressType, isDefault: boolean, isActive: boolean, shouldFetch?: boolean, onError?: TypeOnError
): IUseAddresses {

  const swrResponse: IUseBase<ResponseApi<GetAddressSubscriptionResponseDto>> = useInstance<ResponseApi<GetAddressSubscriptionResponseDto>>(
    baseURL,
    token,
    shouldFetch ? `/addresses?addressType=${addressType}&isDefault=${isDefault}&isActive=${isActive}` : null,
    onError
  );

  const patch = async (id: number, dto: UpdateAddressRequestDto): Promise<ResponseApi<UpdateAddressResponseDto>> => {
    let path: string = `/addresses/${id}`;
    const action = CreateActionWithEndpoint<ResponseApi<UpdateAddressResponseDto>, UpdateAddressRequestDto, ResponseApi<GetAddressSubscriptionResponseDto>>(
      baseURL,
      token,
      swrResponse.mutate,
      'patch',
      onError
    );
    return action(path, dto);
  };

  const add = async (dto: CreateAddressRequestDto): Promise<ResponseApi<CreateAddressResponseDto>> => {
    const response = await Fetcher<ResponseApi<CreateAddressResponseDto>, CreateAddressRequestDto>(baseURL, token)('/address', 'post', dto)
    return response
  }

  const del = async (id: number): Promise<void> => {
    const response = await Fetcher<void>(baseURL, token)(`/addresses/${id}`, 'delete')
    return response
  }

  return {
    ...swrResponse,
    patch, add, del
  } as IUseAddresses;
}
