import {
  GetCompanyResponseDto,
  GetSubscriptionsInfoResponseDto,
  UpdateCompanyRequestDto,
  UpdateCompanyResponseDto
} from '../dto/Company'
import { ResponseApi, TypeOnError } from '../global'
import { CreateAction, CreateActionWithEndpoint, IUseBase, useInstance } from './IUseBaseElements'

export interface IUseCompany
  extends IUseBase<ResponseApi<GetCompanyResponseDto>> {
  update: (user: UpdateCompanyRequestDto) => Promise<ResponseApi<UpdateCompanyResponseDto>>,
  getSubscriptionsInfo: () => Promise<ResponseApi<GetSubscriptionsInfoResponseDto>>
}

export function useCompany(baseURL: string, token: string, companyId: number, shouldFetch?: boolean, onError?: TypeOnError): IUseCompany {

  const swrResponse: IUseBase<ResponseApi<GetCompanyResponseDto>> = useInstance<ResponseApi<GetCompanyResponseDto>>(
    baseURL,
    token,
    shouldFetch ? `/companies/${companyId}` : null,
    onError
  );


  const update = async (company: UpdateCompanyRequestDto): Promise<ResponseApi<UpdateCompanyResponseDto>> => {

    let path: string = `/companies/${companyId}`;
    const action = CreateActionWithEndpoint<ResponseApi<UpdateCompanyResponseDto>,
      UpdateCompanyRequestDto,
      ResponseApi<GetCompanyResponseDto>>(
        baseURL,
        token,
        swrResponse.mutate,
        'patch',
        onError
      );
    return action(path, company);

  }

  const getSubscriptionsInfo = async (): Promise<ResponseApi<GetSubscriptionsInfoResponseDto>> => {
    let path: string = `/companies/${companyId}/subscriptions`;
    const action = CreateAction<ResponseApi<GetSubscriptionsInfoResponseDto>,
      void,
      ResponseApi<GetCompanyResponseDto>>(
        baseURL,
        token,
        swrResponse.mutate,
        'get',
        path,
        onError
      );
    return action();

  }

  return { ...swrResponse, update, getSubscriptionsInfo } as IUseCompany
}
